import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { TextArea } from "../TextArea";

type FormTextAreaProps<TFieldValues extends FieldValues> = {
	control: Control<TFieldValues, any, any>;
	name: FieldPath<TFieldValues>;
	className?: string;
	readonly?: boolean;
	placeholder?: string;
};

export const FormTextArea = <TFieldValues extends FieldValues>(
	props: FormTextAreaProps<TFieldValues>,
) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => (
				<TextArea
					{...field}
					readonly={props.readonly}
					error={fieldState.error}
					className={props.className}
					placeholder={props.placeholder}
				/>
			)}
		/>
	);
};

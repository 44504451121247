import { Controller, FieldValues } from "react-hook-form";
import { FormSchemaInclude, FormSchemaIncludeProps } from "../../layout/form/FormSchemaInclude";
import { CurrencyLineItem } from "../CurrencyLineItem";
import { FormInputProps } from "./FormInput";

type FormCurrencyLineItemProps<TFieldValues extends FieldValues> = FormInputProps<TFieldValues> &
	FormSchemaIncludeProps & {
		label: string;
		marker?: boolean;
		className?: string;
		id?: string;
		tooltip?: string;
		readonly?: boolean;
	};

export const FormCurrencyLineItem = <TFieldValues extends FieldValues>({
	name,
	control,
	label,
	marker,
	id,
	tooltip,
	readonly,
	...props
}: FormCurrencyLineItemProps<TFieldValues>) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<CurrencyLineItem
					label={label}
					marker={marker}
					tooltip={tooltip}
					{...field}
					error={fieldState.error}
					errorMessage={props.errorMessage}
					readonly={readonly}
					id={id}
				/>
			)}
		/>
	</FormSchemaInclude>
);

import { ContractSuretyType, FileExtension, ValidAddress } from "@inrev/common";
import { DateTime } from "luxon";
import { omit } from "rambda";
import { useEffect, useRef, useState } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { v4 } from "uuid";
import { RawCreateParams, ZodDiscriminatedUnionOption, z } from "zod";

export const logProps = (props: Record<string, any>) =>
	Object.keys(props).forEach((key) => console.log(`${key}: `, props[key]));

export const contractSuretyTypeToString = (contractSuretyType: ContractSuretyType): string => {
	return match(contractSuretyType)
		.with("bid", () => "Bid")
		.with("bid_to_final", () => "Bid To Final")
		.with("final", () => "Final")
		.otherwise(() => "--");
};

export function useIsFirstRender() {
	const renderRef = useRef(true);

	if (renderRef.current === true) {
		renderRef.current = false;
		return true;
	}

	return renderRef.current;
}

export function formatEmpty(value: any, defaultValue?: string) {
	if (value === "" || value === undefined || value === null) {
		return defaultValue ?? "--";
	} else {
		return value;
	}
}

export function formatFromISODate(isoDate?: string) {
	if (isoDate !== undefined) {
		return DateTime.fromISO(isoDate).toFormat("MM/dd/yyyy");
	}
}

export const nonEmptyOr = <T extends any>(value: "" | T, replacer: T) => {
	if (value !== "") return value;
	else return replacer;
};

export const formatAddress = (address: Partial<ValidAddress> | string) => {
	if (typeof address === "string") return address;
	let addressString = "";
	if (address.street !== undefined && address.street.length) addressString += `${address.street} `;
	if (address.city !== undefined && address.city.length) addressString += `${address.city}, `;
	if (address.state !== undefined && address.state.length) addressString += `${address.state} `;
	if (address.zip !== undefined && address.zip.length) addressString += address.zip;
	return addressString;
};

export const formatName = (name: {
	firstName: string;
	middleInitial?: string | null;
	lastName: string;
	suffix?: string | null;
}) => {
	return `${name.firstName}${name.middleInitial ? ` ${name.middleInitial}` : ""} ${name.lastName}${name.suffix ? ` ${name.suffix}` : ""}`;
};

export const formatNameOption = (
	name?: {
		firstName: string;
		middleInitial?: string | null;
		lastName: string;
		suffix?: string | null;
	} | null,
) => {
	return (name && formatName(name)) || undefined;
};

export const formatFileName = (unformatted: string) => {
	let formatted = unformatted.replace(/[^a-zA-Z\d\s.]/g, "");
	formatted = formatted.replace(/[\s.]/g, "_");
	return formatted;
};

export const checkLocalStorageAvailable = () => {
	try {
		const testValue = "__localStorageTest__";
		window.localStorage.setItem(testValue, testValue);
		window.localStorage.removeItem(testValue);
		return true;
	} catch (err: unknown) {
		console.log(`Local storage unavailable: ${err}`);
		return false;
	}
};

export const asyncSleep = async (milliseconds: number): Promise<void> => {
	return new Promise((res) => {
		setTimeout(() => {
			res();
		}, milliseconds);
	});
};

export const usePendingItems = <TPendingItem extends { id: string }>() => {
	const [pendingItems, _setPendingItems] = useState<TPendingItem[]>([]);

	const addPendingItem = (item: Omit<TPendingItem, "id"> & { id?: string }) => {
		_setPendingItems([...pendingItems, { ...item, id: item.id ?? v4() } as TPendingItem]);
	};

	const removePendingItem = (id: string) => {
		_setPendingItems(pendingItems.filter((item) => item.id !== id));
	};

	const setPendingItems = (items: (Omit<TPendingItem, "id"> & { id?: string })[]) => {
		_setPendingItems(items.map((item) => ({ ...item, id: item.id ?? v4() }) as TPendingItem));
	};

	const clearPendingItems = () => {
		setPendingItems([]);
	};

	return [
		pendingItems,
		addPendingItem,
		removePendingItem,
		setPendingItems,
		clearPendingItems,
	] as const;
};

export const useScrollToId = (block?: ScrollLogicalPosition) => {
	const domReadyRef = useRef(null);
	const [searchParams] = useSearchParams();
	const scrollToId = searchParams.get("st");

	useEffect(() => {
		if (domReadyRef !== null && scrollToId !== null) {
			document
				.getElementById(scrollToId)
				?.scrollIntoView({ block: block ?? "center", behavior: "smooth" });
		}
	}, [domReadyRef]);

	return domReadyRef;
};

export const convertControlToOnBlur = <TFieldData extends FieldValues>(
	control: Control<TFieldData>,
	name: FieldPath<TFieldData>,
) => {
	const { register, ..._control } = control;
	return {
		..._control,
		register: () => {
			const { onBlur, onChange, ..._register } = register<any>(name);
			return { ..._register, onBlur: onChange, onChange: async () => {} };
		},
	};
};

export const getNameAndExtensionFromFileName = (fileNameWithExtension: string) => {
	const nameChunks = fileNameWithExtension.split(".");
	const extension = nameChunks[nameChunks.length - 1] as FileExtension;
	const name = fileNameWithExtension.slice(0, fileNameWithExtension.length - extension.length - 1);
	return { fileName: name, extension };
};

export const formatNegativesAndLeadingZeros = (value?: string, precision?: number) => {
	if (!!!value || value === "") return value;
	if (value === ".") return "0.";
	// Fixes for error if user hits backspace on a single digit number
	if (value[0] === ".") return "0" + value;
	const decimalParts = value.split(".");
	// This will strip any leading zeros from the number but still retain the negative sign if it exists
	const beforeDecimalParsed = parseInt(decimalParts[0]).toString();
	let beforeDecimal = beforeDecimalParsed.match(/-{0,1}0{1}|-{0,1}[1-9]{1}[0-9]*|-{1}/)![0];
	if (decimalParts.length > 1) {
		return [beforeDecimal, decimalParts[1].slice(0, precision)].join(".");
	}
	return beforeDecimal;
};

export const boolToYN = (value: any) => (typeof value !== "boolean" ? value : value ? "Yes" : "No");

export const stripEmpty = (value: any) => {
	if (value === "") return undefined;
	else return value;
};

export const stripEmptySchema = (schema: z.ZodType<any, z.ZodTypeDef, any>) => {
	return z
		.any()
		.transform((val) => {
			return stripEmpty(val);
		})
		.pipe(schema);
};

export const stripEmptyKeys = (schema: z.ZodType<object> | z.ZodOptional<z.ZodType<object>>) => {
	return z
		.object({})
		.passthrough()
		.transform((val) => {
			const newVal: Record<string, any> = {};
			let allUndefined = true;

			Object.entries(val).map((entry) => {
				if (entry[1] !== "") {
					newVal[entry[0]] = entry[1];
					allUndefined = false;
				}
			});

			if (allUndefined) return undefined;
			else return newVal;
		})
		.pipe(schema);
};

export const strippedDiscriminatedUnion = <
	Discriminator extends string,
	Types extends [
		ZodDiscriminatedUnionOption<Discriminator>,
		...ZodDiscriminatedUnionOption<Discriminator>[],
	],
>(
	discriminator: Discriminator,
	options: Types,
	params?: RawCreateParams,
) => {
	const duSchema = z.discriminatedUnion(discriminator, options, params);
	return duSchema.transform<Omit<z.infer<typeof duSchema>, Discriminator>>((data) =>
		omit(discriminator, data),
	);
};

import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { PhoneNumberInput } from "../PhoneNumberInput";

type FormPhoneNumberInputProps<TFieldValues extends FieldValues> = {
	className?: string;
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	condensed?: true;
	readonly?: boolean;
};

export const FormPhoneNumberInput = <TFieldValues extends FieldValues>(
	props: FormPhoneNumberInputProps<TFieldValues>,
) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				const { value, ...fieldProps } = field;
				return <PhoneNumberInput {...props} {...fieldProps} error={fieldState.error} />;
			}}
		/>
	);
};

import { ChangeEvent, InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "readOnly" | "prefix"> & {
	condensed?: true;
	compact?: true;
	onChange?: (
		event: ChangeEvent<HTMLInputElement> & {
			target: ChangeEvent<HTMLInputElement>["target"] & {
				value: ChangeEvent<HTMLInputElement>["target"]["value"] | null;
			};
		},
	) => void;
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
	prefix?: ReactNode;
	postfix?: ReactNode;
	align?: "left" | "right";
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			type,
			condensed,
			compact,
			error,
			errorMessage,
			readonly,
			prefix,
			postfix,
			align,
			disabled,
			...props
		},
		ref,
	) => {
		return (
			<div
				className={cn(
					"relative flex w-full h-[50px] rounded-md outline outline-[1px] outline-offset-[-1px] text-gray-900 outline-gray-300 bg-gray-50 bg-opacity-70 text-[17px] leading-[20px] placeholder:text-gray-500 focus-within:outline-[2px] focus-within:outline-gray-600 focus-within:hover:outline-gray-600",
					disabled ? "cursor-default opacity-50" : "hover:outline-gray-500",
					condensed
						? "h-[46px] max-h-[46px] text-[16px] font-normal"
						: compact
							? "h-[38px] text-[15px] font-normal"
							: undefined,
					className,
					readonly ? "opacity-80" : undefined,
					error
						? "outline-red-500 hover:outline-red-500 focus-within:outline-red-500 focus-within:hover:outline-red-500 focus-within:outline-[2px]"
						: undefined,
				)}
			>
				{prefix && (
					<div
						className={cn(
							"h-full flex items-center rounded-l-md text-gray-700 bg-gray-100 border-r border-r-gray-300",
							condensed
								? "px-[15px] text-[15px]"
								: compact
									? "px-[14px] text-[13px]"
									: "px-[20px] text-[17px]",
							readonly ? "text-gray-500" : undefined,
						)}
					>
						{prefix}
					</div>
				)}
				<input
					type={type ?? "text"}
					disabled={readonly}
					tabIndex={0}
					className={cn(
						"px-[18px] py-[17px] w-full outline-none bg-transparent",
						align === "right" ? "text-right" : undefined,
						readonly ? "bg-gray-100 text-gray-800" : undefined,
					)}
					{...props}
					ref={ref}
					// Needed to disable browser auto-filling
					autoComplete="one-time-code"
					data-1p-ignore
					data-lpignore
				/>
				{postfix && (
					<div
						className={cn(
							"h-full flex items-center rounded-r-md text-gray-700 bg-gray-100 border-l border-l-gray-300",
							condensed
								? "px-[15px] text-[15px]"
								: compact
									? "px-[12px] text-[13px]"
									: "px-[20px] text-[17px]",
							readonly ? "text-gray-500" : undefined,
						)}
					>
						{postfix}
					</div>
				)}
				<FormError error={error} errorMessage={errorMessage} />
			</div>
		);
	},
);

import { Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { cn } from "../lib/utils";

type ModalProps = {
	onClickOutside?: () => void;
	children: ReactNode;
	itemClassName?: string;
};

export const Modal = ({ onClickOutside, children, itemClassName }: ModalProps) =>
	createPortal(
		<div
			onMouseDown={(e) => {
				e.stopPropagation();
				onClickOutside && onClickOutside();
			}}
			className="absolute bg-gray-950 bg-opacity-30 top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center z-[200000] overflow-auto"
		>
			<Transition
				appear={true}
				show={true}
				as={Fragment}
				enter="transition-opacity ease-in duration-[90ms]"
				enterFrom="opacity-50"
				enterTo="opacity-100"
				leave="transition-opacity ease-in duration-75"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<FocusLock autoFocus={false}>
					<div onMouseDown={(e) => e.stopPropagation()} className={cn("relative", itemClassName)}>
						{children}
					</div>
				</FocusLock>
			</Transition>
			{/* <div className="h-[5%] w-full"></div> */}
		</div>,
		document.body,
	);

import { z } from 'zod';
import { Upload as Upload$1, Approve as Approve$1 } from './contract/contract-surety-bond-form.dto.mjs';
import * as contractSuretyBondForm_dto from './contract/contract-surety-bond-form.dto.mjs';
export { contractSuretyBondForm_dto as Contract };
import { zswitch } from '../../../../types/utils.mjs';
import * as adminSubmittedSuretyBondForm_dto from './admin-submitted-surety-bond-form.dto.mjs';
export { adminSubmittedSuretyBondForm_dto as Submitted };
import * as adminIssuedSuretyBondForm_dto from './admin-issued-surety-bond-form.dto.mjs';
export { adminIssuedSuretyBondForm_dto as Issued };

var Upload;
(function (Upload) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = zswitch(input => {
                switch (input.suretyType) {
                    case "contract": return Upload$1.AgentIssue.Request.schema;
                    default: return z.never();
                }
            });
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Upload.AgentIssue || (Upload.AgentIssue = {}));
    (function (PhysicalIssue) {
        (function (Request) {
            Request.schema = zswitch(input => {
                switch (input.suretyType) {
                    case "contract": return Upload$1.PhysicalIssue.Request.schema;
                    default: return z.never();
                }
            });
        })(PhysicalIssue.Request || (PhysicalIssue.Request = {}));
    })(Upload.PhysicalIssue || (Upload.PhysicalIssue = {}));
})(Upload || (Upload = {}));
var Approve;
(function (Approve) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = zswitch(input => {
                switch (input.suretyType) {
                    case "contract": return Approve$1.AgentIssue.Request.schema;
                    default: return z.never();
                }
            });
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Approve.AgentIssue || (Approve.AgentIssue = {}));
    (function (Request) {
        Request.schema = zswitch(input => {
            switch (input.suretyType) {
                case "contract": return Approve$1.Request.schema;
                default: return z.never();
            }
        });
    })(Approve.Request || (Approve.Request = {}));
})(Approve || (Approve = {}));

export { Approve, Upload };

import { z } from 'zod';
import { finalContractSuretyBondClosedReasonSchema, dateTimeSchema } from '../../../../enums.mjs';
import { currencySchema, zswitch } from '../../../utils.mjs';
import { Data as Data$1 } from './contract-surety-bond.types.mixins.mjs';
import { Underwriting } from '../../quote/submitted/contract/submitted-contract-surety-quote.types.mixins.mjs';

var Data;
(function (Data) {
    Data.schema = Data$1.baseSchema.extend({
        contractSuretyType: z.literal("final"),
        closedReason: finalContractSuretyBondClosedReasonSchema.nullish(),
        contractAmount: currencySchema,
        contractDate: dateTimeSchema,
        project: Underwriting.Project.schema,
        expectedCompletionDate: dateTimeSchema.nullish(),
        actualCompletionDate: dateTimeSchema.nullish(),
        actualContractAmount: currencySchema.nullish(),
    });
})(Data || (Data = {}));
var Close;
(function (Close) {
    (function (Payload) {
        Payload.baseSchema = z.object({
            suretyType: z.literal('contract'),
            contractSuretyType: z.literal("final"),
            closedReason: finalContractSuretyBondClosedReasonSchema,
            closedNote: z.string().optional(),
        });
        Payload.projectCompletedReasonsSchema = z.object({
            closedReason: finalContractSuretyBondClosedReasonSchema.extract(["project_completed"]),
            actualCompletionDate: dateTimeSchema,
            actualContractAmount: currencySchema,
        });
        Payload.allOtherReasonsSchema = z.object({
            closedReason: finalContractSuretyBondClosedReasonSchema.exclude(["project_completed"]),
        });
        Payload.schema = Payload.baseSchema.and(zswitch(input => {
            switch (input.closedReason) {
                case "project_completed":
                    return Payload.projectCompletedReasonsSchema;
                default:
                    return Payload.allOtherReasonsSchema;
            }
        }));
    })(Close.Payload || (Close.Payload = {}));
})(Close || (Close = {}));

export { Close, Data };

import { useState } from "react";
import { cn } from "../lib/utils";
import { Separator } from "../ui/Separator";
import { Modal } from "./Modal";
import { ModalItemWithHeader } from "./ModalItemWithHeader";

type MoreAccountDetailsLayoutProps = {
	children: React.ReactNode;
	className?: string;
};

type MoreAccountDetailsItemProps = {
	children: React.ReactNode;
	title: string;
};

export const MoreAccountDetailsLayout = ({
	children,
	className,
}: MoreAccountDetailsLayoutProps) => {
	const [open, setOpen] = useState(false);
	return (
		<div className={cn(className, "flex items-center space-x-[8px] pt-[6px]")}>
			<div className="flex items-center h-[16px] gap-x-[12px]" onClick={() => setOpen(true)}>
				<Separator orientation="vertical" className="bg-gray-200" />
				<span className="text-gray-600/90 font-[450] hover:text-gray-900 text-[13px] cursor-pointer">
					More Details
				</span>
			</div>
			{open && (
				<Modal onClickOutside={() => setOpen(false)}>
					<ModalItemWithHeader header="More Details" onClose={() => setOpen(false)}>
						<div className="min-w-fit max-h-[85vh] overflow-y-auto mt-[20px] text-gray-700 font-normal rounded-md bg-white shadow-lg z-[10] select-none px-6 pb-8">
							{children}
						</div>
					</ModalItemWithHeader>
				</Modal>
			)}
		</div>
	);
};

export const MoreAccountDetailsItem = ({ children, title }: MoreAccountDetailsItemProps) => {
	return (
		<div className="h-min">
			<div className="sticky top-0 z-50 bg-white w-full pb-[13px] text-[13.5px] text-gray-900">
				{title}
			</div>
			{children}
		</div>
	);
};

import { z } from 'zod';

var Vendor;
(function (Vendor) {
    Vendor.reportSchema = z.object({
        frozen: z.boolean().nullish(),
        bankruptcy: z.boolean().nullish(),
        score: z.number().nullish(),
    });
})(Vendor || (Vendor = {}));
var OFAC;
(function (OFAC) {
    (function (Vendor) {
        Vendor.reportSchema = z.object({
            hit: z.boolean()
        });
    })(OFAC.Vendor || (OFAC.Vendor = {}));
})(OFAC || (OFAC = {}));

export { OFAC, Vendor };

import { Dtos } from "@inrev/common";
import { z } from "zod";
import { BondRequest } from "./types";

export const getBondIssuanceFormValidationSchema = (
	request: BondRequest & Extract<BondRequest, { status: "accepted" }>,
) => {
	const bondFormBaseSchema = Dtos.SuretyBondIssuance.Contract.Create.Request.schema.refine((data) =>
		request.bondIssuance.bondForm.allowedIssuanceMethods.includes(data.issuanceMethod),
	);
	const indemnityAgreementSchema = Dtos.IndemnityAgreement.Create.Request.schema.refine((data) => {
		return (
			request.bondIssuance.indemnityAgreement.allowedSignatureMethods.includes(
				data.signatureMethod,
			) &&
			request.bondIssuance.indemnityAgreement.indemnitors.companies.length ===
				data.companySigners.length &&
			data.companySigners.every((signer) =>
				request.bondIssuance.indemnityAgreement.indemnitors.companies.some(
					(company) => company.id === signer.contactId,
				),
			) &&
			request.bondIssuance.indemnityAgreement.indemnitors.individuals.length ===
				data.individualSigners.length &&
			data.individualSigners.every((signer) =>
				request.bondIssuance.indemnityAgreement.indemnitors.individuals.some(
					(individual) => individual.id === signer.contactId,
				),
			)
		);
	});
	const bondFormSchema = bondFormBaseSchema;

	if (request.bondIssuance.indemnityAgreement.status === "incomplete") {
		return z.object({ bondForm: bondFormSchema, indemnityAgreement: indemnityAgreementSchema });
	} else {
		return z.object({ bondForm: bondFormSchema });
	}
};

export const getBondRequestDataValidationSchema =
	Dtos.SuretyQuote.Contract.Submit.Request.Data.buildDataValidationSchema;

// export const getBondRequestDataValidationSchema = (formSchema: BondRequest["draft"]["schema"]) => z.object({
//     ...sectionSchemaIncludes({
//         principal: z.union([
//             z.custom<BondRequestDraftData["principal"]>().pipe(
//                 z.object({
//                     newPrincipal: z.literal(true),
//                     company: z.object({
//                         draftId: stripEmptySchema(z.string().uuid()),
//                         name: stripEmptySchema(z.string().trim()),
//                         fein: stripEmptySchema(z.string().trim().regex(/^[0-9]*$/).optional()),
//                         address: addressValidationTransformSchema
//                     }),
//                     companies: z.array(
//                         z.object({
//                             draftId: stripEmptySchema(z.string().uuid()),
//                             name: stripEmptySchema(z.string().trim()),
//                             fein: stripEmptySchema(z.string().trim().regex(/^[0-9]*$/).optional()),
//                             address: addressValidationTransformSchema
//                         })
//                     ),
//                     individuals: z.array(
//                         z.discriminatedUnion("type", [
//                             accountIndividualOwnerSchema,
//                             z.object({ type: z.literal("spouse") })
//                             .merge(accountIndividualOwnerSchema.omit({ type: true, ssn: true, creditReportPermission: true }))
//                         ])
//                     ),
//                     ...sectionSchemaIncludes({
//                         creditReportPermission: z.array(z.object({
//                             id: z.string().uuid(),
//                             permission: z.literal(true)
//                         }))
//                     }, formSchema, "principal", "creditReportPermission")
//                 }).superRefine((val, ctx) => {
//                     if (val.newPrincipal && val.companies.length === 0 && val.individuals.length === 0) {
//                         ctx.addIssue({
//                             code: ZodIssueCode.custom,
//                             path: ['companies'],
//                             message: 'Please include at least one individual owner, company owner, subsidiary, or affiliate.'
//                         });
//                         ctx.addIssue({
//                             code: ZodIssueCode.custom,
//                             path: ['individuals'],
//                             message: 'Please include at least one individual owner, company owner, subsidiary, or affiliate.'
//                         });
//                     }
//                 }),
//             ),
//             z.custom<BondRequestDraftData["principal"]>().pipe(
//                 z.object({
//                     newPrincipal: z.literal(false),
//                     principalCompanyId: stripEmptySchema(z.string().uuid()),
//                     ...sectionSchemaIncludes({
//                         creditReportPermission: z.array(z.object({
//                             id: z.string().uuid(),
//                             permission: z.literal(true)
//                         }))
//                     }, formSchema, "principal", "creditReportPermission")
//                 })
//             )
//         ])
//     }, formSchema, "principal"),
//     ...sectionSchemaIncludes({
//         history: z.object({
//             ...sectionSchemaIncludes({
//                 naicsCode: stripEmptySchema(z.string())
//             }, formSchema, "history", "naicsCode"),
//             ...sectionSchemaIncludes({
//                 largestProjectCompleted: stripEmptySchema(stringToNumberSchema)
//             }, formSchema, "history", "largestProjectCompleted"),
//             ...sectionSchemaIncludes({
//                 failedToCompleteAProject: stripEmptySchema(z.boolean())
//             }, formSchema, "history", "failedToCompleteAProject"),
//             ...sectionSchemaIncludes({
//                 constructionProjectGrossLoss: stripEmptySchema(z.boolean())
//             }, formSchema, "history", "constructionProjectGrossLoss"),
//             ...sectionSchemaIncludes({
//                 currentConstructionProjectGrossLoss: stripEmptySchema(z.boolean())
//             }, formSchema, "history", "currentConstructionProjectGrossLoss"),
//             ...sectionSchemaIncludes({
//                 lostAPaymentSuit: stripEmptySchema(z.boolean())
//             }, formSchema, "history", "lostAPaymentSuit"),
//             ...sectionSchemaIncludes({
//                 causedSuretyLoss: stripEmptySchema(z.boolean())
//             }, formSchema, "history", "causedSuretyLoss")
//         })
//     }, formSchema, "history"),
//     ...sectionSchemaIncludes({
//         financials: z.object({
//             ...sectionSchemaIncludes({
//                 companyDraftId: stripEmptySchema(z.string().uuid())
//             }, formSchema, "financials", "companyDraftId"),
//             ...sectionSchemaIncludes({
//                 statementDate: stripEmptySchema(isoDateSchema)
//             }, formSchema, "financials", "statementDate"),
//             ...sectionSchemaIncludes({
//                 preparationMethod: stripEmptySchema(z.enum(["internal", "cpa_compilation", "cpa_review", "cpa_audit", "does_not_keep"]))
//             }, formSchema, "financials", "preparationMethod"),
//             ...sectionSchemaIncludes({
//                 balanceSheet: z.object({
//                     ...sectionSchemaIncludes({
//                         corporateCash: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.corporateCash"),
//                     ...sectionSchemaIncludes({
//                         accountsReceivable: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.accountsReceivable"),
//                     ...sectionSchemaIncludes({
//                         underbillings: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.underbillings"),
//                     ...sectionSchemaIncludes({
//                         currentAssets: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.currentAssets"),
//                     ...sectionSchemaIncludes({
//                         totalAssets: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.totalAssets"),
//                     ...sectionSchemaIncludes({
//                         accountsPayable: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.accountsPayable"),
//                     ...sectionSchemaIncludes({
//                         creditCardsPayable: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.creditCardsPayable"),
//                     ...sectionSchemaIncludes({
//                         bankLinePayable: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.bankLinePayable"),
//                     ...sectionSchemaIncludes({
//                         accruedExpenses: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.accruedExpenses"),
//                     ...sectionSchemaIncludes({
//                         overbillings: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.overbillings"),
//                     ...sectionSchemaIncludes({
//                         currentPortionOfLtd: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.currentPortionOfLtd"),
//                     ...sectionSchemaIncludes({
//                         currentLiabilities: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.currentLiabilities"),
//                     ...sectionSchemaIncludes({
//                         termLoanDebt: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.termLoanDebt"),
//                     ...sectionSchemaIncludes({
//                         totalLiabilities: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "balanceSheet.totalLiabilities"),
//                 })
//             }, formSchema, "financials", "balanceSheet"),
//             ...sectionSchemaIncludes({
//                 incomeStatement: z.object({
//                     ...sectionSchemaIncludes({
//                         revenue: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "incomeStatement.revenue"),
//                     ...sectionSchemaIncludes({
//                         gaExpense: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "incomeStatement.gaExpense"),
//                     ...sectionSchemaIncludes({
//                         profitableYtd: stripEmptySchema(z.boolean())
//                     }, formSchema, "financials", "incomeStatement.profitableYtd"),
//                     ...sectionSchemaIncludes({
//                         profitableLastFye: stripEmptySchema(z.boolean())
//                     }, formSchema, "financials", "incomeStatement.profitableLastFye"),
//                     ...sectionSchemaIncludes({
//                         profitableYearPriorToLastFiscalYear: stripEmptySchema(z.boolean())
//                     }, formSchema, "financials", "incomeStatement.profitableYearPriorToLastFiscalYear"),
//                 })
//             }, formSchema, "financials", "incomeStatement"),
//             ...sectionSchemaIncludes({
//                 wip: z.object({
//                     ...sectionSchemaIncludes({
//                         projectBacklogCost: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "wip.projectBacklogCost"),
//                     ...sectionSchemaIncludes({
//                         projectBacklogGrossProfit: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "wip.projectBacklogGrossProfit"),
//                 })
//             }, formSchema, "financials", "wip"),
//             ...sectionSchemaIncludes({
//                 bankDetails: z.object({
//                     ...sectionSchemaIncludes({
//                         blocSize: stripEmptySchema(stringToNumberSchema)
//                     }, formSchema, "financials", "bankDetails.blocSize"),
//                 })
//             }, formSchema, "financials", "wip"),
//             ...sectionSchemaIncludes({
//                 files: uploadedFilesSchema([FileType.fye_company_balanceSheet, FileType.fye_company_incomeStatement], [FileType.fye_company_balanceSheet, FileType.fye_company_incomeStatement])
//             }, formSchema, "financials", "files")
//         })
//     }, formSchema, "financials"),
//     ...sectionSchemaIncludes({
//         bond: z.object({
//             type: stripEmptySchema(z.nativeEnum(ContractSuretyType)),
//             ...sectionSchemaIncludes({
//                 bondAmount: stripEmptySchema(stringToNumberSchema)
//             }, formSchema, "bond", "bondAmount"),
//             ...sectionSchemaIncludes({
//                 bidAmount: stripEmptySchema(stringToNumberSchema)
//             }, formSchema, "bond", "bidAmount"),
//             ...sectionSchemaIncludes({
//                 bidAmount: z.enum(["percent", "dollars"])
//             }, formSchema, "bond", "bidBondAmountType"),
//             ...sectionSchemaIncludes({
//                 contractAmount: stripEmptySchema(stringToNumberSchema)
//             }, formSchema, "bond", "contractAmount"),
//             ...sectionSchemaIncludes({
//                 tListingRequired: stripEmptySchema(z.boolean())
//             }, formSchema, "bond", "tListingRequired"),
//             ...sectionSchemaIncludes({
//                 project: z.object({
//                     ...sectionSchemaIncludes({
//                         description: stripEmptySchema(z.string().trim())
//                     }, formSchema, "bond", "project.description"),
//                     ...sectionSchemaIncludes({
//                         city: stripEmptySchema(z.string())
//                     }, formSchema, "bond", "project.city"),
//                     ...sectionSchemaIncludes({
//                         state: stripEmptySchema(z.string())
//                     }, formSchema, "bond", "project.state"),
//                     ...sectionSchemaIncludes({
//                         zip: stripEmptySchema(numberToStringSchema(5))
//                     }, formSchema, "bond", "project.zip"),
//                     ...sectionSchemaIncludes({
//                         scopeOfWork: stripEmptySchema(z.string())
//                     }, formSchema, "bond", "project.scopeOfWork"),
//                     ...sectionSchemaIncludes({
//                         completionBond: stripEmptySchema(z.boolean())
//                     }, formSchema, "bond", "completionBond"),
//                     ...sectionSchemaIncludes({
//                         contractDamages: z.array(z.string())
//                     }, formSchema, "bond", "project.contractDamages"),
//                     ...sectionSchemaIncludes({
//                         contractHazards: z.array(z.string())
//                     }, formSchema, "bond", "project.contractHazards"),
//                     ...sectionSchemaIncludes({
//                         files: uploadedFilesSchema([FileType.contract], [FileType.contract])
//                     }, formSchema, "bond", "project.files")
//                 })
//             }, formSchema, "bond", "project"),
//             bondForm: bondFormSchema
//         }).refine(val => {
//             if ("upload" in val.bondForm && val.bondForm.upload) {
//                 if (val.type === "bid" && !(val.bondForm.upload.type === "bid")) {
//                     return false;
//                 } else if (val.type === "final" && !([BondType.pnp, BondType.performance, BondType.payment].includes(val.bondForm.upload.type as any))) {
//                     return false;
//                 } else {
//                     return true;
//                 }
//             } else {
//                 return true;
//             }
//         }),
//     }, formSchema, "bond"),
// });

// export const getBondRequestBondIssuanceDataValidationSchema = (formSchema: BondRequestBondIssuanceDraftSchema) => z.object({
//     ...schemaIncludes({
//         indemnityAgreement: z.object({
//             ...schemaIncludes({
//                 signatureMethod: stripEmptySchema(z.enum(["electronic", "physical"]))
//             }, formSchema, "indemnityAgreement.allowedSignatureMethods"),
//             ...schemaIncludes({
//                 company_indemnitors: z.array(z.object({
//                     id: z.string().uuid(),
//                     signer_email: stripEmptySchema(z.string().trim().email()),
//                 })),
//             }, formSchema, "indemnityAgreement.company_indemnitors"),
//             ...schemaIncludes({
//                 individual_indemnitors: z.array(z.object({
//                     id: z.string().uuid(),
//                     email: stripEmptySchema(z.string().trim().email()),
//                 })),
//             }, formSchema, "indemnityAgreement.individual_indemnitors")
//         })
//     }, formSchema, "indemnityAgreement"),
//     ...schemaIncludes({
//         bondForm: z.object({
//             ...schemaIncludes({
//                 issuance_method: stripEmptySchema(z.enum(["electronic", "physical", "agent"]))
//             }, formSchema, "bondForm.issuance_method"),
//             ...schemaIncludes({
//                 contract_date: stripEmptySchema(isoDateSchema)
//             }, formSchema, "bondForm.contract_date"),
//             ...schemaIncludes({
//                 description: stripEmptySchema(z.string())
//             }, formSchema, "bondForm.description"),
//             ...schemaIncludes({
//                 obligee: z.object({
//                     ...schemaIncludes({
//                         name: stripEmptySchema(z.string())
//                     }, formSchema, "bondForm.obligee.name"),
//                     ...schemaIncludes({
//                         address: stripEmptySchema(addressValidationTransformSchema)
//                     }, formSchema, "bondForm.obligee.address"),
//                     ...schemaIncludes({
//                         role: stripEmptySchema(z.enum(['project_owner', 'lender', 'gc', 'other']))
//                     }, formSchema, "bondForm.obligee.name"),
//                 })
//             }, formSchema, "bondForm.obligee"),
//             ...schemaIncludes({
//                 signer: stripEmptyKeys(z.object({
//                     ...schemaIncludes({
//                         firstName: z.string().trim()
//                     }, formSchema, "bondForm.signer.firstName"),
//                     ...schemaIncludes({
//                         lastName: z.string().trim()
//                     }, formSchema, "bondForm.signer.lastName"),
//                     ...schemaIncludes({
//                         suffix: z.enum(["JR", "III", "IV", "V"]).optional()
//                     }, formSchema, "bondForm.signer.suffix"),
//                     ...schemaIncludes({
//                         title: z.string().trim().optional()
//                     }, formSchema, "bondForm.signer.title"),
//                 }).optional())
//             }, formSchema, "bondForm.signer"),
//         })
//     }, formSchema, "bondForm"),
// })

import { HTMLAttributes, ReactNode, useMemo, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Modal } from "../layout/Modal";
import { cn } from "../lib/utils";

export type ButtonProps = {
	color: "light-blue" | "gray" | "green" | "red" | "light-gray" | "none";
	type?: HTMLButtonElement["type"];
	form?: string;
	disabled?: boolean;
	children?: ReactNode;
	tabIndex?: number;
	className?: string;
	style?: HTMLAttributes<HTMLDivElement>["style"];
	rounded?: true;
	filled?: boolean;
	shadow?: true;
	thinFont?: true;
	width?: string;
	fullWidth?: boolean;
	confirmationModal?: boolean;
	confirmationMessage?: string;
	confirmationMessageSubtext?: ReactNode;
	loading?: boolean;
	onClick?: () => void;
};

const getClassName = (
	props: Pick<
		ButtonProps,
		"thinFont" | "color" | "filled" | "shadow" | "rounded" | "className" | "disabled"
	>,
) => {
	let className =
		"h-[34px] shrink-0 whitespace-nowrap min-w-fit flex items-center justify-center px-[16px] text-sm cursor-pointer select-none";

	if (props.thinFont) {
		className += " font-medium";
	} else {
		className += " font-semibold";
	}

	if (props.color === "light-blue") {
		if (props.filled) {
			className += " text-white bg-inrev-light-blue enabled:hover:brightness-95";
		} else {
			className +=
				" border border-inrev-light-blue text-inrev-light-blue enabled:hover:bg-inrev-light-blue enabled:hover:bg-opacity-[.08]";
		}

		if (props.shadow) {
			className += " shadow-[2px_2px_0_rgba(71,124,204,0.25)]";
		}
	} else if (props.color === "gray") {
		className += " text-gray-800";

		if (props.filled) {
			className += " bg-gray-100 enabled:hover:brightness-[98%] border border-gray-200";
		} else {
			className += " border border-gray-500 enabled:hover:bg-gray-50 enabled:hover:border-gray-400";
		}
	} else if (props.color === "green") {
		if (props.filled) {
			className += " text-white bg-inrev-green enabled:hover:brightness-95";
		} else {
			className +=
				" border border-inrev-green text-inrev-green enabled:hover:bg-inrev-green enabled:hover:bg-opacity-[.04]";
		}
	} else if (props.color === "red") {
		if (props.filled) {
			className += " text-white bg-red-500 enabled:hover:brightness-95";
		} else {
			className += " border border-red-500 text-red-500";
		}
	} else if (props.color === "light-gray") {
		className += " text-gray-700";
		if (props.filled) {
			className += " bg-gray-100 enabled:hover:brightness-[98%] border border-gray-100";
		} else {
			className +=
				" border border-gray-400/80 enabled:hover:bg-gray-100/80 enabled:hover:border-gray-400";
		}
	} else if (props.color === "none") {
		if (props.filled) {
			className += "enabled:hover:brightness-[98%]";
		} else {
			className += " enabled:hover";
		}
	}

	if (props.rounded) {
		className += " rounded-full";
	} else {
		className += " rounded-[4px]";
	}

	if (props.disabled) {
		className += " cursor-default hover:default";
	}

	return cn(className, props.className);
};

const getLoaderColor = (color: ButtonProps["color"]) => {
	switch (color) {
		case "light-blue":
		case "green":
			return "white";
		case "gray":
			return "#1f2937";
	}
};

export const Button = ({
	thinFont,
	color,
	filled,
	shadow,
	rounded,
	children,
	width,
	fullWidth,
	confirmationModal,
	confirmationMessage,
	confirmationMessageSubtext,
	loading,
	...props
}: ButtonProps) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
	const className = useMemo(
		() => getClassName({ thinFont, color, filled, shadow, rounded, className: props.className }),
		[thinFont, color, filled, shadow, rounded, props.className],
	);

	const handleConfirmationClick = () => {
		setShowConfirmationModal(false);
		if (props.onClick) {
			props.onClick();
		}
	};

	const handleClick = () => {
		if (confirmationModal) {
			setShowConfirmationModal(true);
		} else if (props.onClick && !props.disabled && !loading) {
			props.onClick();
		}
	};

	return (
		<>
			<button
				tabIndex={1}
				{...props}
				type={props.type ?? "button"}
				className={cn(className, props.disabled ? "opacity-[0.45] cursor-default" : undefined)}
				onClick={handleClick}
			>
				{!loading && children}
				{loading && <ScaleLoader color={getLoaderColor(color)} height={12} width={3} />}
			</button>
			{showConfirmationModal && (
				<Modal>
					<div className="py-[38px] px-[60px] bg-white flex flex-col items-center justify-center rounded-md shadow-lg space-y-[45px]">
						<div className="flex flex-col items-center space-y-[15px]">
							<div className="text-[19px] text-gray-900 font-normal w-full flex justify-center">
								{confirmationMessage}
							</div>
							<div className="text-[16px] w-[90%] text-gray-600 font-thin text-center">
								{confirmationMessageSubtext}
							</div>
						</div>
						<div className="w-fit flex space-x-[8px]">
							<Button
								color="gray"
								className="w-[125px]"
								thinFont
								onClick={() => setShowConfirmationModal(false)}
							>
								Cancel
							</Button>
							<Button
								color="light-blue"
								className="w-[125px]"
								filled
								thinFont
								onClick={handleConfirmationClick}
							>
								Confirm
							</Button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

import { cn } from "../lib/utils";

type SeparatorProps = {
	className?: string;
	orientation?: "horizontal" | "vertical";
};

const Separator = ({ className, orientation = "horizontal", ...props }: SeparatorProps) => (
	<div
		className={cn(
			"shrink-0 bg-gray-100 dark:bg-gray-800",
			orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
			className,
		)}
		{...props}
	></div>
);

export { Separator };

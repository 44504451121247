import { ReactNode } from "react";
import { HiExclamationTriangle } from "react-icons/hi2";
import { cn } from "../lib/utils";
import { Icon } from "./Icon";

type NotificationCardProps = {
	type: "action" | "pending";
	text: string;
	right?: ReactNode;
};

export const NotificationCard = ({ type, text, right }: NotificationCardProps) => (
	<div
		className={cn(
			"w-full flex items-center space-y-[10px] justify-between bg-opacity-[0.15] border border-opacity-[0.25] rounded-sm pl-[15px] pr-[10px] py-[10px] text-[14px] text-gray-800 font-medium",
			type === "action" && "border-inrev-yellow bg-inrev-yellow",
			type === "pending" && "border-gray-400 bg-gray-400",
		)}
	>
		<div className="flex-1 flex items-center space-x-[10px]">
			{type === "action" && (
				<>
					<HiExclamationTriangle className="text-[22px] fill-inrev-yellow mt-[1px]" />
					<span>
						Action Required:
						<span className="pl-[8px] w-full text-[14px] text-gray-600">{text}</span>
					</span>
				</>
			)}
			{type === "pending" && (
				<>
					<Icon
						type="stopwatch"
						className="h-[15px] stroke-gray-600 stroke-[1.35] overflow-visible ml-[3px]"
					/>
					<span className="pl-[8px] w-full text-[14px] text-gray-600">{text}</span>
				</>
			)}
		</div>
		{right && right}
	</div>
);

import { Modal } from "../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../components/layout/ModalItemWithHeader";
import { AdminBondRequest } from "../../../domain/admin/request/types";
import { AdminUpdateBondRequestForm } from "./AdminUpdateBondRequestForm";

type AdminChangeBondRequestTermsModalProps = {
	request: AdminBondRequest;
	onClose: () => void;
};

export const AdminChangeBondRequestTermsModal = ({
	request,
	onClose,
}: AdminChangeBondRequestTermsModalProps) => {
	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header="Change Bond Request Terms"
				onClose={onClose}
				bodyClassName="pt-[20px]"
			>
				<AdminUpdateBondRequestForm request={request} onSubmit={onClose} />
			</ModalItemWithHeader>
		</Modal>
	);
};

import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { cn } from "../lib/utils";
import { Icon } from "./Icon";

export type SearchInputProps = {
	className?: string;
	inputClassName?: string;
	placeholder?: string;
	autofocus?: true;
	onChange?: (value: string) => void;
};

export const SearchInput = (props: SearchInputProps) => {
	const ref = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState<string>("");
	const [showClearButton, setShowClearButton] = useState<boolean>(value.length > 0);

	const debounced = useDebouncedCallback((value) => {
		if (props.onChange) props.onChange(value);
	}, 450);

	useEffect(() => {
		if (props.autofocus && ref.current !== null) {
			ref.current.focus();
		}
	}, [ref.current]);

	useEffect(() => {
		if (value.length) {
			debounced.cancel();
			debounced(value);

			if (!showClearButton) {
				setShowClearButton(true);
			}
		} else {
			if (props.onChange) props.onChange(value);
			if (showClearButton) {
				setShowClearButton(false);
			}
		}
	}, [value]);

	return (
		<div
			className={cn(
				"flex items-center space-x-[10px] w-fit pr-[18px] pl-[15px] h-[36px] disabled:cursor-not-allowed disabled:opacity-50 rounded-full bg-white focus-within:outline-gray-400 focus-within:outline focus-within:outline-[1px] focus-within:outline-offset-[-1px] focus-within:shadow-[1px_1px_0_#d1d5db] focus-within:cursor-text cursor-pointer",
				props.className,
			)}
			onClick={() => {
				if (ref.current) ref.current.focus();
			}}
		>
			<Icon type="search" className="fill-gray-700" width={13} height={13} />
			<input
				type="text"
				ref={ref}
				value={value}
				placeholder={props.placeholder}
				onChange={(event) => {
					setValue(event.target.value ?? "");
				}}
				className={cn(
					"flex-1 h-full pr-[8px] pb-[1px] text-[14px] font-normal leading-[10px] text-gray-900 cursor-pointer placeholder:text-gray-400 bg-transparent focus-visible:cursor-text focus:outline-none focus:placeholder-transparent placeholder:text-sm",
					props.inputClassName,
				)}
			/>
			{showClearButton && (
				<div
					className="w-fit h-fit"
					onClick={(e) => {
						e.stopPropagation();
						setValue("");
						if (ref.current) ref.current.blur();
					}}
				>
					<Icon
						type="close-filled"
						className="fill-gray-400 hover:fill-gray-500 cursor-pointer"
						width={14}
						height={14}
					/>
				</div>
			)}
		</div>
	);
};
SearchInput.displayName = "Input";

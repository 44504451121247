import { ReactNode, forwardRef, useEffect, useState } from "react";
import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { Input } from "./Input";

export type Transformer<TInputValue> = {
	input: (value: TInputValue) => string;
	output: (value: string) => TInputValue;
	blockChange?: (value: string) => boolean;
};

export type InputTransformerProps<
	TFieldValues extends FieldValues,
	TInputValue,
> = ControllerRenderProps<TFieldValues> & {
	transformer: Transformer<TInputValue>;
	unblockTransformOnBlur?: boolean;
	className?: string;
	condensed?: true;
	maxLength?: number;
	error?: FieldError;
	prefix?: ReactNode;
	postfix?: ReactNode;
	align?: "left" | "right";
};

export const InputTransformer = forwardRef<HTMLInputElement, InputTransformerProps<any, any>>(
	({ transformer, ...props }, ref) => {
		const [internalValue, setInternalValue] = useState<any>(transformer.input(props.value));

		useEffect(() => {
			const transformedInput = transformer.input(props.value);
			if (transformedInput !== internalValue) {
				setInternalValue(transformedInput);
			}
		}, [props.value]);

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (transformer.blockChange && transformer.blockChange(e.target.value)) {
				setInternalValue(e.target.value);
			} else {
				props.onChange(transformer.output(e.target.value));
			}
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			if (props.unblockTransformOnBlur) {
				props.onChange(transformer.output(e.target.value));
				setInternalValue(transformer.input(props.value));
			}
			props.onBlur();
		};

		return (
			<Input
				{...props}
				ref={ref}
				onChange={handleChange}
				onBlur={handleBlur}
				value={internalValue}
			/>
		);
	},
);

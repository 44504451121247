import type { Dtos } from "@inrev/common";
import { HiOutlineBuildingOffice, HiOutlineUser } from "react-icons/hi2";

type AccountIndemnitorProps = {
	type: "company" | "individual";
	indemnitor:
		| AccountIndemnitorListProps["indemnityAgreement"]["indemnitors"]["companies"][number]
		| AccountIndemnitorListProps["indemnityAgreement"]["indemnitors"]["individuals"][number];
	number: number;
	showESignatureDetails: boolean;
};

const AccountIndemnitor = ({
	type,
	indemnitor,
	number,
	showESignatureDetails,
}: AccountIndemnitorProps) => {
	return (
		<div className="grid auto-rows-auto grid-cols-[auto_auto_1fr_auto] gap-x-[10px] gap-y-[2px] w-full text-[13.5px] text-gray-900 font-medium bg-gray-100 border border-gray-200 rounded-[3px] px-[12px] py-[8px] drop-shadow-sm">
			<span className="font-medium text-[12px] text-gray-600 self-center">{number}.</span>
			{type === "company" && <HiOutlineBuildingOffice className="text-[16px] self-center" />}
			{type === "individual" && (
				<HiOutlineUser className="text-[15.5px] stroke-[1.75] self-center" />
			)}
			<span className="flex-1 self-center">{indemnitor.name}</span>
			{showESignatureDetails && (
				<>
					{indemnitor.completed !== undefined && !indemnitor.completed && (
						<span className="italic text-gray-600 font-normal text-[13px] self-center">
							Hasn't signed
						</span>
					)}
					{indemnitor.completed && (
						<span className="text-inrev-green font-medium text-[13px] self-center">Signed</span>
					)}
				</>
			)}
			{showESignatureDetails && !!indemnitor.signerEmail && (
				<>
					<div className="col-span-2"></div>
					<div className="col-span-2 text-[13px] font-normal text-gray-500">
						{indemnitor.signerEmail}
					</div>
				</>
			)}
		</div>
	);
};

export type AccountIndemnitorListProps = {
	indemnityAgreement: Dtos.SuretyAccount.Submitted.Contract.Get.Response["indemnityAgreement"];
};

export const AccountIndemnitorList = ({ indemnityAgreement }: AccountIndemnitorListProps) => {
	const showESignatureDetails =
		indemnityAgreement.status !== "incomplete" &&
		indemnityAgreement.signatureMethod === "electronic";

	return (
		<div className="flex flex-col gap-[10px]">
			{indemnityAgreement.indemnitors.companies.map((indemnitor, index) => (
				<AccountIndemnitor
					key={index}
					type="company"
					indemnitor={indemnitor}
					number={index + 1}
					showESignatureDetails={showESignatureDetails}
				/>
			))}
			{indemnityAgreement.indemnitors.individuals.map((indemnitor, index) => (
				<AccountIndemnitor
					key={index}
					type="individual"
					indemnitor={indemnitor}
					number={indemnityAgreement.indemnitors.companies.length + index + 1}
					showESignatureDetails={showESignatureDetails}
				/>
			))}
		</div>
	);
};

import { forwardRef, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { useNumericFormat } from "react-number-format";
import { formatNegativesAndLeadingZeros } from "../../utils";
import { Input, InputProps } from "./Input";

type NumberInputProps = Omit<InputProps, "value" | "type" | "prefix"> & {
	defaultValue: string | undefined;
	precision?: number;
	min?: number;
	max?: number;
	maxLength?: number;
	className?: string;
	condensed?: true;
	error?: FieldError;
	errorMessage?: string;
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
	(props: NumberInputProps) => {
		const { format } = useNumericFormat({
			displayType: "input",
			decimalScale: props.precision ?? 0,
			valueIsNumericString: true,
			maxLength: props.maxLength,
		});
		const [internalValue, setInternalValue] = useState<string>(
			(() => {
				let value = props.defaultValue ?? "";
				const parsed = parseFloat(value);
				if (Number.isNaN(parsed)) return "";
				else return format?.(parsed.toString()) ?? "";
			})(),
		);

		useEffect(() => {
			const parsed = parseFloat(internalValue);
			let value: string;
			if (Number.isNaN(parsed)) value = "";
			else value = parsed.toString();
			props.onChange?.({ target: { value } } as any);
		}, [internalValue]);

		useEffect(() => {
			let value = props.defaultValue ?? "";
			const parsed = parseFloat(value);
			if (Number.isNaN(parsed)) value = "";
			else
				value = (+(
					format?.(formatNegativesAndLeadingZeros(parsed.toString()) ?? "") ?? ""
				)).toString();
			if (value !== internalValue) setInternalValue(value);
		}, [props.defaultValue]);

		if (format === undefined) return <></>;
		return (
			<Input
				{...props}
				value={internalValue}
				onChange={(e) => {
					const preFormatted = formatNegativesAndLeadingZeros(e.target.value) ?? "";
					const length = preFormatted.length;
					if (preFormatted[length - 1] === "-" || preFormatted[length - 1] === ".")
						setInternalValue(preFormatted);
					else {
						const parsed = parseFloat(preFormatted);
						if (Number.isNaN(parsed)) setInternalValue(format?.(preFormatted ?? ""));
						else setInternalValue(format?.(preFormatted ?? ""));
					}
				}}
				onBlur={(e) => {
					const parsed = parseFloat(internalValue);
					let value: string;
					if (Number.isNaN(parsed)) value = "";
					else {
						value = Math.max(
							props.min ?? -Infinity,
							Math.min(props.max ?? Infinity, parsed),
						).toString();
					}
					setInternalValue(value);
					props.onBlur?.(e);
				}}
			/>
		);
	},
);

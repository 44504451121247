import { AgentId, Dtos } from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";

export const useFetchAgentPreviews = () => {
	const { get } = useRequest();

	const { data, error, isLoading } = useQuery({
		queryKey: ["agentPreviews"],
		queryFn: async () => await get<Dtos.Agent.Preview.Get.Response[]>(`/v2/agents/previews`),
		retry: false,
		staleTime: Infinity,
		cacheTime: Infinity,
	});

	return { agentPreviews: data, agentPreviewsError: error, agentPreviewsLoading: isLoading };
};

export const useFetchAgentUserPreviews = () => {
	const { get } = useRequest();

	const { data, error, isLoading } = useQuery({
		queryKey: ["agentUserPreviews"],
		queryFn: async () =>
			await get<Dtos.Agent.UserPreview.Get.Response[]>(`/v2/agents/user-previews`),
		retry: false,
	});

	return {
		agentUserPreviews: data,
		agentUserPreviewsError: error,
		agentUserPreviewsLoading: isLoading,
	};
};

export const useInviteAgentUser = () => {
	const { get, post } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: "inviteAgentUser",
		mutationFn: async (data: Dtos.Agent.Invite.Request) => {
			await post("/v2/agents/invite", data, "none");
		},
		onSuccess: async () => {
			queryClient.invalidateQueries(["agentUserPreviews"]);
			const updatedAgentPreviews =
				await get<Dtos.Agent.UserPreview.Get.Response[]>(`/v2/agents/user-previews`);
			queryClient.setQueryData("agentPreviews", updatedAgentPreviews);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		inviteAgentUser: mutation.mutate,
		inviteAgentUserIsLoading: mutation.isLoading,
		inviteAgentUserError: mutation.error,
		inviteAgentUserIsSuccess: mutation.isSuccess,
	};
};

export const useResendAgentUserInvitation = () => {
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: "resendAgentUserInvitation",
		mutationFn: async (agentId: AgentId) => {
			await post(`/v2/agents/${agentId}/invite/resend`, undefined, "none");
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		resendAgentUserInvitation: mutation.mutate,
		resendAgentUserInvitationIsLoading: mutation.isLoading,
		resendAgentUserInvitationError: mutation.error,
	};
};

export const useCancelAgentUserInvitation = () => {
	const { _delete, get } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: "cancelAgentUserInvitation",
		mutationFn: async (agentId: AgentId) => {
			await _delete(`/v2/agents/${agentId}/invite`, undefined, "none");
		},
		onMutate: async (agentId: AgentId) => {
			const previousData = queryClient.getQueryData("agentPreviews");
			queryClient.setQueryData(
				"agentPreviews",
				(old: Dtos.Agent.UserPreview.Get.Response[] | undefined) => {
					if (!old) return [];
					return old.filter((agentPreview) => agentPreview.id !== agentId);
				},
			);
			return { previousData };
		},
		onSuccess: async () => {
			queryClient.invalidateQueries(["agentUserPreviews"]);
			const updatedAgentPreviews =
				await get<Dtos.Agent.UserPreview.Get.Response[]>(`/v2/agents/user-previews`);
			queryClient.setQueryData("agentPreviews", updatedAgentPreviews);
		},
		onError: (error: ApiError, _variables, context) => {
			if (!!context) {
				queryClient.setQueryData("agentPreviews", context.previousData);
			}
			triggerErrorModal(error);
		},
	});

	return {
		cancelAgentUserInvitation: mutation.mutate,
		cancelAgentUserInvitationIsLoading: mutation.isLoading,
		cancelAgentUserInvitationError: mutation.error,
	};
};

export const useCompleteAgentUserAccountSetup = () => {
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationKey: "completeAgentUserAccountSetup",
		mutationFn: async (data: Dtos.Agent.CompleteAccountSetup.Request) => {
			await post(`/v2/agents/complete-setup`, data, "none");
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["agentUserPreviews"]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		completeAgentUserAccountSetup: mutation.mutate,
		completeAgentUserAccountSetupIsLoading: mutation.isLoading,
		completeAgentUserAccountSetupError: mutation.error,
		completeAgentUserAccountSetupIsSuccess: mutation.isSuccess,
	};
};

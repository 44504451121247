import { SubmittedSuretyAccount } from "../../../../../../domain/agent/account/types";
import {
	CompletedIndemnityAgreementSection,
	IncompleteIndemnityAgreementSection,
} from "./IndemnityAgreementSectionStatuses";

export type AccountIndemnityAgreementSectionProps = {
	account: SubmittedSuretyAccount;
};

export const AccountIndemnityAgreementSection = ({
	account,
}: AccountIndemnityAgreementSectionProps) => {
	if (account.contract.indemnityAgreement.status === "complete") {
		return <CompletedIndemnityAgreementSection account={account} />;
	}
	return <IncompleteIndemnityAgreementSection account={account} />;
};

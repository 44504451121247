import { type Types, contractSuretyAccountUnderwritingModelSizeDef } from "@inrev/common";
import { useContext, useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormItem } from "../../../../../components/layout/form/FormItem";
import { CardGrayBody } from "../../../../../components/ui/CardGrayBody";
import { Icon } from "../../../../../components/ui/Icon";
import { FormErrorCard } from "../../../../../components/ui/form/FormErrorCard";
import { FormIndividualCreditReportPermissionList } from "../../../../../components/ui/form/FormIndividualCreditReportPermissionList";
import { FormQuestionLabel } from "../../../../../components/ui/form/FormQuestionLabel";
import { useScrollToId } from "../../../../../utils";
import { AccountExposureSizeSelect } from "../../../../agent/account/shared/AccountExposureSizeSelect";
import { AdminAccountReUnderwriteContext } from "../AdminAccountReUnderwriteModal";

export const AdminAccountReUnderwriteDraftDetailsSection = () => {
	const domReadyRef = useScrollToId();
	const { draft, account } = useContext(AdminAccountReUnderwriteContext);
	const detailsSchema = draft.schema.details;
	const { watch, formState, control, trigger } =
		useFormContext<Types.SuretyAccount.Draft.Contract.Data>();
	const creditReportPermission = watch("details.creditReportPermission");
	const creditReportPermissionFieldArray = useFieldArray({
		control,
		name: "details.creditReportPermission",
		keyName: "id",
	});
	const disabledModelSizes = useMemo(() => {
		return [...contractSuretyAccountUnderwritingModelSizeDef].filter(
			(size) => !account.contract.underwriting.availableModelSizes.includes(size),
		);
	}, [account.contract.underwriting.availableModelSizes]);

	useEffect(() => {
		if (formState.isSubmitted) {
			trigger();
		}
	}, [creditReportPermission]);

	if (!detailsSchema) return null;
	return (
		<div
			ref={domReadyRef}
			className="w-full min-h-fit flex flex-col space-y-[45px] pt-[18px] pb-[125px]"
		>
			<FormErrorCard
				error={formState.errors.details?.companies}
				className="!mt-[60px] !mb-[-25px]"
			/>
			<FormItem schemaInclude={detailsSchema.include.exposureSize}>
				<FormQuestionLabel marker className="pb-[5px]">
					Model Size
				</FormQuestionLabel>
				<AccountExposureSizeSelect
					name="details.exposureSize"
					control={control}
					disabledOptions={disabledModelSizes}
				/>
			</FormItem>
			{!!creditReportPermissionFieldArray.fields.length && (
				<CardGrayBody
					header={
						<div className="flex flex-col space-y-[8px]">
							<div className="flex space-x-[10px] items-center">
								<Icon type="action-required" className="h-[20px] mt-[1px]" />
								<div className="text-[17px] font-semibold">Expired Credit Reports</div>
							</div>
							<div className="text-[14px] text-gray-500">
								Please confirm permission to obtain updated credit information for the following
								individuals
							</div>
						</div>
					}
					headerClassName="py-[20px] px-[26px]"
					className="mt-[40px]"
				>
					<FormIndividualCreditReportPermissionList
						path="details.creditReportPermission"
						creditReportPermissionFieldArray={creditReportPermissionFieldArray}
						errors={formState.errors.details?.creditReportPermission ?? []}
					/>
				</CardGrayBody>
			)}
		</div>
	);
};

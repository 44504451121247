import { AttachmentFile, defaultAttachmentTypeLabelMap } from "@inrev/common";
import { useContext, useState } from "react";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { AttachmentsSection } from "../../../../../components/layout/AttachmentsSection";
import { CommentsSection } from "../../../../../components/layout/CommentsSection";
import { ArchivedBanner } from "../../../../../components/ui/Banner";
import { Button } from "../../../../../components/ui/Button";
import { Icon } from "../../../../../components/ui/Icon";
import { MoreActionsMenu } from "../../../../../components/ui/MoreActionsMenu";
import { Separator } from "../../../../../components/ui/Separator";
import { useArchiveRequest, useUnarchiveRequest } from "../../../../../domain/agent/request/api";
import { BondRequest } from "../../../../../domain/agent/request/types";
import { BondRequestCardHeader } from "../../../../shared/request/BondRequestCardHeader";
import { BondRequestCardLayout } from "../../../../shared/request/BondRequestCardLayout";
import { BondRequestCommentsContext } from "../../../../shared/request/SubmittedBondRequestLayoutWrapper";

type ReviewBondRequestViewProps = {
	request: Extract<BondRequest, { status: "review" }>;
};

export const ReviewBondRequestView = ({ request }: ReviewBondRequestViewProps) => {
	const [attachments, setAttachments] = useState<AttachmentFile[]>(request.attachments);
	const actionRequired = request.supplementalRequirements.length > 0;
	const { archiveRequest } = useArchiveRequest();
	const { unarchiveRequest } = useUnarchiveRequest();
	const { comments, commentsLoading, createComment, createCommentLoading } = useContext(
		BondRequestCommentsContext,
	);
	const reviewReasonDescriptions = Array.from(
		new Set(request.reviewReasons.map((r) => r.description)),
	);

	return (
		<>
			<BondRequestCardLayout
				banner={
					<>
						{request.archived && (
							<ArchivedBanner onUnarchive={() => unarchiveRequest(request.id)} />
						)}
						{/* {actionRequired && <ActionRequiredBanner />} */}
					</>
				}
			>
				<BondRequestCardHeader
					request={request}
					actionButtons={
						<div className="flex items-center flex-col space-y-[4px] select-none">
							<Button color="light-blue" disabled filled rounded className="w-full">
								<div className="flex items-center space-x-[7px]">
									<span>Accept Terms</span>
								</div>
							</Button>
							{!request.archived && (
								<MoreActionsMenu
									items={[
										{
											label: "Archive",
											icon: <HiOutlineArchiveBox className="text-[15px]" />,
											onClick: () => {
												archiveRequest(request.id);
											},
										},
									]}
									panelClassName="mt-[10px]"
								/>
							)}
						</div>
					}
				/>
				<div className="w-full h-fit flex flex-col space-y-[12px] !mt-[-5px]">
					<div className="min-h-fit px-[25px] py-[12px] flex items-center justify-center space-x-[12px]">
						<Icon type="headset" className="fill-gray-600 h-[18px]" />
						<Separator orientation="vertical" className="h-[20px] w-[1.5px] bg-gray-300" />
						<span className="text-[13px] text-gray-600 font-[475] italic">
							An underwriter is taking a closer look at this request and may reach out with
							additional questions.
						</span>
					</div>
					<div className="flex flex-col w-full h-fit min-h-fit bg-gray-50 border-t border-t-gray-200/50 divide-y divide-gray-200/70 rounded-b-md">
						<div className="w-full h-fit min-h-fit flex flex-col space-y-[18px] p-[40px] pt-[25px]">
							<span className="text-[18px] text-gray-900 font-medium">What caused a review?</span>
							<ol className="flex flex-col space-y-[8px] px-[12px] text-gray-900 text-[14px] font-normal list-inside">
								{reviewReasonDescriptions.length > 0 ? (
									reviewReasonDescriptions.map((description, index) => (
										<li key={index}>{description}</li>
									))
								) : (
									<li key={0}>One or more details of the request needs review</li>
								)}
							</ol>
						</div>
						{actionRequired && new Set(request.supplementalRequirements).size > 0 ? (
							<div className="w-full h-fit min-h-fit flex flex-col space-y-[18px] px-[40px] pt-[25px] pb-[30px]">
								<div className="flex-1 flex items-center space-x-[10px]">
									<Icon type="action-required" className="h-[20px]" />
									<span className="text-[16px] font-medium">Action Required</span>
								</div>
								<div className="w-full flex flex-col space-y-[10px] pl-[14px]">
									<span className="text-[14px] font-medium">
										Please ensure the following additional documents are attached:
									</span>
									<ol className="flex flex-col space-y-[4px] list-disc text-gray-900 text-[14px] font-normal list-inside">
										{Array.from(new Set(request.supplementalRequirements)).map(
											(requirement, index) => (
												<li key={index}>{requirement}</li>
											),
										)}
									</ol>
								</div>
							</div>
						) : undefined}
					</div>
				</div>
			</BondRequestCardLayout>
			<CommentsSection
				comments={comments}
				commentsLoading={commentsLoading}
				createComment={createComment}
				createCommentLoading={createCommentLoading}
				commentDownloadHeader={`${request.displayTitle} For ${request.principal.name}`}
			/>
			<AttachmentsSection
				attachments={attachments}
				downloadAllName={`${request.account.displayName} ${request.displayTitle} Attachments`}
				typeLabelMap={defaultAttachmentTypeLabelMap}
				upload={{
					url: `/v2/surety/quotes/${request.id}/attachments`,
					onChange: (val) => setAttachments(val),
					allowedTypesAndLabels: defaultAttachmentTypeLabelMap,
					invalidateQueryKeys: [
						["bondRequests", request.id],
						["suretyAccounts", request.account.id],
					],
				}}
				download={{
					baseUrl: `/v2/surety/quotes/${request.id}/attachments`,
					baseQueryKey: "bondRequestAttachments",
				}}
			/>
		</>
	);
};

import { ReactNode } from "react";
import { Link } from "react-router-dom";

export type SummarySectionProps = {
	header: string;
	to?: { pathname: string; section: string };
	children: ReactNode;
};

export const SummarySection = ({ header, to, children }: SummarySectionProps) => (
	<div className="flex flex-col rounded border border-gray-200">
		<div className="w-full h-[50px] flex items-center px-[20px] bg-white rounded-t-md border-b border-b-gray-200 text-[17px] text-gray-800 font-medium">
			{to && <Link to={`${to.pathname}?section=${to.section}`}>{header}</Link>}
			{!to && header}
		</div>
		<div className="w-full flex flex-col space-y-[20px] flex-1 bg-gray-50 rounded-b-md px-[30px] py-[25px]">
			{children}
		</div>
	</div>
);

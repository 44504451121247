import { formatToPercent } from "@inrev/common";
import { CardGrayBody } from "../../../../components/ui/CardGrayBody";
import { LineItem } from "../../../../components/ui/LineItem";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { formatEmpty } from "../../../../utils";

export const MetricsSection = ({
	currentFinancialStatement,
}: {
	currentFinancialStatement: AdminSuretyAccount["financialStatements"][number] | undefined;
}) => {
	return (
		<div className="w-full flex flex-col space-y-[30px]">
			<div className="text-[17px] !my-[-10px] font-medium italic text-gray-500">Metrics</div>
			<div className="flex space-x-[10px]">
				<div className="flex flex-col space-y-[15px] flex-1">
					<CardGrayBody
						header={<div className="!my-[-10px] !mx-[-8px]">Liquidity</div>}
						contentClassName="py-[20px] px-[30px] space-y-[12px]"
					>
						<LineItem
							label="Cash / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.corporateCashToTotalAssets),
							)}
						</LineItem>
						<LineItem
							label="Working Capital / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatement?.calculations.workingCapitalToTotalAssets,
								),
							)}
						</LineItem>
						<LineItem
							label="Net Cash / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.netCashToTotalAssets),
							)}
						</LineItem>
						<LineItem
							label="Receivables Turnover"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(currentFinancialStatement?.calculations.accountsReceivableTurnover)}{" "}
							{currentFinancialStatement?.calculations.accountsReceivableTurnover ? "Days" : ""}
						</LineItem>
						<LineItem
							label="Underbillings / Working Capital"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatement?.calculations.underbillingsToWorkingCapital,
								),
							)}
						</LineItem>
						<LineItem label="BLOC % Available" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.blocAvailability),
							)}
						</LineItem>
					</CardGrayBody>
					<CardGrayBody
						header={<div className="!my-[-10px] !mx-[-8px]">Leverage</div>}
						contentClassName="py-[20px] px-[30px] space-y-[12px]"
					>
						<LineItem
							label="Total Liabilities / Equity"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.totalLiabilitiesToEquity),
							)}
						</LineItem>
						<LineItem
							label={
								<span>
									Working Capital / Current Assets
									<br />
									(Compression)
								</span>
							}
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatement?.calculations.workingCapitalToCurrentAssets,
								),
							)}
						</LineItem>
						<LineItem
							label="Current Debt / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.currentDebtToTotalAssets),
							)}
						</LineItem>
						<LineItem
							label="Bank Debt / Total Assets"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.bankDebtToTotalAssets),
							)}
						</LineItem>
					</CardGrayBody>
				</div>
				<div className="flex flex-col space-y-[15px] flex-1">
					<CardGrayBody
						header={<div className="!my-[-10px] !mx-[-8px]">Underwriting</div>}
						contentClassName="py-[20px] px-[30px] space-y-[12px]"
					>
						<LineItem label="Cash / Backlog" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.corporateCashToBacklogCost),
							)}
						</LineItem>
						<LineItem
							label="Working Capital / Backlog"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatement?.calculations.workingCapitalToBacklogCost,
								),
							)}
						</LineItem>
						<LineItem label="Equity / Backlog" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.equityToBacklogCost),
							)}
						</LineItem>
						<LineItem label="G&A / Equity" className="text-[14px]" labelClassName="text-[14px]">
							{formatEmpty(
								formatToPercent(currentFinancialStatement?.calculations.gaExpenseToEquity),
							)}
						</LineItem>
						<LineItem
							label="Backlog Gross Profit / G&A"
							className="text-[14px]"
							labelClassName="text-[14px]"
						>
							{formatEmpty(
								formatToPercent(
									currentFinancialStatement?.calculations.backlogGrossProfitToGaExpense,
								),
							)}
						</LineItem>
					</CardGrayBody>
				</div>
			</div>
		</div>
	);
};

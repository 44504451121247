import { FileType } from "@inrev/common";
import { pick } from "rambda";
import { useContext } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useGetFileUrl } from "../../../../../../../api";
import { FormItem } from "../../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../../components/layout/form/FormRow";
import { FormSchemaInclude } from "../../../../../../../components/layout/form/FormSchemaInclude";
import { FormSection } from "../../../../../../../components/layout/form/FormSection";
import { BondFormSelect } from "../../../../../../../components/ui/BondFormSelect";
import { FileUpload } from "../../../../../../../components/ui/Upload";
import { FormCurrencyInput } from "../../../../../../../components/ui/form/FormCurrencyInput";
import { FormDatePicker } from "../../../../../../../components/ui/form/FormDatePicker";
import { FormItemLabel } from "../../../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../../../../components/ui/form/FormYesNo";
import { BondRequestDraftData } from "../../../../../../../domain/agent/request/types";
import { useScrollToId } from "../../../../../../../utils";
import { DraftBondRequestContext } from "../../DraftBondRequestView";
import { bondFormTypesAndLabels } from "../../constants";

export const DraftBidToFinalBondRequestBondSection = () => {
	const { request, bondFormTemplates } = useContext(DraftBondRequestContext);
	const { control, formState } = useFormContext<BondRequestDraftData>();
	const bondFormController = useController({
		control,
		name: "bond.bondForm",
	});
	const domReadyRef = useScrollToId();
	const allowedBondFormUploadTypesAndLabels = pick(
		["pnp", "performance", "payment"],
		bondFormTypesAndLabels,
	);
	const filesController = useController({
		control,
		name: "bond.project.files",
	});
	const { getFileUrl } = useGetFileUrl();

	return (
		<div ref={domReadyRef} className="flex flex-col space-y-[45px] pt-[18px] pb-[125px] min-h-fit">
			<FormSection header="Bid Results" indent>
				<FormItemGroup>
					<FormRow>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.actualBidAmount}
							id="actualBidAmount"
						>
							<FormItemLabel>Low Bid Amount (Principal's Bid)</FormItemLabel>
							<FormCurrencyInput name="bond.actualBidAmount" control={control} updateOnBlur />
						</FormItem>
						<FormItem schemaInclude={request.draft.schema.bond?.include.secondBid} id="secondBid">
							<FormItemLabel>Second Lowest Bid</FormItemLabel>
							<FormCurrencyInput name="bond.secondBid" control={control} />
						</FormItem>
					</FormRow>
					<FormRow>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.actualBidDate}
							id="actualBidDate"
						>
							<FormItemLabel>Bid Date</FormItemLabel>
							<FormDatePicker name="bond.actualBidDate" control={control} />
						</FormItem>
					</FormRow>
				</FormItemGroup>
			</FormSection>
			<FormSection header="Final Bond" indent topPadding>
				<FormItemGroup>
					<FormRow>
						<FormItem schemaInclude={request.draft.schema.bond?.include.bondAmount} id="bondAmount">
							<FormItemLabel>Bond Amount</FormItemLabel>
							<FormCurrencyInput name="bond.bondAmount" control={control} />
						</FormItem>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.contractAmount}
							id="contractAmount"
						>
							<FormItemLabel>Contract Amount</FormItemLabel>
							<FormCurrencyInput name="bond.contractAmount" control={control} />
						</FormItem>
					</FormRow>

					<FormRow>
						<FormItem
							schemaInclude={request.draft.schema.bond?.include.project?.contractDate}
							id="contractDate"
							className="flex-[1]"
						>
							<FormItemLabel>Contract Date</FormItemLabel>
							<FormDatePicker name="bond.project.contractDate" control={control} />
						</FormItem>
					</FormRow>
					<FormItem
						schemaInclude={request.draft.schema.bond?.include.tListingRequired}
						id="tListingRequired"
					>
						<FormQuestionLabel>Does the final bond require a Treasury Listing?</FormQuestionLabel>
						<FormYesNo name="bond.tListingRequired" control={control} />
					</FormItem>
				</FormItemGroup>
			</FormSection>
			<FormSection
				header="Contract"
				subHeader="Please upload a copy of the contract"
				schemaInclude={request.draft.schema.bond?.include.project?.files !== undefined}
				id="contract"
			>
				<FileUpload
					value={filesController.field.value}
					onChange={filesController.field.onChange}
					onBlur={filesController.field.onBlur}
					onDownload={(fileId) =>
						getFileUrl({
							baseUrl: `/v2/surety/quotes/draft/${request.id}/files/${fileId}`,
							queryKey: [`downloadQuoteDraftFile`, fileId, true],
							asDownload: true,
						})
					}
					allowedTypesAndLabels={{ [FileType.contract]: "Contract" }}
					requiredTypes={[FileType.contract]}
					maxTypesPerFile={1}
					maxFiles={1}
					showErrors={formState.isSubmitted}
					hideChecklist
					className="w-[450px]"
				/>
			</FormSection>
			<FormSchemaInclude schemaInclude={request.draft.schema.bond?.include.bondForm !== undefined}>
				<BondFormSelect
					value={bondFormController.field.value}
					onChange={bondFormController.field.onChange}
					onBlur={bondFormController.field.onBlur}
					onDownload={(fileId) =>
						getFileUrl({
							baseUrl: `/v2/surety/quotes/draft/${request.id}/files/${fileId}`,
							queryKey: [`downloadQuoteDraftFile`, fileId, true],
							asDownload: true,
						})
					}
					bondFormTemplates={bondFormTemplates}
					allowedBondFormUploadTypesAndLabels={allowedBondFormUploadTypesAndLabels}
					header="Final Bond Form"
					id="form"
				/>
			</FormSchemaInclude>
		</div>
	);
};

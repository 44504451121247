import { zodResolver } from "@hookform/resolvers/zod";
import { City, Dtos, StreetAddress, Types, USStateOrTerritory, ZipCode } from "@inrev/common";
import { useMemo } from "react";
import { UseFieldArrayReturn, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";
import { useAddressValidation } from "../../../../../../../api";
import { Modal } from "../../../../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../../components/layout/form/FormRow";
import { Button } from "../../../../../../../components/ui/Button";
import { FormAddressAutocomplete } from "../../../../../../../components/ui/form/FormAddressAutocomplete";
import { FormInput } from "../../../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../../../components/ui/form/FormItemLabel";
import { formatAddress } from "../../../../../../../utils";
import { stripEmptyResolver } from "../../../../../../../utils/form";
import { numericStringSchema, uniqueSchema } from "../../../../../../../validation";

export type DefaultCompanyFormData =
	Types.SuretyQuote.Draft.Contract.Data["principal"]["companies"][number];

const getDefaultFormData = (): DefaultCompanyFormData => ({
	draftId: v4(),
	name: "",
	fein: "",
	address: {
		street: "" as StreetAddress,
		city: "" as City,
		state: "" as USStateOrTerritory,
		zip: "" as ZipCode,
	},
});

export const getCompanyFormSchema = (feins: string[]) =>
	Dtos.SuretyQuote.Contract.Submit.Request.NewAccount.Data.Principal.Company.schema.extend({
		fein: uniqueSchema(feins, numericStringSchema.optional(), "FEIN already used"),
	});
export type CompanyFormData = z.infer<ReturnType<typeof getCompanyFormSchema>>;

type DraftBondRequestNewPrincipalCompanyModalProps = {
	principalFein: string;
	companies: Types.SuretyQuote.Draft.Contract.Data["principal"]["companies"];
	fieldArray: UseFieldArrayReturn<
		Types.SuretyQuote.Draft.Contract.Data,
		"principal.companies",
		"draftId"
	>;
	selectedIndex?: number;
	onClose: () => void;
};

export const DraftBondRequestNewPrincipalCompanyModal = ({
	principalFein,
	companies,
	fieldArray,
	selectedIndex,
	onClose,
}: DraftBondRequestNewPrincipalCompanyModalProps) => {
	const selectedCompany = selectedIndex !== undefined ? companies[selectedIndex] : undefined;
	const feins = useMemo(
		() => [
			...companies
				.filter((c) => c.draftId !== selectedCompany?.draftId)
				.map((company) => company.fein)
				.filter((fein) => fein !== ""),
			principalFein,
		],
		[companies],
	);
	const defaultFormData = selectedCompany ?? getDefaultFormData();
	const formMethods = useForm<DefaultCompanyFormData, any, CompanyFormData>({
		defaultValues: defaultFormData,
		resolver: stripEmptyResolver(zodResolver(getCompanyFormSchema(feins))),
		reValidateMode: "onBlur",
	});
	const { addressFieldControl, resetAddressValidation } =
		useAddressValidation<DefaultCompanyFormData>(
			selectedIndex !== undefined ? formatAddress(companies[selectedIndex].address) : "",
			formMethods,
			"address",
		);

	const handleSave = (formData: CompanyFormData) => {
		if (selectedIndex !== undefined) {
			fieldArray.update(selectedIndex, { ...formData, fein: formData.fein ?? "" });
		} else {
			fieldArray.append({ ...formData, fein: formData.fein ?? "" });
		}
		resetAddressValidation();
		onClose();
	};

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader header="New Company" onClose={onClose}>
				<div className="max-w-[820px] min-w-[747px] px-[40px] pt-[25px] pb-[35px]">
					<form className="w-full h-fit flex flex-col space-y-[25px]">
						<FormItemGroup condensed>
							<FormRow>
								<FormItem className="flex-2" condensed>
									<FormItemLabel condensed>Name</FormItemLabel>
									<FormInput name="name" control={formMethods.control} condensed />
								</FormItem>
								<FormItem className="max-w-[200px]" condensed>
									<FormItemLabel condensed>FEIN</FormItemLabel>
									<FormInput name="fein" control={formMethods.control} type="numeric" condensed />
								</FormItem>
							</FormRow>
							<FormItem condensed>
								<FormItemLabel condensed>Address</FormItemLabel>
								<FormAddressAutocomplete
									control={addressFieldControl}
									className="h-[46px] text-[16px]"
								/>
							</FormItem>
						</FormItemGroup>
						<div className="w-full flex justify-center mt-[5px]">
							<Button
								onClick={formMethods.handleSubmit(handleSave)}
								color="light-blue"
								filled
								className="w-[150px] h-[36px]"
								tabIndex={2}
								loading={formMethods.formState.isSubmitting}
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

import {
	ColumnDef,
	ColumnFiltersState,
	ColumnSizingState,
	Row,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useEffect, useRef, useState } from "react";
import { checkLocalStorageAvailable } from "../../../utils";
import { cn } from "../../lib/utils";
import { TableCell } from "./TableCell";
import { TableHeaderCell } from "./TableHeaderCell";
import { fuzzyFilter } from "./utils";

export type DataTableProps<TData, TValue> = {
	name: string;
	columnDefs: ColumnDef<TData, TValue>[];
	data?: TData[];
	loadingMessage?: string;
	columnVisibility?: VisibilityState;
	columnFilters?: ColumnFiltersState;
	onRowClick?: (row: TData) => void;
	searchString?: string;
	expandToRows?: boolean;
	tableClassName?: string;
	tableHeaderClassName?: string;
	tableBodyClassName?: string;
	tableBodyBorderColor?: string;
};

export const getInitialColumnSizing = <TData, TValue>(
	tableName: string,
	columnDefs: ColumnDef<TData, TValue>[],
) => {
	const storedColumnSizing = checkLocalStorageAvailable()
		? JSON.parse(window.localStorage.getItem(`inrev_table_${tableName}`)!)
		: {};
	const initialColumnSizing: Record<string, number> = {};

	columnDefs.forEach((columnDef) => {
		if (columnDef.id) {
			if (storedColumnSizing?.[columnDef.id]) {
				initialColumnSizing[columnDef.id] = storedColumnSizing[columnDef.id];
			} else if (columnDef.size !== undefined) {
				initialColumnSizing[columnDef.id] = columnDef.size;
			} else {
				initialColumnSizing[columnDef.id] = 180;
			}
		}
	});

	return initialColumnSizing;
};

export function DataTable<TData, TValue>({
	name,
	columnDefs,
	data,
	loadingMessage,
	columnVisibility,
	columnFilters,
	onRowClick,
	searchString,
	expandToRows,
	tableClassName,
	tableHeaderClassName,
	tableBodyClassName,
}: DataTableProps<TData, TValue>) {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [globalFilter, setGlobalFilter] = useState(searchString ?? "");
	const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(
		getInitialColumnSizing(name, columnDefs),
	);
	const parentRef = useRef<HTMLDivElement>(null);

	const table = useReactTable<TData>({
		data: data ?? [],
		columns: columnDefs,
		state: {
			sorting,
			columnVisibility,
			columnFilters,
			globalFilter,
			columnSizing,
		},
		defaultColumn: {
			minSize: 130,
		},

		renderFallbackValue: "--",
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: fuzzyFilter,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnSizingChange: setColumnSizing,
		autoResetAll: false,
	});

	const headerCellComponents = table.getHeaderGroups().map((headerGroup) => {
		return headerGroup.headers.map((header) => {
			if (header.id === "action_menu") {
				return null;
			}
			return (
				<TableHeaderCell
					key={header.id}
					header={header}
					columnSizingInfo={table.getState().columnSizingInfo}
					columnSizing={columnSizing[header.id]}
					setColumnSizing={(newColumnSize) =>
						setColumnSizing({ ...columnSizing, [header.id]: newColumnSize })
					}
				/>
			);
		});
	});

	const { rows } = table.getRowModel();
	const rowsIncludeActionMenu = columnDefs[columnDefs.length - 1].id === "action_menu";

	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 58,
		overscan: 20,
	});

	const rowComponents = (() => {
		if (rows.length) {
			return rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
				const row = rows[virtualRow.index] as Row<TData>;
				return (
					<div
						key={row.id}
						className="group min-w-full bg-white flex items-center border-b border-b-gray-100 text-[15px] text-gray-700 cursor-pointer hover:bg-[rgba(251,252,253,1)] hover:text-gray-950 last:border-gray-100"
						style={{
							height: `${virtualRow.size}px`,
							transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
						}}
						onClick={() => {
							onRowClick && onRowClick(row.original);
						}}
					>
						{row.getVisibleCells().map((cell) => {
							if (cell.column.id === "action_menu") {
								return (
									<div key={cell.id} className="sticky w-0 overflow-visible h-fit right-[5px]">
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</div>
								);
							}
							return (
								<TableCell
									key={cell.id}
									cell={cell}
									noTruncate={
										(cell.column.columnDef.meta as { noTruncate?: boolean } | undefined)
											?.noTruncate ?? false
									}
									rowsIncludeActionMenu={rowsIncludeActionMenu}
								/>
							);
						})}
					</div>
				);
			});
		} else {
			return <div></div>;
		}
	})();

	useEffect(() => {
		if (table.getState().columnSizingInfo.isResizingColumn) {
			document.body.style.cursor = "ew-resize";
		} else {
			document.body.style.cursor = "initial";
			if (checkLocalStorageAvailable() && !(Object.keys(columnSizing).length === 0)) {
				window.localStorage.setItem(`inrev_table_${name}`, JSON.stringify(columnSizing));
			}
		}
	}, [table.getState().columnSizingInfo.isResizingColumn]);

	useEffect(() => {
		setGlobalFilter(searchString ?? "");
	}, [searchString]);

	return (
		// Parent container
		<div
			className={cn(
				"relative w-full",
				expandToRows ? "h-fit min-h-fit overflow-hidden" : "h-full",
				tableClassName,
			)}
			style={expandToRows ? { height: rowVirtualizer.getTotalSize() + 33 } : undefined}
		>
			{/* Overflow wrapper */}
			<div ref={parentRef} className={cn("relative h-full w-full overflow-auto bg-gray-50")}>
				{/* Table */}
				<div className="w-full" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
					{/* Header */}
					<div
						className={cn(
							"sticky z-10 top-0 left-0 right-0 h-[33px] min-w-fit flex bg-gray-100 border-y border-gray-200 select-none",
							tableHeaderClassName,
						)}
					>
						{headerCellComponents}
					</div>
					{/* Body */}
					<div
						className={cn("min-w-full h-fit min-h-full flex flex-col", tableBodyClassName)}
						style={{ width: table.getTotalSize() }}
					>
						{!!data && rowComponents}
						{!!!data && (
							<div className="mt-[32px] self-center text-gray-500 italic text-[13px] animate-pulse">
								{loadingMessage ?? "Loading..."}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="absolute z-20 top-0 left-0 w-[1px] h-[33px] bg-gray-200" />
			<div className="absolute z-20 top-0 right-0 w-[1px] h-[33px] bg-gray-200" />
			<div className="absolute z-20 top-[33px] left-0 bottom-0 w-[1px] bg-gray-100" />
			<div className="absolute z-20 top-[33px] right-0 bottom-0 w-[1px] bg-gray-100" />
			<div className="absolute z-20 right-0 left-0 bottom-0 h-[1px] bg-gray-100" />
		</div>
	);
}

import { useMemo } from "react";
import { cn } from "../lib/utils";

type CompletionBarProps = {
	value: number;
	max: number;
	formatter?: (value: number) => string;
	postfix?: string;
	showValues?: boolean;
	hideMax?: boolean;
	hideBar?: boolean;
	className?: string;
	barWrapperClassName?: string;
	barClassName?: string;
	overflowClassName?: string;
};

export const CompletionBar = ({
	value,
	max,
	formatter,
	postfix,
	hideMax,
	hideBar,
	className,
	barWrapperClassName,
	barClassName,
	overflowClassName,
}: CompletionBarProps) => {
	const formattedValue = useMemo(() => (formatter ? formatter(value) : value), [value]);
	const formattedMax = useMemo(() => (formatter ? formatter(max) : max), [max]);

	return (
		<div className={cn("w-full flex flex-col space-y-[8px] min-w-fit", className)}>
			{!hideMax && (
				<div className="text-[13px] text-gray-700">
					{formattedValue}
					{!hideMax ? ` / ${formattedMax}` : ""}
					{!!postfix && ` ${postfix}`}
				</div>
			)}
			{!hideBar && (
				<div
					className={cn(
						"flex h-[15px] w-full border-[1px] border-gray-500 rounded-[3px] p-[2px] shadow-sm",
						barWrapperClassName,
					)}
				>
					<div
						className={cn(
							"bg-inrev-green h-full",
							barClassName,
							value > max ? overflowClassName : undefined,
						)}
						style={{ width: value === 0 ? 0 : `${(Math.min(value / max, 1) * 100).toFixed(2)}%` }}
					></div>
				</div>
			)}
		</div>
	);
};

import { ReactNode, createContext, useEffect, useState } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { Modal } from "../components/layout/Modal";
import { Button } from "../components/ui/Button";
import { Card } from "../components/ui/Card";
import { ApiError } from "../domain/shared/types";

export const GlobalErrorMessageModalContext = createContext<{
	triggerErrorModal: (err?: ApiError) => void;
}>(undefined!);

type GlobalErrorMessageModalWrapperProps = {
	children: ReactNode;
};

export const GlobalErrorMessageModalProvider = ({
	children,
}: GlobalErrorMessageModalWrapperProps) => {
	const [open, setOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState<
		{ header: string; description: string } | undefined
	>(undefined);
	const triggerErrorModal = (err?: ApiError) => {
		if (err && err.externalMessage) setErrorMessage(err.externalMessage);
		setOpen(true);
	};

	useEffect(() => {
		if (!open) setErrorMessage(undefined);
	}, [open]);

	return (
		<>
			<GlobalErrorMessageModalContext.Provider value={{ triggerErrorModal }}>
				{children}
			</GlobalErrorMessageModalContext.Provider>
			{open && (
				<Modal onClickOutside={() => setOpen(false)}>
					<Card className="flex flex-col items-center gap-y-[24px] p-[24px] w-[500px] max-w-[500px]">
						<div className="flex space-x-[28px] w-full px-[28px] py-[22px] border border-opacity-40 border-red-500 bg-red-50 rounded">
							<HiOutlineExclamationCircle className="text-[32px] stroke-[1.75] text-red-600/90 shrink-0 mt-[3px]" />
							<div className="text-red-600/90 flex flex-col space-y-[10px]">
								<span className="text-[20px] font-semibold">
									{errorMessage && <p>{errorMessage.header}</p>}
									{!errorMessage && <p>Something went wrong</p>}
								</span>
								<span className="text-[15px] font-medium">
									{errorMessage && <p>{errorMessage.description}</p>}
									{!errorMessage && (
										<p>
											An inRev engineer has been notified of your issue and is investigating. In
											some cases, it's useful to refresh the page and try again.
											<br />
											<br />
											Apologies for the inconvenience.
										</p>
									)}
								</span>
							</div>
						</div>
						<Button onClick={() => setOpen(false)} color="gray" filled className="w-1/2">
							OK
						</Button>
					</Card>
				</Modal>
			)}
		</>
	);
};

import { FieldError, Merge } from "react-hook-form";
import { cn } from "../../lib/utils";

type FormErrorProps = {
	error?: Merge<FieldError, (FieldError | undefined)[]>;
	errorMessage?: string;
	className?: string;
};

export const FormError = ({ error, errorMessage, className }: FormErrorProps) =>
	error ? (
		<div
			className={cn(
				"absolute left-0 top-[-8px] mx-[8px] px-[4px] bg-white text-[12px] leading-[16px] font-normal text-red-400",
				className,
			)}
		>
			{errorMessage ?? error.message}
		</div>
	) : null;

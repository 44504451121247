import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { DefaultIndividualFormData, IndividualOwnerFormData, IndividualSpouseFormData } from ".";
import { UseAddressValidationReturn } from "../../../../../../../../api";
import { FormItem } from "../../../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../../../components/layout/form/FormRow";
import { Button } from "../../../../../../../../components/ui/Button";
import { YesNo } from "../../../../../../../../components/ui/YesNo";
import { FormAddressAutocomplete } from "../../../../../../../../components/ui/form/FormAddressAutocomplete";
import { FormDropdown } from "../../../../../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../../../../components/ui/form/FormQuestionLabel";
import { NameSuffix } from "../../../../../../../../types";
import { formatAddress } from "../../../../../../../../utils";

type IndividualSpouseFormProps = {
	form: UseFormReturn<DefaultIndividualFormData, any, IndividualSpouseFormData>;
	addressValidation: UseAddressValidationReturn;
	validatedOwnerData: IndividualOwnerFormData | undefined;
	onBack: () => void;
	onSubmit: (data: IndividualSpouseFormData) => void;
};

export const IndividualSpouseForm = ({
	form,
	addressValidation,
	validatedOwnerData,
	onBack,
	onSubmit,
}: IndividualSpouseFormProps) => {
	const spouseType = form.watch("type");

	const handleSubmit = (formData: IndividualSpouseFormData) => {
		addressValidation.resetAddressValidation();
		onSubmit(formData);
	};

	useEffect(() => {
		if (
			validatedOwnerData &&
			spouseType === "" &&
			addressValidation.addressFieldControl.value === ""
		) {
			addressValidation.setAddressValue(formatAddress(validatedOwnerData.address));
		}
	}, []);

	return (
		<form className="w-full h-fit flex flex-col space-y-[25px]">
			<FormItem>
				<FormQuestionLabel condensed>Is the spouse also an owner of the company?</FormQuestionLabel>
				<YesNo
					value={spouseType === "" ? spouseType : spouseType === "owner"}
					onChange={(value) =>
						form.setValue("type", value === true ? "owner" : value === false ? "spouse" : "")
					}
				/>
			</FormItem>
			<FormItemGroup condensed>
				<FormRow>
					<FormItem className="flex-2" condensed>
						<FormItemLabel condensed>First Name</FormItemLabel>
						<FormInput name="firstName" control={form.control} condensed />
					</FormItem>
					<FormItem className="max-w-[90px]" condensed>
						<FormItemLabel condensed>Middle Initial</FormItemLabel>
						<FormInput
							name="middleInitial"
							control={form.control}
							maxLength={1}
							transformer={{
								input: (value) => value as string,
								output: (value) => value.toUpperCase(),
							}}
							condensed
						/>
					</FormItem>
					<FormItem condensed className="flex-2">
						<FormItemLabel condensed>Last Name</FormItemLabel>
						<FormInput name="lastName" control={form.control} condensed />
					</FormItem>
					<FormItem className="w-[100px] max-w-[100px]" condensed>
						<FormItemLabel condensed>Suffix</FormItemLabel>
						<FormDropdown
							name="suffix"
							control={form.control}
							options={Object.values(NameSuffix).map((suffix) => ({
								value: suffix,
								label: suffix,
							}))}
							buttonClassName="h-[46px] text-[16px]"
						/>
					</FormItem>
				</FormRow>
				<FormItem condensed>
					<FormItemLabel condensed>Address</FormItemLabel>
					<FormAddressAutocomplete
						control={addressValidation.addressFieldControl}
						className="h-[46px] text-[16px]"
					/>
				</FormItem>
				<FormRow>
					<FormItem condensed>
						<FormItemLabel condensed>
							Email
							<span className="text-gray-400 text-[13px] ml-[8px] font-thin">If Available</span>
						</FormItemLabel>
						<FormInput name="email" control={form.control} condensed />
					</FormItem>
					{spouseType === "owner" && (
						<FormItem className="max-w-[200px]" condensed>
							<FormItemLabel condensed>SSN</FormItemLabel>
							<FormInput name="ssn" control={form.control} type="numeric" maxLength={9} condensed />
						</FormItem>
					)}
				</FormRow>
			</FormItemGroup>
			<div className="w-full flex justify-center pt-[5px] space-x-[10px]">
				<Button onClick={onBack} color="gray" className="w-[150px] h-[36px]">
					Back
				</Button>
				<Button
					onClick={form.handleSubmit(handleSubmit)}
					color="light-blue"
					filled
					className="w-[150px] h-[36px]"
					disabled={spouseType === ""}
					loading={form.formState.isSubmitting}
				>
					Save
				</Button>
			</div>
		</form>
	);
};

import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { Button, ButtonProps } from "../ui/Button";

export type ShrinkingHeaderProps = {
	title: string;
	shrunk: boolean;
	button?: {
		content?: ReactNode;
		props?: ButtonProps;
	};
};

export const ShrinkingHeader = (props: ShrinkingHeaderProps) => {
	return (
		<div className="w-full sticky top-0 left-0 bg-white z-[1000] px-[10px]">
			<div className={`h-full flex items-center border-b border-b-gray-200 pb-[10px]`}>
				<div
					className="transition-all ease-linear flex-1 text-[24px] text-gray-800 font-semibold translate-y-[36%]"
					style={{ fontSize: props.shrunk ? 18 : 24 }}
				>
					{props.title}
				</div>
				{props.button && props.button.props && (
					<Button
						{...props.button.props}
						className={cn(
							`transition-[font-size,height] ease-linear translate-y-[45%]`,
							props.button.props.className,
						)}
						style={{ height: props.shrunk ? 22 : 32, fontSize: props.shrunk ? 11 : 13 }}
					>
						{props.button.content ?? ""}
					</Button>
				)}
			</div>
		</div>
	);
};

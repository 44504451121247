import { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import { Link } from "react-router-dom";
import { cn } from "../lib/utils";
import { FormSchemaInclude, FormSchemaIncludeProps } from "./form/FormSchemaInclude";

export type SummaryItemProps = {
	[K in keyof FormSchemaIncludeProps]-?: FormSchemaIncludeProps[K];
} & {
	label?: string;
	value?: string | "" | (string | "")[] | ReactNode;
	to?: { pathname: string; section: string; id?: string };
	vertical?: true;
	error?: FieldError | string | Record<string, any>;
};

const SummaryItemValue = ({
	value,
	vertical,
}: { value: string | ReactNode | undefined; vertical?: true }) => (
	<div className={cn("font-normal text-[15px]", vertical ? "px-[10px]" : undefined)}>
		{value ?? "--"}
	</div>
);

const SummaryItemContent = ({ label, value, vertical, error }: Omit<SummaryItemProps, "url">) => {
	const _value = (() => {
		if (Array.isArray(value)) {
			const filteredValue = value.filter((val) => val !== "" && val !== undefined);
			if (vertical) return filteredValue;
			else return filteredValue.join(", ");
		} else if (value === "") return undefined;
		else return value;
	})();

	return (
		<div
			className={cn(
				"group flex w-fit text-[14px] text-gray-900 last:!mb-0 cursor-pointer",
				vertical ? "flex-col space-y-[5px] pb-[10px]" : "items-center space-x-[8px]",
				error !== undefined ? "text-red-500" : undefined,
			)}
		>
			{label !== undefined && (
				<div className="font-semibold group-hover:underline">
					{label}
					{vertical ? "" : ":"}
				</div>
			)}

			<div className={cn("font-normal text-[15px]", vertical ? "px-[10px]" : undefined)}>
				{Array.isArray(_value) && !_value.length && (
					<SummaryItemValue vertical={vertical} value={undefined} />
				)}
				{Array.isArray(_value) && !!_value.length && (
					<div className="flex flex-col space-y-[2px]">
						{_value.map((val, index) => (
							<SummaryItemValue key={index} vertical={vertical} value={val} />
						))}
					</div>
				)}
				{!Array.isArray(_value) && <SummaryItemValue vertical={vertical} value={_value} />}
			</div>
		</div>
	);
};

const SummaryItemLink = (
	props: Omit<SummaryItemProps, "url"> & { to: NonNullable<SummaryItemProps["to"]> },
) => (
	<Link
		to={`${props.to.pathname}?section=${props.to.section}${props.to.id !== undefined ? `&st=${props.to.id}` : ""}`}
	>
		<SummaryItemContent {...props} />
	</Link>
);

export const SummaryItem = ({ to, ...props }: SummaryItemProps) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		{(() => {
			if (to !== undefined) {
				return <SummaryItemLink to={to} {...props} />;
			} else return <SummaryItemContent {...props} />;
		})()}
	</FormSchemaInclude>
);

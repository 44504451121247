import { useState } from "react";
import { IslandLayout } from "../../../../components/layout/IslandLayout";
import { Button } from "../../../../components/ui/Button";
import { Card } from "../../../../components/ui/Card";
import { getStatusTagByStatus } from "../../../../components/ui/StatusTag";
import { agentUserRoleTypeLabelMap } from "../../../../constants";
import { useFetchAgentUserPreviews } from "../../../../domain/agent/user/api";
import { formatEmpty } from "../../../../utils";
import { AgentInviteFormModal } from "./AgentInviteFormModal";
import { InvitationPending } from "./InvitationPending";

export const MyTeamView = () => {
	const [inviteFormOpen, setInviteFormOpen] = useState<boolean>(false);
	const { agentUserPreviews, agentUserPreviewsLoading, agentUserPreviewsError } =
		useFetchAgentUserPreviews();

	return (
		<IslandLayout>
			<div className="flex flex-col gap-[30px] w-[1000px] max-w-[1000px] h-fit flex-flex-col mt-[25px] mx-[40px] pb-[75px]">
				<div className="flex w-full justify-between gap-[25px]">
					<span className="text-[26px] text-gray-800 font-semibold">My Team</span>
					<Button
						color="light-blue"
						rounded
						thinFont
						onClick={() => setInviteFormOpen(true)}
						disabled={!!!agentUserPreviews || agentUserPreviewsLoading}
					>
						Invite a Team Member
					</Button>
				</div>
				<div className="w-full flex flex-col">
					<Card className="flex items-center bg-gray-100 w-full gap-[12px] px-[12px] py-[5px] border-gray-400 text-[14px] font-medium text-gray-700">
						<div className="flex-[3] truncate">Email</div>
						<div className="flex-[2] truncate">First Name</div>
						<div className="flex-[2] truncate">Last Name</div>
						<div className="flex-[2] truncate">Role</div>
						<div className="flex-[2] truncate">Status</div>
					</Card>
					{!!agentUserPreviews && !agentUserPreviewsLoading && (
						<div className="w-full h-fit flex flex-col text-gray-800">
							{agentUserPreviews
								.sort((a, b) => Number(a.invitationPending) - Number(b.invitationPending))
								.map((agentPreview) => (
									<div
										key={agentPreview.id}
										className="flex items-center gap-[12px] w-full px-[12px] py-[18px] border-b border-b-gray-200/80"
									>
										<div className="flex-[3] truncate">{agentPreview.email}</div>
										<div className="flex-[2] truncate">{agentPreview.firstName}</div>
										<div className="flex-[2] truncate">{agentPreview.lastName}</div>
										<div className="flex-[2] truncate">
											{formatEmpty(agentUserRoleTypeLabelMap[agentPreview.userRoles[0]])}
										</div>
										<div className="flex-[2] truncate">
											{agentPreview.invitationPending && <InvitationPending agent={agentPreview} />}
											{!agentPreview.invitationPending && getStatusTagByStatus("active")}
										</div>
									</div>
								))}
						</div>
					)}
					{(!!!agentUserPreviews || agentUserPreviewsLoading) && !agentUserPreviewsError && (
						<div className="w-full px-[12px] py-[18px]">
							<div className="w-full h-[30px] bg-gray-100 animate-pulse rounded-md"></div>
						</div>
					)}
				</div>
			</div>
			{inviteFormOpen && <AgentInviteFormModal onClose={() => setInviteFormOpen(false)} />}
		</IslandLayout>
	);
};

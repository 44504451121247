import { ReactNode } from "react";
import { HeaderActions } from "./HeaderActions";

export type TableLayoutProps = {
	children?: ReactNode;
	title?: ReactNode;
	includeHeaderActionButtons?: boolean;
};

export const TableLayout = (props: TableLayoutProps) => {
	return (
		<div className="w-full h-full flex flex-col px-[30px]">
			<div className="w-full min-w-fit pt-[18px] pb-[22px] flex items-center space-x-[20px]">
				<div className="flex-1 text-gray-700 font-semibold text-[17px]">{props.title}</div>
				<HeaderActions includeActionButtons={props.includeHeaderActionButtons} />
			</div>
			<div className="w-full flex-1 pb-[15px]">{props.children}</div>
		</div>
	);
};

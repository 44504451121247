import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";
import { CheckboxOption } from "./CheckboxOption";
import { Tooltip } from "./Tooltip";

export type CheckboxGroupProps = {
	value: string[];
	options: { value: string; label: string; tooltip?: string }[];
	className?: string;
	labelClassName?: string;
	onChange: (value: string[]) => void;
	error?: FieldError;
	errorMessage?: string;
};

export const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>(
	({ value, options, className, labelClassName, onChange, error, errorMessage }, _ref) => (
		<div
			className={cn(
				"relative flex flex-col w-fit px-[25px] py-[16px] rounded-md border border-transparent",
				className,
				error ? "border-red-500" : undefined,
			)}
		>
			{options.map((option, index) => {
				const checked = value.includes(option.value);
				return (
					<div
						key={index}
						className="flex space-x-[15px] items-center cursor-pointer"
						onClick={() => {
							if (checked) onChange(value.filter((val) => val !== option.value));
							else onChange([...value, option.value]);
						}}
					>
						<CheckboxOption
							value={option.value}
							checked={checked}
							label={option.label}
							labelClassName={labelClassName}
							tooltip={
								option.tooltip && (
									<Tooltip id={option.label} content={option.tooltip} className="text-[16px]" />
								)
							}
						/>
					</div>
				);
			})}
			<FormError error={error} errorMessage={errorMessage} className="!mt-0 top-[-9px]" />
		</div>
	),
);

import { ReactNode } from "react";
import { StackedCard } from "../../../components/ui/StackedCard";

type BondRequestCardLayoutProps = {
	banner?: ReactNode;
	children: ReactNode;
};

export const BondRequestCardLayout = ({ banner, children }: BondRequestCardLayoutProps) => (
	<div className="w-full h-fit flex flex-col space-y-[12px]">
		{!!banner && banner}
		<StackedCard className="w-full h-fit max-h-fit">
			<div id="bond_request_card" className="w-full h-fit flex flex-col">
				{children}
			</div>
		</StackedCard>
	</div>
);

import { ReactNode } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { cn } from "../lib/utils";

type InfoCardProps = {
	className?: string;
	children?: ReactNode;
};

export const InfoCard = ({ className, children }: InfoCardProps) => (
	<div
		className={cn(
			"flex space-x-[15px] w-full px-[25px] py-[22px] border border-opacity-40 border-inrev-light-blue bg-opacity-[.07] bg-inrev-light-blue rounded",
			className,
		)}
	>
		<HiOutlineInformationCircle className="w-[55px] text-[24px] mt-[1px] stroke-[1.75] text-inrev-light-blue" />
		<div className="text-gray-900 text-[15px]">{children}</div>
	</div>
);

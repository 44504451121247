import { z } from 'zod';
import { Data as Data$1 } from './contract/contract-surety-carrier-agency-relationship.types.mjs';
import * as contractSuretyCarrierAgencyRelationship_types from './contract/contract-surety-carrier-agency-relationship.types.mjs';
export { contractSuretyCarrierAgencyRelationship_types as Contract };

var Data;
(function (Data) {
    Data.schema = z.object({
        powersAndSeals: z.boolean(),
        contract: Data$1.schema.nullish(),
    });
})(Data || (Data = {}));

export { Data };

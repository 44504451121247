import { useQuery } from "react-query";
import { useRequest } from "../../../utils/request";
import { AgencyStats } from "./types";

export const useGetAgencyStats = () => {
	const { get } = useRequest();
	const { data, error, isLoading } = useQuery({
		queryKey: ["agencyStats"],
		queryFn: async () => await get<AgencyStats>("/v2/agency/dashboard"),
		retry: false,
	});

	return { agencyStats: data, agencyStatsError: error, agencyStatsIsLoading: isLoading };
};

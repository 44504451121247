import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/react";
import { forwardRef, useEffect, useState } from "react";
import { HiOutlineMapPin } from "react-icons/hi2";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { AddressFormFieldControl } from "../../api";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

export type AddressAutocompleteProps = {
	value: AddressFormFieldControl["value"];
	onChange?: AddressFormFieldControl["onChange"];
	onBlur?: AddressFormFieldControl["onBlur"];
	placeholder?: string;
	disabled?: boolean;
	className?: string;
	condensed?: true;
	error?: AddressFormFieldControl["error"];
};

export const AddressAutocomplete = forwardRef<HTMLElement, AddressAutocompleteProps>(
	({ value, onChange, onBlur, placeholder, className, condensed, error, disabled }, _ref) => {
		const [formattedSelection, setFormattedSelection] = useState<string | undefined>();
		const {
			ready,
			value: autocompleteValue,
			suggestions: { status, data },
			setValue: setAutocompleteValue,
			clearSuggestions,
		} = usePlacesAutocomplete({
			defaultValue: value,
			debounce: 200,
			requestOptions: {
				componentRestrictions: {
					country: ["us", "pr", "vi", "gu"],
				},
				types: ["street_address", "subpremise", "premise", "post_box"],
			},
		});

		useEffect(() => {
			if (!!formattedSelection) {
				setAutocompleteValue(formattedSelection, false);
			}
		}, [formattedSelection]);

		useEffect(() => {
			onChange && onChange(autocompleteValue);
		}, [autocompleteValue]);

		useEffect(() => {
			if (value !== autocompleteValue) {
				setAutocompleteValue(value, false);
			} else if (!!formattedSelection) {
				onBlur && onBlur();
				setFormattedSelection(undefined);
			}
		}, [value]);

		const handleSelect = ({ description }: { description: string }) => {
			getGeocode({ address: description }).then((results) => {
				const { formatted_address } = results[0];
				setFormattedSelection(formatted_address);
			});
			clearSuggestions();
		};

		const renderSuggestions = () =>
			data.map((suggestion) => {
				const {
					place_id,
					structured_formatting: { main_text, secondary_text },
				} = suggestion;

				return (
					<div
						key={place_id}
						className={
							"flex items-center text-gray-700 w-full py-[9px] px-[10px] space-x-[5px] hover:bg-gray-100"
						}
						onClick={() => handleSelect(suggestion)}
					>
						<span className="font-medium">{main_text}</span>
						<span className="text-gray-500 font-normal text-[15px]">{secondary_text}</span>
					</div>
				);
			});

		return (
			<Combobox value={value} disabled={!ready}>
				<div
					className={cn(
						"relative flex w-full h-[50px] rounded-md outline outline-[1px] outline-offset-[-1px] text-gray-900 outline-gray-300 bg-gray-50 bg-opacity-70 text-[16.5px] leading-[20px] placeholder:text-gray-500/85 focus-within:outline-[2px] focus-within:outline-gray-600 disabled:cursor-not-allowed disabled:opacity-50",
						disabled ? "cursor-not-allowed opacity-50" : "hover:outline-gray-600",
						className,
						condensed ? "!h-[46px] text-[16px]" : undefined,
						error
							? "outline-red-500 focus-within:outline-red-500 hover:outline-red-500"
							: undefined,
					)}
				>
					<div className="h-full w-[54px] flex items-center justify-center rounded-l-md text-gray-700 bg-gray-100 border-r border-r-gray-300">
						<HiOutlineMapPin className="text-[20px]" />
					</div>
					<ComboboxInput
						value={autocompleteValue}
						tabIndex={0}
						onChange={(e) => setAutocompleteValue(e.target.value)}
						onBlur={() => {
							onBlur && onBlur();
						}}
						placeholder={placeholder}
						// Needed to disable browser auto-filling
						autoComplete="one-time-code"
						data-1p-ignore
						data-lpignore
						className="px-[18px] py-[17px] w-full outline-none bg-transparent"
					/>
					{status === "OK" && (
						<ComboboxOptions
							onMouseDown={(e) => e.preventDefault()}
							className="absolute w-fit min-w-full top-full px-0 mt-[5px] text-gray-700 font-normal rounded bg-white shadow-lg z-[10] cursor-pointer select-none outline outline-[1px] outline-gray-100 outline-offset-[-1px]"
						>
							{renderSuggestions()}
						</ComboboxOptions>
					)}
					<FormError error={error} className="left-[42px]" />
				</div>
			</Combobox>
		);
	},
);

import FocusLock from "react-focus-lock";
import { Spinner } from "../ui/Spinner";

export const LoadingModal = () => (
	<FocusLock returnFocus>
		<div className="absolute top-0 right-0 bottom-0 left-0 bg-white/50 flex items-center justify-center pb-[10%] z-50">
			<Spinner />
			<button className="opacity-0 w-0 h-0" />
		</div>
	</FocusLock>
);

import { LabelHTMLAttributes } from "react";
import { cn } from "../../lib/utils";

export type FormLabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
	marker?: boolean;
	condensed?: true;
	compact?: true;
};

export const FormItemLabel = ({ marker, condensed, compact, ...props }: FormLabelProps) => {
	return (
		<div className="relative h-fit">
			<label
				{...props}
				className={cn(
					"text-[13px] text-gray-800/95 font-[515]",
					condensed ? "text-[13px] font-[515]" : compact ? "text-[13px] font-[515]" : undefined,
					props.className,
				)}
			>
				{props.children}
			</label>
			{marker && (
				<div className="absolute top-[11px] left-[-14px] border-b-[4px] border-b-gray-300 border-l-[3px] border-l-transparent border-r-[3px] border-r-transparent rotate-90"></div>
			)}
		</div>
	);
};

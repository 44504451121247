import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { StackedCard, StackedCardProps } from "./StackedCard";

export type StackedCardGrayBodyProps = StackedCardProps & {
	header: ReactNode;
	contentClassName?: string;
	hideBody?: boolean;
};

export const StackedCardGrayBody = ({
	className,
	header,
	contentClassName,
	hideBody,
	children,
}: StackedCardGrayBodyProps) => (
	<StackedCard className={className}>
		<div className="w-full h-full min-h-fit flex flex-col">
			<div
				className={cn(
					"w-full px-[33px] pt-[26px] pb-[25px] bg-white border-b border-b-gray-300 rounded-t-md",
					hideBody ? "border-b-0 rounded-b-md" : undefined,
				)}
			>
				{header}
			</div>
			{!hideBody && (
				<div
					className={cn(
						"w-full h-fit min-h-fit flex-1 flex flex-col py-[35px] px-[45px] space-y-[18px] rounded-b-md bg-gray-50",
						contentClassName,
					)}
				>
					{children}
				</div>
			)}
		</div>
	</StackedCard>
);

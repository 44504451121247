import { NotificationCard } from "../../../components/ui/NotificationCard";
import { AdminBondRequest } from "../../../domain/admin/request/types";

type AdminBondRequestActionCardStackProps = {
	request: AdminBondRequest;
};

export const AdminBondRequestNotificationStack = ({
	request,
}: AdminBondRequestActionCardStackProps) => {
	const actionsRequired = (() => {
		const actions = [];
		if (request.status === "review") {
			actions.push("Review terms");
			if (request.bondForm.source === "upload") {
				actions.push("Review uploaded bond form");
			}
		}
		if (request.status === "pending") {
			if (request.bondIssuance.pendingBondFormReview) {
				if (request.bondForm.issuanceMethod === "agent") {
					actions.push("Review uploaded bond form for agent issue");
				} else {
					actions.push("Review uploaded bond form");
				}
			}
			if (request.bondIssuance.pendingInRevIssue) {
				if (request.bondForm.issuanceMethod === "electronic") {
					actions.push(
						"Fill uploaded bond form, add digital seal and signatures, and send to the agent for electronic issue",
					);
				} else {
					if (request.bondForm.source === "library") {
						actions.push(
							"Print pre-filled bond form, add physical seal and wet signatures, and send both electronic and physical copies to the agent",
						);
					}
					if (request.bondForm.source === "upload") {
						actions.push(
							"Print and fill uploaded bond form, add physical seal and wet signatures, and send both electronic and physical copies to the agent",
						);
					}
				}
			}
			if (request.bondIssuance.pendingAgentIssue) {
				actions.push("Upload a copy of the agent issued bond form");
			}
			if (request.bondIssuance.pendingAgentIssueAdminReview) {
				actions.push("Review and finalize agent issued bond form");
			}
		}
		return actions;
	})();

	const pendingItems = (() => {
		const pendingItems: string[] = [];
		if (request.status === "pending") {
			if (request.bondIssuance.pendingIndemnityAgreement) {
				pendingItems.push("Waiting for one or more indemnitors to sign the indemnity agreement");
			}
			if (request.bondIssuance.pendingAgentIssue && !request.bondIssuance.pendingBondFormReview) {
				pendingItems.push("Pending agent issue");
			}
		}
		return pendingItems;
	})();

	return actionsRequired.length > 0 || pendingItems.length > 0 ? (
		<div className="w-full flex flex-col space-y-[10px] mb-[-5px]">
			{actionsRequired.map((action, index) => (
				<NotificationCard key={index} type="action" text={action} />
			))}
			{pendingItems.map((pendingItem, index) => (
				<NotificationCard key={index} type="pending" text={pendingItem} />
			))}
		</div>
	) : (
		<></>
	);
};

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import {
	BaseNAICSCode,
	NAICSCode,
	NAICSCodeGroupMap,
	UnwrapMap,
	getBaseNaicsCode,
	isSetOverlap,
	isSubsetOf,
	naicsCodeGroupMap,
} from "@inrev/common";
import { RankingInfo, rankItem, rankings } from "@tanstack/match-sorter-utils";
import { ReactNode, forwardRef, useEffect, useMemo, useState } from "react";
import { FieldError, Merge } from "react-hook-form";
import {
	HiChevronDoubleRight,
	HiChevronRight,
	HiOutlinePencilSquare,
	HiPlus,
} from "react-icons/hi2";
import { useIsFirstRender } from "../../utils";
import { NAICSCodesList } from "../../views/admin/shared/NAICSCodeList";
import { Modal } from "../layout/Modal";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import { MultiSelectContent, MultiSelectSearch } from "./GroupMultiSelect";

export type NAICSCodeSelectProps = {
	value: NAICSCode[];
	title: string;
	buttonClassName?: string;
	buttonIconClassName?: string;
	selectText: string;
	editText: string;
	searchPlaceholder?: string;
	error?: Merge<FieldError, (FieldError | undefined)[]>;
	errorMessage?: string;
	readonly?: boolean;
	onChange: (value: NAICSCode[]) => void;
	onBlur?: () => void;
	subsetFilters?: [
		{ label: string; subset: NAICSCode[] },
		...{ label: string; subset: NAICSCode[] }[],
	];
	defaultSubsetIndex?: number;
	allNAICSCodesLabel?: string;
} & (
	| {
			subsetFilters: [
				{ label: string; subset: NAICSCode[] },
				...{ label: string; subset: NAICSCode[] }[],
			];
			defaultSubsetIndex?: number;
			allNAICSCodesLabel: string;
	  }
	| {
			subsetFilters?: undefined;
			defaultSubsetIndex?: undefined;
			allNAICSCodesLabel?: undefined;
	  }
);

const fuzzyMatchAndSort = (searchString: string, subsetFilterSet?: Set<NAICSCode>) => {
	const resultMap: NAICSCodeGroupMap = new Map();
	let matchedGroups: [RankingInfo, RankingInfo, ...UnwrapMap<NAICSCodeGroupMap>, boolean][] = [];
	let subCodes: [RankingInfo, NAICSCode, string][] = [];
	naicsCodeGroupMap.forEach((group, baseNaicsCode) => {
		const groupRanking = rankItem(group.label, searchString, {
			accessors: [
				{
					accessor: (data) => data,
					threshold: rankings.WORD_STARTS_WITH,
					maxRanking: rankings.EQUAL,
				},
			],
		});
		subCodes = [];
		group.subCodes.forEach((label, subCode) => {
			if (!!!subsetFilterSet || subsetFilterSet.has(subCode)) {
				const subCodeRanking = rankItem(label, searchString, {
					accessors: [
						{
							accessor: (data) => data,
							threshold: rankings.WORD_STARTS_WITH,
							maxRanking: rankings.EQUAL,
						},
					],
				});
				if (subCodeRanking.passed || groupRanking.passed) {
					subCodes.push([subCodeRanking, subCode, label]);
				}
			}
		});
		subCodes = subCodes.sort((a, b) => (a[0].rank > b[0].rank ? -1 : 1));
		if (subCodes.length) {
			matchedGroups.push([
				groupRanking,
				subCodes[0][0],
				baseNaicsCode,
				{
					label: group.label,
					subCodes: new Map(subCodes.map(([_, subCode, label]) => [subCode, label])),
				},
				false,
			]);
		}
	});
	matchedGroups = matchedGroups.sort((a, b) => {
		const aMax = Math.max(a[0].rank, a[1].rank);
		const bMax = Math.max(b[0].rank, b[1].rank);
		if (aMax > bMax || (aMax === bMax && a[3])) return -1;
		return 1;
	});
	matchedGroups.forEach((entry) => {
		resultMap.set(entry[2], entry[3]);
	});

	return resultMap;
};

const NAICSCodeSelectGroup = ({
	label,
	size,
	allSelected,
	subsetSelected,
	subCodeSize,
	onSelect,
	onDeselect,
	children,
}: {
	label: string;
	size: number;
	allSelected: boolean;
	subsetSelected: boolean;
	subCodeSize: number;
	onSelect: () => void;
	onDeselect: () => void;
	children: ReactNode;
}) => (
	<Disclosure as="div" className="flex flex-col w-full min-h-fit relative">
		<div className="sticky left-0 top-0 right-0 h-[48px] mb-[-48px] shadow-sm"></div>
		<DisclosureButton
			as="div"
			className="group/expand z-20 flex items-center h-[48px] space-x-[16px] sticky top-0 left-0 right-0 text-[14px] text-gray-600 font-semibold px-[25px] mb-[-1px] bg-white cursor-pointer"
		>
			<div className="flex items-center h-full">
				<HiChevronRight className="text-[16px] stroke-[.75] text-gray-400 group-hover/expand:text-gray-800 ui-open:text-gray-800 ui-open:rotate-90" />
			</div>
			<div className="flex-1 flex items-center space-x-[10px]">
				<Checkbox
					checked={allSelected}
					partialChecked={subsetSelected}
					onClick={(e) => {
						e.stopPropagation();
						allSelected || subsetSelected ? onDeselect() : onSelect();
					}}
				/>
				<span>{label}</span>
			</div>
			{size > 0 && (
				<span className="font-medium text-[13px] text-gray-500">
					{size}/{subCodeSize}
				</span>
			)}
		</DisclosureButton>
		<DisclosurePanel className="relative flex-1 flex flex-col mt-[3px] mx-[20px]">
			{children}
			<div className="absolute left-[-20px] right-[-20px] top-[-2px] h-[2px] bg-white"></div>
		</DisclosurePanel>
	</Disclosure>
);

const NAICSCodeSelectSubCodeOption = ({
	label,
	selected,
	onSelect,
	onDeselect,
	optionClassName,
}: {
	label: string;
	selected: boolean;
	onSelect: () => void;
	onDeselect: () => void;
	optionClassName?: string;
}) => (
	<div
		className={cn(
			"group flex items-center text-gray-800 w-full py-[10px] pl-[20px] pr-[10px] mb-[-1px] [&:nth-last-child(3)]:mb-0 space-x-[15px] hover:bg-gray-100 border-x border-b first:border-t border-gray-200 cursor-pointer hover:outline hover:outline-[1px] hover:outline-offset-[-1px] hover:outline-gray-300",
			optionClassName,
		)}
		onClick={() => {
			selected ? onDeselect() : onSelect();
		}}
	>
		<Checkbox checked={selected} />
		<div className="h-fit flex-1 text-[15px] text-gray-800 font">{label}</div>
	</div>
);

const onGroupSelect = (
	group: UnwrapMap<NAICSCodeGroupMap>[1],
	value: NAICSCode[],
	onChange: (value: NAICSCode[]) => void,
) => {
	const subCodes: NAICSCode[] = [];
	group.subCodes.forEach((_, subCode) => {
		subCodes.push(subCode);
	});
	onChange([...new Set([...value, ...subCodes])]);
};

const onGroupDeselect = (
	group: UnwrapMap<NAICSCodeGroupMap>[1],
	value: NAICSCode[],
	onChange: (value: NAICSCode[]) => void,
) => {
	const subCodesSet: Set<NAICSCode> = new Set(value);
	group.subCodes.forEach((_, subCode) => {
		subCodesSet.delete(subCode);
	});
	onChange([...subCodesSet]);
};

const onSubCodeSelect = (
	subCode: NAICSCode | Set<NAICSCode>,
	value: NAICSCode[],
	onChange: (value: NAICSCode[]) => void,
) => {
	if (typeof subCode === "string") {
		onChange([...new Set([...value, subCode])]);
	} else {
		onChange([...new Set([...value, ...subCode.values()])]);
	}
};

const onSubCodeDeselect = (
	subCode: NAICSCode | Set<NAICSCode>,
	value: NAICSCode[],
	onChange: (value: NAICSCode[]) => void,
) => {
	if (typeof subCode === "string") {
		onChange(value.filter((code) => code !== subCode));
	} else {
		onChange(value.filter((code) => !subCode.has(code)));
	}
};

const getGroupComponents = (
	groupMap: NAICSCodeGroupMap,
	baseNAICSCodeSet: Set<BaseNAICSCode>,
	value: NAICSCode[],
	valueSet: Set<NAICSCode>,
	onChange: (value: NAICSCode[]) => void,
) => {
	const groupComponents: ReactNode[] = [];
	let groupKey = 0;
	groupMap.forEach((group, baseNaicsCode) => {
		if (
			(() => {
				if (typeof baseNaicsCode === "string") {
					if (baseNAICSCodeSet.has(baseNaicsCode)) {
						return true;
					}
				} else if (isSetOverlap(baseNAICSCodeSet, baseNaicsCode)) {
					return true;
				}
				return false;
			})()
		) {
			const subCodeComponents: ReactNode[] = [];
			let subCodeKey = 0;
			let selectedSubCodeCount = 0;
			group.subCodes.forEach((label, subCode) => {
				subCodeComponents.push(
					<NAICSCodeSelectSubCodeOption
						key={++subCodeKey}
						label={label}
						selected={(() => {
							if (typeof subCode === "string") {
								if (valueSet.has(subCode)) {
									selectedSubCodeCount++;
									return true;
								}
							} else if (isSubsetOf(subCode, valueSet)) {
								selectedSubCodeCount++;
								return true;
							}
							return false;
						})()}
						onSelect={() => onSubCodeSelect(subCode, value, onChange)}
						onDeselect={() => onSubCodeDeselect(subCode, value, onChange)}
					/>,
				);
			});
			groupComponents.push(
				<NAICSCodeSelectGroup
					key={++groupKey}
					label={group.label}
					size={selectedSubCodeCount}
					subCodeSize={group.subCodes.size}
					allSelected={selectedSubCodeCount === group.subCodes.size}
					subsetSelected={selectedSubCodeCount !== group.subCodes.size}
					onSelect={() => onGroupSelect(group, value, onChange)}
					onDeselect={() => onGroupDeselect(group, value, onChange)}
				>
					{subCodeComponents}
					<div className="w-full h-[12px] z-30 bg-white shrink-0"></div>
				</NAICSCodeSelectGroup>,
			);
		} else {
			const subCodeComponents: ReactNode[] = [];
			let subCodeKey = 0;
			group.subCodes.forEach((label, subCode) => {
				subCodeComponents.push(
					<NAICSCodeSelectSubCodeOption
						key={++subCodeKey}
						label={label}
						selected={false}
						onSelect={() => onSubCodeSelect(subCode, value, onChange)}
						onDeselect={() => onSubCodeSelect(subCode, value, onChange)}
					/>,
				);
			});
			groupComponents.push(
				<NAICSCodeSelectGroup
					key={++groupKey}
					label={group.label}
					size={0}
					subCodeSize={group.subCodes.size}
					allSelected={false}
					subsetSelected={false}
					onSelect={() => onGroupSelect(group, value, onChange)}
					onDeselect={() => onGroupDeselect(group, value, onChange)}
				>
					{subCodeComponents}
					<div className="w-full h-[12px] z-30 bg-white shrink-0"></div>
				</NAICSCodeSelectGroup>,
			);
		}
	});
	return groupComponents;
};

const NAICSCodeSubsetFilterListItem = ({
	label,
	selected,
	onClick,
}: { label: string; selected: boolean; onClick: () => void }) => (
	<div
		className={cn(
			selected
				? "flex items-center px-[12px] py-[8px] rounded cursor-pointer bg-inrev-blue text-[13px] font-medium text-gray-50 shadow-md"
				: "flex items-center px-[12px] py-[8px] rounded cursor-pointer bg-gray-50 hover:bg-gray-200/50 text-[13px] font-medium text-gray-600",
		)}
		onClick={onClick}
	>
		<span className="flex-1">{label}</span>
		<HiChevronDoubleRight className="text-[13px] stroke-[.75]" />
	</div>
);

export const NAICSCodeSelect = forwardRef<HTMLDivElement, NAICSCodeSelectProps>(
	(
		{
			title,
			subsetFilters,
			defaultSubsetIndex,
			allNAICSCodesLabel,
			buttonClassName,
			buttonIconClassName,
			selectText,
			editText,
			error,
			errorMessage,
			readonly,
			onChange,
			onBlur,
			...props
		},
		_ref,
	) => {
		const isFirstRender = useIsFirstRender();
		const [open, setOpen] = useState<boolean>(false);
		const subsetFilterSets = useMemo(
			() => subsetFilters?.map(({ subset }) => new Set(subset)),
			[subsetFilters],
		);
		const [selectedSubsetFilterIndex, setSelectedSubsetFilterIndex] = useState<number | undefined>(
			defaultSubsetIndex,
		);
		const [valuesChanged, setValuesChanged] = useState<boolean>(false);
		const [searchValue, setSearchValue] = useState<string>("");
		const valueSet = useMemo(() => new Set(props.value), [props.value]);
		const valueToBaseNaicsCodes = useMemo(
			() => new Set(props.value.map((code) => getBaseNaicsCode(code))),
			[props.value],
		);
		const groupComponents = useMemo(() => {
			if (searchValue !== "" || selectedSubsetFilterIndex !== undefined) {
				return getGroupComponents(
					fuzzyMatchAndSort(
						searchValue,
						selectedSubsetFilterIndex !== undefined && subsetFilterSets !== undefined
							? subsetFilterSets[selectedSubsetFilterIndex]
							: undefined,
					),
					valueToBaseNaicsCodes,
					props.value,
					valueSet,
					onChange,
				);
			}
			return getGroupComponents(
				naicsCodeGroupMap,
				valueToBaseNaicsCodes,
				props.value,
				valueSet,
				onChange,
			);
		}, [subsetFilterSets, selectedSubsetFilterIndex, searchValue, valueSet, valueToBaseNaicsCodes]);

		useEffect(() => {
			if (!open && !isFirstRender && onBlur) {
				onBlur();
			}
			setValuesChanged(false);
		}, [open]);

		useEffect(() => {
			if (!isFirstRender && !valuesChanged) {
				setValuesChanged(true);
			}
		}, [props.value]);

		return (
			<div className={cn("relative h-fit")}>
				<div className="flex flex-col space-y-[20px] py-[5px]">
					<NAICSCodesList naicsCodes={props.value} showCount />
					{!readonly && (
						<Button
							color="gray"
							filled
							thinFont
							rounded
							onClick={() => setOpen(true)}
							disabled={readonly}
							className="w-fit"
						>
							<div className="flex items-center space-x-[8px]">
								{!!props.value.length && (
									<>
										<HiOutlinePencilSquare className="text-[17px] stroke-[1.75]" />
										<span>{editText}</span>
									</>
								)}
								{!props.value.length && (
									<>
										<HiPlus className="text-[16px] stroke-[.75]" />
										<span>{editText}</span>
									</>
								)}
							</div>
						</Button>
					)}
				</div>
				{open && (
					<Modal onClickOutside={() => setOpen(false)}>
						<div
							className={cn(
								"flex flex-col min-w-full h-[85vh] overflow-y-auto mt-[20px] text-gray-700 font-normal rounded-md bg-white shadow-lg z-[10] select-none",
								!!subsetFilters && subsetFilters.length ? "w-[800px]" : "w-[600px]",
							)}
						>
							<MultiSelectSearch
								setSearchValue={setSearchValue}
								valuesChanged={valuesChanged}
								onSaveClick={setOpen}
								title={title}
								placeholder={props.searchPlaceholder ?? "Search NAICS codes"}
							/>
							<MultiSelectContent groupComponents={groupComponents} hasFilters={!!subsetFilters}>
								{!!subsetFilters && (
									<>
										{subsetFilters.map(({ label }, index) => (
											<NAICSCodeSubsetFilterListItem
												key={index}
												label={label}
												selected={index === selectedSubsetFilterIndex}
												onClick={() => setSelectedSubsetFilterIndex(index)}
											/>
										))}
										<NAICSCodeSubsetFilterListItem
											label={allNAICSCodesLabel}
											selected={selectedSubsetFilterIndex === undefined}
											onClick={() => setSelectedSubsetFilterIndex(undefined)}
										/>
									</>
								)}
							</MultiSelectContent>
						</div>
					</Modal>
				)}
				<FormError error={error} errorMessage={errorMessage} />
			</div>
		);
	},
);

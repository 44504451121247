import { Modal } from "../../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../../components/layout/ModalItemWithHeader";
import { AdminSuretyAccount } from "../../../../../domain/admin/account/types";
import { SubmittedSuretyAccount } from "../../../../../domain/agent/account/types";
import { AccountCompanyCard } from "./AccountCompanyCard";

type AccountCompaniesModalProps = {
	account: SubmittedSuretyAccount | AdminSuretyAccount;
	onRequestButtonClick: (id: string) => void;
	onClose: () => void;
	options: {
		showRequestButton: boolean;
		showPrequalButton: boolean;
	};
};

export const AccountCompaniesModal = ({
	account,
	onRequestButtonClick,
	onClose,
	options,
}: AccountCompaniesModalProps) => {
	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header={`${account.companies.length} Compan${account.companies.length === 1 ? "y" : "ies"}`}
				onClose={onClose}
				className="h-full max-h-[70vh]"
				bodyClassName="overflow-y-auto"
			>
				<div className="w-full h-full overflow-y-auto">
					<div className="flex flex-col w-[600px] min-w-[600px]">
						{account.companies.map((company) => (
							<AccountCompanyCard
								account={account}
								company={company}
								onRequestButtonClick={onRequestButtonClick}
								options={options}
							/>
						))}
					</div>
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

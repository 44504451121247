import { useEffect, useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { CurrencyInput } from "../CurrencyInput";

type FormCurrencyInputProps<TFieldValues extends FieldValues> = {
	className?: string;
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	allowNegativeValue?: boolean;
	condensed?: true;
	compact?: true;
	updateOnBlur?: true;
	readonly?: boolean;
};

export const FormCurrencyInput = <TFieldValues extends FieldValues>({
	allowNegativeValue,
	condensed,
	compact,
	updateOnBlur,
	className,
	readonly,
	...props
}: FormCurrencyInputProps<TFieldValues>) => {
	const [controlledValue, setControlledValue] = useState<string>("");

	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				useEffect(() => {
					if (updateOnBlur) setControlledValue(field.value);
				}, []);
				useEffect(() => {
					if (updateOnBlur && field.value !== controlledValue) {
						field.onBlur();
					}
				}, [field.value]);

				return (
					<CurrencyInput
						{...field}
						defaultValue={field.value}
						value={updateOnBlur ? controlledValue : field.value}
						onChange={undefined}
						onBlur={updateOnBlur ? () => field.onChange(controlledValue) : field.onBlur}
						onValueChange={(value) =>
							updateOnBlur ? setControlledValue(value ?? "") : field.onChange(value ?? "")
						}
						allowNegativeValue={allowNegativeValue}
						condensed={condensed}
						compact={compact}
						className={className}
						error={fieldState.error}
						errorMessage={props.errorMessage}
						readonly={readonly}
					/>
				);
			}}
		/>
	);
};

import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { Button } from "../ui/Button";
import { Modal } from "./Modal";

export type ConfirmationModalProps = {
	onConfirm?: () => void;
	onCancel?: () => void;
	message?: ReactNode;
	subMessage?: ReactNode;
	confirmButtonText?: string;
	confirmationButtonClassName?: string;
	cancelButton?: false;
	cancelButtonText?: string;
	cancelButtonClassName?: string;
	className?: string;
};

export const ConfirmationModal = ({
	onConfirm,
	onCancel,
	message,
	subMessage,
	confirmButtonText,
	confirmationButtonClassName,
	cancelButton,
	cancelButtonText,
	cancelButtonClassName,
	className,
}: ConfirmationModalProps) => (
	<Modal onClickOutside={onCancel}>
		<div
			className={cn(
				"py-[35px] px-[35px] bg-white flex flex-col items-center rounded-md shadow-lg space-y-[35px]",
				className,
			)}
		>
			<div className="flex flex-col items-center space-y-[15px]">
				{message !== undefined && (
					<div className="text-[19px] text-gray-900 font-normal w-full flex text-center">
						{message}
					</div>
				)}
				{subMessage !== undefined && (
					<div className="text-[16px] text-gray-600 text-center">{subMessage}</div>
				)}
			</div>
			<div className="w-fit flex space-x-[8px] items-center">
				{cancelButton !== false && (
					<Button
						color="gray"
						className={cn("w-[125px]", cancelButtonClassName)}
						thinFont
						onClick={onCancel}
					>
						{cancelButtonText ?? "Cancel"}
					</Button>
				)}
				<Button
					color="light-blue"
					className={cn("w-[125px]", confirmationButtonClassName)}
					filled
					onClick={onConfirm}
				>
					{confirmButtonText ?? "Confirm"}
				</Button>
			</div>
		</div>
	</Modal>
);

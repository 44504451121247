import { ReactNode } from "react";
import { HiOutlineXMark } from "react-icons/hi2";
import { cn } from "../lib/utils";

type ModalItemWithHeaderProps = {
	header: ReactNode;
	children?: ReactNode;
	className?: string;
	headerClassName?: string;
	bodyClassName?: string;
	onClose?: () => void;
};

export const ModalItemWithHeader = ({
	header,
	children,
	className,
	headerClassName,
	bodyClassName,
	onClose,
}: ModalItemWithHeaderProps) => (
	<div className={cn("flex flex-col bg-white rounded-md border-gray-100 shadow-lg", className)}>
		<div
			className={cn(
				"flex items-start w-full px-[20px] pt-[14px] pb-[12px] bg-gray-50 border-b border-b-gray-200 rounded-t-md text-[14px] text-gray-800 font-medium shrink-0",
				headerClassName,
			)}
		>
			<div className="flex-1">{header}</div>
			<HiOutlineXMark
				className="text-[18px] text-gray-600 hover:text-gray-800 cursor-pointer stroke-[2.25] mt-[1px]"
				onClick={onClose}
			/>
		</div>
		<div className={cn("w-full flex-1", bodyClassName)}>{children}</div>
	</div>
);

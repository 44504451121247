import { Get as Get$1 } from './contract/submitted-contract-surety-quote.dto.mjs';
import * as submittedContractSuretyQuote_dto from './contract/submitted-contract-surety-quote.dto.mjs';
export { submittedContractSuretyQuote_dto as Contract };
import * as submittedSuretyQuoteMixins from './submitted-surety-quote-mixins.mjs';
export { submittedSuretyQuoteMixins as Mixins };

var Get;
(function (Get) {
    (function (Response) {
        (function (Admin) {
            Admin.schema = Get$1.Response.Admin.schema;
        })(Response.Admin || (Response.Admin = {}));
        Response.schema = Get$1.Response.schema;
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

import { zodResolver } from "@hookform/resolvers/zod";
import { CarrierName, Dtos, SuretyAccountUnderwritingStatus, SuretyType } from "@inrev/common";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ConfirmationModalClickWrapper } from "../../../../components/layout/ConfirmationModalClickWrapper";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../components/layout/form/FormRow";
import { Button } from "../../../../components/ui/Button";
import { FormDropdown } from "../../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { FormNumberInput } from "../../../../components/ui/form/FormNumberInput";
import { FormTextArea } from "../../../../components/ui/form/FormTextArea";
import {
	AdminSuretyAccount,
	AdminUpdateAccountStatusFormData,
} from "../../../../domain/admin/account/types";
import { stripEmptyResolver } from "../../../../utils/form";
import { AdminReUnderwriteSuretyAccountModalContext } from "../AdminAccountView";
import { AdminUpdateAccountBondingLineFormCard } from "./AdminAccountBondingLine";

const getDefaultFormData = (account: AdminSuretyAccount): AdminUpdateAccountStatusFormData => {
	if (account.contract === undefined) throw new Error("Contract is undefined");
	const lnicBondingLine = account.contract.carrierBondingLines.find(
		(bondingLine) => bondingLine.carrierName === "lexington-national-for-accelerant",
	);
	const accelerantBondingLine = account.contract.carrierBondingLines.find(
		(bondingLine) => bondingLine.carrierName === "accelerant",
	);
	return {
		suretyType: SuretyType.contract,
		status: "",
		score: account.contract.underwriting.score?.toString() ?? "",
		reviewNote: account.contract.underwriting.reviewNote ?? "",
		bondingLine: {
			rate: account.contract.bondingLine?.rate?.toString() ?? "",
			singleLimit: account.contract.bondingLine?.singleLimit?.toString() ?? "",
			aggregateLimit: account.contract.bondingLine?.aggregateLimit?.toString() ?? "",
			autoUpdate: account.contract.bondingLine?.autoUpdate ?? true,
			isPublished: account.contract.bondingLine?.isPublished ?? false,
		},
		carrierBondingLines: [
			{
				carrierName: "lexington-national-for-accelerant",
				suretyType: SuretyType.contract,
				bondingLine: {
					rate: lnicBondingLine?.rate.toString() ?? "",
					singleLimit: lnicBondingLine?.singleLimit.toString() ?? "",
					aggregateLimit: lnicBondingLine?.aggregateLimit.toString() ?? "",
					autoUpdate: lnicBondingLine?.autoUpdate ?? true,
				},
			},
			{
				carrierName: "accelerant",
				suretyType: SuretyType.contract,
				bondingLine: {
					rate: accelerantBondingLine?.rate.toString() ?? "",
					singleLimit: accelerantBondingLine?.singleLimit.toString() ?? "",
					aggregateLimit: accelerantBondingLine?.aggregateLimit.toString() ?? "",
					autoUpdate: accelerantBondingLine?.autoUpdate ?? true,
				},
			},
		],
	};
};

export type AdminUpdateAccountStatusFormProps = {
	account: AdminSuretyAccount;
	showScore: boolean;
	showBondingLinesWithoutStatus: boolean;
	handleSubmit: (data: Dtos.Admin.SuretyAccount.Update.Status.Request) => void;
};

export const AdminUpdateAccountStatusFormCard = ({
	account,
	showScore,
	showBondingLinesWithoutStatus,
	handleSubmit,
}: AdminUpdateAccountStatusFormProps) => {
	const defaultFormData = useMemo(() => {
		return getDefaultFormData(account);
	}, [
		account.contract.underwriting,
		account.contract.bondingLine,
		account.contract.carrierBondingLines,
	]);
	const formMethods = useForm<
		AdminUpdateAccountStatusFormData,
		any,
		Dtos.Admin.SuretyAccount.Update.Status.Request
	>({
		defaultValues: defaultFormData,
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(
			zodResolver(Dtos.Admin.SuretyAccount.Update.Status.Request.schema),
		),
	});

	const status = formMethods.watch("status");
	const carrierBondingLines = formMethods.watch("carrierBondingLines");
	const carrierBondingLineNames = useMemo(() => {
		return carrierBondingLines.map((bondingLine) => bondingLine.carrierName);
	}, [carrierBondingLines]);
	const statusOptions = useMemo(() => {
		const options = [];
		if (account.contract.underwriting.status !== SuretyAccountUnderwritingStatus.approved)
			options.push({ label: "Approved", value: "approved" });
		if (account.contract.underwriting.status !== SuretyAccountUnderwritingStatus.declined)
			options.push({ label: "Declined", value: "declined" });
		return options;
	}, [account.status]);
	const ReUnderwriteModalButton = useContext(AdminReUnderwriteSuretyAccountModalContext);

	useEffect(() => {
		formMethods.reset(defaultFormData);
	}, [defaultFormData]);

	return (
		<>
			<form className="flex flex-col space-y-[30px] w-full px-[10px]">
				<div className="flex space-x-[60px] w-full">
					<FormItemGroup condensed className="space-y-[15px] flex-[2]">
						<FormRow>
							<FormItem condensed>
								<FormItemLabel condensed>Status</FormItemLabel>
								<FormDropdown
									name="status"
									control={formMethods.control}
									options={statusOptions}
									buttonClassName="bg-white"
									condensed
									placeholder="Select a status"
								/>
							</FormItem>
							{showScore && (
								<FormItem condensed>
									<FormItemLabel condensed>Score</FormItemLabel>
									<FormNumberInput
										name="score"
										control={formMethods.control}
										className="bg-white"
										condensed
										readonly={account.contract.underwriting.score !== undefined}
										precision={3}
										min={0}
										max={5}
										errorMessage="Required"
									/>
								</FormItem>
							)}
						</FormRow>
						{status !== "declined" && (status !== "" || showBondingLinesWithoutStatus) && (
							<>
								<AdminUpdateAccountBondingLineFormCard
									carrierName={"inrev" as CarrierName}
									control={formMethods.control}
									paths={{
										rate: "bondingLine.rate",
										singleLimit: "bondingLine.singleLimit",
										aggregateLimit: "bondingLine.aggregateLimit",
										autoUpdate: "bondingLine.autoUpdate",
										isPublished: "bondingLine.isPublished",
									}}
									onMatchClick={() => {
										const { isPublished, ...bondingLine } = formMethods.getValues("bondingLine");
										formMethods.setValue(
											"carrierBondingLines",
											carrierBondingLines.map((carrierBondingLine) => ({
												...carrierBondingLine,
												carrierName: carrierBondingLine.carrierName,
												bondingLine: {
													...bondingLine,
													singleLimit: bondingLine.singleLimit,
													aggregateLimit: bondingLine.aggregateLimit,
												},
											})),
											{ shouldValidate: true },
										);
									}}
								/>
								{carrierBondingLineNames.map((carrierName, index) => (
									<AdminUpdateAccountBondingLineFormCard
										key={carrierName}
										carrierName={carrierName as CarrierName}
										control={formMethods.control}
										paths={{
											rate: `carrierBondingLines.${index}.bondingLine.rate`,
											singleLimit: `carrierBondingLines.${index}.bondingLine.singleLimit`,
											aggregateLimit: `carrierBondingLines.${index}.bondingLine.aggregateLimit`,
											autoUpdate: `carrierBondingLines.${index}.bondingLine.autoUpdate`,
										}}
										onMatchClick={() => {
											const bondingLine = formMethods.getValues("bondingLine");
											const carrierBondingLine = formMethods.getValues(
												`carrierBondingLines.${index}`,
											);
											formMethods.setValue(
												`carrierBondingLines.${index}`,
												{
													...carrierBondingLine,
													carrierName,
													bondingLine: {
														...bondingLine,
														singleLimit: bondingLine.singleLimit,
														aggregateLimit: bondingLine.aggregateLimit,
													},
												},
												{ shouldValidate: true },
											);
										}}
									/>
								))}
							</>
						)}
					</FormItemGroup>
					<div className="flex flex-col space-y-[20px] flex-1">
						{ReUnderwriteModalButton}
						<FormItem condensed className="flex-[1] justify-start">
							<FormItemLabel condensed>Note</FormItemLabel>
							<FormTextArea
								name="reviewNote"
								control={formMethods.control}
								className="bg-white bg-opacity-100 h-[175px] min-h-fit"
							/>
						</FormItem>
					</div>
				</div>
				<ConfirmationModalClickWrapper
					message="Are you sure?"
					onConfirm={formMethods.handleSubmit(handleSubmit)}
				>
					<Button
						color={
							status === "approved" && status !== account.contract.underwriting.status
								? "green"
								: status === "declined" && status !== account.contract.underwriting.status
									? "red"
									: "gray"
						}
						filled
						disabled={
							status === "" ||
							(status === account.contract.underwriting.status && !formMethods.formState.isDirty)
						}
						className="w-fit self-end"
					>
						{status === "approved" && status !== account.contract.underwriting.status
							? "Approve"
							: status === "declined" && status !== account.contract.underwriting.status
								? "Decline"
								: "Save"}
					</Button>
				</ConfirmationModalClickWrapper>
			</form>
		</>
	);
};

import { useEffect, useState } from "react";
import { Control, Controller, FieldPath, FieldPathValue, FieldValues } from "react-hook-form";
import { Input } from "../Input";
import { InputTransformer, Transformer } from "../InputTransformer";

export type FormInputProps<TFieldValues extends FieldValues> = {
	control: Control<TFieldValues, any, any>;
	name: FieldPath<TFieldValues>;
	errorMessage?: string;
	updateOnBlur?: true;
	className?: string;
	condensed?: true;
	maxLength?: number;
	readonly?: boolean;
	disabled?: boolean;
} & (
	| {
			type: "percent";
			min?: number;
			max?: number;
	  }
	| {
			type: "numeric";
			min?: number;
			max?: number;
	  }
	| {
			type?: undefined;
			transformer?: Transformer<FieldPathValue<TFieldValues, FieldPath<TFieldValues>>>;
	  }
);

export const FormInput = <TFieldValues extends FieldValues>(
	props: FormInputProps<TFieldValues>,
) => {
	const [controlledValue, setControlledValue] = useState<string>("");

	if (props.type === undefined && props.transformer !== undefined) {
		return (
			<Controller
				name={props.name}
				control={props.control}
				render={({ field, fieldState }) => {
					useEffect(() => {
						if (field.value !== controlledValue) setControlledValue(field.value);
					}, [field.value]);

					return (
						<InputTransformer
							{...field}
							{...props}
							onBlur={props.updateOnBlur ? () => field.onChange(controlledValue) : field.onBlur}
							onChange={
								props.updateOnBlur ? (e) => setControlledValue(e.target.value) : field.onChange
							}
							transformer={props.transformer!}
							error={fieldState.error}
						/>
					);
				}}
			/>
		);
	} else if (props.type === "percent") {
		return (
			<Controller
				name={props.name}
				control={props.control}
				render={({ field, fieldState }) => {
					useEffect(() => {
						if (field.value !== controlledValue) setControlledValue(field.value);
					}, [field.value]);

					return (
						<InputTransformer
							{...field}
							{...props}
							onBlur={() => {
								let _value = props.updateOnBlur ? controlledValue : field.value;
								let parsedValue: number | undefined;
								if (props.min !== undefined) {
									if (parsedValue === undefined) parsedValue = parseFloat(_value);
									parsedValue = Math.max(parsedValue, props.min);
								}
								if (props.max !== undefined) {
									if (parsedValue === undefined) parsedValue = parseFloat(_value);
									parsedValue = Math.min(parsedValue, props.max);
								}
								if (props.updateOnBlur) {
									field.onChange(parsedValue);
								} else {
									field.onBlur();
								}
								props.updateOnBlur ? () => field.onChange(controlledValue) : field.onBlur;
							}}
							onChange={props.updateOnBlur ? (value) => setControlledValue(value) : field.onChange}
							value={props.updateOnBlur ? controlledValue : field.value}
							transformer={{
								input: (value: string | "") => value,
								output: (value: string) => {
									const parsed = parseInt(value);
									if (Number.isNaN(parsed)) return "";
									else return Math.max(Math.min(parsed, 100), 0).toString();
								},
							}}
							error={fieldState.error}
							postfix="%"
							align="right"
						/>
					);
				}}
			/>
		);
	} else if (props.type === "numeric") {
		return (
			<Controller
				name={props.name}
				control={props.control}
				render={({ field, fieldState }) => {
					useEffect(() => {
						if (field.value !== controlledValue) setControlledValue(field.value);
					}, [field.value]);

					return (
						<InputTransformer
							{...field}
							{...props}
							onBlur={() => {
								let _value = props.updateOnBlur ? controlledValue : field.value;
								let parsedValue: number | undefined;
								if (props.min !== undefined) {
									if (parsedValue === undefined) parsedValue = parseFloat(_value);
									parsedValue = Math.max(parsedValue, props.min);
								}
								if (props.max !== undefined) {
									if (parsedValue === undefined) parsedValue = parseFloat(_value);
									parsedValue = Math.min(parsedValue, props.max);
								}
								if (props.updateOnBlur) {
									field.onChange(parsedValue);
								} else {
									field.onBlur();
								}
								props.updateOnBlur ? () => field.onChange(controlledValue) : field.onBlur;
							}}
							onChange={props.updateOnBlur ? (value) => setControlledValue(value) : field.onChange}
							value={props.updateOnBlur ? controlledValue : field.value}
							transformer={{
								input: (value: string | "") => value.toString(),
								output: (value: string) => {
									return value.replace(/\D/g, "");
								},
							}}
							error={fieldState.error}
						/>
					);
				}}
			/>
		);
	} else {
		return (
			<Controller
				name={props.name}
				control={props.control}
				render={({ field, fieldState }) => {
					useEffect(() => {
						if (field.value !== controlledValue) setControlledValue(field.value);
					}, [field.value]);

					return (
						<Input
							{...field}
							{...props}
							onBlur={props.updateOnBlur ? () => field.onChange(controlledValue) : field.onBlur}
							onChange={
								props.updateOnBlur ? (e) => setControlledValue(e.target.value) : field.onChange
							}
							error={fieldState.error}
						/>
					);
				}}
			/>
		);
	}
};

import {
	ColumnSizingInfoState,
	ColumnSizingState,
	Header,
	flexRender,
} from "@tanstack/react-table";
import { HTMLAttributes, useEffect, useRef } from "react";
import { cn } from "../../lib/utils";
import { Icon } from "../Icon";
import { Separator } from "../Separator";

export type TableHeaderCellProps<TData, TValue> = HTMLAttributes<HTMLDivElement> & {
	header: Header<TData, TValue>;
	columnSizingInfo: ColumnSizingInfoState;
	columnSizing: ColumnSizingState[keyof ColumnSizingState];
	setColumnSizing: (columnSize: number) => void;
};
export const TableHeaderCell = <TData, TValue>({
	header,
	columnSizingInfo,
	columnSizing,
	setColumnSizing,
	className,
}: TableHeaderCellProps<TData, TValue>) => {
	const cellRef = useRef<HTMLDivElement>(null);
	const isActionMenu = header.id === "action_menu";

	useEffect(() => {
		if (cellRef.current && columnSizing < cellRef.current.clientWidth) {
			setColumnSizing(cellRef.current.clientWidth);
		}
	}, [columnSizing]);

	useEffect(() => {
		if (
			columnSizingInfo.isResizingColumn === header.id &&
			columnSizingInfo.startSize !== null &&
			columnSizingInfo.deltaOffset !== null
		) {
			const newSize = columnSizingInfo.startSize + columnSizingInfo.deltaOffset;
			if (newSize !== columnSizing) {
				setColumnSizing(newSize);
			}
		}
	}, [columnSizingInfo.startSize, columnSizingInfo.deltaOffset]);

	return (
		<div
			className={cn(
				`h-full flex items-center text-[13px] font-medium text-gray-700 pl-[15px] cursor-pointer min-w-fit truncate`,
				isActionMenu ? "sticky right-[1px] flex-1 z-[-1]" : undefined,
				className,
				"[&:nth-last-child(1)]:flex-1",
			)}
			ref={cellRef}
			style={{ width: columnSizing, minWidth: header.column.columnDef.minSize }}
		>
			<div
				className="flex-1 flex items-center h-full space-x-[8px]"
				onClick={header.column.getToggleSortingHandler()}
			>
				<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
				<div className="w-[20px] flex items-center justify-center">
					{{
						asc: (
							<Icon type="long-arrow" className="fill-gray-900 rotate-180" width={7} height={9.8} />
						),
						desc: <Icon type="long-arrow" className="fill-gray-900" width={7} height={9.8} />,
					}[header.column.getIsSorted() as string] ?? null}
				</div>
			</div>
			{!isActionMenu && (
				<div className="px-1 cursor-ew-resize" onMouseDown={header.getResizeHandler()}>
					<Separator orientation="vertical" className="h-[18px] bg-gray-300" />
				</div>
			)}
		</div>
	);
};

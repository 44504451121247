import { z } from 'zod';
import { stringOrEmptyString } from '../../../../utils.mjs';

var Data;
(function (Data) {
    Data.bondSchema = z.object({
        bidBondId: stringOrEmptyString,
        contractAmount: stringOrEmptyString,
        actualBidAmount: stringOrEmptyString,
        secondBid: stringOrEmptyString,
        actualBidDate: stringOrEmptyString,
    });
})(Data || (Data = {}));

export { Data };

import { z, ZodIssueCode } from 'zod';
import { ExposureSize, SuretyType } from '../../../../../enums.mjs';
import { dataSchema } from '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import { zswitch, includesSchema } from '../../../../../types/utils.mjs';
import { Submit as Submit$4 } from '../../../account/contract/contract-surety-account.dto.mjs';
import { Get } from '../../submitted/contract/submitted-contract-surety-quote.dto.mjs';
import { Submit as Submit$5 } from '../../surety-quote.dto.mixins.mjs';
import { Submit as Submit$3 } from './bid-contract-surety-quote.dto.mjs';
import * as bidContractSuretyQuote_dto from './bid-contract-surety-quote.dto.mjs';
export { bidContractSuretyQuote_dto as Bid };
import { Submit as Submit$2 } from './bid-to-final-contract-surety-quote.dto.mjs';
import * as bidToFinalContractSuretyQuote_dto from './bid-to-final-contract-surety-quote.dto.mjs';
export { bidToFinalContractSuretyQuote_dto as BidToFinal };
import { Submit as Submit$1 } from './final-contract-surety-quote.dto.mjs';
import * as finalContractSuretyQuote_dto from './final-contract-surety-quote.dto.mjs';
export { finalContractSuretyQuote_dto as Final };

var Submit;
(function (Submit) {
    (function (Request) {
        (function (Base) {
            Base.schema = zswitch(input => {
                switch (input.data.bond.type) {
                    case "bid":
                        return Submit$3.Request.Base.schema;
                    case "bid_to_final":
                        return Submit$2.Request.Base.schema;
                    case "final":
                        return Submit$1.Request.Base.schema;
                    default:
                        return z.never();
                }
            });
        })(Request.Base || (Request.Base = {}));
        let NewAccount;
        (function (NewAccount) {
            let Data;
            (function (Data) {
                let Principal;
                (function (Principal) {
                    let Individual;
                    (function (Individual) {
                        Individual.creditReportPermissionSchema = Submit$4.Request.New.Data.Details.Individual.creditReportPermissionSchema;
                        Individual.ownerSchema = Submit$4.Request.New.Data.Details.Individual.ownerSchema;
                        Individual.spouseSchema = Submit$4.Request.New.Data.Details.Individual.spouseSchema;
                        Individual.schema = z.discriminatedUnion("type", [Individual.ownerSchema, Individual.spouseSchema]);
                    })(Individual = Principal.Individual || (Principal.Individual = {}));
                    (function (Company) {
                        Company.schema = Submit$4.Request.New.Data.Details.Company.schema;
                    })(Principal.Company || (Principal.Company = {}));
                    Principal.baseSchema = z.object({
                        newPrincipal: z.literal(true),
                        company: Submit$4.Request.New.Data.Details.Company.schema,
                        companies: z.array(Submit$4.Request.New.Data.Details.Company.schema),
                        individuals: z.array(Submit$4.Request.New.Data.Details.Individual.schema),
                        creditReportPermission: z.array(Individual.creditReportPermissionSchema),
                        fundNames: z.array(z.string())
                    });
                    Principal.baseRefinement = (val, ctx) => {
                        if (val.newPrincipal && (val.companies === undefined || val.companies.length === 0) && (val.individuals === undefined || val.individuals.length === 0)) {
                            ctx.addIssue({
                                code: ZodIssueCode.custom,
                                path: ['companies'],
                                message: 'Please include at least one individual owner, company owner, subsidiary, or affiliate.'
                            });
                            ctx.addIssue({
                                code: ZodIssueCode.custom,
                                path: ['individuals'],
                                message: 'Please include at least one individual owner, company owner, subsidiary, or affiliate.'
                            });
                        }
                        if (val.newPrincipal && val.company.fein && val.company.fein !== '') {
                            const matchingCompany = val.companies.find(c => c.fein === val.company.fein);
                            if (matchingCompany) {
                                ctx.addIssue({
                                    code: z.ZodIssueCode.custom,
                                    message: "FEINs must be unique across principal and companies",
                                    path: ["company", "fein"]
                                });
                                ctx.addIssue({
                                    code: z.ZodIssueCode.custom,
                                    message: "FEINs must be unique across principal and companies",
                                    path: ["companies", val.companies.indexOf(matchingCompany), "fein"]
                                });
                            }
                        }
                    };
                })(Principal = Data.Principal || (Data.Principal = {}));
                let Bond;
                (function (Bond) {
                    Bond.schema = z.discriminatedUnion("type", [
                        Submit$3.Request.Bond.schema,
                        Submit$1.Request.Bond.schema
                    ], { errorMap: (issue, ctx) => ({ message: issue.code === ZodIssueCode.invalid_union_discriminator ? "Required" : ctx.defaultError }) });
                })(Bond = Data.Bond || (Data.Bond = {}));
                let Small;
                (function (Small) {
                    Small.request = z.object({
                        principal: Principal.baseSchema.extend({
                            exposureSize: z.literal(ExposureSize.small, { errorMap: () => ({ message: "Please select an exposure size" }) }),
                        }).superRefine(Principal.baseRefinement),
                        history: Submit$4.Request.New.Data.History.Small.schema,
                        financials: Submit$4.Request.New.Data.Financials.Small.schema,
                        bond: Bond.schema,
                    });
                })(Small = Data.Small || (Data.Small = {}));
                let Medium;
                (function (Medium) {
                    Medium.request = z.object({
                        principal: Principal.baseSchema.extend({
                            exposureSize: z.literal(ExposureSize.medium, { errorMap: () => ({ message: "Please select an exposure size" }) }),
                        }).superRefine(Principal.baseRefinement),
                        history: Submit$4.Request.New.Data.History.Medium.schema,
                        financials: Submit$4.Request.New.Data.Financials.Medium.schema,
                        bond: Bond.schema,
                    });
                })(Medium = Data.Medium || (Data.Medium = {}));
                let Large;
                (function (Large) {
                    Large.request = z.object({
                        principal: Principal.baseSchema.extend({
                            exposureSize: z.literal(ExposureSize.large, { errorMap: () => ({ message: "Please select an exposure size" }) }),
                        }).superRefine(Principal.baseRefinement),
                        history: Submit$4.Request.New.Data.History.Large.schema,
                        financials: Submit$4.Request.New.Data.Financials.Large.schema,
                        bond: Bond.schema,
                    });
                })(Large = Data.Large || (Data.Large = {}));
                Data.schema = zswitch(input => {
                    switch (input?.principal?.exposureSize) {
                        case ExposureSize.small:
                            return Small.request;
                        case ExposureSize.medium:
                            return Medium.request;
                        case ExposureSize.large:
                            return Large.request;
                        default:
                            return Small.request;
                    }
                });
            })(Data = NewAccount.Data || (NewAccount.Data = {}));
            NewAccount.schema = z.object({
                suretyType: z.literal(SuretyType.contract),
                data: Data.schema,
                draftData: dataSchema
            });
        })(NewAccount = Request.NewAccount || (Request.NewAccount = {}));
        let ExistingAccount;
        (function (ExistingAccount) {
            let Data;
            (function (Data) {
                let Bond;
                (function (Bond) {
                    Bond.schema = z.discriminatedUnion("type", [
                        Submit$3.Request.Bond.schema,
                        Submit$2.Request.Bond.schema,
                        Submit$1.Request.Bond.schema
                    ], { errorMap: (issue, ctx) => ({ message: issue.code === ZodIssueCode.invalid_union_discriminator ? "Required" : ctx.defaultError }) });
                })(Bond = Data.Bond || (Data.Bond = {}));
                Data.buildPrincipalDataValidationSchema = (principalFormSchema) => Submit$5.Request.ExistingAccount.Base.Principal.schema.extend({
                    creditReportPermission: includesSchema(principalFormSchema?.include?.creditReportPermission, z.array(z.object({
                        id: z.string().uuid(),
                        permission: z.literal(true)
                    })))
                });
                Data.buildDataValidationSchema = (formSchema) => z.object({
                    principal: Data.buildPrincipalDataValidationSchema(formSchema.principal),
                    history: includesSchema(formSchema.history, Submit$4.Request.Existing.Data.buildHistoryDataValidationSchema(formSchema.history)),
                    financials: includesSchema(formSchema.financials, Submit$4.Request.Existing.Data.buildFinancialsDataValidationSchema(formSchema.financials)),
                    bond: Bond.schema,
                });
            })(Data = ExistingAccount.Data || (ExistingAccount.Data = {}));
            ExistingAccount.buildDtoSchema = (formSchema) => z.object({
                suretyType: z.literal(SuretyType.contract),
                data: Data.buildDataValidationSchema(formSchema),
                draftData: dataSchema
            });
        })(ExistingAccount = Request.ExistingAccount || (Request.ExistingAccount = {}));
        const schemaBuilderBase = z.object({ principal: z.object({ newPrincipal: z.boolean() }).passthrough() }).passthrough();
        (function (Data) {
            Data.buildDataValidationSchema = (formSchema) => zswitch(input => {
                const parsedBaseInput = schemaBuilderBase.safeParse(input);
                if (!parsedBaseInput.success) {
                    return z.never();
                }
                if (parsedBaseInput.data.principal.newPrincipal) {
                    return NewAccount.Data.schema;
                }
                return ExistingAccount.Data.buildDataValidationSchema(formSchema);
            });
        })(Request.Data || (Request.Data = {}));
        Request.buildDtoSchema = (formSchema) => zswitch(input => {
            const parsedBaseInput = schemaBuilderBase.safeParse(input);
            if (!parsedBaseInput.success) {
                return z.never();
            }
            if (parsedBaseInput.data.principal.newPrincipal) {
                return NewAccount.schema;
            }
            return ExistingAccount.buildDtoSchema(formSchema);
        });
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Submit };

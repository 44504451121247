import { ReactNode } from "react";
import { cn } from "../lib/utils";

type KPICardProps = {
	title: string;
	value: ReactNode;
	className?: string;
	titleClassName?: string;
	valueClassName?: string;
};

export const KPICard = ({
	title,
	value,
	className,
	titleClassName,
	valueClassName,
}: KPICardProps) => {
	return (
		<div
			className={cn(
				"h-[160px] flex flex-col px-[25px] py-[14px] rounded-md bg-gray-100",
				className,
			)}
		>
			<div
				className={cn(
					"flex-1 text-sm font-medium text-gray-800 ml-[-7px] mt-[-2px]",
					titleClassName,
				)}
			>
				{title}
			</div>
			<div className={cn("flex justify-end font-light text-gray-700 text-[42px]", valueClassName)}>
				{value}
			</div>
		</div>
	);
};

import { zodResolver } from "@hookform/resolvers/zod";
import {
	CarrierName,
	Dtos,
	SuretyAccountId,
	SuretyType,
	formatToDollar,
	formatToPercent,
} from "@inrev/common";
import { useState } from "react";
import { Control, FieldValues, Path, useForm } from "react-hook-form";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { MergeExclusive, Schema } from "type-fest";
import { AnyZodObject, z } from "zod";
import { ConfirmationModalClickWrapper } from "../../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../../components/layout/LoadingModal";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../components/layout/form/FormRow";
import { Button } from "../../../../components/ui/Button";
import { Card } from "../../../../components/ui/Card";
import { CompletionBar } from "../../../../components/ui/CompletionBar";
import { FormCheckboxOption } from "../../../../components/ui/form/FormCheckboxOption";
import { FormCurrencyInput } from "../../../../components/ui/form/FormCurrencyInput";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { FormPercentInput } from "../../../../components/ui/form/FormPercentInput";
import {
	useAdminUpdateSuretyAccountBondingLine,
	useAdminUpdateSuretyAccountCarrierBondingLine,
} from "../../../../domain/admin/account/api";
import { AdminUpdateAccountBondingLineFormData } from "../../../../domain/admin/account/types";
import { formatEmpty } from "../../../../utils";
import { carrierLogoIconMap } from "../../../../utils/carrier";
import { stripEmptyResolver } from "../../../../utils/form";

type AdminUpdateAccountBondingLineFormProps<TFieldValues extends FieldValues> = {
	carrierName: CarrierName;
	control: Control<TFieldValues, any, any>;
	paths: Partial<
		Schema<
			MergeExclusive<
				Omit<AdminUpdateAccountBondingLineFormData, "bondingLine">,
				AdminUpdateAccountBondingLineFormData["bondingLine"]
			>,
			Path<TFieldValues>
		>
	>;
	onMatchClick?: () => void;
};

export const AdminUpdateAccountBondingLineForm = <TFieldValues extends FieldValues>({
	control,
	paths,
	carrierName,
	onMatchClick,
}: AdminUpdateAccountBondingLineFormProps<TFieldValues>) => {
	return (
		<>
			<FormItemGroup compact>
				<div className="flex items-center space-x-[15px]">
					{carrierLogoIconMap[carrierName]}
					{(!!paths.autoUpdate || !!paths.isPublished) && (
						<FormRow className="justify-end items-center">
							{!!paths.isPublished && (
								<FormCheckboxOption
									name={paths.isPublished}
									control={control}
									label="Publish"
									compact
								/>
							)}
							{!!paths.autoUpdate && (
								<FormCheckboxOption
									name={paths.autoUpdate}
									control={control}
									label="Auto Update"
									compact
								/>
							)}
						</FormRow>
					)}
				</div>
				{!!paths.rate && (
					<FormItem compact>
						<FormItemLabel compact>Rate</FormItemLabel>
						<FormPercentInput
							name={paths.rate}
							control={control}
							min={0}
							max={100}
							precision={3}
							compact
							errorMessage="Required"
						/>
					</FormItem>
				)}
				<FormRow>
					{!!paths.singleLimit && (
						<FormItem compact>
							<FormItemLabel compact>Single Limit</FormItemLabel>
							<FormCurrencyInput
								name={paths.singleLimit}
								control={control}
								compact
								errorMessage="Required"
							/>
						</FormItem>
					)}
					{!!paths.aggregateLimit && (
						<FormItem compact>
							<FormItemLabel compact>Aggregate Limit</FormItemLabel>
							<FormCurrencyInput
								name={paths.aggregateLimit}
								control={control}
								compact
								errorMessage="Required"
							/>
						</FormItem>
					)}
				</FormRow>
			</FormItemGroup>
			{!!onMatchClick && (
				<div className="flex divide-x divide-x-gray-200 pt-[7px]">
					<span
						className="text-[12px] text-gray-500 font-normal hover:underline cursor-pointer"
						onClick={onMatchClick}
					>
						Match
					</span>
				</div>
			)}
		</>
	);
};

export const AdminUpdateAccountBondingLineFormCard = <TFieldValues extends FieldValues>(
	props: AdminUpdateAccountBondingLineFormProps<TFieldValues>,
) => (
	<Card className="flex flex-col space-y-[12px] p-[30px] pt-[25px]">
		<AdminUpdateAccountBondingLineForm {...props} />
	</Card>
);

const getAdminUpdateAccountBondingLineFormDefaultValues = (
	bondingLine: AdminUpdateAccountBondingLineFormModalProps<AnyZodObject>["bondingLine"],
): AdminUpdateAccountBondingLineFormData => ({
	suretyType: SuretyType.contract,
	carrierName: bondingLine.carrierName,
	bondingLine: {
		rate: bondingLine.rate?.toString() ?? "",
		singleLimit: bondingLine.singleLimit?.toString() ?? "",
		aggregateLimit: bondingLine.aggregateLimit?.toString() ?? "",
		autoUpdate: bondingLine.autoUpdate ?? true,
		isPublished: bondingLine.isPublished,
	},
});

export type AdminUpdateAccountBondingLineFormModalProps<TSchema extends AnyZodObject> = {
	bondingLine: {
		carrierName: string;
		rate?: number;
		singleLimit?: number;
		aggregateLimit?: number;
		autoUpdate?: boolean;
		isPublished?: boolean;
		exposure: number;
	};
	schema: TSchema;
	handleSubmit(data: z.infer<TSchema>): void;
	onClose: () => void;
};

export const AdminUpdateAccountBondingLineFormModal = <TSchema extends AnyZodObject>({
	bondingLine,
	schema,
	handleSubmit,
	onClose,
}: AdminUpdateAccountBondingLineFormModalProps<TSchema>) => {
	const formMethods = useForm<AdminUpdateAccountBondingLineFormData, any, z.infer<TSchema>>({
		defaultValues: getAdminUpdateAccountBondingLineFormDefaultValues(bondingLine),
		resolver: stripEmptyResolver(zodResolver(schema)),
		reValidateMode: "onBlur",
	});

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header="Update Bonding Line"
				bodyClassName="flex flex-col space-y-[12px] p-[30px] pt-[25px]"
				onClose={onClose}
			>
				<form>
					<AdminUpdateAccountBondingLineForm
						control={formMethods.control}
						paths={{
							carrierName: "carrierName",
							rate: "bondingLine.rate",
							singleLimit: "bondingLine.singleLimit",
							aggregateLimit: "bondingLine.aggregateLimit",
							autoUpdate: "bondingLine.autoUpdate",
							isPublished:
								bondingLine.isPublished !== undefined ? "bondingLine.isPublished" : undefined,
						}}
						carrierName={bondingLine.carrierName as CarrierName}
					/>
					<div className="flex justify-end mt-[45px] space-x-[10px]">
						<Button onClick={onClose} color="gray" className="w-[80px]" filled thinFont>
							Cancel
						</Button>
						<ConfirmationModalClickWrapper
							onConfirm={formMethods.handleSubmit(handleSubmit)}
							message="Are you sure?"
						>
							<Button color="light-blue" className="w-[80px]" filled thinFont>
								Save
							</Button>
						</ConfirmationModalClickWrapper>
					</div>
				</form>
			</ModalItemWithHeader>
		</Modal>
	);
};

export type AdminEditableAccountBondingLineFormCardProps = {
	accountId: SuretyAccountId;
	carrierName: string;
	rate?: number;
	singleLimit?: number;
	aggregateLimit?: number;
	autoUpdate?: boolean;
	isPublished?: boolean;
	exposure: number;
};

export const AdminEditableAccountBondingLineFormCard = (
	props: AdminEditableAccountBondingLineFormCardProps,
) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const { updateSuretyAccountBondingLine, updateSuretyAccountBondingLineIsLoading } =
		useAdminUpdateSuretyAccountBondingLine(props.accountId);
	const { updateSuretyAccountCarrierBondingLine, updateSuretyAccountCarrierBondingLineIsLoading } =
		useAdminUpdateSuretyAccountCarrierBondingLine(props.accountId);

	const getSubmitHandler = (carrierName: string) => {
		if (carrierName === "inrev") {
			return (data: Dtos.Admin.SuretyAccount.Update.BondingLine.Request) => {
				setIsEditing(false);
				updateSuretyAccountBondingLine(data);
			};
		}
		return (data: Dtos.Admin.SuretyAccountCarrierBondingLine.Update.Request) => {
			setIsEditing(false);
			updateSuretyAccountCarrierBondingLine(data);
		};
	};

	return (
		<Card className="flex flex-col space-y-[12px] p-[20px] pt-[15px]">
			<div className="flex items-center space-x-[20px]">
				{carrierLogoIconMap[props.carrierName as CarrierName]}
				<div className="flex items-center gap-x-[10px] divide-x divide-x-gray-300 flex-1">
					<span className="text-gray-800 font-medium text-[18px]">
						{formatEmpty(formatToPercent(props.rate))}
					</span>
					<span className="text-gray-600 font-normal text-[16px] pl-[10px]">
						{formatEmpty(formatToDollar(props.singleLimit))} /{" "}
						{formatEmpty(formatToDollar(props.aggregateLimit))}
					</span>
				</div>
				<Button
					color="gray"
					className="border-0 p-[8px] w-fit h-fit my-[-3px]"
					onClick={() => setIsEditing(true)}
				>
					<HiOutlinePencilSquare className="text-[20px] text-gray-600" />
				</Button>
			</div>
			<CompletionBar
				value={props.exposure}
				max={props.aggregateLimit ?? 0}
				formatter={(value) => formatEmpty(formatToDollar(value, true))}
				barWrapperClassName="border-gray-300"
			/>
			{(props.isPublished !== undefined || props.autoUpdate !== undefined) && (
				<div className="flex justify-end text-[12px] italic text-gray-500 font-normal">
					{props.isPublished !== undefined
						? props.isPublished
							? "Published, "
							: "Not Published, "
						: ""}
					{props.autoUpdate !== undefined
						? props.autoUpdate
							? "Auto Updates"
							: "Manual Updates"
						: ""}
				</div>
			)}
			{isEditing && (
				<AdminUpdateAccountBondingLineFormModal
					bondingLine={props}
					schema={(() => {
						if (props.carrierName === "inrev") {
							return Dtos.Admin.SuretyAccount.Update.BondingLine.Request.schema;
						}
						return Dtos.Admin.SuretyAccountCarrierBondingLine.Update.Request.schema;
					})()}
					handleSubmit={getSubmitHandler(props.carrierName)}
					onClose={() => setIsEditing(false)}
				/>
			)}
			{(updateSuretyAccountBondingLineIsLoading ||
				updateSuretyAccountCarrierBondingLineIsLoading) && <LoadingModal />}
		</Card>
	);
};

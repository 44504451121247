import { ContractSuretyType, finalContractSuretyBondClosedReasonSchema, dateTimeSchema, finalContractSuretyBondFormTypeSchema } from '../../../../../enums.mjs';
import { isoDateSchema, currencySchema, rateSchema } from '../../../../../types/utils.mjs';
import { Get as Get$1, Import as Import$1 } from './admin-contract-surety-bond-mixins.dto.mjs';
import { Close as Close$1 } from '../../../../../types/surety/bond/contract/final-contract-surety-bond.types.mjs';
import { Get as Get$2 } from '../../../../surety/bond/contract/final-contract-surety-bond.dto.mjs';
import { Underwriting } from '../../../../../types/surety/quote/submitted/contract/submitted-contract-surety-quote.types.mixins.mjs';
import { Get as Get$3 } from '../../bond-form/admin-issued-surety-bond-form.dto.mjs';
import { z } from 'zod';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Get$1.Response.baseSchema.extend(Get$2.Response.rawSchema.shape)
            .extend({
            bondForm: Get$3.Response.schema,
            actualCompletionDate: isoDateSchema.optional(),
            actualContractAmount: currencySchema.optional(),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Import;
(function (Import) {
    (function (Request) {
        Request.schema = Import$1.Request.baseSchema.extend({
            contractSuretyType: z.literal(ContractSuretyType.final),
            rate: rateSchema,
            premium: currencySchema,
            bond: Import$1.Request.baseSchema.shape.bond.extend({
                data: z.object({
                    closedReason: finalContractSuretyBondClosedReasonSchema.nullish(),
                    contractAmount: currencySchema,
                    contractDate: dateTimeSchema,
                    expectedCompletionDate: dateTimeSchema.nullish(),
                    actualCompletionDate: dateTimeSchema.nullish(),
                    actualContractAmount: currencySchema.nullish(),
                    project: Underwriting.Project.Input.schema
                }),
            }),
            bondForm: Import$1.Request.baseSchema.shape.bondForm.extend({
                data: z.object({
                    bondFormType: finalContractSuretyBondFormTypeSchema,
                    contractDescription: z.string(),
                }),
            })
        });
    })(Import.Request || (Import.Request = {}));
})(Import || (Import = {}));
var Close;
(function (Close) {
    (function (Request) {
        Request.schema = Close$1.Payload.schema;
    })(Close.Request || (Close.Request = {}));
})(Close || (Close = {}));

export { Close, Get, Import };

import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { FormSchemaInclude, FormSchemaIncludeProps } from "./FormSchemaInclude";

type FormItemGroupProps = FormSchemaIncludeProps & {
	condensed?: true;
	compact?: true;
	className?: string;
	children: ReactNode;
};

export const FormItemGroup = (props: FormItemGroupProps) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<div
			className={cn(
				"w-full flex flex-col h-fit space-y-[20px] shrink-0",
				props.condensed ? "space-y-[16px]" : props.compact ? "space-y-[16px]" : undefined,
				props.className,
			)}
		>
			{props.children}
		</div>
	</FormSchemaInclude>
);

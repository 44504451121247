import { ReactElement, ReactNode } from "react";
import { HiOutlineBolt, HiOutlineCheckCircle, HiOutlineShieldCheck } from "react-icons/hi2";
import { SuretyAccount } from "../../domain/agent/account/types";
import { SuretyBond } from "../../domain/agent/bond/types";
import { BondRequest } from "../../domain/agent/request/types";
import { cn } from "../lib/utils";
import { Icon } from "./Icon";

export type StatusCardCoreProps = {
	textColor: keyof typeof textColorMap;
	bgColor?: keyof typeof bgColorMap;
	className?: string;
	icon?: ReactNode;
	label: string;
};

const textColorMap = {
	green: "text-inrev-green",
	"light-blue": "text-inrev-light-blue",
	gray: "text-gray-600",
	"dark-yellow": "text-inrev-dark-yellow",
	red: "text-red-500",
};

const bgColorMap = {
	yellow: "bg-inrev-yellow bg-opacity-[0.08] rounded-[3px] px-[15px] py-[7px]",
	"light-blue": "bg-inrev-light-blue bg-opacity-[0.08] rounded-[3px] px-[15px] py-[7px]",
	green: "bg-inrev-green bg-opacity-[0.08] rounded-[3px] px-[15px] py-[7px]",
	red: "bg-red-500 bg-opacity-[0.08] rounded-[3px] px-[15px] py-[7px]",
};

const StatusCardCore = ({ textColor, bgColor, className, icon, label }: StatusCardCoreProps) => {
	return (
		<div
			className={cn(
				`flex items-center justify-end space-x-[6px] text-[16px] font-medium bg-opacity-[.08] rounded-[3px] select-none`,
				textColorMap[textColor],
				bgColor !== undefined ? bgColorMap[bgColor] : undefined,
				className,
			)}
		>
			{icon ?? <></>}
			<span>{label}</span>
		</div>
	);
};

export const ReviewStatusCard = () => (
	<StatusCardCore textColor="dark-yellow" bgColor="yellow" label="Underwriter Review" />
);
export const ApprovedStatusCard = () => (
	<StatusCardCore textColor="green" bgColor="green" label="Approved" />
);
export const AcceptedStatusCard = () => (
	<StatusCardCore
		textColor="green"
		icon={<HiOutlineCheckCircle className="fill-white text-[18px]" />}
		label="Terms Accepted"
	/>
);
export const DeclinedStatusCard = () => (
	<StatusCardCore textColor="red" bgColor="red" label="Declined" />
);
export const ActiveStatusCard = () => (
	<StatusCardCore
		textColor="light-blue"
		icon={<HiOutlineBolt className="fill-white text-[17px]" />}
		label="Active"
	/>
);
export const PendingStatusCard = () => (
	<StatusCardCore
		textColor="gray"
		icon={
			<Icon type="stopwatch" className="h-[17px] stroke-gray-600 stroke-[1.35] overflow-visible" />
		}
		label="Pending"
	/>
);
export const OpenStatusCard = () => (
	<StatusCardCore
		textColor="light-blue"
		bgColor="light-blue"
		icon={<HiOutlineShieldCheck className="fill-white text-[20px] stroke-[1.5]" />}
		label="Open"
	/>
);

const statusMap: Partial<
	Record<
		BondRequest["status"] | SuretyBond["status"] | SuretyAccount["status"],
		ReactElement<StatusCardCoreProps>
	>
> = {
	review: <ReviewStatusCard />,
	approved: <ApprovedStatusCard />,
	accepted: <AcceptedStatusCard />,
	declined: <DeclinedStatusCard />,
	active: <ActiveStatusCard />,
	pending: <PendingStatusCard />,
	open: <OpenStatusCard />,
};

export const getStatusCardByStatus = (status: keyof typeof statusMap) => statusMap[status] ?? null;

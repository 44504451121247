import { RadioGroup } from "@headlessui/react";
import { cn } from "../lib/utils";
import { RadioButton } from "./RadioButton";
import { FormRadioGroupItemProps } from "./form/FormRadioGroup";

export const RadioCardItem = <TType,>({
	className,
	readonly,
	disabled,
	children,
	...props
}: FormRadioGroupItemProps<TType> & { readonly?: boolean }) => {
	return (
		<RadioGroup.Option
			{...props}
			disabled={disabled}
			className={({ checked }) =>
				cn(
					`relative w-full max-w-[550px] pl-[25px] select-none flex items-center rounded-md cursor-pointer text-gray-900 border border-gray-300 bg-white hover:border-gray-400 hover:bg-gray-50 focus-visible:ring focus-visible:ring-offset-0 focus-visible:ring-inrev-light-blue focus-visible:ring-opacity-30 ${checked ? "outline outline-inrev-light-blue outline-[2px] outline-offset-[-2px] bg-inrev-light-blue/[.06]" : ""}`,
					className,
					disabled
						? "opacity-50 outline-gray-300 bg-gray-100 text-gray-600 cursor-default hover:border-initial hover:bg-initial"
						: undefined,
					readonly
						? `hover:bg-initial hover:border-initial cursor-default ${checked ? "outline-gray-300 bg-gray-100 text-gray-600" : "border-opacity-80 text-gray-400"}`
						: undefined,
				)
			}
		>
			{({ checked }) => (
				<>
					<div className="flex h-full items-center justify-center">
						<RadioButton checked={checked} readonly={readonly} />
					</div>
					<div className="h-full flex-1">{children}</div>
				</>
			)}
		</RadioGroup.Option>
	);
};

import { z } from 'zod';
import { suretyQuoteStatusSchema, SuretyQuoteStatus } from '../../../../../enums.mjs';
import { Get as Get$1 } from '../surety-quote-preview.dto.mixins.mjs';
import { suretyAccountId } from '../../../../../types/opaque.mjs';
import { currencySchema, isoDateSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseSchema = (suretySubType) => Get$1.Response.baseSchema.extend({
            status: suretyQuoteStatusSchema.exclude([SuretyQuoteStatus.draft]),
            suretySubType: z.literal(suretySubType),
            amount: currencySchema,
            description: z.string(),
            submittedAt: isoDateSchema,
            accountId: suretyAccountId,
            accountName: z.string(),
            principalName: z.string(),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

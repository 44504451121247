import { cn } from "../lib/utils";

type SpinnerProps = {
	containerClassName?: string;
	spinnerClassName?: string;
};

export const Spinner = ({ containerClassName, spinnerClassName }: SpinnerProps) => (
	<div className={cn("aspect-square w-10 h-10", containerClassName)}>
		<div
			className={cn(
				"aspect-square animate-spin w-10 h-10 border-[6px] border-[#4b5563] border-double border-t-transparent rounded-full",
				spinnerClassName,
			)}
		></div>
	</div>
);

import { HiOutlineInformationCircle } from "react-icons/hi2";
import { cn } from "../lib/utils";

type TooltipProps = {
	id: string;
	content: string;
	className?: string;
	focusable?: true;
};

export const Tooltip = ({ id, content, className, focusable }: TooltipProps) => (
	<HiOutlineInformationCircle
		id={`tt-${id}`}
		tabIndex={focusable ? undefined : -1}
		data-tooltip-html={content}
		className={cn(
			"text-[22px] mb-[2.5px] inline-block cursor-pointer focus:outline-none text-gray-400 fill-gray-50 hover:text-gray-600 stroke-[1.75px] shrink-0",
			className,
		)}
	/>
);

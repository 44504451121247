import { type Dtos, formatToDollar } from "@inrev/common";
import { Decimal } from "decimal.js-light";
import { useMemo } from "react";
import { KPICard } from "../../../components/ui/KPICard";
import { carrierLogoIconMap } from "../../../utils/carrier";
import { AccountIndemnitorList } from "../../agent/account/status/underwritten/indemnity/AccountIndemnitorList";

type BondRequestTermsAndConditionsProps = {
	request: Extract<
		Dtos.SuretyQuote.Get.Response,
		{ status: "approved" | "accepted" | "rejected" | "pending" }
	>;
};

export const BondRequestTermsAndConditions = ({ request }: BondRequestTermsAndConditionsProps) => {
	const requiredIndemnitors = useMemo(() => {
		const indemnityAgreement = request.bondIssuance.indemnityAgreement;
		if (indemnityAgreement.status === "incomplete" || indemnityAgreement.status === "pending") {
			return [
				...indemnityAgreement.indemnitors.companies,
				...indemnityAgreement.indemnitors.individuals,
			];
		}
	}, [request.bondIssuance.indemnityAgreement]);
	if (request.suretyType !== "contract") return <></>;
	return (
		<div className="flex flex-col w-full min-h-fit">
			<div
				className="w-full h-[1.5px] bg-center"
				style={{
					backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='1' viewBox='0 0 10 1' fill='none'%3E%3Cline x1='0' y1='1' x2='10' y2='0' stroke-width='1.5' stroke='%23E5E7EB' stroke-dasharray='4 6'/%3E%3C/svg%3E")`,
				}}
			></div>
			<div className="flex flex-col space-y-[40px] w-full h-fit px-[40px] py-[30px] pt-[25px]">
				<div className="flex flex-col space-y-[20px]">
					<div className="font-medium text-[16px] text-gray-900">Terms</div>
					<div className="flex flex-col gap-[14px] bg-gray-100 rounded-md px-[20px] py-[14px] pt-[12px] w-fit">
						<span className="flex-1 text-[13.5px] text-gray-700 font-[450]">Carrier</span>
						<div className="flex items-center gap-[12px]">
							{carrierLogoIconMap[request.carrier.name]}
							<span className="text-[17px] text-gray-800 font-[475]">
								{request.carrier.legalName}
							</span>
						</div>
					</div>
					<div className="flex space-x-[20px]">
						{request.contractSuretyType !== "bid" && (
							<>
								<KPICard
									title="Rate"
									value={`${new Decimal(request.rate).times(100)}%`}
									className="w-[160px] h-[120px]"
									titleClassName="text-gray-700 text-[13.5px]"
									valueClassName="text-[36px] font-[320]"
								/>
								<KPICard
									title="Premium"
									value={formatToDollar(Math.round(request.premium))!}
									className="w-[160px] h-[120px] flex-1"
									titleClassName="text-gray-700 text-[13.5px]"
									valueClassName="text-[36px] font-[320]"
								/>
								<KPICard
									title="Commission"
									value={`${new Decimal(request.agencyCommissionPercentage).times(100)}%`}
									className="w-[160px] h-[120px]"
									titleClassName="text-gray-700 text-[13.5px]"
									valueClassName="text-[36px] font-[320]"
								/>
							</>
						)}
						{request.contractSuretyType === "bid" && (
							<>
								<KPICard
									title="Final Bond Rate"
									value={`${new Decimal(request.finalBondContingentRate).times(100)}%`}
									className="w-[160px] h-[120px]"
									titleClassName="text-gray-700 text-[13.5px]"
									valueClassName="text-[36px] font-[320]"
								/>
								<KPICard
									title="Final Bond Premium"
									value={formatToDollar(Math.round(request.finalBondContingentPremium))!}
									className="w-[160px] h-[120px] flex-1"
									titleClassName="text-gray-700 text-[13.5px]"
									valueClassName="text-[36px] font-[320]"
								/>
								<KPICard
									title="Commission"
									value={`${new Decimal(request.agencyCommissionPercentage).times(100)}%`}
									className="w-[160px] h-[120px]"
									titleClassName="text-gray-700 text-[13.5px]"
									valueClassName="text-[36px] font-[320]"
								/>
							</>
						)}
					</div>
					{requiredIndemnitors?.length ? (
						<div className="flex flex-col gap-y-[20px] !mt-[32px]">
							<div className="text-[13px] text-gray-800 font-normal">
								The following individuals and/or companies are required to sign an indemnity
								agreement:
							</div>
							<AccountIndemnitorList indemnityAgreement={request.bondIssuance.indemnityAgreement} />
						</div>
					) : null}
				</div>
				<div className="flex flex-col space-y-[10px]">
					<div className="font-medium text-[16px] text-gray-900">Conditions</div>
					<div className="font-normal text-[12px] text-gray-700">
						This approval is contingent upon: (a) the work having not already started (b) the
						contract not involving design responsibility, environmental or remediation work,
						efficiency guarantees, takeover of an existing contract, work at a superfund site or
						work for a sovereign nation owner.
					</div>
				</div>
			</div>
		</div>
	);
};

import { z } from 'zod';
import { Get as Get$1 } from '../surety-quote.dto.mixins.mjs';
import { companyContactId, carrierName, suretyBondId, agencyId, agentId } from '../../../../types/opaque.mjs';
import { currencySchema, rateSchema, dateTimeToISODateTimeSchema, attachmentSchema, percentSchema, dateTimeToISODateSchema } from '../../../../types/utils.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import { suretyAccountUnderwritingStatusSchema, suretyBondIssuanceMethodSchema, SuretyQuoteStatus } from '../../../../enums.mjs';
import { Get as Get$2 } from '../../../admin/surety/bond-form/admin-submitted-surety-bond-form.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseSchema = Get$1.Response.baseSchema.extend({
            bondAmount: currencySchema,
            account: z.object({
                id: z.string().uuid(),
                displayName: z.string().optional(),
                primaryCompanyContactId: companyContactId,
                primaryCompanyName: z.string(),
                underwritingStatus: suretyAccountUnderwritingStatusSchema,
                rate: rateSchema.nullish()
            }),
            description: z.string(),
            archived: z.boolean(),
            submittedAt: dateTimeToISODateTimeSchema,
            attachments: z.array(attachmentSchema()),
        });
        Response.underwrittenSchemaShape = {
            agencyCommissionPercentage: percentSchema,
            rate: rateSchema,
            premium: currencySchema,
            expires: dateTimeToISODateSchema,
            carrier: z.object({
                name: carrierName,
                displayName: z.string(),
                legalName: z.string(),
            }),
            bondIssuance: z.object({
                bondForm: z.object({
                    allowedIssuanceMethods: z.array(suretyBondIssuanceMethodSchema),
                    description: z.string()
                })
            })
        };
        Response.draftStatusShape = {
            status: z.literal(SuretyQuoteStatus.draft)
        };
        Response.reviewStatusShape = {
            status: z.literal(SuretyQuoteStatus.review),
            carrier: z.object({
                name: carrierName,
                displayName: z.string(),
                legalName: z.string(),
            }).optional(),
            reviewReasons: z.array(z.object({ description: z.string() })),
            supplementalRequirements: z.string().array().default([]),
        };
        Response.approvedStatusShape = {
            status: z.literal(SuretyQuoteStatus.approved)
        };
        Response.acceptedStatusShape = {
            status: z.literal(SuretyQuoteStatus.accepted),
        };
        Response.rejectedStatusShape = {
            status: z.literal(SuretyQuoteStatus.rejected)
        };
        Response.declinedStatusShape = {
            status: z.literal(SuretyQuoteStatus.declined)
        };
        Response.pendingStatusShape = {
            status: z.literal(SuretyQuoteStatus.pending),
            bondIssuance: z.object({
                method: suretyBondIssuanceMethodSchema,
                bondNumber: z.string().optional(),
                pendingIndemnityAgreement: z.boolean(),
                pendingBondFormReview: z.boolean(),
                pendingInRevIssue: z.boolean(),
                pendingAgentIssue: z.boolean(),
                pendingAgentIssueAdminReview: z.boolean(),
            })
        };
        Response.boundStatusShape = {
            status: z.literal(SuretyQuoteStatus.bound),
            bondId: suretyBondId
        };
        (function (Admin) {
            Admin.baseShape = {
                agencyId: agencyId,
                agencyDisplayName: z.string(),
                agentName: z.string().nullish(),
                agentId: agentId.nullish(),
                reviewReasons: z.array(z.object({ description: z.string() })),
                reviewNote: z.string().optional(),
                supplementalRequirements: z.string().array().default([]),
                bondForm: Get$2.Response.schema,
                carrier: z.object({
                    name: carrierName,
                    displayName: z.string(),
                    legalName: z.string(),
                }).optional(),
                carrierRelationships: z.array(z.object({
                    name: carrierName,
                    agencyCommissionPercentage: percentSchema
                }))
            };
        })(Response.Admin || (Response.Admin = {}));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

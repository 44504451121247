import { Navigate, Route, Routes } from "react-router-dom";
import { Tooltip as TT } from "react-tooltip";
import { AdminPrefetchProvider } from "./providers/AdminPrefetchProvider";
import { AuthGuard } from "./utils/guards";
import { AdminSuretyAccountView } from "./views/admin/account/AdminAccountView";
import { AdminAccountsView } from "./views/admin/accounts/AdminAccountsView";
import { AdminBondView } from "./views/admin/bond/AdminBondView";
import { AdminImportBondView } from "./views/admin/import/AdminImportBondView";
import { AdminMainLayout } from "./views/admin/layout/AdminMainLayout";
import { AdminTableLayout } from "./views/admin/layout/AdminTableLayout";
import { AdminBondRequestView } from "./views/admin/request/AdminBondRequestView";
import { AdminRequestsView } from "./views/admin/requests/RequestsView";
import { BondsView } from "./views/agent/bonds/BondsView";

export const AdminApp = () => {
	return (
		<AdminPrefetchProvider>
			<AdminMainLayout>
				<Routes>
					<Route path="requests" element={<AuthGuard component={AdminRequestsView} />} />
					<Route path="requests/:id" element={<AuthGuard component={AdminBondRequestView} />} />
					<Route
						path="bonds"
						element={<AuthGuard component={() => <BondsView tableLayout={AdminTableLayout} />} />}
					/>
					<Route path="bonds/:id" element={<AuthGuard component={AdminBondView} />} />
					<Route path="accounts" element={<AuthGuard component={AdminAccountsView} />} />
					<Route path="accounts/:id" element={<AuthGuard component={AdminSuretyAccountView} />} />
					<Route path="import">
						<Route path="bonds" element={<AuthGuard component={AdminImportBondView} />} />
					</Route>
					<Route path="*" element={<Navigate to="/requests" />} />
				</Routes>
			</AdminMainLayout>
			<TT anchorSelect="[id^='tt-']" />
		</AdminPrefetchProvider>
	);
};

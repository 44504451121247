import { LineChart } from "@tremor/react";

type LineChartGeneratorProps = {
	category: string;
	data: any;
	valueFormatter: (number: number) => string;
	index: string;
	yAxisWidth: number;
};

export const LineChartGenerator = ({
	category,
	data,
	valueFormatter,
	index,
	yAxisWidth,
}: LineChartGeneratorProps) => {
	return (
		<LineChart
			className="h-[175px]"
			data={data}
			index={index}
			categories={[category]}
			colors={["inrev-light-blue"]}
			valueFormatter={valueFormatter}
			yAxisWidth={yAxisWidth}
			showGridLines={false}
			showLegend={false}
			showAnimation={true}
		/>
	);
};

import type { Types } from "@inrev/common";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FormItem } from "../../../../../../components/layout/form/FormItem";
import { Tooltip } from "../../../../../../components/ui/Tooltip";
import { FormCurrencyInput } from "../../../../../../components/ui/form/FormCurrencyInput";
import { FormNAICSCodeSelect } from "../../../../../../components/ui/form/FormNAICSCodeSelect";
import { FormQuestionLabel } from "../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../../../components/ui/form/FormYesNo";
import { BondRequestDraftData } from "../../../../../../domain/agent/request/types";
import { useScrollToId } from "../../../../../../utils";
import { SuretyAccountDraftContext } from "../AccountDraftView";

type _AccountDraftHistorySectionProps = {
	draft: Types.SuretyAccount.Draft.Type;
};

export const _AccountDraftHistorySection = ({ draft }: _AccountDraftHistorySectionProps) => {
	const { control, formState } = useFormContext<BondRequestDraftData>();
	const domReadyRef = useScrollToId();

	return (
		<div ref={domReadyRef} className="flex flex-col pt-[18px] pb-[125px] min-h-fit space-y-[50px]">
			<FormItem schemaInclude={draft.schema.history?.include.naicsCodes} id="naicsCode">
				<FormQuestionLabel marker>
					What type of work do the companies in this account specialize in?
				</FormQuestionLabel>
				<FormNAICSCodeSelect
					name="history.naicsCodes"
					control={control}
					selectText="Select specialties"
					editText="Edit Specialties"
					title="Select Account Specialties"
					searchPlaceholder="Search specialties..."
					error={formState.errors.history?.naicsCodes}
					readonly={draft.schema.history?.readonly?.naicsCodes}
				/>
			</FormItem>
			<FormItem
				schemaInclude={draft.schema.history?.include.largestProjectCompleted}
				id="largestProjectCompleted"
			>
				<FormQuestionLabel marker>
					What is the value of the largest project completed by the companies in this account?
				</FormQuestionLabel>
				<FormCurrencyInput
					name="history.largestProjectCompleted"
					control={control}
					allowNegativeValue={false}
					className="w-[300px]"
					readonly={draft.schema.history?.readonly?.largestProjectCompleted}
				/>
			</FormItem>
			<FormItem
				schemaInclude={draft.schema.history?.include.failedToCompleteAProject}
				id="failedToCompleteAProject"
			>
				<FormQuestionLabel marker>
					Has any company in this account ever failed to complete a project?
				</FormQuestionLabel>
				<FormYesNo
					name="history.failedToCompleteAProject"
					control={control}
					readonly={draft.schema.history?.readonly?.failedToCompleteAProject}
				/>
			</FormItem>
			<FormItem
				schemaInclude={draft.schema.history?.include.lostAPaymentSuit}
				id="lostAPaymentSuit"
			>
				<FormQuestionLabel marker>
					Has any company in this account ever lost a payment suit?
				</FormQuestionLabel>
				<FormYesNo
					name="history.lostAPaymentSuit"
					control={control}
					readonly={draft.schema.history?.readonly?.lostAPaymentSuit}
				/>
			</FormItem>
			<FormItem
				schemaInclude={draft.schema.history?.include.causedSuretyLoss}
				id="causedSuretyLoss"
			>
				<FormQuestionLabel marker>
					Has any company in this account ever caused a loss to a surety?
				</FormQuestionLabel>
				<FormYesNo
					name="history.causedSuretyLoss"
					control={control}
					readonly={draft.schema.history?.readonly?.causedSuretyLoss}
				/>
			</FormItem>
			<FormItem
				schemaInclude={draft.schema.history?.include.constructionProjectGrossLoss}
				id="constructionProjectGrossLoss"
			>
				<FormQuestionLabel marker>
					In the past 3 years, has any company in this account experienced a gross loss on any
					project greater than 10% of that project's contract value?
					<Tooltip
						id="history.constructionProjectGrossLoss"
						className="ml-[10px]"
						content="For example, a gross loss of at least $10,000 on a $100,000 project"
					/>
				</FormQuestionLabel>
				<FormYesNo
					name="history.constructionProjectGrossLoss"
					control={control}
					readonly={draft.schema.history?.readonly?.constructionProjectGrossLoss}
				/>
			</FormItem>
			<FormItem
				schemaInclude={draft.schema.history?.include.currentConstructionProjectGrossLoss}
				id="currentConstructionProjectGrossLoss"
			>
				<FormQuestionLabel marker>
					Does any company in this account expect a gross loss on a project they are currently
					working on?
				</FormQuestionLabel>
				<FormYesNo
					name="history.currentConstructionProjectGrossLoss"
					control={control}
					readonly={draft.schema.history?.readonly?.currentConstructionProjectGrossLoss}
				/>
			</FormItem>
		</div>
	);
};

export const AccountDraftHistorySection = () => {
	const account = useContext(SuretyAccountDraftContext);

	return <_AccountDraftHistorySection draft={account.draft} />;
};

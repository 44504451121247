import type {
	ArrayPath,
	FieldArray,
	FieldError,
	FieldErrorsImpl,
	FieldValues,
	Merge,
	UseFieldArrayReturn,
} from "react-hook-form";
import { ConfirmationModalClickWrapper } from "../../layout/ConfirmationModalClickWrapper";
import { cn } from "../../lib/utils";
import { Checkbox } from "../Checkbox";
import { InlineLink } from "../InlineLink";

type DraftBondRequestIndividualCreditReportPermissionProps = {
	value: boolean;
	name: string;
	error?: FieldError | undefined;
	className?: string;
	onSelect: () => void;
	onDeselect: () => void;
};

export const IndividualCreditReportPermission = ({
	value,
	name,
	error,
	className,
	onSelect,
	onDeselect,
}: DraftBondRequestIndividualCreditReportPermissionProps) => {
	return (
		<div className={cn("flex flex-col space-x-[10px] text-[14px]", className)}>
			<div className="flex space-x-[15px] items-center">
				<ConfirmationModalClickWrapper
					shouldConfirm={!value}
					onConfirm={onSelect}
					subMessage={
						<div className="max-w-[450px] h-full flex space-x-[15px] basis-0">
							<div className="w-[8px] min-h-full rounded bg-gray-200"></div>
							<div className="text-[14px] font-normal text-gray-600 leading-[23px] text-left">
								You understand that by checking this box,{" "}
								<span className="font-semibold">{name}</span> is providing "written instructions" to
								InRev under the
								<br />
								<InlineLink
									to="https://www.ftc.gov/legal-library/browse/statutes/fair-credit-reporting-act"
									newTab
								>
									Fair Credit Reporting Act
								</InlineLink>{" "}
								authorizing InRev to obtain personal credit report information or other information
								from Experian.
								<br />
								<span className="font-semibold">{name}</span> authorizes InRev to obtain such
								information solely for underwriting purposes.
							</div>
						</div>
					}
					confirmButtonText="I Understand"
					cancelButton={false}
				>
					<Checkbox
						className="mt-[1px]"
						checked={value}
						onChange={() => {
							if (value) {
								onDeselect();
							}
						}}
					/>
				</ConfirmationModalClickWrapper>
				<div className="text-gray-700">
					<span className="font-medium text-gray-800">{name}</span> authorizes InRev to obtain
					personal credit report information
				</div>
			</div>
			{!!error && <div className="text-xs text-red-500 !mt-[5px] !ml-[32px]">Required</div>}
		</div>
	);
};

type FormIndividualCreditReportPermissionData = {
	name: string;
	id: string;
	permission: boolean;
};

type FormIndividualCreditReportPermissionListProps<
	TFieldValues extends FieldValues,
	TPath extends ArrayPath<TFieldValues>,
> = {
	path: TPath;
	creditReportPermissionFieldArray: FieldArray<
		TFieldValues,
		TPath
	> extends FormIndividualCreditReportPermissionData
		? UseFieldArrayReturn<TFieldValues, TPath>
		: never;
	errors:
		| Merge<
				FieldError,
				(
					| Merge<
							FieldError,
							FieldErrorsImpl<{
								name: string;
								id: string;
								permission: boolean;
							}>
					  >
					| undefined
				)[]
		  >
		| undefined;
};

export const FormIndividualCreditReportPermissionList = <
	TFieldValues extends FieldValues,
	TPath extends ArrayPath<TFieldValues>,
>({
	creditReportPermissionFieldArray,
	errors,
}: FormIndividualCreditReportPermissionListProps<TFieldValues, TPath>) => {
	return (
		<div className="flex flex-col auto-cols-max divide-y-[1px] divide-y-gray-100">
			{creditReportPermissionFieldArray.fields.map((permission, index) => (
				<IndividualCreditReportPermission
					key={permission.id}
					value={(permission as any).permission}
					name={(permission as any).name}
					error={errors?.[index]?.permission}
					onSelect={() =>
						creditReportPermissionFieldArray.update(index, { ...permission, permission: true })
					}
					onDeselect={() =>
						creditReportPermissionFieldArray.update(index, { ...permission, permission: false })
					}
					className="py-[20px] px-[26px]"
				/>
			))}
		</div>
	);
};

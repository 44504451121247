import { ReactElement } from "react";
import { SummaryItemProps } from "./SummaryItem";

type SummaryItemGroupProps = {
	children: ReactElement<SummaryItemProps> | ReactElement<SummaryItemProps>[];
};

export const SummaryItemGroup = ({ children }: SummaryItemGroupProps) => {
	const childrenIsArray = Array.isArray(children);
	if (childrenIsArray && children.every((child) => !child.props.schemaInclude)) return null;
	else if (!childrenIsArray && children.props.schemaInclude !== true) return null;
	else return <div className="flex flex-col space-y-[5px]">{children}</div>;
};

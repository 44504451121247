import { z } from 'zod';
import '../../../../../types/opaque.mjs';
import { stripEmptySchema, uploadedFileSchema, bondFormSchema, currencySchema } from '../../../../../types/utils.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import { FileType, bidContractSuretyBondFormTypeDef, bidBondAmountTypeSchema, dateTimeSchema, finalContractSuretyBondFormTypeSchema } from '../../../../../enums.mjs';
import { Submit as Submit$1 } from './contract-surety-quote.dto.mixins.mjs';

var Submit;
(function (Submit) {
    (function (Request) {
        let Bond;
        (function (Bond) {
            Bond.schema = Submit$1.Request.Bond.baseSchema.extend({
                type: z.literal('bid'),
                bidBondAmountType: stripEmptySchema(bidBondAmountTypeSchema),
                bondAmountPercentOfBid: stripEmptySchema(z.coerce.number().min(0).multipleOf(0.001)),
                bidAmount: stripEmptySchema(currencySchema),
                bidDate: stripEmptySchema(dateTimeSchema),
                finalBondType: stripEmptySchema(finalContractSuretyBondFormTypeSchema),
                completionBond: stripEmptySchema(z.boolean()),
                project: Submit$1.Request.Bond.Project.baseSchema.extend({
                    files: z.array(uploadedFileSchema([FileType.bid_package])).min(1)
                }),
                bondForm: bondFormSchema([...bidContractSuretyBondFormTypeDef])
            });
        })(Bond = Request.Bond || (Request.Bond = {}));
        (function (Base) {
            const baseSchema = Submit$1.Request.Base.baseSchema;
            Base.schema = baseSchema.extend({
                data: baseSchema.shape.data.extend({
                    bond: Bond.schema
                }).passthrough()
            }).passthrough();
        })(Request.Base || (Request.Base = {}));
    })(Submit.Request || (Submit.Request = {}));
})(Submit || (Submit = {}));

export { Submit };

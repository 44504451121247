import { z } from 'zod';
import { currencySchema } from '../../../../utils.mjs';
import { ContractSuretyType } from '../../../../../enums.mjs';
import { Underwriting as Underwriting$1 } from './submitted-contract-surety-quote.types.mixins.mjs';

var Underwriting;
(function (Underwriting) {
    Underwriting.schema = z.object({
        quote: Underwriting$1.Quote.baseSchema,
        project: Underwriting$1.Project.schema
    });
})(Underwriting || (Underwriting = {}));
var Details;
(function (Details) {
    Details.schema = z.object({
        contractSuretyType: z.literal(ContractSuretyType.final),
        contractAmount: currencySchema,
        underwriting: Underwriting.schema
    });
})(Details || (Details = {}));

export { Details, Underwriting };

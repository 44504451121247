import { ReactNode } from "react";

export type RowActionButtonProps = {
	children: ReactNode;
	onClick?: () => void;
};

export const RowActionButton = ({ children, onClick }: RowActionButtonProps) => (
	<div
		className="group-icon w-fit h-fit p-[8px] rounded hover:bg-gray-100 border border-transparent hover:border-gray-200"
		onClick={onClick}
	>
		{children}
	</div>
);

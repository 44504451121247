import { useMemo } from "react";
import { cn } from "../lib/utils";

type BarListProps = {
	data: {
		label: string;
		value: number;
	}[];
	valueFormatter?: (value: number) => string;
	className?: string;
	barClassName?: string;
	valueClassName?: string;
};

const getWidthsFromValues = (dataValues: number[]) => {
	let maxValue = -Infinity;
	dataValues.forEach((value) => {
		maxValue = Math.max(maxValue, value);
	});

	return dataValues.map((value) => {
		if (value === 0) return 0;
		return Math.max((value / maxValue) * 100, 1);
	});
};

export const BarList = ({
	data,
	valueFormatter,
	className,
	barClassName,
	valueClassName,
}: BarListProps) => {
	const max = useMemo(() => Math.max(...data.map((d) => d.value)), [data]);
	const widths = useMemo(() => getWidthsFromValues(data.map((d) => d.value)), [data, max]);
	return (
		<div className={cn("flex justify-between space-x-[15px] text-[14px]", className)}>
			<div className="flex flex-col flex-1 space-y-[8px]">
				{data.map((d, i) => (
					<div
						className={cn(
							`flex items-center h-[30px] text-gray-600 bg-gray-100 rounded min-w-[3px]`,
							barClassName,
						)}
						style={{ width: `${widths[i]}%` }}
						key={i}
					>
						<span className="mx-[8px]">{d.label}</span>
					</div>
				))}
			</div>
			<div className="flex flex-col space-y-[8px]">
				{data.map((d, i) => (
					<div
						className={cn(
							"flex items-center text-gray-800 text-[14px] h-[30px] w-fit",
							valueClassName,
						)}
						key={i}
					>
						{valueFormatter ? valueFormatter(d.value) : d.value}
					</div>
				))}
			</div>
		</div>
	);
};

import { z } from 'zod';
import { percentSchema } from '../../../utils.mjs';
import { paymentTemplateSchema } from '../../../payment/payment-template.mjs';

var Data;
(function (Data) {
    Data.schema = z.object({
        agencyCommissionPercentage: percentSchema,
        netCommissionPercentage: percentSchema,
        paymentChainTemplate: z.array(paymentTemplateSchema),
    });
})(Data || (Data = {}));

export { Data };

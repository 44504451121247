import { ReactNode } from "react";
import { GlobalLoadingProvider } from "../../providers/GlobalLoadingProvider";

type MainLayoutProps = {
	mainNav: ReactNode;
	children: ReactNode;
};

export const MainLayout = ({ mainNav, children }: MainLayoutProps) => {
	return (
		<div className="transition-all grid grid-cols-[60px_1fr] md:grid-cols-[230px_1fr] h-screen w-full overflow-hidden antialiased">
			{mainNav}
			<div className="flex-1 h-full flex flex-col items-center relative">
				<GlobalLoadingProvider>{children}</GlobalLoadingProvider>
			</div>
		</div>
	);
};

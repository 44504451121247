import { Dtos } from "@inrev/common";
import { useState } from "react";
import { HiCheck } from "react-icons/hi";
import { ConfirmationModalClickWrapper } from "../../../../components/layout/ConfirmationModalClickWrapper";
import { Separator } from "../../../../components/ui/Separator";
import {
	useCancelAgentUserInvitation,
	useResendAgentUserInvitation,
} from "../../../../domain/agent/user/api";

type InvitationPendingProps = {
	agent: Dtos.Agent.UserPreview.Get.Response;
};

export const InvitationPending = ({ agent }: InvitationPendingProps) => {
	const [hasResent, setHasResent] = useState<boolean>(false);
	const {
		resendAgentUserInvitation,
		resendAgentUserInvitationIsLoading,
		resendAgentUserInvitationError,
	} = useResendAgentUserInvitation();
	const { cancelAgentUserInvitation } = useCancelAgentUserInvitation();

	const handleResend = () => {
		setHasResent(true);
		resendAgentUserInvitation(agent.id);
	};

	if (!agent.invitationPending) return null;
	return (
		<div className="flex flex-col gap-[10px] w-fit shrink-0">
			<div className="text-[13px] text-gray-700/90 font-medium w-fit italic px-[12px] py-[5px] bg-gray-100 rounded-md">
				Invitation Sent
			</div>
			<div className="flex gap-[10px] items-center justify-between text-[11px] px-[5px]">
				{!hasResent && (
					<span className="cursor-pointer hover:underline text-gray-600" onClick={handleResend}>
						Resend
					</span>
				)}
				{resendAgentUserInvitationIsLoading && <span className="text-gray-600">Resending...</span>}
				{hasResent &&
					!resendAgentUserInvitationIsLoading &&
					resendAgentUserInvitationError === null && (
						<div className="flex items-center gap-[5px] text-inrev-light-blue">
							<HiCheck />
							<span>Resent</span>
						</div>
					)}
				{resendAgentUserInvitationError !== null && (
					<span className="text-red-600">Resend Failed</span>
				)}
				<Separator orientation="vertical" className="bg-gray-200 h-[10px]" />
				<ConfirmationModalClickWrapper
					message={`Are you sure you want to cancel this invitation for ${agent.email}?`}
					className="max-w-[500px]"
					shouldConfirm={!resendAgentUserInvitationIsLoading}
					onConfirm={() => cancelAgentUserInvitation(agent.id)}
				>
					<span
						className="cursor-pointer hover:underline text-red-400"
						onClick={() => cancelAgentUserInvitation(agent.id)}
					>
						Cancel
					</span>
				</ConfirmationModalClickWrapper>
			</div>
		</div>
	);
};

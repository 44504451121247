import { Schema } from "@inrev/common";
import { ReactNode } from "react";

export type FormSchemaIncludeProps = {
	schemaInclude?: boolean | Schema<any>;
};

export const FormSchemaInclude = (props: FormSchemaIncludeProps & { children: ReactNode }) => {
	if (!("schemaInclude" in props) || props.schemaInclude) {
		return props.children;
	} else {
		return null;
	}
};

import { initializeCommonLibraryContext } from "@inrev/common/lib/esm/context";
import * as Sentry from "@sentry/react";
import { mimes } from "mrmime";
import React from "react";
import "react-day-picker/dist/style.css";
import ReactDOM from "react-dom/client";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import App from "./App.tsx";
import { getFromValidatedAddressCache, setValidatedAddressCache } from "./api.ts";
import "./index.css";

initializeCommonLibraryContext({
	checkValidAddressCacheFn: getFromValidatedAddressCache,
	setValidAddressCacheFn: setValidatedAddressCache,
});

Sentry.init({
	environment: import.meta.env.VITE_SENTRY_ENV,
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserProfilingIntegration(),
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	profilesSampleRate: 0.2,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

mimes[".docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
mimes["docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
mimes[".xls"] = "application/vnd.ms-excel";
mimes["xls"] = "application/vnd.ms-excel";
mimes[".xlsx"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
mimes["xlsx"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

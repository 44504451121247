import { forwardRef } from "react";
import { CurrencyInputProps } from "react-currency-input-field";
import { FieldError } from "react-hook-form";
import { CurrencyInput } from "./CurrencyInput";
import { LineItem } from "./LineItem";

type CurrencyLineItemProps = CurrencyInputProps & {
	label: string;
	marker?: boolean;
	className?: string;
	id?: string;
	tooltip?: string;
	onChange?: (value: string) => void;
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
};

export const CurrencyLineItem = forwardRef<HTMLInputElement, CurrencyLineItemProps>(
	({ label, marker, className, id, tooltip, ...props }, _ref) => (
		<LineItem id={id} label={label} marker={marker} className={className} tooltip={tooltip}>
			<CurrencyInput
				{...props}
				condensed
				onChange={undefined}
				onValueChange={(e) => props.onChange && props.onChange(e ?? "")}
				className="w-full max-w-[220px] h-[40px] bg-white text-[16px]"
			/>
		</LineItem>
	),
);

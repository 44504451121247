import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { FormItemLabel, FormLabelProps } from "./FormItemLabel";

export const FormQuestionLabel = ({
	className,
	subLabel,
	...props
}: FormLabelProps & { subLabel?: ReactNode }) => {
	if (subLabel) {
		return (
			<div className="flex flex-col space-y-[8px]">
				<FormItemLabel
					{...props}
					className={cn("text-gray-800/95 text-[14.75px] font-[485]", className)}
				/>
				<div className="text-[14px] text-gray-500 font-normal !mb-[5px] italic">{subLabel}</div>
			</div>
		);
	} else {
		return (
			<FormItemLabel
				{...props}
				className={cn("mb-[3px] text-gray-800/95 text-[14.75px] font-[485]", className)}
			/>
		);
	}
};

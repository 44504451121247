import { Cell, flexRender } from "@tanstack/react-table";
import { cn } from "../../lib/utils";

type TableCellProps<TData, TValue> = {
	cell: Cell<TData, TValue>;
	noTruncate?: boolean;
	rowsIncludeActionMenu?: boolean;
};
export const TableCell = <TData, TValue>({
	cell,
	noTruncate,
	rowsIncludeActionMenu,
}: TableCellProps<TData, TValue>) => {
	const isActionMenu = cell.column.id === "action_menu";

	if (isActionMenu) return flexRender(cell.column.columnDef.cell, cell.getContext());
	else
		return (
			<div
				className={cn(
					"h-full flex items-center px-[15px]",
					rowsIncludeActionMenu ? "[&:nth-last-child(2)]:flex-1" : "[&:nth-last-child(1)]:flex-1",
				)}
				style={{ width: cell.column.getSize(), minWidth: cell.column.getSize() }}
			>
				<div className={cn(!noTruncate ? "truncate" : undefined)}>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</div>
			</div>
		);
};

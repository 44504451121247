import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import {
	AttachmentFile,
	AttachmentFileType,
	FileExtension,
	FileId,
	FileType,
	UploadedFile,
	attachmentGroupMap,
} from "@inrev/common";
import { useEffect, useMemo, useRef, useState } from "react";
import { HiCheck, HiOutlineExclamationCircle } from "react-icons/hi2";
import { QueryKey } from "react-query";
import { v4 } from "uuid";
import { UseUpload, useAttachmentUpload, useFileUpload } from "../../domain/agent/request/api";
import { UploadFile } from "../../types";
import { getNameAndExtensionFromFileName } from "../../utils";
import { Modal } from "../layout/Modal";
import { cn } from "../lib/utils";
import { AttachmentTypeMultiSelect } from "./AttachmentTypeMultiSelect";
import { Button } from "./Button";
import { Card } from "./Card";
import { Dropzone } from "./Dropzone";
import { FileUploadItem, PendingFileUploadItem } from "./FileUploadItem";

export type UploadProps<
	TFileType extends FileType = FileType,
	T = UploadedFile<TFileType>,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = {
	files: (UploadedFile<TFileType> & { createdAtMs?: number })[];
	useUpload: UseUpload<T, TFileType>;
	onUpload: (value: T) => void;
	onDelete: (id: string) => void;
	onUpdate: (value: T) => void;
	onDownload?: (id: string) => void;
	onBlur?: () => void;
	disablePDFViewer?: boolean;
	baseUrl?: string;
	queryKey?: string;
	expandedByDefault?: boolean;
	disableTypeSelection?: boolean;
	disableTabs?: boolean;
	allowedTypesAndLabels: TAllowedTypesAndLabels;
	allowTypeConfirmation?: boolean;
	allowedExtensions?: FileExtension[];
	requiredTypes?: TRequired;
	typeQuestionText?: string;
	maxTypesPerFile?: number;
	subsequentFileTypeRestriction?: true;
	maxFiles?: number;
	hideChecklist?: true;
	showErrors?: boolean;
	preventDelete?: boolean;
	className?: string;
};

const Upload = <
	TFileType extends FileType = FileType,
	T = UploadedFile<TFileType>,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>({
	files,
	useUpload,
	onUpload,
	onDelete,
	onDownload,
	onBlur,
	onUpdate,
	expandedByDefault = false,
	disableTypeSelection = false,
	disablePDFViewer = false,
	baseUrl,
	queryKey,
	disableTabs,
	allowedTypesAndLabels,
	allowedExtensions,
	requiredTypes,
	allowTypeConfirmation,
	maxTypesPerFile,
	subsequentFileTypeRestriction,
	maxFiles,
	showErrors,
	hideChecklist,
	preventDelete,
	className,
}: UploadProps<TFileType, T, TAllowedTypesAndLabels, TAllowedType, TRequired>) => {
	const [filesPendingUpload, setFilesPendingUpload] = useState<UploadFile<TFileType>[]>([]);
	const [filesPendingUpdate, setFilesPendingUpdate] = useState<UploadedFile<TFileType>[]>([]);
	const [fileIdsPendingDelete, setFileIdsPendingDelete] = useState<string[]>([]);
	const [showTypeSelection, setShowTypeSelection] = useState<boolean>(false);
	const [fileForTypeSelection, setFileForTypeSelection] = useState<File | undefined>();
	const [uploadModalError, setUploadModalError] = useState<string | undefined>();

	const [tabHeight, setTabHeight] = useState<number>(0);

	const allowedTypes = useMemo(() => {
		const types = Object.keys(allowedTypesAndLabels);
		if (!types.length) throw new Error();
		return types as [TFileType, ...TFileType[]];
	}, [allowedTypesAndLabels]);

	const categorizedFiles = useMemo(() => {
		const categoryMap: Record<string, (UploadedFile<TFileType> & { createdAtMs?: number })[]> = {};
		const fileMap: Array<{
			categoryLabel: string;
			files: (UploadedFile<TFileType> & { createdAtMs?: number })[];
		}> = [];
		const otherFiles: (UploadedFile<TFileType> & { createdAtMs?: number })[] = [];

		files.forEach((file) => {
			let fileCategorized = false;
			for (let [tab, typeLabelMap] of attachmentGroupMap) {
				file.types.forEach((fileType) => {
					if (typeLabelMap[fileType]) {
						if (!categoryMap[tab]) {
							categoryMap[tab] = [];
						}
						if (!categoryMap[tab].includes(file)) {
							categoryMap[tab].push(file);
							fileCategorized = true;
						}
					}
				});
			}
			if (!fileCategorized) {
				otherFiles.push(file);
			}
		});

		fileMap.push({ categoryLabel: "All", files });
		// Order the tabs based on the order of the attachmentGroupMap
		for (let [tab, _typeLabelMap] of attachmentGroupMap) {
			if (categoryMap[tab]) {
				fileMap.push({ categoryLabel: tab, files: categoryMap[tab] });
			}
		}
		// Add Other category if there are any files that don't fit into a category
		if (otherFiles.length && otherFiles.length !== files.length) {
			fileMap.push({ categoryLabel: "Other", files: otherFiles });
		}

		return fileMap;
	}, [files]);

	const showAttachmentGroupTabs = () => {
		if (disableTabs) {
			return false;
		}
		if (!categorizedFiles.length || !categorizedFiles[0].files.length) {
			return false;
		}
		if (
			categorizedFiles.length === 2 &&
			categorizedFiles[0].categoryLabel === "All" &&
			categorizedFiles[1].categoryLabel === "Other"
		) {
			return false;
		}
		return true;
	};

	const atLeastOneSelectedType = (
		selectedTypes: TFileType[],
	): selectedTypes is [TFileType, ...TFileType[]] => {
		// always return true if types are optional
		if (!requiredTypes || requiredTypes.length === 0) return true;
		return selectedTypes.length > 0;
	};

	const requiredTypesFulfilled = useMemo(() => {
		if (!requiredTypes?.length) return true;
		return requiredTypes.every((requiredType) =>
			files.some((file) => file.types?.includes(requiredType)),
		);
	}, [requiredTypes, files]);

	const { uploadFn, updateFn, deleteFn } = useUpload({
		onUploadSuccess: (data, args) => {
			setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
			onUpload(data);
		},
		onUploadError: (error, args) => {
			console.error(error);
			setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
		},
		onUpdateSuccess: (data, args) => {
			setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
			onUpdate(data);
		},
		onUpdateError: (error, args) => {
			console.error(error);
			setFilesPendingUpdate(filesPendingUpdate?.filter((file) => file.id !== args.pendingFile.id));
		},
		onDeleteSuccess: (args) => {
			onDelete(args.id);
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
			onBlur?.();
		},
		onDeleteError: (error, args) => {
			console.error(error);
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
		},
	});

	const handleUpload = (dropZoneFiles: File[]) => {
		dropZoneFiles.forEach((file, idx) => {
			const { fileName, extension } = getNameAndExtensionFromFileName(file.name);
			if (allowedTypes.length === 1) {
				const uploadArgs = {
					rawFile: file,
					pendingFile: { id: v4() as FileId, name: fileName, extension, types: allowedTypes },
				};
				setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
				uploadFn(uploadArgs);
			} else if (subsequentFileTypeRestriction && (files.length || filesPendingUpload.length)) {
				// Upload the file with no type first, then the user to select a type when known
				const uploadArgs = {
					rawFile: file,
					pendingFile: {
						id: v4() as FileId,
						name: fileName,
						extension,
						types: [...files, ...filesPendingUpload][idx].types,
					},
				};
				setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
				uploadFn(uploadArgs);
			} else if (!!requiredTypes) {
				setFileForTypeSelection(file);
				setShowTypeSelection(true);
			} else {
				const uploadArgs = {
					rawFile: file,
					pendingFile: { id: v4() as FileId, name: fileName, extension, types: [] },
				};
				setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
				uploadFn(uploadArgs);
			}
		});
	};

	const handleDelete = (id: string) => {
		if (!preventDelete) {
			setFileIdsPendingDelete([...fileIdsPendingDelete, id]);
			deleteFn({ id });
		}
	};

	const handleTypeConfirmation = (file: UploadedFile<TFileType>) => {
		try {
			const updateArgs = {
				pendingFile: {
					...file,
				},
			};
			updateFn(updateArgs);
		} catch (error) {
			console.error(error);
		}
	};

	const handleTypeConfirmationCancel = (file: UploadedFile<TFileType>) => {
		try {
			const updateArgs = {
				pendingFile: {
					...file,
					types: [],
				},
			};
			updateFn(updateArgs);
		} catch (error) {
			console.error(error);
		}
	};

	const handleUpdateFileTypes = (file: UploadedFile<TFileType>, newTypes: TFileType[]) => {
		try {
			if (requiredTypes && !atLeastOneSelectedType(newTypes))
				throw new Error("Please select at least one type");
			const updateArgs = {
				pendingFile: {
					...file,
					types: newTypes,
				},
			};
			updateFn(updateArgs);
		} catch (error) {
			if (error instanceof Error) setUploadModalError(error.message);
		}
	};

	const handleUploadTypeSelection = (newTypes: TFileType[]) => {
		try {
			if (requiredTypes && !atLeastOneSelectedType(newTypes))
				throw new Error("Please select at least one type");
			if (!fileForTypeSelection) throw new Error("No file to upload");
			const { fileName, extension } = getNameAndExtensionFromFileName(fileForTypeSelection.name);
			const uploadArgs = {
				rawFile: fileForTypeSelection,
				pendingFile: { id: v4() as FileId, name: fileName, extension, types: newTypes },
			};
			setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
			uploadFn(uploadArgs);
		} catch (error) {
			if (error instanceof Error) setUploadModalError(error.message);
		}
	};

	useEffect(() => {
		onBlur?.();
	}, [files]);

	return (
		<div className={cn("relative flex flex-col space-y-[25px] w-full", className)}>
			{requiredTypes && !!requiredTypes.length && !hideChecklist && (
				<div
					className={cn(
						"relative flex-1 flex flex-col space-y-[5px] pl-[23px] after:absolute after:w-[3px] after:rounded-full after:bg-gray-100 after:top-0 after:bottom-0 after:left-0",
						showErrors && !requiredTypesFulfilled ? "after:bg-red-100" : undefined,
					)}
				>
					{requiredTypes.map((type) => {
						const typeIncluded = files.some((file) => file.types.includes(type));
						return (
							<div
								key={type}
								className={cn(
									"flex items-center space-x-[10px] text-[15px]",
									typeIncluded ? "opacity-100" : "opacity-70",
									showErrors && !typeIncluded ? "text-red-500 opacity-100" : undefined,
								)}
							>
								<div
									className={cn(
										"relative aspect-square h-4 w-4 rounded-full border border-gray-400 bg-white focus:outline-none flex items-center justify-center",
										showErrors && !typeIncluded ? "border-red-500" : undefined,
									)}
								>
									{typeIncluded && (
										<div className="flex items-center justify-center absolute top-[-1px] left-[-1px] aspect-square h-4 w-4 rounded-full bg-inrev-light-blue ml-[-1] mt-[-1]">
											<HiCheck className="text-[10px] text-white stroke-[4px]" />
										</div>
									)}
								</div>
								<div>{allowedTypesAndLabels[type]}</div>
							</div>
						);
					})}
				</div>
			)}
			<div className="w-full flex flex-col space-y-[10px]">
				{showErrors &&
					!requiredTypesFulfilled &&
					hideChecklist &&
					requiredTypes &&
					!!requiredTypes.length && <div className="text-[13px] text-red-500">Required</div>}
				{(maxFiles === undefined || files.length + filesPendingUpload.length < maxFiles) &&
					!showAttachmentGroupTabs() && (
						<Dropzone allowedExtensions={allowedExtensions} onSuccess={handleUpload} />
					)}
				{(!!files.length || !!filesPendingUpload.length) && (
					<>
						{showAttachmentGroupTabs() ? (
							<TabGroup vertical className="flex flex-row gap-[44px]">
								<TabList
									as="div"
									className="flex flex-col w-[150px] gap-y-[4px] text-[13px] cursor-pointer"
								>
									{categorizedFiles.map((tabGroup, idx) => (
										<Tab
											as="div"
											key={`tab-${idx}`}
											className={`h-[36px] py-[9px] rounded-[4px] text-gray-500/90 data-[selected]:font-[475] data-[selected]:text-gray-800 hover:data-[selected]:text-gray-800 data-[selected]:bg-gray-100/80 hover:data-[selected]:bg-gray-100/80 hover:cursor-pointer hover:text-gray-600/95 px-3 flex items-center justify-start outline-none ring-0`}
										>
											{tabGroup.categoryLabel}
										</Tab>
									))}
								</TabList>
								<TabPanels className="flex-1">
									{(maxFiles === undefined ||
										files.length + filesPendingUpload.length < maxFiles) && (
										<>
											<Dropzone allowedExtensions={allowedExtensions} onSuccess={handleUpload} />
											<div className="mb-4" />
										</>
									)}
									{categorizedFiles?.map((fileSet, idx) => (
										<AttachmentItemTabPanel
											key={`tab-panel-${idx}`}
											tabHeight={tabHeight}
											files={fileSet.files}
											currentTab={idx}
											baseUrl={baseUrl}
											queryKey={queryKey}
											allowedTypesAndLabels={allowedTypesAndLabels}
											preventDelete={preventDelete}
											expandedByDefault={expandedByDefault}
											maxTypesPerFile={maxTypesPerFile}
											disableTypeSelection={disableTypeSelection}
											allowTypeConfirmation={allowTypeConfirmation}
											fileIdsPendingDelete={fileIdsPendingDelete}
											filesPendingUpload={filesPendingUpload}
											onSave={handleUpdateFileTypes}
											handleTypeConfirmation={handleTypeConfirmation}
											handleTypeConfirmationCancel={handleTypeConfirmationCancel}
											setTabHeight={setTabHeight}
											handleDelete={handleDelete}
											onDownload={onDownload}
										/>
									))}
								</TabPanels>
							</TabGroup>
						) : (
							<>
								{filesPendingUpload.map((file) => (
									<PendingFileUploadItem
										key={file.id}
										{...file}
										typeLabelMap={allowedTypesAndLabels}
									/>
								))}
								{files.map((file) => (
									<FileUploadItem
										key={file.id}
										{...file}
										baseUrl={baseUrl}
										queryKey={queryKey}
										typeLabelMap={allowedTypesAndLabels}
										preventDelete={preventDelete}
										onSave={handleUpdateFileTypes}
										disablePDFViewer={disablePDFViewer}
										onTypeConfirmation={() => {
											handleTypeConfirmation(file);
										}}
										onTypeConfirmationCancel={() => {
											handleTypeConfirmationCancel(file);
										}}
										expandedByDefault={expandedByDefault}
										maxTypesPerFile={maxTypesPerFile}
										disableTypeSelection={disableTypeSelection}
										allowTypeConfirmation={allowTypeConfirmation}
										onDelete={handleDelete}
										deleting={fileIdsPendingDelete.includes(file.id)}
										onDownload={onDownload}
									/>
								))}
							</>
						)}
					</>
				)}
			</div>
			{showTypeSelection && (
				<AttachmentTypeMultiSelect
					availableTypes={allowedTypes}
					initialTypes={[]}
					selectOne={maxTypesPerFile === 1}
					expandedByDefault={expandedByDefault}
					onSave={handleUploadTypeSelection}
					title="Select File Type"
					close={() => setShowTypeSelection(false)}
					onBlur={onBlur}
				/>
			)}
			{uploadModalError && (
				<Modal onClickOutside={() => setUploadModalError(undefined)}>
					<Card className="flex flex-col items-center gap-y-[24px] p-[24px] w-[500px] max-w-[500px]">
						<div className="flex space-x-[28px] w-full px-[28px] py-[22px] border border-opacity-40 border-red-500 bg-red-50 rounded">
							<HiOutlineExclamationCircle className="text-[32px] stroke-[1.75] text-red-600/90 shrink-0 mt-[3px]" />
							<div className="text-red-600/90 flex flex-col space-y-[10px]">
								<span className="text-[20px] font-semibold">
									<p>Error</p>
								</span>
								<span className="text-[15px] font-medium">
									<p>{uploadModalError}</p>
								</span>
							</div>
						</div>
						<Button
							onClick={() => setUploadModalError(undefined)}
							color="gray"
							filled
							className="w-1/2"
						>
							OK
						</Button>
					</Card>
				</Modal>
			)}
		</div>
	);
};

export type FileUploadProps<
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = Omit<
	UploadProps<TFileType, UploadedFile<TFileType>, TAllowedTypesAndLabels, TAllowedType, TRequired>,
	"files" | "useUpload" | "onUpload" | "onDelete" | "onUpdate"
> & {
	value: UploadedFile<TFileType>[];
	onChange: (value: UploadedFile<TFileType>[]) => void;
};

export const FileUpload = <
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>(
	props: FileUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
) => {
	const { value, onChange, ...rest } = props;
	const onUpload = (file: UploadedFile<TFileType>) => onChange([...value, file]);
	const onDelete = (id: string) => onChange(value.filter((file) => file.id !== id));
	const onUpdate = (file: UploadedFile<TFileType>) =>
		onChange(value.map((exiting) => (exiting.id === file.id ? file : exiting)));

	return (
		<Upload
			{...rest}
			files={value}
			onUpload={onUpload}
			onDelete={onDelete}
			onUpdate={onUpdate}
			useUpload={useFileUpload}
		/>
	);
};

export type AttachmentUploadProps<
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = Omit<
	UploadProps<
		TFileType,
		AttachmentFile<TFileType>,
		TAllowedTypesAndLabels,
		TAllowedType,
		TRequired
	>,
	"files" | "useUpload" | "onUpload" | "onDelete" | "onUpdate" | "onConfirm"
> & {
	value: AttachmentFile<TFileType>[];
	onChange: (value: AttachmentFile<TFileType>[]) => void;
	onDownload?: (id: string) => void;
	onUpdate?: (value: AttachmentFile<TFileType>) => void;
	url: `${string}/attachments`;
	invalidateQueryKeys?: QueryKey[];
};

export const AttachmentUpload = <
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>(
	props: AttachmentUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
) => {
	const { value, onChange, url, ...rest } = props;
	const onUpload = (attachment: AttachmentFile<TFileType>) => onChange([...value, attachment]);
	const onDelete = (id: string) => onChange(value.filter((attachment) => attachment.id !== id));
	const onUpdate = (attachment: AttachmentFile<TFileType>) =>
		onChange(value.map((existing) => (existing.id === attachment.id ? attachment : existing)));
	const attachmentFiles = useMemo(
		() =>
			value.map((attachment) => ({
				...attachment.file,
				id: attachment.id as unknown as FileId,
				createdAtMs: attachment.createdAtMs,
			})),
		[value],
	);

	return (
		<Upload
			{...rest}
			files={attachmentFiles}
			onUpload={onUpload}
			onDelete={onDelete}
			onUpdate={onUpdate}
			useUpload={useAttachmentUpload<TFileType>(url, props.invalidateQueryKeys)}
			onDownload={props.onDownload}
		/>
	);
};

const AttachmentItemTabPanel = <
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
>({
	tabHeight,
	files,
	allowedTypesAndLabels,
	preventDelete,
	expandedByDefault,
	maxTypesPerFile,
	disableTypeSelection,
	allowTypeConfirmation,
	fileIdsPendingDelete,
	filesPendingUpload,
	currentTab,
	baseUrl,
	queryKey,
	handleTypeConfirmation,
	handleTypeConfirmationCancel,
	handleDelete,
	onSave,
	onDownload,
	setTabHeight,
}: {
	tabHeight: number;
	files: (UploadedFile<TFileType> & { createdAtMs?: number })[];
	itemIdx?: number;
	allowedTypesAndLabels: TAllowedTypesAndLabels;
	preventDelete?: boolean;
	expandedByDefault?: boolean;
	maxTypesPerFile?: number;
	disableTypeSelection?: boolean;
	allowTypeConfirmation?: boolean;
	fileIdsPendingDelete: string[];
	filesPendingUpload: UploadFile<TFileType>[];
	currentTab: number;
	baseUrl?: string;
	queryKey?: string;
	handleTypeConfirmation?: (file: UploadedFile<TFileType>) => void;
	handleTypeConfirmationCancel?: (file: UploadedFile<TFileType>) => void;
	handleDelete: (id: string) => void;
	onSave: (file: UploadedFile<TFileType>, newTypes: TFileType[]) => void;
	onDownload?: (id: string) => void;
	setTabHeight: (height: number) => void;
}) => {
	const tabRef = useRef<HTMLDivElement>(null); // used so when changing tabs the height doesn't move

	useEffect(() => {
		if (tabRef.current) {
			if (tabRef.current.clientHeight > tabHeight) {
				setTabHeight(tabRef.current.clientHeight);
			}
		}
	}, [tabRef]);

	return (
		<TabPanel
			as="div"
			ref={currentTab === 0 ? tabRef : null}
			className={"min-h-fit"}
			style={tabHeight ? { height: tabHeight } : {}}
		>
			<div className="w-full. max-w-full flex flex-col space-y-[10px] cursor-default">
				{filesPendingUpload.map((file) => (
					<PendingFileUploadItem key={file.id} {...file} typeLabelMap={allowedTypesAndLabels} />
				))}
				{files
					.sort((a, b) => (a.createdAtMs && b.createdAtMs ? b.createdAtMs - a.createdAtMs : 0))
					.map((file) => (
						<FileUploadItem
							key={file.id}
							{...file}
							createdAtMs={file.createdAtMs}
							baseUrl={baseUrl}
							queryKey={queryKey}
							typeLabelMap={allowedTypesAndLabels}
							preventDelete={preventDelete}
							onSave={onSave}
							onTypeConfirmation={() => {
								!!handleTypeConfirmation && handleTypeConfirmation(file);
							}}
							onTypeConfirmationCancel={() => {
								!!handleTypeConfirmationCancel && handleTypeConfirmationCancel(file);
							}}
							expandedByDefault={expandedByDefault}
							maxTypesPerFile={maxTypesPerFile}
							disableTypeSelection={disableTypeSelection}
							allowTypeConfirmation={allowTypeConfirmation}
							onDelete={handleDelete}
							deleting={fileIdsPendingDelete.includes(file.id)}
							onDownload={onDownload}
						/>
					))}
			</div>
		</TabPanel>
	);
};

import { z } from 'zod';
import { suretyQuoteId, agencyId, suretyAccountId, companyContactId } from '../../../types/opaque.mjs';
import { dateTimeToISODateTimeSchema, zswitch } from '../../../types/utils.mjs';
import '../../../types/credit-report/company-credit-report.mjs';
import '../../../types/credit-report/individual-credit-report.mjs';
import '../../../types/payment/payment-template.mjs';
import '../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../types/surety/account/surety-account.types.mjs';
import '../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../types/surety/bond/surety-bond.types.mjs';
import '../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../types/surety/indemnity/indemnity-agreement.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.baseSchema = z.object({
            id: suretyQuoteId,
            agencyId: agencyId,
            createdAt: dateTimeToISODateTimeSchema,
            displayTitle: z.string(),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Submit;
(function (Submit) {
    (function (Request) {
        let NewAccount;
        (function (NewAccount) {
            (function (Base) {
                (function (Principal) {
                    Principal.schema = z.object({
                        newPrincipal: z.literal(true),
                    });
                })(Base.Principal || (Base.Principal = {}));
            })(NewAccount.Base || (NewAccount.Base = {}));
        })(NewAccount = Request.NewAccount || (Request.NewAccount = {}));
        let ExistingAccount;
        (function (ExistingAccount) {
            (function (Base) {
                (function (Principal) {
                    Principal.schema = z.object({
                        newPrincipal: z.literal(false),
                        principalAccountId: suretyAccountId,
                        principalCompanyId: companyContactId,
                    });
                })(Base.Principal || (Base.Principal = {}));
            })(ExistingAccount.Base || (ExistingAccount.Base = {}));
        })(ExistingAccount = Request.ExistingAccount || (Request.ExistingAccount = {}));
        (function (Base) {
            let Principal;
            (function (Principal) {
                Principal.schema = z
                    .object({ newPrincipal: z.boolean() })
                    .passthrough()
                    .pipe(zswitch((input) => {
                    switch (input.newPrincipal) {
                        case true:
                            return NewAccount.Base.Principal.schema.passthrough();
                        case false:
                            return ExistingAccount.Base.Principal.schema.passthrough();
                        default:
                            return z.never();
                    }
                }));
            })(Principal = Base.Principal || (Base.Principal = {}));
            Base.baseSchema = (suretyType) => z
                .object({
                suretyType: z.literal(suretyType),
                data: z
                    .object({
                    principal: Principal.schema,
                })
                    .passthrough(),
            })
                .passthrough();
        })(Request.Base || (Request.Base = {}));
    })(Submit.Request || (Submit.Request = {}));
})(Submit || (Submit = {}));

export { Get, Submit };

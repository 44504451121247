import { ReactNode } from "react";
import { cn } from "../lib/utils";

export type StackedCardProps = {
	className?: string;
	wrapperClassName?: string;
	children?: ReactNode;
};

export const StackedCard = ({ className, wrapperClassName, children }: StackedCardProps) => {
	return (
		<div className={cn("relative isolate w-full !mb-[5px]", wrapperClassName)}>
			<div className="border border-gray-300 rounded-md bg-white absolute top-[6px] left-[6px] right-[-6px] bottom-[-6px] z-[-1] w-full"></div>
			<div className={cn("w-full h-full border border-gray-300 rounded-md bg-white", className)}>
				{children}
			</div>
		</div>
	);
};

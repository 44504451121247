import { DateTime } from "luxon";
import { ReactNode } from "react";
import { cn } from "../../../../components/lib/utils";
import { CardGrayBody } from "../../../../components/ui/CardGrayBody";

type AdminAccountBondingLineSectionLayoutProps = {
	header: string;
	headerRight: ReactNode;
	createdAt?: DateTime;
	children: ReactNode;
	className?: string;
};

export const AdminAccountBondingLineSectionLayout = ({
	header,
	headerRight,
	createdAt,
	children,
	className,
}: AdminAccountBondingLineSectionLayoutProps) => (
	<div className={cn("flex flex-col space-y-[12px]", className)}>
		<CardGrayBody
			header={
				<div className="w-full flex items-center space-x-[10px] justify-between">
					<span>{header}</span>
					{headerRight}
				</div>
			}
			headerClassName="py-[10px] px-[25px] pr-[10px] text-[17px] font-medium"
			className="shadow-sm"
			contentClassName="w-full p-[30px] pt-[25px] flex grid-auto-rows space-x-[40px]"
		>
			{children}
		</CardGrayBody>
		{createdAt !== undefined && (
			<span className="text-[13px] italic text-gray-500 self-end">
				Analysis last run {createdAt.toLocaleString(DateTime.DATETIME_MED)}
			</span>
		)}
	</div>
);

import { Switch, SwitchProps } from "@headlessui/react";
import { HiCheck } from "react-icons/hi2";
import { cn } from "../lib/utils";

export type CheckboxProps = SwitchProps<"button"> & { partialChecked?: boolean; compact?: true };

export const Checkbox = (props: CheckboxProps) => {
	return (
		<Switch
			{...props}
			className={cn(
				"flex items-center justify-center w-[17px] min-w-[17px] h-[17px] min-h-[17px] rounded-[3px] bg-white outline outline-[1px] outline-offset-[-1px] outline-gray-600 group-hover:outline-[2px] hover:outline-[2px] shadow-sm cursor-pointer",
				props.compact ? "w-[16px] min-w-[16px] h-[16px] min-h-[16px] rounded-[2.5px]" : undefined,
				props.className,
				props.checked || props.partialChecked
					? "bg-inrev-light-blue outline-[0px] hover:outline-[0px] group-hover:outline-[0px]"
					: undefined,
			)}
		>
			{props.checked && <HiCheck className="text-[12px] text-white stroke-[2.25px]" />}
			{!props.checked && props.partialChecked && <hr className="w-[9px] h-[2px] bg-white" />}
		</Switch>
	);
};

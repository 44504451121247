type InlineLinkProps = {
	children: string;
	to: string;
	newTab?: true;
	tabIndex?: number;
};

export const InlineLink = ({ children, to, newTab }: InlineLinkProps) => (
	<a
		href={to}
		target={newTab ? "_blank" : undefined}
		className="text-inrev-light-blue underline active:text-inrev-light-blue active:underline visited:bg-inrev-light-blue visited:underline"
	>
		{children}
	</a>
);

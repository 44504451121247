import { cn } from "../lib/utils";

type SideAnchoredTextBlockProps = {
	text: string;
	containerClassName?: string;
	textBlockClassName?: string;
};

export const SideAnchoredTextBlock = ({
	text,
	containerClassName,
	textBlockClassName,
}: SideAnchoredTextBlockProps) => {
	return (
		<div className={cn("flex space-x-[15px] items-center", containerClassName, "relative")}>
			<div className="w-[2px] rounded-full bg-gray-200 absolute top-0 bottom-0 left-0"></div>
			<div
				className={cn("text-[16px] text-gray-900 leading-[22px] font-normal", textBlockClassName)}
			>
				{text}
			</div>
		</div>
	);
};

import type { SectionSchemaFromData } from "@inrev/common";
import { useMemo } from "react";
import {
	type Section,
	ShrinkingHeaderSectionNavLayout,
	type ShrinkingHeaderSectionNavLayoutProps,
} from "./ShrinkingHeaderSectionNavLayout";

export type ShrinkingHeaderSectionSchemaNavLayoutProps<
	TSchema extends SectionSchemaFromData<Record<string, any>>,
> = Omit<ShrinkingHeaderSectionNavLayoutProps, "sections"> & {
	schema: TSchema;
	sectionMap: { [K in keyof TSchema]: K extends string ? Section<K> : never };
	summarySectionComponent: (props: { sectionNames: (keyof TSchema)[] }) => JSX.Element;
};

export const ShrinkingHeaderSectionSchemaNavLayout = <
	TSchema extends SectionSchemaFromData<Record<string, any>>,
>(
	props: ShrinkingHeaderSectionSchemaNavLayoutProps<TSchema>,
) => {
	const sections: Section[] = useMemo(() => {
		const sections = Object.entries(props.schema)
			.sort((a, b) => a[1]!.index - b[1]!.index)
			.map((entry) => ({
				...props.sectionMap[entry[0]],
				name: entry[0],
			}));
		if (!!props.summarySectionComponent) {
			sections.push({
				name: "summary",
				navLabel: "Summary",
				component: (
					<props.summarySectionComponent sectionNames={sections.map((section) => section.name)} />
				),
				navError: false,
				headerTitle: "Summary",
			});
		}
		return sections;
	}, [props.schema, props.sectionMap, props.summarySectionComponent]);

	return <ShrinkingHeaderSectionNavLayout {...props} sections={sections} />;
};

import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyContactId, Dtos, FinancialStatementId } from "@inrev/common";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../components/layout/form/FormRow";
import { Button } from "../../../../components/ui/Button";
import { LineItemStackedCard } from "../../../../components/ui/LineItemStackedCard";
import { FormCurrencyLineItem } from "../../../../components/ui/form/FormCurrencyLineItem";
import { FormDatePicker } from "../../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { FormYesNoLineItem } from "../../../../components/ui/form/FormYesNoLineItem";
import { financialsPreparationMethodOptions } from "../../../../constants";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { useAdminUpsertFinancialStatement } from "../../../../domain/admin/financial-statement/api";

export type DefaultAdminFinancialStatementFormData = {
	date: string;
	preparationMethod: string;
	corporateCash: string;
	currentAssets: string;
	currentLiabilities: string;
	totalAssets: string;
	totalLiabilities: string;
	accountsPayable: string;
	accountsReceivable: string;
	underbillings: string;
	creditCardsPayable: string;
	bankLinePayable: string;
	blocSize: string;
	accruedExpenses: string;
	overbillings: string;
	currentPortionOfLtd: string;
	termLoanDebt: string;
	revenue: string;
	gaExpense: string;
	profitable: boolean | "";
	companyContactId: string;
};

const getDefaultFormData = (
	companyContactId: CompanyContactId,
): DefaultAdminFinancialStatementFormData => ({
	date: "",
	preparationMethod: "",
	corporateCash: "",
	currentAssets: "",
	currentLiabilities: "",
	totalAssets: "",
	totalLiabilities: "",
	accountsPayable: "",
	accountsReceivable: "",
	underbillings: "",
	creditCardsPayable: "",
	bankLinePayable: "",
	blocSize: "",
	accruedExpenses: "",
	overbillings: "",
	currentPortionOfLtd: "",
	termLoanDebt: "",
	revenue: "",
	gaExpense: "",
	profitable: "",
	companyContactId: companyContactId,
});

const selectedToFormData = (
	selectedFinancialStatement: AdminSuretyAccount["financialStatements"][number],
): DefaultAdminFinancialStatementFormData => ({
	date: selectedFinancialStatement.date,
	preparationMethod: selectedFinancialStatement.preparationMethod ?? "",
	corporateCash: selectedFinancialStatement.corporateCash?.toString() ?? "",
	currentAssets: selectedFinancialStatement.currentAssets?.toString() ?? "",
	currentLiabilities: selectedFinancialStatement.currentLiabilities?.toString() ?? "",
	totalAssets: selectedFinancialStatement.totalAssets?.toString() ?? "",
	totalLiabilities: selectedFinancialStatement.totalLiabilities?.toString() ?? "",
	accountsPayable: selectedFinancialStatement.accountsPayable?.toString() ?? "",
	accountsReceivable: selectedFinancialStatement.accountsReceivable?.toString() ?? "",
	underbillings: selectedFinancialStatement.underbillings?.toString() ?? "",
	creditCardsPayable: selectedFinancialStatement.creditCardsPayable?.toString() ?? "",
	bankLinePayable: selectedFinancialStatement.bankLinePayable?.toString() ?? "",
	blocSize: selectedFinancialStatement.blocSize?.toString() ?? "",
	accruedExpenses: selectedFinancialStatement.accruedExpenses?.toString() ?? "",
	overbillings: selectedFinancialStatement.overbillings?.toString() ?? "",
	currentPortionOfLtd: selectedFinancialStatement.currentPortionOfLtd?.toString() ?? "",
	termLoanDebt: selectedFinancialStatement.termLoanDebt?.toString() ?? "",
	revenue: selectedFinancialStatement.revenue?.toString() ?? "",
	gaExpense: selectedFinancialStatement.gaExpense?.toString() ?? "",
	profitable: selectedFinancialStatement.profitable ?? "",
	companyContactId: selectedFinancialStatement.companyContactId!,
});

type AdminUpsertFinancialStatementFormData = z.infer<
	typeof Dtos.Admin.FinancialStatement.Create.Request.baseSchema
>;

export type AdminUpsertFinancialStatementModalProps = {
	account: AdminSuretyAccount;
	selectedFinancialStatementId?: FinancialStatementId;
	onClose: () => void;
};

export const AdminFinancialStatementModal = ({
	account,
	selectedFinancialStatementId,
	onClose,
}: AdminUpsertFinancialStatementModalProps) => {
	const {
		upsertFinancialStatement,
		upsertFinancialStatementIsLoading,
		upsertFinancialStatementSuccess,
	} = useAdminUpsertFinancialStatement(account.id);
	const selectedFinancialStatement =
		selectedFinancialStatementId !== undefined
			? account.financialStatements.find(
					(statement) => statement.id === selectedFinancialStatementId,
				)
			: undefined;

	const defaultFormData =
		selectedFinancialStatement !== undefined
			? selectedToFormData(selectedFinancialStatement)
			: getDefaultFormData(account.primaryCompanyId);

	const formMethods = useForm<
		DefaultAdminFinancialStatementFormData,
		any,
		Dtos.Admin.FinancialStatement.Create.Request
	>({
		defaultValues: defaultFormData,
		resolver: zodResolver(Dtos.Admin.FinancialStatement.Create.Request.schema),
	});

	const handleSubmit = (formData: AdminUpsertFinancialStatementFormData) => {
		if (selectedFinancialStatement) {
			const selectedFinancialStatementDate = DateTime.fromISO(selectedFinancialStatement.date, {
				zone: "utc",
			}).toFormat("yyyy-MM-dd");
			if (selectedFinancialStatementDate !== formData.date) {
				return upsertFinancialStatement({
					data: {
						...formData,
						companyContactId: account.primaryCompanyId,
					},
					financialStatementId: selectedFinancialStatementId,
				});
			}
		}
		upsertFinancialStatement({ data: { ...formData, companyContactId: account.primaryCompanyId } });
	};

	useEffect(() => {
		if (upsertFinancialStatementSuccess) onClose();
	}, [upsertFinancialStatementSuccess]);

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header={
					selectedFinancialStatementId ? "Edit Financial Statement" : "Add Financial Statement"
				}
				onClose={onClose}
			>
				<div className="max-w-[820px] min-w-[747px] px-[40px] pt-[25px] pb-[35px]">
					<form className="w-full h-fit flex flex-col space-y-[25px]">
						<FormItemGroup condensed>
							<FormRow className="justify-start gap-x-[40px]">
								<FormItem className="max-w-fit shrink-0" id="date">
									<FormItemLabel marker>Statement Date</FormItemLabel>
									<FormDatePicker
										name="date"
										control={formMethods.control}
										defaultMonth={DateTime.now().minus({ years: 1 }).endOf("year")}
									/>
								</FormItem>
								<FormItem id="preparationMethod">
									<FormItemLabel marker>Preparation Method</FormItemLabel>
									<FormDropdown
										name="preparationMethod"
										control={formMethods.control}
										placeholder="Select one"
										options={financialsPreparationMethodOptions}
									/>
								</FormItem>
							</FormRow>
							<LineItemStackedCard
								className="w-full h-fit"
								id="balanceSheet"
								header={
									<div className="w-full h-fit flex">
										<div className="flex-1 flex flex-col space-y-[8px]">
											<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
												Balance Sheet
											</div>
										</div>
										<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
											Where do I find this?
										</div>
									</div>
								}
							>
								<FormCurrencyLineItem
									label="Cash"
									marker
									name="corporateCash"
									control={formMethods.control}
									id="corporateCash"
								/>
								<FormCurrencyLineItem
									label="Accounts Receivable (< 90 days)"
									marker
									name="accountsReceivable"
									control={formMethods.control}
									tooltip="<table width='500'><tr><td>Subtract Accounts Receivable older than 90 days. If an Aging Schedule is not available, subtract 10% of Accounts Receivable.</td></tr></table>"
									id="accountsReceivable"
								/>
								<FormCurrencyLineItem
									label="Underbillings"
									marker
									name="underbillings"
									control={formMethods.control}
									tooltip="<table width='500'><tr><td>This current asset is typically listed as “Underbillings” or “Costs in Excess of Billings”. This can be found in the Assets section of the balance sheet or as a total on the WIP.</td></tr></table>"
									id="underbillings"
								/>
								<FormCurrencyLineItem
									label="Current Assets"
									marker
									name="currentAssets"
									control={formMethods.control}
									id="currentAssets"
								/>
								<FormCurrencyLineItem
									label="Total Assets"
									marker
									name="totalAssets"
									control={formMethods.control}
									id="totalAssets"
								/>
								<FormCurrencyLineItem
									label="Accounts Payable"
									marker
									name="accountsPayable"
									control={formMethods.control}
									id="accountsPayable"
								/>
								<FormCurrencyLineItem
									label="Credit Cards Payable"
									marker
									name="creditCardsPayable"
									control={formMethods.control}
									id="creditCardsPayable"
								/>
								<FormCurrencyLineItem
									label="Revolving Line of Credit"
									marker
									name="bankLinePayable"
									control={formMethods.control}
									id="bankLinePayable"
								/>
								<FormCurrencyLineItem
									label="Accrued Expenses"
									marker
									name="accruedExpenses"
									control={formMethods.control}
									id="accruedExpenses"
								/>
								<FormCurrencyLineItem
									label="Overbillings"
									marker
									name="overbillings"
									control={formMethods.control}
									id="overbillings"
								/>
								<FormCurrencyLineItem
									label="Current Portion of Long Term Debt"
									marker
									name="currentPortionOfLtd"
									control={formMethods.control}
									id="currentPortionOfLtd"
								/>
								<FormCurrencyLineItem
									label="Current Liabilities"
									marker
									name="currentLiabilities"
									control={formMethods.control}
									id="currentLiabilities"
								/>
								<FormCurrencyLineItem
									label="Term Loan Debt"
									marker
									name="termLoanDebt"
									control={formMethods.control}
									id="termLoanDebt"
								/>
								<FormCurrencyLineItem
									label="Total Liabilities"
									marker
									name="totalLiabilities"
									control={formMethods.control}
									id="totalLiabilities"
								/>
							</LineItemStackedCard>
							<LineItemStackedCard
								className="w-full h-fit"
								header={
									<div className="w-full h-fit flex">
										<div className="flex-1 flex flex-col space-y-[8px]">
											<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
												Income Statement
											</div>
										</div>
										<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
											Where do I find this?
										</div>
									</div>
								}
								id="incomeStatement"
							>
								<FormCurrencyLineItem
									label="Revenue"
									marker
									name="revenue"
									control={formMethods.control}
									id="revenue"
								/>
								<FormCurrencyLineItem
									label="General Administrative Expense"
									marker
									name="gaExpense"
									control={formMethods.control}
									id="gaExpense"
								/>
								<FormYesNoLineItem
									label="Profitable"
									marker
									name="profitable"
									control={formMethods.control}
									id="profitable"
								/>
							</LineItemStackedCard>
							<LineItemStackedCard
								className="w-full h-fit"
								header={
									<div className="w-full h-fit flex">
										<div className="flex-1 flex flex-col space-y-[8px]">
											<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">
												Bank Details
											</div>
										</div>
										<div className="text-[12px] text-inrev-light-blue/80 font-medium underline cursor-pointer">
											Where do I find this?
										</div>
									</div>
								}
								id="bankDetails"
							>
								<FormCurrencyLineItem
									label="Revolving Line of Credit Limit"
									marker
									name="blocSize"
									control={formMethods.control}
									id="blocSize"
								/>
							</LineItemStackedCard>
						</FormItemGroup>
						<div className="w-full flex justify-center mt-[5px]">
							<Button
								onClick={formMethods.handleSubmit(handleSubmit)}
								color="light-blue"
								filled
								className="w-[150px] h-[36px]"
								tabIndex={2}
								loading={formMethods.formState.isSubmitting || upsertFinancialStatementIsLoading}
							>
								Submit
							</Button>
						</div>
					</form>
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

import { RadioGroup, RadioOptionProps as _RadioOptionProps } from "@headlessui/react";
import { ReactNode } from "react";
import { RadioButton } from "./RadioButton";

type RadioOptionProps<TType> = _RadioOptionProps<"div", TType> & {
	label?: ReactNode;
};

export const RadioOption = <TType,>({ label, ...props }: RadioOptionProps<TType>) => (
	<RadioGroup.Option {...props} className="flex items-center space-x-[15px] cursor-pointer">
		{({ checked }) => (
			<>
				<RadioButton checked={checked} />
				{label !== undefined && (
					<RadioGroup.Label
						as="span"
						className="text-[15px] font-normal text-gray-900 leading-[19px]"
					>
						{label}
					</RadioGroup.Label>
				)}
			</>
		)}
	</RadioGroup.Option>
);

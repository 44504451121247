import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos, FileType } from "@inrev/common";
import { useForm } from "react-hook-form";
import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { FormItem } from "../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../components/layout/form/FormRow";
import { FormSection } from "../../../components/layout/form/FormSection";
import { Button } from "../../../components/ui/Button";
import { LocalFileUpload } from "../../../components/ui/LocalFileUpload";
import { NotificationCard } from "../../../components/ui/NotificationCard";
import { FormDatePicker } from "../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../components/ui/form/FormItemLabel";
import { usStateOrTerritoryOptions } from "../../../constants";
import { useCompleteAgentIssuedBondForm } from "../../../domain/admin/request/api";
import {
	AdminBondRequest,
	AdminUploadAgentIssuedBondFormFormData,
} from "../../../domain/admin/request/types";
import { NameSuffix } from "../../../types";
import { stripEmptyResolver } from "../../../utils/form";

type AdminCompleteAgentIssuedBondFormFormProps = {
	request: Extract<AdminBondRequest, { status: "pending" }>;
};

const getDefaultFormData = (
	request: AdminCompleteAgentIssuedBondFormFormProps["request"],
): AdminUploadAgentIssuedBondFormFormData => ({
	suretyType: request.suretyType,
	contractSuretyType: request.contractSuretyType === "bid" ? "bid" : "final",
	bondForm: {
		data: {
			bondNumber: request.bondForm.fillData.bondNumber ?? "",
			effectiveDate: request.bondForm.fillData.effectiveDate ?? "",
			contractDate: "",
			contractDescription: request.description,
			bidDate: "",
			projectDescription: request.description,
			obligees: [
				{
					name: "",
					address: {
						street: "",
						city: "",
						state: "",
						zip: "",
					},
					role: "",
				},
			],
			principalSigner: {
				firstName: "",
				lastName: "",
				suffix: "",
				title: "",
			},
		},
	},
});

const _AdminCompleteAgentIssuedBondFormForm = ({
	request,
}: AdminCompleteAgentIssuedBondFormFormProps) => {
	const formMethods = useForm<
		AdminUploadAgentIssuedBondFormFormData,
		any,
		Dtos.Admin.SuretyBondForm.Upload.AgentIssue.Request
	>({
		defaultValues: getDefaultFormData(request),
		reValidateMode: "onBlur",
		resolver: (() => {
			if (request.bondIssuance.pendingAgentIssue) {
				return stripEmptyResolver(
					zodResolver(Dtos.Admin.SuretyBondForm.Upload.AgentIssue.Request.schema),
					false,
				);
			}
			return stripEmptyResolver(
				zodResolver(Dtos.Admin.SuretyBondForm.Approve.AgentIssue.Request.schema),
				false,
			);
		})(),
	});
	const file = formMethods.watch("bondForm.file");

	const { completeAgentIssuedBondForm, completeAgentIssuedBondFormIsLoading } =
		useCompleteAgentIssuedBondForm(request.id, !request.bondIssuance.pendingAgentIssueAdminReview);

	const handleSubmit = (data: Dtos.Admin.SuretyBondForm.Upload.AgentIssue.Request) => {
		completeAgentIssuedBondForm(data);
	};

	return (
		<form className="flex flex-col space-y-[35px]">
			{request.bondIssuance.pendingAgentIssue && (
				<NotificationCard
					type="action"
					text="Provide the following details and upload a copy of the agent issued bond form"
				/>
			)}
			{request.bondIssuance.pendingAgentIssueAdminReview && (
				<NotificationCard
					type="action"
					text="Provide the following details to finalize the agent issued bond form"
				/>
			)}
			<FormItemGroup>
				<FormRow>
					<FormItem condensed>
						<FormItemLabel>Bond Number</FormItemLabel>
						<FormInput
							control={formMethods.control}
							name={`bondForm.data.bondNumber`}
							className="bg-white"
							condensed
						/>
					</FormItem>
					<FormItem condensed>
						<FormItemLabel>Effective Date</FormItemLabel>
						<FormDatePicker
							name="bondForm.data.effectiveDate"
							control={formMethods.control}
							condensed
						/>
					</FormItem>
					{(request.contractSuretyType === "final" ||
						request.contractSuretyType === "bid_to_final") && (
						<FormItem condensed>
							<FormItemLabel condensed>Contract Date</FormItemLabel>
							<FormDatePicker
								control={formMethods.control}
								name={`bondForm.data.contractDate`}
								className="bg-white"
								condensed
							/>
						</FormItem>
					)}
					{request.contractSuretyType === "bid" && (
						<FormItem condensed>
							<FormItemLabel condensed>Bid Date</FormItemLabel>
							<FormDatePicker
								control={formMethods.control}
								name={`bondForm.data.bidDate`}
								className="bg-white"
								condensed
							/>
						</FormItem>
					)}
				</FormRow>
				<FormItem>
					<FormItemLabel>Description</FormItemLabel>
					<span className="!my-[5px] text-[14px] text-gray-500 italic">
						As it appears on the bond form
					</span>
					<FormInput
						control={formMethods.control}
						name={
							request.contractSuretyType === "bid"
								? `bondForm.data.projectDescription`
								: `bondForm.data.contractDescription`
						}
						className="bg-white"
						condensed
					/>
				</FormItem>
			</FormItemGroup>
			<FormSection header="Obligee">
				<FormItemGroup condensed>
					<FormRow>
						<FormItem condensed>
							<FormItemLabel condensed>Name</FormItemLabel>
							<FormInput
								control={formMethods.control}
								name={`bondForm.data.obligees.0.name`}
								className="bg-white"
								condensed
							/>
						</FormItem>
						<FormItem condensed className="w-[50px] max-w-[225px]">
							<FormItemLabel condensed>Role</FormItemLabel>
							<FormDropdown
								control={formMethods.control}
								name={"bondForm.data.obligees.0.role"}
								buttonClassName="bg-white"
								options={[
									{ value: "project_owner", label: "Project Owner" },
									{ value: "lender", label: "Lender" },
									{ value: "gc", label: "General Contractor" },
									{ value: "other", label: "Other" },
								]}
								placeholder="Select one"
								condensed
							/>
						</FormItem>
					</FormRow>
					<FormItem condensed>
						<FormItemLabel condensed>Street</FormItemLabel>
						<FormInput
							control={formMethods.control}
							name={`bondForm.data.obligees.0.address.street`}
							className="bg-white"
							condensed
						/>
						<FormRow>
							<FormItem condensed>
								<FormItemLabel condensed>City</FormItemLabel>
								<FormInput
									control={formMethods.control}
									name={`bondForm.data.obligees.0.address.city`}
									className="bg-white"
									condensed
								/>
							</FormItem>
							<FormItem condensed className="w-[100px] max-w-[100px]">
								<FormItemLabel condensed>State</FormItemLabel>
								<FormDropdown
									control={formMethods.control}
									name={`bondForm.data.obligees.0.address.state`}
									options={usStateOrTerritoryOptions}
									buttonClassName="h-[46px] text-[16px] bg-white"
									optionsClassName="max-h-[230px] overflow-y-auto"
									condensed
								/>
							</FormItem>
							<FormItem condensed className="w-[100px] max-w-[100px]">
								<FormItemLabel condensed>Zip</FormItemLabel>
								<FormInput
									control={formMethods.control}
									name={`bondForm.data.obligees.0.address.zip`}
									className="bg-white"
									condensed
								/>
							</FormItem>
						</FormRow>
					</FormItem>
				</FormItemGroup>
			</FormSection>
			<FormSection
				header="Signer"
				subHeader="If available"
				subHeaderClassName="text-gray-500 italic"
			>
				<FormItemGroup condensed>
					<FormRow>
						<FormItem condensed>
							<FormItemLabel condensed>First Name</FormItemLabel>
							<FormInput
								control={formMethods.control}
								name={`bondForm.data.principalSigner.firstName`}
								className="bg-white"
								condensed
							/>
						</FormItem>
						<FormItem condensed>
							<FormItemLabel condensed>Last Name</FormItemLabel>
							<FormInput
								control={formMethods.control}
								name={`bondForm.data.principalSigner.lastName`}
								className="bg-white"
								condensed
							/>
						</FormItem>
						<FormItem className="w-[100px] max-w-[100px]" condensed>
							<FormItemLabel condensed>Suffix</FormItemLabel>
							<FormDropdown
								control={formMethods.control}
								name="bondForm.data.principalSigner.suffix"
								options={Object.values(NameSuffix).map((suffix) => ({
									value: suffix,
									label: suffix,
								}))}
								buttonClassName="h-[46px] text-[16px] bg-white"
								condensed
							/>
						</FormItem>
					</FormRow>
					<FormItem condensed>
						<FormItemLabel condensed>Title</FormItemLabel>
						<FormInput
							control={formMethods.control}
							name={`bondForm.data.principalSigner.title`}
							className="bg-white"
							condensed
						/>
					</FormItem>
				</FormItemGroup>
			</FormSection>
			{request.bondIssuance.pendingAgentIssue && (
				<LocalFileUpload
					value={file ? [file] : []}
					onChange={(files) => formMethods.setValue("bondForm.file", files[0])}
					allowedTypesAndLabels={{
						[FileType.filled_bond_form]: "Filled Bond Form",
					}}
					allowedExtensions={["pdf"]}
					requiredTypes={[FileType.filled_bond_form]}
					maxTypesPerFile={1}
					maxFiles={1}
					hideChecklist={true}
					showErrors={formMethods.formState.errors.bondForm?.file !== undefined}
				/>
			)}
			<ConfirmationModalClickWrapper
				shouldConfirm={true}
				message="Are you sure?"
				onConfirm={formMethods.handleSubmit(handleSubmit)}
			>
				<Button
					color="light-blue"
					className="max-w-fit self-center px-[50px] !mb-[20px] !mt[45px]"
					filled
				>
					Submit
				</Button>
			</ConfirmationModalClickWrapper>
			{completeAgentIssuedBondFormIsLoading && <LoadingModal />}
		</form>
	);
};

export const AdminCompleteAgentIssuedBondFormForm = ({
	request,
}: AdminCompleteAgentIssuedBondFormFormProps) => {
	if (
		(request.bondIssuance.pendingAgentIssue || request.bondIssuance.pendingAgentIssueAdminReview) &&
		!request.bondIssuance.pendingBondFormReview &&
		!request.bondIssuance.pendingIndemnityAgreement
	) {
		return <_AdminCompleteAgentIssuedBondFormForm request={request} />;
	}
	return <></>;
};

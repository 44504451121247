import { ContractSuretyType, Dtos } from "@inrev/common";
import { ReactNode, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { WorkflowLayout } from "../../../components/layout/WorkflowLayout";
import { Separator } from "../../../components/ui/Separator";
import { bondRequestTypeLabelMap } from "../../../constants";
import { useAdminFetchBond } from "../../../domain/admin/bond/api";
import { useFetchBond } from "../../../domain/agent/bond/api";
import { SubmittedBondRequest } from "../../../domain/agent/request/types";
import { useComments } from "../../../domain/shared/comments/api";

type _SubmittedBondRequestLayoutWrapperProps = {
	request: SubmittedBondRequest;
	bidBondNumber?: string;
	children: ReactNode;
};

type SubmittedBondRequestLayoutWrapperProps = Omit<
	_SubmittedBondRequestLayoutWrapperProps,
	"bidBond"
>;

export const _SubmittedBondRequestLayoutWrapper = ({
	request,
	bidBondNumber,
	children,
}: _SubmittedBondRequestLayoutWrapperProps) => {
	const navigate = useNavigate();

	return (
		<WorkflowLayout
			title={
				<div className="flex items-center space-x-[10px] cursor-default">
					<span>
						{bondRequestTypeLabelMap[request.contractSuretyType]} Bond Request
						{request.contractSuretyType === ContractSuretyType.bid_to_final &&
						bidBondNumber !== undefined
							? ` from Bid Bond ${bidBondNumber}`
							: ""}
					</span>
					<Separator orientation="vertical" className="bg-gray-300 h-[18px]" />
					<span
						className="text-gray-600 font-normal cursor-pointer hover:underline"
						onClick={() => navigate(`/accounts/${request.account.id}`)}
					>
						{request.principal.name}
					</span>
				</div>
			}
			contentClassName="bg-gray-50 bg-opacity-[.35]"
		>
			<div className="flex justify-center w-full h-full overflow-y-auto pt-[18px]">
				<div className="w-[725px] max-w-[725px] h-fit flex flex-col items-center space-y-[52px] pb-[125px]">
					{children}
				</div>
			</div>
		</WorkflowLayout>
	);
};

export const BondRequestCommentsContext = createContext<{
	comments: Dtos.Comment.Get.Response[] | undefined;
	commentsLoading: boolean;
	createComment: (message: string) => void;
	createCommentLoading: boolean;
}>(undefined!);

export const SubmittedBondRequestLayoutWrapper = ({
	request,
	...props
}: SubmittedBondRequestLayoutWrapperProps) => {
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"quotes",
		request.id,
	);

	const { bond: bidBond } = useFetchBond(
		(() => {
			if (request.contractSuretyType === ContractSuretyType.bid_to_final) return request.bidBondId;
			return "";
		})(),
		request.contractSuretyType === ContractSuretyType.bid_to_final,
	);

	return (
		<BondRequestCommentsContext.Provider
			value={{ comments, commentsLoading, createComment, createCommentLoading }}
		>
			<_SubmittedBondRequestLayoutWrapper
				request={request}
				bidBondNumber={bidBond?.number}
				{...props}
			/>
		</BondRequestCommentsContext.Provider>
	);
};

export const AdminSubmittedBondRequestLayoutWrapper = ({
	request,
	...props
}: SubmittedBondRequestLayoutWrapperProps) => {
	const { bond: bidBond } = useAdminFetchBond(
		(() => {
			if (request.contractSuretyType === ContractSuretyType.bid_to_final) return request.bidBondId;
			return "";
		})(),
		request.contractSuretyType === ContractSuretyType.bid_to_final,
	);

	return (
		<_SubmittedBondRequestLayoutWrapper
			request={request}
			bidBondNumber={bidBond?.number}
			{...props}
		/>
	);
};

import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { UserMenu } from "../ui/UserMenu";

type WorkflowLayoutProps = {
	title?: string | ReactNode;
	subHeader?: ReactNode;
	contentClassName?: string;
	children?: ReactNode;
};

export const WorkflowLayout = ({
	title,
	subHeader,
	contentClassName,
	children,
}: WorkflowLayoutProps) => {
	return (
		<div className="w-full h-full grid grid-rows-[45px_1fr]">
			<div className="w-full h-[45px] flex items-center border border-b-gray-100 text-[13px] text-gray-700 font-medium px-[30px]">
				<div className="flex-1">{title}</div>
				<UserMenu />
			</div>
			{subHeader}
			<div
				className={cn(
					"w-full min-w-fit flex-1 flex justify-center overflow-y-auto relative",
					contentClassName,
				)}
			>
				{children}
			</div>
		</div>
	);
};

import { z } from 'zod';

var Upload;
(function (Upload) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = z.object({
                file: z.object({
                    name: z.string(),
                    base64: z.string(),
                }),
            });
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Upload.AgentIssue || (Upload.AgentIssue = {}));
})(Upload || (Upload = {}));

export { Upload };

import { z } from 'zod';
import { addressSchema } from '../../../opaque.mjs';
import { zswitch } from '../../../utils.mjs';
import { Data as Data$2, Close as Close$2 } from './bid-contract-surety-bond.types.mjs';
import * as bidContractSuretyBond_types from './bid-contract-surety-bond.types.mjs';
export { bidContractSuretyBond_types as Bid };
import { Data as Data$1, Close as Close$1 } from './final-contract-surety-bond.types.mjs';
import * as finalContractSuretyBond_types from './final-contract-surety-bond.types.mjs';
export { finalContractSuretyBond_types as Final };

var Obligee;
(function (Obligee) {
    Obligee.roleDef = ["project_owner", "gc", "lender", "other"];
    Obligee.roleSchema = z.enum(Obligee.roleDef);
    Obligee.Role = Obligee.roleSchema.Values;
    Obligee.schema = z.object({
        name: z.string(),
        address: addressSchema,
        role: Obligee.roleSchema
    });
})(Obligee || (Obligee = {}));
var Data;
(function (Data) {
    Data.schema = zswitch(input => {
        switch (input.contractSuretyType) {
            case "bid":
                return Data$2.schema;
            case "final":
                return Data$1.schema;
            default:
                return z.never();
        }
    });
})(Data || (Data = {}));
var Close;
(function (Close) {
    (function (Payload) {
        Payload.schema = zswitch(input => {
            switch (input.contractSuretyType) {
                case "bid":
                    return Close$2.Payload.schema;
                case "final":
                    return Close$1.Payload.schema;
                default:
                    return z.never();
            }
        });
    })(Close.Payload || (Close.Payload = {}));
})(Close || (Close = {}));

export { Close, Data, Obligee };

import { ReactNode } from "react";
import { FieldError } from "react-hook-form";
import { Link } from "react-router-dom";
import { cn } from "../lib/utils";
import { FormSchemaInclude, FormSchemaIncludeProps } from "./form/FormSchemaInclude";

type SummaryItemSectionProps = FormSchemaIncludeProps & {
	header: string;
	error?: FieldError | string | Record<string, any>;
	to?: { pathname: string; section: string; id: string };
	children: ReactNode;
};

export const SummaryItemSection = ({
	header,
	error,
	to,
	children,
	...props
}: SummaryItemSectionProps) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<div className="flex flex-col space-y-[15px]">
			<div
				className={cn(
					"text-[16px] text-gray-500 font-semibold hover:underline cursor-pointer w-fit",
					error !== undefined ? "text-red-500" : undefined,
				)}
			>
				{to !== undefined ? (
					<Link to={`${to.pathname}?section=${to.section}&st=${to.id}`}>{header}</Link>
				) : (
					header
				)}
			</div>
			<div className="w-full flex flex-col space-y-[15px] px-[10px]">{children}</div>
		</div>
	</FormSchemaInclude>
);

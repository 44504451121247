import { z } from 'zod';
import { Create as Create$1, Get, Update as Update$1 } from '../../../../../surety/account/draft/contract/contract-surety-account-draft.dto.mjs';

var Create;
(function (Create) {
    (function (Request) {
        Request.schema = Create$1.Request.schema;
    })(Create.Request || (Create.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema.shape.draft;
    })(Create.Response || (Create.Response = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = z.object({
            suretyType: z.literal("contract"),
            data: Update$1.Request.schema,
            previousData: Update$1.Request.schema,
        });
    })(Update.Request || (Update.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema.shape.draft;
    })(Update.Response || (Update.Response = {}));
})(Update || (Update = {}));

export { Create, Update };

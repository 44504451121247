import { UseFormReturn } from "react-hook-form";
import { DefaultIndividualFormData, IndividualOwnerFormData } from ".";
import { UseAddressValidationReturn } from "../../../../../../../../api";
import { FormItem } from "../../../../../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../../../../../components/layout/form/FormRow";
import { Button } from "../../../../../../../../components/ui/Button";
import { FormAddressAutocomplete } from "../../../../../../../../components/ui/form/FormAddressAutocomplete";
import { FormDropdown } from "../../../../../../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../../../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../../../../../components/ui/form/FormYesNo";
import { NameSuffix } from "../../../../../../../../types";

type IndividualOwnerFormProps = {
	form: UseFormReturn<DefaultIndividualFormData, any, IndividualOwnerFormData>;
	addressValidation: UseAddressValidationReturn;
	onSubmit: (data: IndividualOwnerFormData) => void;
};

export const IndividualOwnerForm = ({
	form,
	addressValidation,
	onSubmit,
}: IndividualOwnerFormProps) => {
	const ownerMarried = form.watch("married");

	const handleSubmit = (formData: IndividualOwnerFormData) => {
		onSubmit(formData);
	};

	return (
		<form className="w-full h-fit flex flex-col space-y-[25px]">
			<FormItemGroup condensed>
				<FormRow>
					<FormItem className="flex-2" condensed>
						<FormItemLabel condensed>First Name</FormItemLabel>
						<FormInput name="firstName" control={form.control} condensed />
					</FormItem>
					<FormItem className="max-w-[90px]" condensed>
						<FormItemLabel condensed>Middle Initial</FormItemLabel>
						<FormInput
							name="middleInitial"
							control={form.control}
							maxLength={1}
							transformer={{
								input: (value) => value as string,
								output: (value) => value.toUpperCase(),
							}}
							condensed
						/>
					</FormItem>
					<FormItem condensed className="flex-2">
						<FormItemLabel condensed>Last Name</FormItemLabel>
						<FormInput name="lastName" control={form.control} condensed />
					</FormItem>
					<FormItem className="w-[100px] max-w-[100px]" condensed>
						<FormItemLabel condensed>Suffix</FormItemLabel>
						<FormDropdown
							name="suffix"
							control={form.control}
							options={Object.values(NameSuffix).map((suffix) => ({
								value: suffix,
								label: suffix,
							}))}
							buttonClassName="h-[46px] text-[16px]"
						/>
					</FormItem>
				</FormRow>
				<FormItem condensed>
					<FormItemLabel condensed>Address</FormItemLabel>
					<FormAddressAutocomplete
						control={addressValidation.addressFieldControl}
						className="h-[46px] text-[16px]"
					/>
				</FormItem>
				<FormRow>
					<FormItem condensed>
						<FormItemLabel condensed>
							Email
							<span className="text-gray-400 text-[13px] ml-[8px] font-regular">If Available</span>
						</FormItemLabel>
						<FormInput name="email" control={form.control} condensed />
					</FormItem>
					<FormItem className="max-w-[200px]" condensed>
						<FormItemLabel condensed>SSN</FormItemLabel>
						<FormInput name="ssn" control={form.control} type="numeric" maxLength={9} condensed />
					</FormItem>
				</FormRow>
			</FormItemGroup>
			<FormRow className="justify-start gap-x-[40px]">
				<FormItem className="max-w-fit">
					<FormQuestionLabel>Does this individual own a home?</FormQuestionLabel>
					<FormYesNo name="ownsAHome" control={form.control} />
				</FormItem>
				<FormItem className="max-w-fit">
					<FormQuestionLabel>Is this individual married?</FormQuestionLabel>
					<FormYesNo name="married" control={form.control} />
				</FormItem>
			</FormRow>
			<div className="w-full flex justify-center mt-[5px]">
				<Button
					onClick={form.handleSubmit(handleSubmit)}
					disabled={ownerMarried === ""}
					color="light-blue"
					filled
					className="w-[150px] h-[36px]"
					tabIndex={2}
					loading={form.formState.isSubmitting}
				>
					{ownerMarried ? "Next" : "Save"}
				</Button>
			</div>
		</form>
	);
};

import { z } from 'zod';
import { currencySchema, rateSchema } from '../../../../utils.mjs';
import { ContractSuretyType, bidBondAmountTypeSchema, dateTimeSchema, finalContractSuretyBondFormTypeSchema } from '../../../../../enums.mjs';
import { Underwriting as Underwriting$1 } from './submitted-contract-surety-quote.types.mixins.mjs';

var Underwriting;
(function (Underwriting) {
    Underwriting.schema = z.object({
        quote: Underwriting$1.Quote.baseSchema,
        project: Underwriting$1.Project.schema
    });
})(Underwriting || (Underwriting = {}));
var Details;
(function (Details) {
    Details.schema = z.object({
        contractSuretyType: z.literal(ContractSuretyType.bid),
        bidBondAmountType: bidBondAmountTypeSchema,
        bondAmountPercentOfBid: z.coerce.number().min(0).multipleOf(0.001),
        bidAmount: currencySchema,
        bidDate: dateTimeSchema,
        finalBondFormType: finalContractSuretyBondFormTypeSchema,
        finalBondContingentRate: rateSchema.nullish(),
        finalBondContingentRateTemplateVersion: z.string().nullish(),
        finalBondContingentPremium: currencySchema.nullish(),
        underwriting: Underwriting.schema
    });
})(Details || (Details = {}));

export { Details, Underwriting };

import { Data as Data$1 } from './contract/submitted-contract-surety-bond-form.types.mjs';
import * as submittedContractSuretyBondForm_types from './contract/submitted-contract-surety-bond-form.types.mjs';
export { submittedContractSuretyBondForm_types as Contract };

var Data;
(function (Data) {
    Data.schema = Data$1.schema;
})(Data || (Data = {}));

export { Data };

import { ArchivedBanner } from "../../../../../components/ui/Banner";
import { useUnarchiveRequest } from "../../../../../domain/agent/request/api";
import { BondRequest } from "../../../../../domain/agent/request/types";
import { SubmittedBondRequestLayout } from "../../../../shared/request/SubmittedBondRequestLayout";

type DeclinedBondRequestViewProps = {
	request: BondRequest & Extract<BondRequest, { status: "declined" }>;
};

export const DeclinedBondRequestView = ({ request }: DeclinedBondRequestViewProps) => {
	const { unarchiveRequest } = useUnarchiveRequest();

	return (
		<SubmittedBondRequestLayout
			request={request}
			banner={
				request.archived ? (
					<ArchivedBanner onUnarchive={() => unarchiveRequest(request.id)} />
				) : undefined
			}
			defaultTermsAndConditionsOpen
		></SubmittedBondRequestLayout>
	);
};

import type { Types } from "@inrev/common";
import { useContext } from "react";
import { _AccountDraftSummarySection } from "../../../../agent/account/status/draft/sections/AccountDraftSummarySection";
import { AdminAccountReUnderwriteContext } from "../AdminAccountReUnderwriteModal";

type AdminAccountReUnderwriteSummarySectionProps = {
	sectionNames: (keyof Types.SuretyAccount.Draft.Schema)[];
};

export const AdminAccountReUnderwriteSummarySection = (
	props: AdminAccountReUnderwriteSummarySectionProps,
) => {
	const { draft } = useContext(AdminAccountReUnderwriteContext);
	return <_AccountDraftSummarySection sectionNames={props.sectionNames} draft={draft} />;
};

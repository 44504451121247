import { Data as Data$1 } from './contract/issued-contract-surety-bond-form.types.mjs';
import * as issuedContractSuretyBondForm_types from './contract/issued-contract-surety-bond-form.types.mjs';
export { issuedContractSuretyBondForm_types as Contract };

var Data;
(function (Data) {
    Data.schema = Data$1.schema;
})(Data || (Data = {}));

export { Data };

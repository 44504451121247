import { memo, useContext, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { HiOutlineQueueList } from "react-icons/hi2";
import { ShrinkingHeaderSectionContext } from "../../../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { BondRequestDraftData } from "../../../../../../../domain/agent/request/types";
import { DraftBondRequestNewPrincipalSection } from "./DraftBondRequestNewPrincipalSection";
import { DraftBondRequestSelectPrincipalSection } from "./DraftBondRequestSelectPrincipalSection";

const PrincipalSectionButton = () => {
	return (
		<div className="flex items-center">
			<HiOutlineQueueList className="text-[14px] mr-[5px]" />
			<div>Select a Principal</div>
		</div>
	);
};

const SelectPrincipalButton = memo(PrincipalSectionButton);

export const DraftBondRequestPrincipalSection = () => {
	const { watch, setValue, formState } = useFormContext<BondRequestDraftData>();
	const { setHeaderButton } = useContext(ShrinkingHeaderSectionContext);
	const newPrincipal = watch("principal.newPrincipal");
	const principalCompanyId = watch("principal.principalCompanyId");
	const confirmAccountTypeSwitch = useMemo(() => {
		return (
			formState.dirtyFields.principal?.individuals !== undefined ||
			formState.dirtyFields.principal?.companies !== undefined ||
			formState.dirtyFields.history !== undefined ||
			formState.dirtyFields.financials !== undefined
		);
	}, [formState]);

	useEffect(() => {
		if (newPrincipal) {
			setHeaderButton({
				content: <SelectPrincipalButton />,
				props: {
					color: "gray",
					rounded: true,
					className: "border-[0px] font-medium text-gray-600 hover:underline hover:bg-white",
					onClick: () => {
						setValue("principal.principalCompanyId", "", { shouldDirty: true });
						setValue("principal.principalAccountId", "", { shouldDirty: true });
						setValue("principal.newPrincipal", false, { shouldDirty: true });
					},
					confirmationModal: confirmAccountTypeSwitch,
					confirmationMessage: "Are you sure you want to create a new account?",
					confirmationMessageSubtext: (
						<span>
							{"All account, financial, and history details you"}
							<br />
							{"have entered will be lost"}
						</span>
					),
				},
			});
		} else if (principalCompanyId !== "") {
			setHeaderButton(undefined);
		} else {
			setHeaderButton({
				content: "New Principal",
				props: {
					color: "light-blue",
					rounded: true,
					onClick: () => {
						setValue("principal.newPrincipal", true, { shouldDirty: true });
					},
					confirmationModal: confirmAccountTypeSwitch,
					confirmationMessage: "Are you sure you want to select an existing account?",
					confirmationMessageSubtext: (
						<span>
							{"All account, financial, and history details you"}
							<br />
							{"have entered will be lost"}
						</span>
					),
				},
			});
		}
		return () => setHeaderButton(undefined);
	}, [newPrincipal, principalCompanyId]);

	return (
		<>
			{newPrincipal ? (
				<DraftBondRequestNewPrincipalSection />
			) : (
				<DraftBondRequestSelectPrincipalSection />
			)}
		</>
	);
};

export type TagProps = {
	children: React.ReactNode;
	colorOverride?: {
		bg?: string;
		border?: string;
	};
	hover?: boolean;
	small?: boolean;
};

export const Tag = ({ children, hover = false, small = false, colorOverride }: TagProps) => {
	const styleBase = `h-6 flex items-center justify-center ${
		colorOverride?.bg ? colorOverride.bg : "bg-gray-100/70"
	} border ${
		colorOverride?.border ? colorOverride.border : "border-gray-200"
	} text-[12px] text-gray-800 rounded-full`;
	const styleHover = "hover:bg-gray-200/55";
	return (
		<div
			className={`${styleBase} ${hover ? styleHover : ""} ${small ? "h-[22px] w-[22px]" : "px-[10px] py-[3px] max-w-fit"}`}
		>
			{children}
		</div>
	);
};

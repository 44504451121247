import { cn } from "../lib/utils";

type RadioButtonProps = {
	checked: boolean;
	readonly?: boolean;
};

export const RadioButton = ({ checked, readonly }: RadioButtonProps) => (
	<div
		className={cn(
			"relative aspect-square h-4 w-4 rounded-full border border-gray-400 bg-white focus:outline-none flex items-center justify-center",
			readonly && !checked ? "border-opacity-40" : undefined,
		)}
	>
		{checked && (
			<div
				className={cn(
					"absolute top-[-1px] left-[-1px] aspect-square h-4 w-4 rounded-full border-[6px] border-inrev-light-blue ml-[-1] mt-[-1]",
					readonly ? "border-gray-400" : undefined,
				)}
			></div>
		)}
	</div>
);

import { Dtos } from "@inrev/common";
import {
	Document,
	Font,
	PDFDownloadLink,
	Image as PDFImage,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { memo } from "react";
import { HiOutlineArrowTopRightOnSquare } from "react-icons/hi2";
import Html from "react-pdf-html";
import OpenSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import OpenSansBoldItalic from "../../assets/fonts/OpenSans-BoldItalic.ttf";
import OpenSansItalic from "../../assets/fonts/OpenSans-Italic.ttf";
import OpenSansRegular from "../../assets/fonts/OpenSans-Regular.ttf";
import { Spinner } from "./Spinner";

// Added the fonts to the project because we want to have the ability to Bold / Italicize the text
// in the PDF comments. This is not possible with the default font. OpenSans is under the Open Font License.
Font.register({
	family: "OpenSans",
	fonts: [
		{ src: OpenSansRegular },
		{ src: OpenSansBold, fontWeight: "bold" },
		{ src: OpenSansItalic, fontStyle: "italic" },
		{
			src: OpenSansBoldItalic,
			fontWeight: "bold",
			fontStyle: "italic",
		},
	],
});

const styles = StyleSheet.create({
	page: {
		flexDirection: "row",
		backgroundColor: "white",
		position: "relative",
		marginVertical: 20,
		paddingBottom: 40,
	},
	commentContainer: {
		margin: 20,
		paddingHorizontal: 10,
		rowGap: 12,
		flexShrink: 1,
	},
	message: {
		fontFamily: "OpenSans",
		fontSize: 12,
		fontWeight: "normal",
	},
	title: {
		fontFamily: "OpenSans",
		fontSize: 18,
	},
	portalLink: {
		fontFamily: "OpenSans",
		fontSize: 10,
	},
	Subtitle: {
		fontFamily: "OpenSans",
		fontSize: 14,
	},
});

const stylesheet = {
	// needed to clear out the margins so the text is centered
	p: {
		margin: 0,
	},
};

function parseCommentMessageForPDF(message: string) {
	if (message.slice(0, 6) === "giphy:") {
		return (
			<PDFImage
				style={{ height: 150, objectFit: "scale-down", objectPosition: "left" }}
				src={getPngFromGiphyUrl(
					`https://i.giphy.com/${filterXSS(message.split(":")[1], { whiteList: {}, stripIgnoreTag: true })}.webp`,
				)}
			/>
		);
	}
	return (
		<Html style={{ fontSize: 10, fontFamily: "OpenSans" }} stylesheet={stylesheet}>
			{filterXSS(message)}
		</Html>
	);
}

function getPngFromGiphyUrl(url: string): Promise<string> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = "anonymous";
		img.onload = () => {
			const height = 200;
			const aspectRatio = img.width / img.height;
			const scaledWidth = height * aspectRatio;
			const canvas = document.createElement("canvas");
			canvas.width = scaledWidth;
			canvas.height = height;

			const ctx = canvas.getContext("2d");
			if (!ctx) {
				reject("Failed to get canvas context");
				return;
			}
			ctx.drawImage(img, 0, 0, scaledWidth, height);

			const dataURL = canvas.toDataURL(`image/png`);
			resolve(dataURL);
		};
		img.onerror = () => {
			reject("Failed to load the image");
		};
		img.src = url;
	});
}

const PDFComments = ({
	comments,
	commentDownloadHeader,
}: {
	comments: Dtos.Comment.Get.Response[];
	commentDownloadHeader?: string;
}) => (
	<Document>
		<Page size="A4" style={styles.page} wrap>
			<View style={styles.commentContainer}>
				{commentDownloadHeader && (
					<View style={{ paddingBottom: 10 }}>
						<Text style={styles.title}>inRev Comments on {commentDownloadHeader}</Text>
					</View>
				)}
				{comments.map((comment) => (
					<PDFComment key={comment.id} comment={comment} />
				))}
			</View>
		</Page>
	</Document>
);

const PDFComment = ({ comment }: { comment: Dtos.Comment.Get.Response }) => (
	<View
		style={{ width: "90vw", paddingBottom: 15, borderBottom: 1, borderColor: "gray" }}
		wrap={comment.message.length > 500 ? true : false}
	>
		<View style={{ paddingBottom: 5 }}>
			<Text style={styles.Subtitle}>
				{comment.fromUserFirstName} {comment.fromUserLastName}{" "}
				<Text style={styles.message}>{comment.fromUserType === "admin" ? "(inRev) " : ""}</Text>
				<Text style={styles.message}>
					- {DateTime.fromISO(comment.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
				</Text>
			</Text>
		</View>
		<View style={{ paddingLeft: 15 }}>{parseCommentMessageForPDF(comment.message)}</View>
	</View>
);

export const DownloadLink = memo(
	({
		fileName = `comments-${new Date().toISOString()}`,
		commentDownloadHeader,
		comments,
	}: {
		fileName: string;
		commentDownloadHeader?: string;
		comments: Dtos.Comment.Get.Response[];
	}) => (
		<PDFDownloadLink
			document={<PDFComments comments={comments} commentDownloadHeader={commentDownloadHeader} />}
			fileName={`${fileName}.pdf`}
		>
			{({ loading }) =>
				loading ? (
					<span className="">
						<Spinner />
					</span>
				) : (
					<span className="text-gray-600/65 hover:text-gray-800">
						<HiOutlineArrowTopRightOnSquare className="text-[19px]" />
					</span>
				)
			}
		</PDFDownloadLink>
	),
);

import { ReactNode, useState } from "react";
import { getCSVWorker } from "../../workers";
import { CSVWorkerResponse } from "../../workers/types";
import { cn } from "../lib/utils";

type CSVDownloadButtonProps<
	TData extends Record<string, string | number | boolean | undefined>[],
	TKeys extends (keyof TData[number])[],
> = {
	fileName: string;
	data: TData;
	keys: TKeys;
	loadingState?: ReactNode;
	className?: string;
	loadingStateClassName?: string;
	children: ReactNode;
};

export const CSVDownloadButton = <
	TData extends Record<string, string | number | boolean | undefined>[],
	TKeys extends (keyof TData[number])[],
>({
	fileName,
	data,
	keys,
	loadingState,
	className,
	loadingStateClassName,
	children,
}: CSVDownloadButtonProps<TData, TKeys>) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleClick = () => {
		if (!isLoading) {
			setIsLoading(true);
			const csvWorker = getCSVWorker();
			csvWorker.onmessage = (event: MessageEvent<CSVWorkerResponse>) => {
				const url = window.URL.createObjectURL(event.data);
				const a = document.createElement("a");
				a.download = `${fileName}.csv`;
				a.href = url;
				a.click();
				window.URL.revokeObjectURL(url);
				setIsLoading(false);
			};
			csvWorker.postMessage({ data, keys });
		}
	};

	return (
		<div
			className={cn(
				"w-fit min-w-fit h-fit min-h-fit cursor-pointer",
				className,
				isLoading && loadingStateClassName ? loadingStateClassName : undefined,
			)}
			onClick={handleClick}
		>
			{loadingState !== undefined && isLoading ? loadingState : children}
		</div>
	);
};

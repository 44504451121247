import { cn } from "../../../components/lib/utils";
import { Button, ButtonProps } from "../../../components/ui/Button";

type AdminButton = Omit<ButtonProps, "color" | "thinFont">;

export const AdminButton = (props: AdminButton) => {
	return (
		<Button
			color="gray"
			onClick={props.onClick}
			thinFont
			className={cn(
				"bg-gray-50 bg-opacity-90 enabled:hover:brightness-[98%] text-gray-700",
				props.className,
			)}
		>
			{props.children}
		</Button>
	);
};

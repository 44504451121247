import { AdminBondRequest } from "../../../domain/admin/request/types";
import { AdminApproveBondFormForAgentIssueForm } from "./AdminApproveBondFormForAgentIssueForm";
import { AdminBondFormCard } from "./AdminBondFormCard";
import { AdminCompleteAgentIssuedBondFormForm } from "./AdminUploadAgentIssuedBondFormForm";
import { AdminUploadCompletedBondFormForm } from "./AdminUploadCompletedBondFormForm";

type AdminBondRequestBondFormCardProps = {
	request: Exclude<AdminBondRequest, { status: "draft" }>;
};

export const AdminBondRequestBondFormCard = ({ request }: AdminBondRequestBondFormCardProps) => {
	return (
		<AdminBondFormCard
			bondForm={request.bondForm}
			bondFormViewUrl={`/v2/admin/surety/quotes/${request.id}/bond-form`}
			bondFormViewQueryKey={["bondFormFileUrl", request.id]}
			bottomContent={(() =>
				request.status === "pending" &&
				(request.bondIssuance.pendingBondFormReview ||
					request.bondIssuance.pendingInRevIssue ||
					request.bondIssuance.pendingAgentIssue ||
					request.bondIssuance.pendingAgentIssueAdminReview ||
					request.bondIssuance.method === "electronic") && (
					<div className="pt-[35px]">
						<AdminUploadCompletedBondFormForm request={request} />
						<AdminApproveBondFormForAgentIssueForm request={request} />
						<AdminCompleteAgentIssuedBondFormForm request={request} />
					</div>
				))()}
		/>
	);
};

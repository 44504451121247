import { z } from 'zod';
import { bidBondAmountTypeSchema, finalContractSuretyBondFormTypeSchema, FileType } from '../../../../../enums.mjs';

var Data;
(function (Data) {
    Data.bondSchema = z.object({
        bidBondAmountType: bidBondAmountTypeSchema,
        bondAmountPercentOfBid: z.union([z.string(), z.literal('')]),
        bidAmount: z.union([z.string(), z.literal('')]),
        bidDate: z.union([z.string(), z.literal('')]),
        finalBondType: z.union([finalContractSuretyBondFormTypeSchema, z.literal('')]),
        completionBond: z.union([z.boolean(), z.literal('')]),
    });
    Data.projectFileTypes = [FileType.bid_package];
})(Data || (Data = {}));

export { Data };

import { forwardRef } from "react";
import Currency, { CurrencyInputProps as CurrencyProps } from "react-currency-input-field";
import { FieldError } from "react-hook-form";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

type CurrencyInputProps = Omit<CurrencyProps, "readOnly"> & {
	condensed?: true;
	compact?: true;
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
};

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
	({ className, condensed, compact, error, errorMessage, readonly, disabled, ...props }, ref) => (
		<div
			className={cn(
				"relative flex w-full h-[50px] rounded-md outline outline-[1px] outline-offset-[-1px] text-gray-900 outline-gray-300 bg-gray-50 bg-opacity-70 text-[16.5px] leading-[20px] placeholder:text-gray-500 focus-within:outline-gray-600 focus-within:hover:outline-gray-600 focus-within:outline-[2px] disabled:cursor-not-allowed disabled:opacity-50",
				disabled ? "cursor-not-allowed opacity-50" : "hover:outline-gray-500",
				condensed
					? "h-[46px] text-[16px] font-normal"
					: compact
						? "h-[38px] text-[15px] font-normal"
						: undefined,
				className,
				readonly ? "opacity-80" : undefined,
				error ? "outline-red-500 hover:outline-red-500 focus-within:outline-red-500" : undefined,
			)}
		>
			<div
				className={cn(
					"h-full flex items-center rounded-l-md text-gray-700 bg-gray-100 border-r border-r-gray-300",
					condensed
						? "px-[15px] text-[15px]"
						: compact
							? "px-[14px] text-[13px]"
							: "px-[20px] text-[16.5px]",
					readonly ? "text-gray-500" : undefined,
				)}
			>
				$
			</div>
			<Currency
				{...props}
				disabled={readonly}
				ref={ref}
				tabIndex={0}
				decimalScale={2}
				className={cn(
					"px-[18px] py-[17px] w-full outline-none bg-transparent",
					readonly ? "bg-gray-100 text-gray-800" : undefined,
				)}
				onValueChange={(e) => {
					props.onValueChange && props.onValueChange(e);
				}}
				autoComplete="one-time-code"
				data-1p-ignore
				data-lpignore
			/>
			<FormError
				error={error}
				errorMessage={errorMessage}
				className={condensed ? "left-[30px]" : "left-[41px]"}
			/>
		</div>
	),
);

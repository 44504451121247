import { z } from 'zod';
import { Details as Details$1 } from './contract/submitted-contract-surety-quote.types.mjs';
import * as submittedContractSuretyQuote_types from './contract/submitted-contract-surety-quote.types.mjs';
export { submittedContractSuretyQuote_types as Contract };
import { includesSchema, isoDateSchema } from '../../../utils.mjs';
import { nameSuffixSchema } from '../../../../enums.mjs';
import { companyContactId, email, individualContactId, firstName, lastName } from '../../../opaque.mjs';

var Details;
(function (Details) {
    Details.schema = Details$1.schema;
})(Details || (Details = {}));
const buildBondIssuanceSchema = (formSchema) => z.object({
    indemnityAgreement: includesSchema(formSchema.include?.indemnityAgreement, z.object({
        signatureMethod: includesSchema(formSchema.include?.indemnityAgreement?.signatureMethod, z.enum(["electronic", "physical"])),
        companyIndemnitors: includesSchema(formSchema.include?.indemnityAgreement?.companyIndemnitors, z.array(z.object({
            companyContactId: companyContactId,
            name: z.string(),
            signerEmail: email,
        }))),
        individualIndemnitors: includesSchema(formSchema.include?.indemnityAgreement?.individualIndemnitors, z.array(z.object({
            id: individualContactId,
            name: z.string(),
            signerEmail: email,
        }))),
    })),
    bondForm: includesSchema(formSchema.include?.bondForm, z.object({
        issuanceMethod: includesSchema(formSchema.include?.bondForm?.issuanceMethod, z.enum(["electronic", "physical"])),
        contractDate: includesSchema(formSchema.include?.bondForm?.contractDate, isoDateSchema),
        description: includesSchema(formSchema.include?.bondForm?.description, z.string()),
        obligee: includesSchema(formSchema.include?.bondForm?.obligee, z.object({
            name: includesSchema(formSchema.include?.bondForm?.obligee?.name, z.string()),
            address: includesSchema(formSchema.include?.bondForm?.obligee?.address, z.string()),
            role: includesSchema(formSchema.include?.bondForm?.obligee?.role, z.enum([
                'project_owner',
                'lender',
                'gc',
                'other',
            ])),
        })),
        signer: includesSchema(formSchema.include?.bondForm?.signer, z.object({
            firstName: includesSchema(formSchema.include?.bondForm?.signer?.firstName, firstName),
            lastName: includesSchema(formSchema.include?.bondForm?.signer?.lastName, lastName),
            suffix: includesSchema(formSchema.include?.bondForm?.signer?.suffix, nameSuffixSchema),
            title: includesSchema(formSchema.include?.bondForm?.signer?.title, z.string()),
        })),
    }))
});

export { Details, buildBondIssuanceSchema };

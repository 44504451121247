import { NavLink } from "react-router-dom";
import inrevLogoIcon from "../../../assets/logos/inrev-logo-icon-white.svg";
import inrevLogo from "../../../assets/logos/inrev-logo-white.svg";
import { useAuthenticatedUser } from "../../../providers/AppLoadingProvider";
import { Button } from "../Button";
import { MainNavItem } from "./components/MainNavItem";

type MainNavProps = {
	label?: string;
	items: {
		dashboard: boolean;
		requests: boolean;
		bonds: boolean;
		accounts: boolean;
	};
};

export const MainNav = ({ label, items }: MainNavProps) => {
	const { user } = useAuthenticatedUser();

	return (
		<div className="flex flex-col w-full h-screen bg-inrev-blue py-[32px] space-y-[33px] shrink-0">
			<div className="flex justify-center md:justify-normal w-full px-0 md:px-[32px] shrink-0">
				<img src={inrevLogo} className="h-[25px] hidden md:block" />
				<img src={inrevLogoIcon} className="h-[25px] block md:hidden" />
			</div>
			<div className="flex flex-col space-y-2 w-full px-0 md:px-[22px] shrink-0">
				{label && (
					<div className="text-white font-medium py-[4px] mb-[10px] w-full flex items-center justify-center text-[13px] bg-[#1d2958]">
						{label}
					</div>
				)}
				{items.dashboard && (
					<NavLink to="/" tabIndex={-1}>
						{({ isActive }) => (
							<MainNavItem onClick={() => {}} active={isActive} label="Dashboard" icon="windows" />
						)}
					</NavLink>
				)}
				{items.requests && (
					<NavLink to="/requests" tabIndex={-1}>
						{({ isActive }) => (
							<MainNavItem
								onClick={() => {}}
								active={isActive}
								label="Bond Requests"
								icon="clipboard"
							/>
						)}
					</NavLink>
				)}
				{items.bonds && (
					<NavLink to="/bonds" tabIndex={-1}>
						{({ isActive }) => (
							<MainNavItem onClick={() => {}} active={isActive} label="Bonds" icon="shield" />
						)}
					</NavLink>
				)}
				{items.accounts && (
					<NavLink to="/accounts" tabIndex={-1}>
						{({ isActive }) => (
							<MainNavItem onClick={() => {}} active={isActive} label="Accounts" icon="building" />
						)}
					</NavLink>
				)}
			</div>
			{user.role === "admin-v2" && (
				<div className="w-full flex-1 flex items-end justify-center">
					<NavLink to="/import/bonds">
						<Button
							color="gray"
							rounded
							className="text-white/60 enabled:hover:bg-white/10 text-[12px] h-[24px]"
							thinFont
						>
							Import Bond
						</Button>
					</NavLink>
				</div>
			)}
		</div>
	);
};

import { Dtos, SuretyAccountId } from "@inrev/common";
import { createContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { useFetchSuretyAccount } from "../../../domain/agent/account/api";
import { SuretyAccount } from "../../../domain/agent/account/types";
import { useComments } from "../../../domain/shared/comments/api";
import { AccountDeclinedView } from "./status/declined/AccountDeclinedView";
import { AccountDraftView } from "./status/draft/AccountDraftView";
import { AccountReviewView } from "./status/review/AccountReviewView";
import { AccountUnderwrittenView } from "./status/underwritten/AccountUnderwrittenView";

export const SuretyAccountContext = createContext<SuretyAccount>(undefined!);
export const SuretyAccountCommentsContext = createContext<{
	comments: Dtos.Comment.Get.Response[] | undefined;
	commentsLoading: boolean;
	createComment: (message: string) => void;
	createCommentLoading: boolean;
}>(undefined!);

const getView = <TSuretyAccount extends SuretyAccount>(account: TSuretyAccount) => {
	if (account.status === "draft") {
		return <AccountDraftView account={account} />;
	} else if (account.status === "review") {
		return <AccountReviewView account={account} />;
	} else if (account.status === "declined") {
		return <AccountDeclinedView account={account} />;
	} else if (account.status === "closed") {
		return <></>;
	} else {
		return <AccountUnderwrittenView account={account} />;
	}
};

export const SuretyAccountView = () => {
	const { id } = useParams();
	const { suretyAccount, suretyAccountIsLoading, suretyAccountError } = useFetchSuretyAccount(id!);
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"accounts",
		id as SuretyAccountId,
	);
	const navigate = useNavigate();

	useEffect(() => {
		if (suretyAccountError) {
			navigate("/accounts");
		}
	}, [suretyAccountError]);

	if (suretyAccountIsLoading || suretyAccount === undefined) return <LoadingModal />;
	else {
		return (
			<SuretyAccountContext.Provider value={suretyAccount}>
				<SuretyAccountCommentsContext.Provider
					value={{ comments, commentsLoading, createComment, createCommentLoading }}
				>
					{getView(suretyAccount)}
				</SuretyAccountCommentsContext.Provider>
			</SuretyAccountContext.Provider>
		);
	}
};

import { z } from 'zod';
import { companyFinancialStatementPreparationMethodSchema, dateTimeSchema } from '../../../enums.mjs';
import { currencySchema } from '../../utils.mjs';

const largeFinancialStatement = z.object({
    preparationMethod: companyFinancialStatementPreparationMethodSchema,
    date: dateTimeSchema,
    corporateCash: currencySchema,
    currentAssets: currencySchema,
    currentLiabilities: currencySchema,
    totalAssets: currencySchema,
    totalLiabilities: currencySchema,
    revenue: currencySchema,
    accountsPayable: currencySchema,
    accountsReceivable: currencySchema,
    underbillings: currencySchema,
    creditCardsPayable: currencySchema,
    bankLinePayable: currencySchema,
    blocSize: currencySchema,
    accruedExpenses: currencySchema,
    overbillings: currencySchema,
    currentPortionOfLtd: currencySchema,
    termLoanDebt: currencySchema,
    gaExpense: currencySchema,
    profitable: z.boolean(),
    profitableLastFiscalYear: z.boolean(),
});
const draftPrincipalFinancialStatementDto = largeFinancialStatement.partial();
const mediumFinancialStatement = largeFinancialStatement.pick({
    preparationMethod: true,
    date: true,
    corporateCash: true,
    currentAssets: true,
    currentLiabilities: true,
    totalAssets: true,
    totalLiabilities: true,
    revenue: true,
    profitable: true,
});
const smallFinancialStatement = mediumFinancialStatement.pick({});

export { draftPrincipalFinancialStatementDto, largeFinancialStatement, mediumFinancialStatement, smallFinancialStatement };

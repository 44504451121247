import { z } from 'zod';
import { naicsCodeSchema } from '../../../../enums.mjs';
import { initializeExposure, exposureSchema, suretyAccountBondingLineSchema, nonNegativeCurrencySchema } from '../../../utils.mjs';

var Exposure;
(function (Exposure) {
    Exposure.initialize = () => ({
        quotes: 0,
        bonds: 0,
        total: 0,
        bid: initializeExposure(),
        bid_to_final: initializeExposure(),
        final: initializeExposure(),
    });
    Exposure.schema = exposureSchema.extend({
        bid: exposureSchema,
        bid_to_final: exposureSchema,
        final: exposureSchema
    });
})(Exposure || (Exposure = {}));
var Metadata;
(function (Metadata) {
    Metadata.schema = z.object({
        bondingLine: suretyAccountBondingLineSchema.nullish(),
        naicsCodes: naicsCodeSchema.array(),
        largestConstructionProjectCompleted: nonNegativeCurrencySchema,
        lostAPaymentSuit: z.boolean(),
        failedToCompleteAConstructionJob: z.boolean(),
        causedSuretyLoss: z.boolean(),
        constructionProjectsGrossLossGt10PercentLast36Months: z.boolean().nullish(),
        currentConstructionProjectExpectedGrossLoss: z.boolean().nullish(),
    });
})(Metadata || (Metadata = {}));

export { Exposure, Metadata };

import { ReactElement, ReactNode } from "react";
import {
	HiCheck,
	HiOutlineArchiveBox,
	HiOutlineBolt,
	HiOutlineCheckCircle,
	HiOutlineShieldCheck,
} from "react-icons/hi2";
import { cn } from "../lib/utils";
import { Icon } from "./Icon";
import { Separator } from "./Separator";

export type BannerCoreProps = {
	textColor: keyof typeof textColorMap;
	bgColor?: keyof typeof bgColorMap;
	className?: string;
	icon?: ReactNode;
	label: ReactNode;
};

const textColorMap = {
	green: "text-inrev-green",
	"light-blue": "text-inrev-light-blue",
	gray: "text-gray-700",
	"dark-yellow": "text-inrev-dark-yellow",
	red: "text-red-500",
};

const bgColorMap = {
	yellow: "bg-inrev-yellow bg-opacity-[0.12]",
	"light-blue": "bg-inrev-light-blue",
	green: "bg-inrev-green bg-opacity-[0.05]",
	red: "bg-red-500 bg-opacity-[0.05]",
	"light-gray": "bg-gray-100/80 bg-opacity-100",
};

const BannerCore = ({ textColor, bgColor, className, icon, label }: BannerCoreProps) => {
	return (
		<div
			className={cn(
				`w-full flex items-center justify-center space-x-[7px] h-[34px] shrink-0 rounded-[3px] text-[14px] font-semibold bg-opacity-[.08] shadow`,
				textColorMap[textColor],
				bgColor !== undefined ? bgColorMap[bgColor] : undefined,
				className,
			)}
		>
			{icon ?? <></>}
			<span className="select-none">{label}</span>
		</div>
	);
};

export const ReviewStatusBanner = () => (
	<BannerCore textColor="dark-yellow" bgColor="yellow" label="Underwriter Review" />
);
export const ApprovedStatusBanner = () => (
	<BannerCore textColor="green" label="Approved" className="py-0 h-fit" />
);
export const AcceptedStatusBanner = () => (
	<BannerCore
		textColor="green"
		bgColor="green"
		icon={<HiOutlineCheckCircle className="fill-white text-[15px] stroke-[1.75] mt-[1px]" />}
		label="Terms Accepted"
	/>
);
export const DeclinedStatusBanner = () => (
	<BannerCore textColor="red" bgColor="red" label="Declined" />
);
export const ActiveStatusBanner = () => (
	<BannerCore
		textColor="light-blue"
		icon={<HiOutlineBolt className="fill-white text-[13px]" />}
		label="Active"
	/>
);
export const PendingStatusBanner = () => (
	<BannerCore
		textColor="gray"
		icon={
			<Icon type="stopwatch" className="h-[13px] stroke-gray-600 stroke-[1.35] overflow-visible" />
		}
		label="Pending"
	/>
);
export const OpenStatusBanner = () => (
	<BannerCore
		textColor="light-blue"
		bgColor="light-blue"
		icon={<HiOutlineShieldCheck className="fill-white text-[16px] stroke-[1.5]" />}
		label="Open"
	/>
);
export const ActionRequiredBanner = () => (
	<BannerCore
		textColor="gray"
		bgColor="light-gray"
		icon={<Icon type="action-required" className="h-[16px]" />}
		label="Action Required"
	/>
);
export const GoBanner = ({ text }: { text: ReactNode }) => (
	<BannerCore
		bgColor="light-gray"
		textColor="gray"
		className="h-fit px-[24px] py-[16px] text-[15px] text-gray-800 space-x-[12px] font-[550]"
		icon={<Icon type="go" className="h-[21px]" />}
		label={text}
	/>
);
export const StopBanner = ({ text }: { text: ReactNode }) => (
	<BannerCore
		textColor="gray"
		bgColor="light-gray"
		className="h-fit px-[24px] py-[20px] text-[16px] space-x-[12px]"
		icon={<Icon type="hand-stop" className="h-[24px]" />}
		label={text}
	/>
);
export const CompleteBanner = ({ text }: { text: ReactNode }) => (
	<BannerCore
		textColor="gray"
		bgColor="light-gray"
		icon={<HiCheck className="text-inrev-green text-[16px] stroke-[3px]" />}
		label={text}
	/>
);
export const ArchivedBanner = ({ onUnarchive }: { onUnarchive: () => void }) => (
	<BannerCore
		textColor="gray"
		bgColor="light-gray"
		icon={<HiOutlineArchiveBox className="text-gray-800 text-[16px]" />}
		label={
			<div className="flex items-center gap-[10px]">
				<span>Archived</span>
				<Separator orientation="vertical" className="bg-gray-300 h-[12px]" />
				<span
					className="text-[12px] font-medium text-gray-400 hover:text-gray-600 hover:underline cursor-pointer"
					onClick={onUnarchive}
				>
					Unarchive
				</span>
			</div>
		}
	/>
);

const statusMap: Record<string, ReactElement<BannerCoreProps>> = {
	review: <ReviewStatusBanner />,
	approved: <ApprovedStatusBanner />,
	accepted: <AcceptedStatusBanner />,
	declined: <DeclinedStatusBanner />,
	active: <ActiveStatusBanner />,
	pending: <PendingStatusBanner />,
	open: <OpenStatusBanner />,
};

export const getStatusBanner = (status: string) => statusMap[status] ?? <></>;

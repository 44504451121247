import { useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { CardGrayBody } from "../../../../../../../components/ui/CardGrayBody";
import { Icon } from "../../../../../../../components/ui/Icon";
import { FormIndividualCreditReportPermissionList } from "../../../../../../../components/ui/form/FormIndividualCreditReportPermissionList";
import { FormSearchableSelectList } from "../../../../../../../components/ui/form/FormSearchableSelectList";
import { BondRequestDraftData } from "../../../../../../../domain/agent/request/types";
import { formatAddress } from "../../../../../../../utils";
import { DraftBondRequestContext } from "../../DraftBondRequestView";

export const DraftBondRequestSelectPrincipalSection = () => {
	const formMethods = useFormContext<BondRequestDraftData>();
	const { principalPreviewsMap } = useContext(DraftBondRequestContext);
	const creditReportPermissionFieldArray = useFieldArray({
		control: formMethods.control,
		name: "principal.creditReportPermission",
		keyName: "_id",
	});

	useEffect(() => {
		if (formMethods.formState.isSubmitted) {
			formMethods.trigger();
		}
	}, [creditReportPermissionFieldArray.fields]);

	return (
		<div className="relative flex flex-col h-full pt-[18px] pb-[35px]">
			<FormSearchableSelectList
				name="principal.principalCompanyId"
				control={formMethods.control}
				searchPlaceholder="Search principals..."
				listItems={Object.keys(principalPreviewsMap).map((key) => {
					const principalPreview = principalPreviewsMap[key as keyof typeof principalPreviewsMap];
					return {
						value: principalPreview.companyContactId,
						element: (
							<div key={key} className="w-full flex py-[20px] px-[30px]">
								<div className="flex flex-col flex-1 space-y-[15px]">
									<div className="flex flex-col space-y-[3px]">
										<div className="flex font-medium text-gray-900 space-x-[10px]">
											<HiOutlineBuildingStorefront className="text-gray-900 mt-[4px] text-[18px]" />
											<span>{principalPreview.name}</span>
										</div>
										<div className="font-normal text-gray-700 text-sm">
											{formatAddress(principalPreview.address)}
										</div>
									</div>
									<div className="flex items-center space-x-[5px] font-normal text-gray-500 text-xs italic">
										<Icon type="building" className="fill-gray-600" width={12} height={12} />
										<span>{principalPreview.accountDisplayName}</span>
									</div>
								</div>
							</div>
						),
						searchValues: principalPreview.searchValues,
					};
				})}
			/>
			<div className="absolute h-[1px] bg-gray-100 top-[-1px] right-0 left-0 z-50"></div>
			{!!creditReportPermissionFieldArray.fields.length && (
				<CardGrayBody
					header={
						<div className="flex flex-col space-y-[8px]">
							<div className="flex space-x-[10px] items-center">
								<Icon type="action-required" className="h-[20px] mt-[1px]" />
								<div className="text-[17px] font-semibold">Expired Credit Reports</div>
							</div>
							<div className="text-[14px] text-gray-500">
								Please confirm permission to obtain updated credit information for the following
								individuals
							</div>
						</div>
					}
					headerClassName="py-[20px] px-[26px]"
					className="mt-[40px]"
				>
					<FormIndividualCreditReportPermissionList
						path="principal.creditReportPermission"
						creditReportPermissionFieldArray={creditReportPermissionFieldArray}
						errors={formMethods.formState.errors.principal?.creditReportPermission ?? []}
					/>
				</CardGrayBody>
			)}
		</div>
	);
};

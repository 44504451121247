import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { YesNo } from "../YesNo";

type FormYesNoProps<TFieldValues extends FieldValues> = {
	control: Control<TFieldValues, any, any>;
	name: FieldPath<TFieldValues>;
	errorMessage?: string;
	readonly?: boolean;
};

export const FormYesNo = <TFieldValues extends FieldValues>(
	props: FormYesNoProps<TFieldValues>,
) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => (
				<YesNo
					{...field}
					readonly={props.readonly}
					error={fieldState.error}
					errorMessage={props.errorMessage}
				/>
			)}
		/>
	);
};

import { zodResolver } from "@hookform/resolvers/zod";
import {
	City,
	Dtos,
	Email,
	FirstName,
	IndividualContactId,
	LastName,
	NameSuffix,
	StreetAddress,
	USStateOrTerritory,
	ValidAddress,
	ZipCode,
	zswitch,
} from "@inrev/common";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCustomCompareMemo } from "use-custom-compare";
import { z } from "zod";
import { useAddressValidation } from "../../../../../api";
import { Modal } from "../../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../../components/layout/ModalItemWithHeader";
import { useAdminUpsertIndividualContact } from "../../../../../domain/admin/account/api";
import { AdminSuretyAccount } from "../../../../../domain/admin/account/types";
import { formatAddress } from "../../../../../utils";
import { nullEmptyResolver } from "../../../../../utils/form";
import { uniqueSSNSchema } from "../../../../../validation";
import { AdminIndividualForm } from "./AdminIndividualForm";
import { AdminIndividualSpouseForm } from "./AdminSpouseForm";

export type DefaultAdminIndividualFormData = Omit<
	Dtos.Admin.IndividualContact.Create.Request,
	"ssn" | "married" | "ownsAHome" | "includeInUnderwriting" | "includeInIndemnity"
> & {
	ssn: string | undefined;
	married: boolean | "";
	ownsAHome: boolean | "";
	includeInIndemnity: boolean | "";
	includeInUnderwriting: boolean | "";
};

const getDefaultFormData = (): DefaultAdminIndividualFormData => ({
	firstName: "" as FirstName,
	middleInitial: "",
	lastName: "" as LastName,
	suffix: "" as NameSuffix,
	address: {
		street: "" as StreetAddress,
		city: "" as City,
		state: "" as USStateOrTerritory,
		zip: "" as ZipCode,
	} as ValidAddress,
	email: "" as Email,
	ssn: "",
	married: "",
	ownsAHome: "",
	includeInIndemnity: "",
	includeInUnderwriting: "",
});

const individualToFormData = (
	selectedIndividual: AdminSuretyAccount["individuals"][number],
): DefaultAdminIndividualFormData => ({
	firstName: selectedIndividual.firstName,
	middleInitial: selectedIndividual.middleInitial,
	lastName: selectedIndividual.lastName,
	suffix: selectedIndividual.suffix,
	address: selectedIndividual.address as ValidAddress,
	email: selectedIndividual.email,
	ssn: selectedIndividual.ssn,
	married: selectedIndividual.spouse !== undefined,
	ownsAHome: selectedIndividual.ownsAHome ?? "",
	includeInIndemnity: selectedIndividual.config.contract.includeInIndemnity,
	includeInUnderwriting: selectedIndividual.config.contract.includeInUnderwriting,
});

const getOwnerSchema = (ssns: string[]) =>
	zswitch((input) => {
		if (input.includeInUnderwriting)
			return Dtos.Admin.IndividualContact.Create.Request.schema.extend({
				ssn: uniqueSSNSchema(ssns),
			});
		return Dtos.Admin.IndividualContact.Create.Request.schema;
	});

export type AdminIndividualOwnerFormData = z.infer<ReturnType<typeof getOwnerSchema>>;

const getSpouseSchema = (ssns: string[]) =>
	zswitch((input) => {
		if (input.includeInUnderwriting)
			return Dtos.Admin.IndividualContact.Create.Request.schema
				.omit({ spouse: true, married: true })
				.extend({
					ssn: uniqueSSNSchema(ssns),
				});
		return Dtos.Admin.IndividualContact.Create.Request.schema.omit({
			spouse: true,
			married: true,
		});
	});
export type AdminIndividualSpouseFormData = z.infer<ReturnType<typeof getSpouseSchema>>;

type AdminIndividualModalProps = {
	account: AdminSuretyAccount;
	selectedIndividualId?: IndividualContactId;
	onClose: () => void;
};

export const AdminIndividualModal = ({
	account,
	selectedIndividualId: selectedOwnerId,
	onClose,
}: AdminIndividualModalProps) => {
	const {
		upsertIndividualContact,
		upsertIndividualContactIsLoading,
		upsertIndividualContactSuccess,
	} = useAdminUpsertIndividualContact(account.id, selectedOwnerId);
	const individualSSNs = useMemo(
		() =>
			account.individuals
				.map((individual) => individual.ssn)
				.filter((ssn) => ssn !== undefined) as string[],
		[account.individuals],
	);

	const selectedOwner =
		selectedOwnerId !== undefined
			? account.individuals.find((individual) => individual.id === selectedOwnerId)
			: undefined;
	const selectedOwnerFilteredSSNs = useMemo(
		() =>
			selectedOwner !== undefined
				? individualSSNs.filter((ssn) => ssn !== selectedOwner.ssn)
				: individualSSNs,
		[selectedOwner, individualSSNs],
	);
	const ownerSchema = useMemo(
		() => getOwnerSchema(selectedOwnerFilteredSSNs),
		[selectedOwnerFilteredSSNs],
	);
	const ownerDefaultValues =
		selectedOwner !== undefined ? individualToFormData(selectedOwner) : getDefaultFormData();
	const ownerForm = useForm<DefaultAdminIndividualFormData, any, AdminIndividualOwnerFormData>({
		defaultValues: ownerDefaultValues,
		resolver: nullEmptyResolver(
			zodResolver(
				ownerSchema.superRefine((val, ctx) => {
					if (!val.includeInIndemnity && !val.includeInUnderwriting) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							path: ["includeInIndemnity"],
							message: "Individual must be included in underwriting or indemnity",
						});
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							path: ["includeInUnderwriting"],
							message: "Individual must be included in underwriting or indemnity",
						});
					}
				}),
			),
		),
		reValidateMode: "onBlur",
	});
	const ownerAddressValidation = useAddressValidation<DefaultAdminIndividualFormData>(
		formatAddress(ownerDefaultValues.address),
		ownerForm,
		"address",
	);
	const [validatedOwnerData, setValidatedOwnerData] = useState<
		AdminIndividualOwnerFormData | undefined
	>();
	const ownerSSN = ownerForm.watch("ssn");
	const ownerOwnsAHome = ownerForm.watch("ownsAHome");
	const ownerIncludedInUnderwriting = ownerForm.watch("includeInUnderwriting");

	const selectedSpouse =
		selectedOwner !== undefined && selectedOwner.spouse !== undefined
			? account.individuals.find((i) => selectedOwner.spouse!.id === i.id)
			: undefined;
	const selectedSpouseFilteredSSNs = useMemo(
		() =>
			selectedSpouse !== undefined
				? individualSSNs.filter((ssn) => ssn !== selectedSpouse.ssn)
				: individualSSNs,
		[selectedSpouse, individualSSNs],
	);
	const spouseSchema = useCustomCompareMemo(
		() =>
			getSpouseSchema([
				...selectedSpouseFilteredSSNs,
				...(ownerSSN !== undefined ? ownerSSN && [ownerSSN] : []),
			]),
		[{ ownerSSN }],
		(prev, next) => {
			return prev[0].ownerSSN === next[0].ownerSSN || next[0].ownerSSN?.length !== 9;
		},
	);
	const spouseDefaultValues =
		selectedSpouse !== undefined ? individualToFormData(selectedSpouse) : getDefaultFormData();
	const spouseForm = useForm<DefaultAdminIndividualFormData, any, AdminIndividualSpouseFormData>({
		defaultValues: spouseDefaultValues,
		resolver: nullEmptyResolver(
			zodResolver(
				spouseSchema.superRefine((val, ctx) => {
					if (!val.includeInIndemnity && !val.includeInUnderwriting) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							path: ["includeInIndemnity"],
							message: "Individual must be included in underwriting or indemnity",
						});
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							path: ["includeInUnderwriting"],
							message: "Individual must be included in underwriting or indemnity",
						});
					}
				}),
			),
		),
		reValidateMode: "onBlur",
	});
	const spouseAddressValidation = useAddressValidation<DefaultAdminIndividualFormData>(
		formatAddress(spouseDefaultValues.address),
		spouseForm,
		"address",
	);
	const spouseSSN = spouseForm.watch("ssn");
	const spouseIncludedInUnderwriting = spouseForm.watch("includeInUnderwriting");

	const currentForm = useMemo(
		() => (validatedOwnerData ? "spouse" : "owner"),
		[validatedOwnerData],
	);

	const handleSubmit = async (
		ownerFormData: AdminIndividualOwnerFormData,
		spouseFormData?: AdminIndividualSpouseFormData,
	) => {
		if (ownerFormData.married) {
			if (!spouseFormData) throw new Error();
			ownerFormData = { ...ownerFormData, spouse: spouseFormData };
		}
		upsertIndividualContact(ownerFormData);
	};

	const onOwnerFormSubmit = (data: AdminIndividualOwnerFormData) => {
		if (data.married) setValidatedOwnerData(data);
		else handleSubmit(data);
	};

	const onSpouseFormSubmit = (data: AdminIndividualSpouseFormData) => {
		if (!validatedOwnerData) throw new Error();
		handleSubmit(validatedOwnerData, data);
	};

	useEffect(() => {
		if (upsertIndividualContactSuccess) onClose();
	}, [upsertIndividualContactSuccess]);

	useEffect(() => {
		if (validatedOwnerData) {
			spouseForm.setValue("ownsAHome", ownerOwnsAHome);
		}
	}, [validatedOwnerData]);

	useEffect(() => {
		if (selectedOwner !== undefined && !ownerIncludedInUnderwriting) ownerForm.setValue("ssn", "");
	}, [ownerIncludedInUnderwriting, selectedOwner]);

	useEffect(() => {
		if (selectedSpouse === undefined && spouseIncludedInUnderwriting && spouseSSN !== "")
			spouseForm.setValue("ssn", "");
	}, [spouseIncludedInUnderwriting, selectedSpouse]);

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header={currentForm === "owner" ? "New Individual" : "New Individual Spouse"}
				onClose={onClose}
			>
				<div className="max-w-[820px] px-[40px] pt-[25px] pb-[35px]">
					{currentForm === "owner" && (
						<AdminIndividualForm
							form={ownerForm}
							addressValidation={ownerAddressValidation}
							onSubmit={onOwnerFormSubmit}
							loading={upsertIndividualContactIsLoading}
						/>
					)}
					{currentForm === "spouse" && (
						<AdminIndividualSpouseForm
							form={spouseForm}
							addressValidation={spouseAddressValidation}
							validatedOwnerData={validatedOwnerData}
							onBack={() => setValidatedOwnerData(undefined)}
							onSubmit={onSpouseFormSubmit}
							loading={upsertIndividualContactIsLoading}
						/>
					)}
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

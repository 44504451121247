import { Dtos } from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError, SuretyEntityIdMap } from "../../shared/types";

export const useFetchComments = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, isLoading } = useQuery({
		queryKey: ["comments", type, id],
		queryFn: async () =>
			await get<Dtos.Comment.Get.Response[]>(
				`/v2/${admin ? "admin/" : ""}surety/${type}/${id}/comments`,
			),
		refetchInterval: 20000,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { comments: data, commentsLoading: isLoading };
};

export const useCreateComment = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const { post } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (message: string) =>
			await post<Dtos.Comment.Get.Response>(
				`/v2/${admin ? "admin/" : ""}surety/${type}/${id}/comments`,
				{ message },
			),
		onSuccess: (data) => {
			queryClient.cancelQueries(["comments", type, id]);
			queryClient.setQueryData(
				["comments", type, id],
				(oldData: Dtos.Comment.Get.Response[] | undefined) => {
					if (!!oldData) {
						return [...oldData, data];
					} else {
						return [data];
					}
				},
			);
			queryClient.invalidateQueries(["followers", type, id]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { createComment: mutation.mutate, createCommentLoading: mutation.isLoading };
};

export const useComments = <T extends keyof SuretyEntityIdMap>(
	type: T,
	id: SuretyEntityIdMap[T],
	admin?: boolean,
) => {
	const { comments, commentsLoading } = useFetchComments(type, id, admin);
	const { createComment, createCommentLoading } = useCreateComment(type, id, admin);

	return { comments, commentsLoading, createComment, createCommentLoading };
};

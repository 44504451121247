import { HiOutlinePlus } from "react-icons/hi2";
import { Tag, TagProps } from "./Tag";

export type AddTagButtonProps = Omit<TagProps, "children"> & {
	small: boolean;
	onClick: () => void;
};

export const AddTagButton = ({ small, onClick, ...rest }: AddTagButtonProps) => {
	return (
		<button
			onClick={(e) => {
				e.preventDefault();
				onClick();
			}}
			className={`rounded-full`}
		>
			<Tag
				hover={true}
				small={small}
				colorOverride={{ bg: "bg-gray-100/80", border: "border-gray-200" }}
				{...rest}
			>
				<div className="flex items-center justify-center text-gray-600 text-[12px]">
					<HiOutlinePlus
						className={`stroke-[2.5px] ${small ? "text-gray-500 text-[11px]" : "mr-2"}`}
					/>
					{!small && <span className="font-medium max-h-6">Add Tags</span>}
				</div>
			</Tag>
		</button>
	);
};

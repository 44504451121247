import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { NAICSCode, getNAICSCodeLabelGroups } from "@inrev/common";
import { HiChevronRight } from "react-icons/hi2";
import { cn } from "../../../components/lib/utils";

type NAICSCodeListProps = {
	naicsCodes: NAICSCode[];
	emptyMessage?: string;
	className?: string;
	hideExpand?: boolean;
	showRatio?: boolean;
	showCount?: boolean;
};

export const NAICSCodesList = ({
	naicsCodes,
	emptyMessage,
	className,
	hideExpand,
	showCount,
	showRatio,
}: NAICSCodeListProps) => {
	const naicsCodeLabelGroups = getNAICSCodeLabelGroups(new Set(naicsCodes));

	if (!naicsCodeLabelGroups.length && emptyMessage === undefined) return <></>;
	return (
		<div
			className={cn(
				!!naicsCodes.length &&
					"flex flex-col w-fit min-w-[500px] max-w-[500px] divide-y divide-gray-200  border border-gray-200 bg-gray-50 bg-opacity-70",
				className,
			)}
		>
			{!naicsCodes.length && <>{emptyMessage}</>}
			{naicsCodes.length > 0 &&
				naicsCodeLabelGroups.map((group, index) => (
					<Disclosure key={index} as="span">
						<div className="w-full">
							<DisclosureButton className="w-full flex flex-col space-y-[15px] px-[15px] py-[10px] text-[15px] outline-none">
								<div className="w-full flex items-center space-x-[10px] text-gray-900 font-regular">
									{!hideExpand && (
										<HiChevronRight className="text-gray-400 text-[14px] stroke-[1.1] ui-open:rotate-90 ui-open:transform" />
									)}
									<div className="flex-1 text-left">{group.label}</div>
									<span>
										{showCount || showRatio ? `${group.subCodesLabels.length}` : ""}
										{showRatio ? `/ ${group.groupSubCodeCount}` : ""}
									</span>
								</div>
								{!hideExpand && (
									<DisclosurePanel className="flex flex-col space-y-[10px] pl-[50px] pr-[10px] pb-[15px]">
										{group.subCodesLabels.map((subCodeLabel, index) => (
											<div
												key={index}
												className="flex flex-col text-[14px] text-gray-500 font-regular w-fit text-left leading-[17px]"
											>
												{subCodeLabel}
											</div>
										))}
									</DisclosurePanel>
								)}
							</DisclosureButton>
						</div>
					</Disclosure>
				))}
		</div>
	);
};

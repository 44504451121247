import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import { ReactNode, forwardRef, useEffect, useMemo, useState } from "react";
import { Button } from "./Button";
import { Icon } from "./Icon";
import { SearchInput } from "./SearchInput";

const getListItemRank = (
	listItem: SearchableSelectListProps["listItems"][number],
	searchString: string,
) => {
	const searchValuesWithRank = listItem.searchValues.map((value) => ({
		value,
		rank: rankItem(value, searchString),
	}));

	return {
		...listItem,
		searchRank: searchValuesWithRank.sort((a, b) => (a.rank.rank > b.rank.rank ? -1 : 1))[0].rank,
	};
};

const fuzzyMatchAndSort = (
	listItems: SearchableSelectListProps["listItems"],
	searchString: string,
) => {
	const itemsWithRank: (SearchableSelectListProps["listItems"][number] & {
		searchRank: RankingInfo;
	})[] = listItems
		.map<SearchableSelectListProps["listItems"][number] & { searchRank: RankingInfo }>(
			(listItem) => ({
				...listItem,
				searchRank: getListItemRank(listItem, searchString).searchRank,
			}),
		)
		.filter((listItem) => listItem.searchRank.passed);
	return itemsWithRank.sort((a, b) => (a.searchRank.rank > b.searchRank.rank ? -1 : 1));
};

export type SearchableSelectListProps = {
	value: string;
	listItems: {
		value: string;
		element: ReactNode;
		searchValues: string[];
	}[];
	searchPlaceholder?: string;
	onChange: (value: any) => void;
	onBlur: () => void;
};

export const SearchableSelectList = forwardRef<HTMLElement, SearchableSelectListProps>(
	({ value, listItems, searchPlaceholder, onChange, onBlur }) => {
		const [searchString, setSearchString] = useState<string>("");
		const filteredListItems = useMemo<SearchableSelectListProps["listItems"]>(
			() => fuzzyMatchAndSort(listItems, searchString),
			[listItems, searchString],
		);

		useEffect(() => {
			onBlur();
		}, [value]);

		return (
			<>
				{value !== "" && (
					<div className="w-full flex flex-col space-y-[24px] min-h-fit mt-[7px]">
						<div className="shrink-0 w-full flex items-center rounded-md cursor-pointer outline outline-inrev-light-blue outline-[2px] outline-offset-[-2px] bg-inrev-light-blue/[.06]">
							<div className="flex h-full pl-[25px] items-center justify-center">
								<div className="relative aspect-square h-4 w-4 rounded-full border border-gray-400 bg-white focus:outline-none flex items-center justify-center">
									<div className="absolute top-[-1px] left-[-1px] aspect-square h-4 w-4 rounded-full border-[6px] border-inrev-light-blue ml-[-1] mt-[-1]" />
								</div>
							</div>
							<div className="h-full flex-1">
								{listItems.find((item) => item.value === value)?.element}
							</div>
						</div>
						<Button
							color="gray"
							className="font-medium w-fit flex space-x-[5px]"
							rounded
							thinFont
							onClick={() => onChange("")}
						>
							<Icon type="close" width={10} height={10} />
							<div>Clear Selection</div>
						</Button>
					</div>
				)}
				<div className={value !== "" ? "hidden" : ""}>
					<div className="flex flex-col w-full max-h-full space-y-[20px]">
						<div className="flex items-center w-full min-h-fit">
							<SearchInput
								className="w-full outline-gray-300 outline outline-[1px] outline-offset-[-1px]"
								placeholder={searchPlaceholder ?? "Search..."}
								onChange={setSearchString}
							/>
						</div>
						{filteredListItems.length ? (
							<div className="relative w-full h-fit max-h-full flex-1 flex flex-col">
								<div className="absolute left-0 right-0 top-0 w-full h-[1px] bg-gray-200 z-10"></div>
								<div className="relative w-full h-fit max-h-full flex-1 overflow-y-auto">
									{filteredListItems.map((listItem) => {
										return (
											<div
												key={listItem.value}
												className="shrink-0 relative group w-full border-b border-x border-gray-200 flex items-center cursor-pointer mb-[-1px] last:mb-[0px] z-20 hover:outline hover:outline-gray-400 hover:outline-[1px] hover:outline-offset-[-1px] hover:bg-gray-50"
												onClick={() => onChange(listItem.value)}
											>
												<div className="flex h-full pl-[25px] items-center justify-center">
													<div className="relative aspect-square h-4 w-4 rounded-full border border-gray-400 bg-white focus:outline-none flex items-center justify-center"></div>
												</div>
												<div className="h-full flex-1">{listItem.element}</div>
											</div>
										);
									})}
								</div>
								<div className="absolute left-0 right-0 bottom-0 w-full h-[1px] bg-gray-200 z-10"></div>
							</div>
						) : (
							<div className="w-full bg-white border border-gray-300 px-[25px] py-[15px] text-sm text-gray-500 flex justify-center italic">
								No results
							</div>
						)}
					</div>
				</div>
			</>
		);
	},
);

import { decryptedSSN } from "@inrev/common";
import { z } from "zod";

export const uniqueSSNSchema = (ssns: string[]) =>
	decryptedSSN.refine((val) => !ssns.includes(val), { message: "SSN Already Used" });

export const numericStringSchema = z
	.string()
	.trim()
	.regex(/^[0-9]*$/, "Must be a number");

export const uniqueSchema = <T>(values: T[], schema: z.ZodSchema<T>, message?: string) =>
	z
		.any()
		.refine((val: T) => !values.includes(val), message)
		.pipe(schema);

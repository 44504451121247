import { z } from 'zod';
import { Data as Data$1 } from './submitted-bid-contract-surety-bond-form.types.mjs';
import * as submittedBidContractSuretyBondForm_types from './submitted-bid-contract-surety-bond-form.types.mjs';
export { submittedBidContractSuretyBondForm_types as Bid };
import { Data as Data$2 } from './submitted-final-contract-surety-bond-form.types.mjs';
import * as submittedFinalContractSuretyBondForm_types from './submitted-final-contract-surety-bond-form.types.mjs';
export { submittedFinalContractSuretyBondForm_types as Final };

var Data;
(function (Data) {
    Data.schema = z.discriminatedUnion("contractSuretyType", [
        Data$1.schema,
        Data$2.schema
    ]);
})(Data || (Data = {}));

export { Data };

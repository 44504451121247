import { ChangeEvent, InputHTMLAttributes, forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

export type TextAreaProps = Omit<
	InputHTMLAttributes<HTMLTextAreaElement>,
	"readOnly" | "prefix"
> & {
	onChange?: (
		event: ChangeEvent<HTMLTextAreaElement> & {
			target: ChangeEvent<HTMLTextAreaElement>["target"] & {
				value: ChangeEvent<HTMLTextAreaElement>["target"]["value"] | null;
			};
		},
	) => void;
	error?: FieldError;
	readonly?: boolean;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({ className, type, error, readonly, ...props }, ref) => {
		return (
			<div
				className={cn(
					"relative flex w-full h-full rounded-md outline outline-[1px] outline-offset-[-1px] text-gray-900 outline-gray-300 bg-gray-50 bg-opacity-70 text-[14px] leading-[20px] placeholder:text-gray-500 focus-within:outline-gray-500 disabled:cursor-not-allowed disabled:opacity-50",
					className,
					readonly ? "opacity-80" : undefined,
					error ? "outline-red-500 focus-within:outline-red-500" : undefined,
				)}
			>
				<textarea
					disabled={readonly}
					tabIndex={0}
					className={cn(
						"resize-none px-[13px] py-[10px] w-full h-full outline-none bg-transparent",
						readonly ? "bg-gray-100 text-gray-800" : undefined,
					)}
					{...props}
					ref={ref}
					// Needed to disable browser auto-filling
					autoComplete="one-time-code"
					data-1p-ignore
					data-lpignore
				/>
				<FormError error={error} />
			</div>
		);
	},
);

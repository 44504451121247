import { ReactElement, useState } from "react";
import { ConfirmationModal, ConfirmationModalProps } from "./ConfirmationModal";

type ConfirmationModalClickWrapperProps = ConfirmationModalProps & {
	children: ReactElement;
	shouldConfirm?: boolean;
};

export const ConfirmationModalClickWrapper = ({
	children,
	shouldConfirm,
	onConfirm,
	onCancel,
	...props
}: ConfirmationModalClickWrapperProps) => {
	const [open, setOpen] = useState<boolean>(false);

	const handleCancel = () => {
		onCancel && onCancel();
		setOpen(false);
	};

	const handleConfirm = () => {
		onConfirm && onConfirm();
		setOpen(false);
	};

	return (
		<>
			<children.type {...children.props} onClick={() => (shouldConfirm ?? true) && setOpen(true)} />
			{open && <ConfirmationModal {...props} onConfirm={handleConfirm} onCancel={handleCancel} />}
		</>
	);
};

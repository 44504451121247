import { DonutChart } from "@tremor/react";

type DonutChartProps = {
	data: {
		name: string;
		value: number;
	}[];
	label: string;
	noDataText: string;
};

export const DonutChartHero = ({ data, label, noDataText }: DonutChartProps) => {
	return (
		<DonutChart
			className="h-[175px] text-[34px]"
			variant="donut"
			data={data}
			valueFormatter={(value) => value.toString()}
			label={label}
			noDataText={noDataText}
		/>
	);
};

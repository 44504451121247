import { Comments, CommentsProps } from "../ui/Comments";
import { DownloadLink } from "../ui/PDFComments";

type CommentsSectionProps = CommentsProps & {
	headerEnd?: React.ReactNode;
	commentDownloadHeader?: string;
};

export const CommentsSection = (props: CommentsSectionProps) => {
	return (
		<div className="flex flex-col w-full space-y-[20px]">
			<div className="flex items-center w-full h-fit min-h-fit">
				<span className="flex-1 text-[20px] text-gray-900 font-medium">Comments</span>
				{!!props.headerEnd && props.headerEnd}
				{props.comments && props.comments.length > 0 && (
					<div className="pl-3">
						<DownloadLink
							fileName="comments"
							comments={props.comments}
							commentDownloadHeader={props.commentDownloadHeader}
						/>
					</div>
				)}
			</div>
			<Comments {...props} />
		</div>
	);
};

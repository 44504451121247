import { CompanyContactId, SuretyAccountId } from "@inrev/common";
import { HiArrowDownTray, HiOutlineBuildingOffice } from "react-icons/hi2";
import { Button } from "../../../../../components/ui/Button";
import { AdminSuretyAccount } from "../../../../../domain/admin/account/types";
import { useGetAccountBondabilityLetter } from "../../../../../domain/agent/account/api";
import { CompanyContact, SubmittedSuretyAccount } from "../../../../../domain/agent/account/types";
import { formatAddress } from "../../../../../utils";

export type AccountCompanyCardProps = {
	account: SubmittedSuretyAccount | AdminSuretyAccount;
	company: CompanyContact;
	onRequestButtonClick: (id: string) => void;
	options: {
		showRequestButton: boolean;
		showPrequalButton: boolean;
	};
};

export const AccountCompanyCard = ({
	account,
	company,
	onRequestButtonClick,
	options,
}: AccountCompanyCardProps) => {
	const getAccountBondabilityLetter = useGetAccountBondabilityLetter(account.id as SuretyAccountId);
	const { refetch, isFetching } = getAccountBondabilityLetter(company.id as CompanyContactId);

	return (
		<div
			key={company.id}
			className="w-full flex items-center py-[25px] px-[30px] border-b border-b-gray-200 last:border-b-0"
		>
			<div className="flex flex-col flex-1 space-y-[15px]">
				<div className="flex flex-col space-y-[3px]">
					<div className="flex font-medium text-gray-900 space-x-[10px]">
						<HiOutlineBuildingOffice className="text-gray-900 mt-[4px] text-[18px]" />
						<span>{company.name}</span>
					</div>
					<div className="font-normal text-gray-600 text-sm">{formatAddress(company.address)}</div>
				</div>
			</div>
			<div className="flex flex-col space-y-[10px]">
				{options.showRequestButton && (
					<Button
						color="light-blue"
						rounded
						shadow
						onClick={() => onRequestButtonClick(company.id)}
					>
						Request a Bond
					</Button>
				)}
				{options.showPrequalButton && (
					<Button
						color="gray"
						rounded
						shadow
						onClick={() => refetch()}
						loading={isFetching}
						disabled={isFetching}
					>
						<HiArrowDownTray className="text-[16px] text-gray-800 stroke-[.6] mr-[8px]" /> Prequal
						Letter
					</Button>
				)}
			</div>
		</div>
	);
};

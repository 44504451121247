import {
	CompanyContactId,
	Dtos,
	IndividualContactId,
	SuretyAccountId,
	SuretyAccountUnderwritingId,
	SuretyType,
} from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { LocallyUploadedFile } from "../../../types";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import { AdminSuretyAccount } from "./types";

export const useAdminFetchSuretyAccount = (id: string) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["suretyAccounts", id],
		queryFn: async () => {
			const response = await get<Dtos.Admin.SuretyAccount.Get.Response>(
				`/v2/admin/surety/accounts/${id}`,
			);
			if (response.contract === undefined)
				throw new Error(
					`Error fetching surety account ${id}: contract is undefined in response dto`,
				);
			return response as AdminSuretyAccount;
		},
		retry: false,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { suretyAccount: data, suretyAccountError: error, suretyAccountIsLoading: isLoading };
};

export const useAdminUpdateSuretyAccountStatus = (
	accountId: SuretyAccountId,
	underwritingId: SuretyAccountUnderwritingId,
) => {
	const queryClient = useQueryClient();
	const { patch } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["updateSuretyAccountStatus", accountId, underwritingId],
		mutationFn: async (data: Dtos.Admin.SuretyAccount.Update.Status.Request) => {
			await patch(
				`/v2/admin/surety/accounts/${accountId}/underwriting/${underwritingId}`,
				data,
				"none",
			);
		},
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ["bondRequests"] });
			await Promise.all([
				queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] }),
				queryClient.refetchQueries({ queryKey: ["accountPreviews"] }),
				queryClient.refetchQueries({ queryKey: ["bondRequestPreviews"] }),
			]);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		updateSuretyAccountStatus: mutation.mutate,
		updateSuretyAccountStatusIsLoading: mutation.isLoading,
	};
};

export const useAdminUpdateSuretyAccountBondingLine = (accountId: SuretyAccountId) => {
	const queryClient = useQueryClient();
	const { put } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["updateSuretyAccountBondingLine", accountId],
		mutationFn: async (data: Dtos.Admin.SuretyAccount.Update.BondingLine.Request) => {
			await put(`/v2/admin/surety/accounts/${accountId}/bonding-line`, data, "none");
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] });
			queryClient.refetchQueries({ queryKey: ["accountPreviews"] });
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		updateSuretyAccountBondingLine: mutation.mutate,
		updateSuretyAccountBondingLineIsLoading: mutation.isLoading,
	};
};

export const useAdminUpdateSuretyAccountCarrierBondingLine = (accountId: SuretyAccountId) => {
	const queryClient = useQueryClient();
	const { put } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.Admin.SuretyAccountCarrierBondingLine.Update.Request) => {
			await put(`/v2/admin/surety/accounts/${accountId}/carrier-bonding-line`, data, "none");
			return data.carrierName;
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] });
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		updateSuretyAccountCarrierBondingLine: mutation.mutate,
		updateSuretyAccountCarrierBondingLineIsLoading: mutation.isLoading,
	};
};

export const useAdminUploadCompletedIndemnityAgreement = (
	accountId: SuretyAccountId,
	suretyType: SuretyType,
) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationKey: ["adminUploadCompletedIndemnityAgreement", accountId, suretyType],
		mutationFn: async (file: LocallyUploadedFile) => {
			const body = {
				suretyType,
				file: {
					name: file.name,
					base64: file.base64,
				},
			};
			await post(`/v2/admin/surety/accounts/${accountId}/indemnity-agreement`, body, "none");
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] });
			queryClient.refetchQueries({
				queryKey: ["indemnityAgreementFileUrl", accountId, SuretyType.contract],
			});
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		uploadCompletedIndemnityAgreement: mutation.mutate,
		uploadCompletedIndemnityAgreementIsLoading: mutation.isLoading,
		uploadCompletedIndemnityAgreementSuccess: mutation.isSuccess,
	};
};

export const useAdminGetAccountIndemnityAgreementFileUrl = (
	accountId: SuretyAccountId,
	suretyType: SuretyType,
	asDownload?: boolean,
) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading, refetch } = useQuery({
		queryKey: ["indemnityAgreementFileUrl", accountId, suretyType, asDownload],
		queryFn: async () =>
			await get(
				`/v2/admin/surety/accounts/${accountId}/indemnity-agreement/${suretyType}?download=${asDownload ?? false}`,
			),
		retry: false,
		staleTime: 60000,
		enabled: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		getIndemnityAgreementFileUrl: refetch,
		indemnityAgreementFileUrl: data,
		indemnityAgreementFileUrlError: error,
		indemnityAgreementFileUrlLoading: isLoading,
	};
};

export const useAdminReUnderwriteSuretyAccount = (
	accountId: SuretyAccountId,
	suretyType: SuretyType,
) => {
	const { post, patch } = useRequest();
	const queryClient = useQueryClient();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const {
		data: draft,
		isLoading: draftLoading,
		error: draftError,
		isSuccess: draftSuccess,
	} = useQuery({
		queryKey: ["AdminReUnderwriteSuretyAccountDraft", accountId, suretyType],
		queryFn: async () => {
			const existingDraft =
				queryClient.getQueryData<Dtos.Admin.SuretyAccount.Draft.Create.Response>([
					"AdminReUnderwriteSuretyAccountDraft",
					accountId,
					suretyType,
				]);
			if (existingDraft === undefined) {
				return await post<Dtos.Admin.SuretyAccount.Draft.Create.Response>(
					`/v2/admin/surety/account-drafts/${accountId}`,
					{ suretyType },
				);
			} else {
				return await patch<Dtos.Admin.SuretyAccount.Draft.Create.Response>(
					`/v2/admin/surety/account-drafts/${accountId}`,
					{
						suretyType,
						data: existingDraft.data,
						previousData: existingDraft.data,
					},
				);
			}
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
		staleTime: 60000 * 5,
		cacheTime: 60000 * 60 * 2,
	});

	const {
		mutate: updateDraft,
		isLoading: updateDraftLoading,
		error: updateDraftError,
	} = useMutation({
		mutationKey: ["AdminReUnderwriteSuretyAccountDraft", accountId, suretyType],
		mutationFn: async (data: Dtos.Admin.SuretyAccount.Draft.Update.Request["data"]) => {
			const existingDraft =
				queryClient.getQueryData<Dtos.Admin.SuretyAccount.Draft.Update.Response>([
					"AdminReUnderwriteSuretyAccountDraft",
					accountId,
					suretyType,
				]);
			if (existingDraft === undefined) {
				throw new Error(
					`Cannot update admin re-underwrite draft. Existing draft data for surety account ${accountId} and surety type ${suretyType} does not exist`,
				);
			}
			return await patch<Dtos.Admin.SuretyAccount.Draft.Update.Response>(
				`/v2/admin/surety/account-drafts/${accountId}`,
				{
					suretyType,
					data,
					previousData: existingDraft.data,
				},
			);
		},
		onSuccess: (response) => {
			queryClient.setQueriesData(
				["AdminReUnderwriteSuretyAccountDraft", accountId, suretyType],
				response,
			);
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	const {
		mutate: reUnderwrite,
		isLoading: reUnderwriteLoading,
		isSuccess: reUnderwriteSuccess,
	} = useMutation({
		mutationFn: async (data: Dtos.Admin.SuretyAccount.Contract.Submit.Request.Existing["data"]) => {
			return await post<Dtos.Admin.SuretyAccount.Submit.Response>(
				`/v2/admin/surety/accounts/${accountId}/re-underwrite`,
				{
					suretyType,
					data,
					options: { notifyFollowers: true },
				},
				"none",
			);
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] });
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		draft,
		draftLoading,
		draftError,
		draftSuccess,
		updateDraft,
		updateDraftLoading,
		updateDraftError,
		reUnderwrite,
		reUnderwriteLoading,
		reUnderwriteSuccess,
		resetDraftQuery: () =>
			queryClient.resetQueries(["AdminReUnderwriteSuretyAccountDraft", accountId, suretyType]),
	};
};

export const useAdminUpsertIndividualContact = (
	accountId: SuretyAccountId,
	individualContactId?: IndividualContactId,
) => {
	const queryClient = useQueryClient();
	const { post, put } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.Admin.IndividualContact.Create.Request) => {
			if (individualContactId) {
				await put(
					`/v2/admin/surety/accounts/${accountId}/individuals/${individualContactId}`,
					data,
					"none",
				);
			} else {
				await post(`/v2/admin/surety/accounts/${accountId}/individuals`, data, "none");
			}
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] });
			queryClient.refetchQueries({
				queryKey: ["indemnityAgreementFileUrl", accountId, SuretyType.contract],
			});
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		upsertIndividualContact: mutation.mutate,
		upsertIndividualContactError: mutation.error,
		upsertIndividualContactIsLoading: mutation.isLoading,
		upsertIndividualContactSuccess: mutation.isSuccess,
	};
};

export const useAdminUpsertCompanyContact = (
	accountId: SuretyAccountId,
	companyContactId?: CompanyContactId,
) => {
	const queryClient = useQueryClient();
	const { post, put } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.Admin.CompanyContact.Create.Request) => {
			if (companyContactId) {
				await put(
					`/v2/admin/surety/accounts/${accountId}/companies/${companyContactId}`,
					data,
					"none",
				);
			} else {
				await post(`/v2/admin/surety/accounts/${accountId}/companies`, data, "none");
			}
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["suretyAccounts", accountId] });
			queryClient.refetchQueries({
				queryKey: ["indemnityAgreementFileUrl", accountId, SuretyType.contract],
			});
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return {
		upsertCompanyContact: mutation.mutate,
		upsertCompanyContactError: mutation.error,
		upsertCompanyContactIsLoading: mutation.isLoading,
		upsertCompanyContactSuccess: mutation.isSuccess,
	};
};

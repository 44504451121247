import { SuretyType } from "@inrev/common";
import { useNavigate } from "react-router-dom";
import { useCreateSuretyAccountDraft } from "../../domain/agent/account/api";
import { useCreateBondRequestDraft } from "../../domain/agent/request/api";
import { cn } from "../lib/utils";
import { Button } from "../ui/Button";
import { UserMenu } from "../ui/UserMenu";

export type HeaderActionsProps = {
	includeActionButtons?: boolean;
	className?: string;
};

export const HeaderActions = ({ includeActionButtons, className }: HeaderActionsProps) => {
	const navigate = useNavigate();
	const { createBondRequest } = useCreateBondRequestDraft();
	const { createSuretyAccount } = useCreateSuretyAccountDraft();

	return (
		<div className={cn("flex space-x-[15px] items-center shrink-0", className)}>
			{includeActionButtons && (
				<div className="flex space-x-3">
					<Button
						color="light-blue"
						rounded
						shadow
						onClick={() => {
							createBondRequest(undefined, {
								onSuccess: (data) => navigate(`/requests/${data.id}`),
							});
						}}
					>
						Request a Bond
					</Button>
					<Button
						color="light-blue"
						rounded
						shadow
						onClick={() =>
							createSuretyAccount(SuretyType.contract, {
								onSuccess: (data) => navigate(`/accounts/${data.id}`),
							})
						}
					>
						Submit an Account
					</Button>
				</div>
			)}
			<UserMenu />
		</div>
	);
};

import { Data as Data$1 } from '../../issued/contract/issued-bid-contract-surety-bond-form.types.mjs';

var Data;
(function (Data) {
    let FillData;
    (function (FillData) {
        FillData.schema = Data$1.FillData.schema.partial();
    })(FillData = Data.FillData || (Data.FillData = {}));
    let Attributes;
    (function (Attributes) {
        Attributes.schema = Data$1.Attributes.schema;
    })(Attributes = Data.Attributes || (Data.Attributes = {}));
    Data.schema = Data$1.schema.extend({
        fillData: FillData.schema,
        attributes: Attributes.schema
    });
})(Data || (Data = {}));

export { Data };

import { z } from 'zod';
import { Get as Get$1 } from '../surety-quote-preview.dto.mixins.mjs';
import { SuretyQuoteStatus, contractSuretyTypeSchema } from '../../../../../enums.mjs';
import { currencySchema } from '../../../../../types/utils.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Get$1.Response.baseSchema.extend({
            status: z.literal(SuretyQuoteStatus.draft),
            principalName: z.string().nullish(),
            accountName: z.string().nullish(),
            amount: currencySchema.nullish(),
            description: z.string().nullish(),
            suretySubType: contractSuretyTypeSchema.nullish()
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

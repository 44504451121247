import { SuretyBondFormType, formatToDollar } from "@inrev/common";
import { DateTime } from "luxon";
import { useContext, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { SummaryItem } from "../../../../../../../components/layout/SummaryItem";
import { SummaryItemGroup } from "../../../../../../../components/layout/SummaryItemGroup";
import { SummaryItemSection } from "../../../../../../../components/layout/SummaryItemSection";
import { SummarySection } from "../../../../../../../components/layout/SummarySection";
import { BondRequestDraftData } from "../../../../../../../domain/agent/request/types";
import { boolToYN } from "../../../../../../../utils";
import { DraftBondRequestContext } from "../../DraftBondRequestView";
import { bondFormTypesAndLabels } from "../../constants";

export const DraftBidToFinalBondRequestSummarySection = () => {
	const { pathname } = useLocation();
	const { request, bondFormTemplates } = useContext(DraftBondRequestContext);
	const { watch, formState } = useFormContext<BondRequestDraftData>();
	const bondData = watch("bond");
	const bondFormTemplate = useMemo(() => {
		if (bondData.bondForm.templateId !== "") {
			return bondFormTemplates.find((template) => template.id === bondData.bondForm.templateId);
		}
	}, [bondData]);
	const bondFormTypeLabel = useMemo(() => {
		const type: SuretyBondFormType | undefined =
			bondFormTemplate?.type ??
			(bondData.bondForm.upload ? bondData.bondForm.upload.type || undefined : undefined);
		if (type) return bondFormTypesAndLabels[type];
	}, [bondData, bondFormTemplate]);

	return (
		<div className="w-full flex flex-col space-y-[25px] pt-[18px] pb-[125px]">
			<SummarySection header="Bid Results" to={{ pathname, section: "bond" }}>
				<SummaryItemGroup>
					<SummaryItem
						label="Low Bid Amount (Principal's Bid)"
						value={formatToDollar(bondData.contractAmount)}
						error={formState.errors.bond?.contractAmount}
						schemaInclude={Boolean(request.draft.schema.bond?.include.contractAmount)}
						to={{ pathname, section: "bond", id: "contractAmount" }}
					/>
					<SummaryItem
						label="Second Lowest Bid"
						value={formatToDollar(bondData.secondBid)}
						error={formState.errors.bond?.secondBid}
						schemaInclude={Boolean(request.draft.schema.bond?.include.secondBid)}
						to={{ pathname, section: "bond", id: "secondBid" }}
					/>
					<SummaryItem
						label="Bid Date"
						value={
							bondData.actualBidDate !== ""
								? DateTime.fromISO(bondData.actualBidDate).toLocaleString(DateTime.DATE_FULL)
								: "--"
						}
						error={formState.errors.bond?.actualBidDate}
						schemaInclude={Boolean(request.draft.schema.bond?.include.actualBidDate)}
						to={{ pathname, section: "financials", id: "actualBidDate" }}
					/>
				</SummaryItemGroup>
			</SummarySection>
			<SummarySection header="Final Bond" to={{ pathname, section: "bond" }}>
				<SummaryItemGroup>
					<SummaryItem
						label="Final Bond Amount"
						value={formatToDollar(bondData.bondAmount)}
						error={formState.errors.bond?.bondAmount}
						schemaInclude={Boolean(request.draft.schema.bond?.include.bondAmount)}
						to={{ pathname, section: "bond", id: "bondAmount" }}
					/>
					<SummaryItem
						label="Contract Date"
						value={
							typeof bondData.project.contractDate === "object"
								? DateTime.fromJSDate(bondData.project.contractDate).toLocaleString(
										DateTime.DATE_FULL,
									)
								: bondData.project.contractDate
						}
						error={formState.errors.bond?.project?.contractDate}
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.contractDate)}
						to={{ pathname, section: "financials", id: "contractDate" }}
					/>
					<SummaryItem
						label="Does the final bond require a Treasury Listing?"
						value={boolToYN(bondData.tListingRequired)}
						error={formState.errors.bond?.tListingRequired}
						vertical
						schemaInclude={Boolean(request.draft.schema.bond?.include.tListingRequired)}
						to={{ pathname, section: "bond", id: "tListingRequired" }}
					/>
				</SummaryItemGroup>
				<SummaryItemSection
					header="Contract"
					schemaInclude={Boolean(request.draft.schema.bond?.include.project?.files !== undefined)}
					to={{ pathname, section: "bond", id: "contract" }}
				>
					<SummaryItem
						label="File"
						value={
							bondData.project.files[0] !== undefined
								? `${bondData.project.files[0].name}.${bondData.project.files[0].extension}`
								: "--"
						}
						error={formState.errors.bond?.project?.files}
						schemaInclude={Boolean(request.draft.schema.bond?.include.project?.files)}
						to={{ pathname, section: "bond", id: "contract" }}
					/>
				</SummaryItemSection>
				<SummaryItemSection
					header="Bond Form"
					schemaInclude={Boolean(request.draft.schema.bond?.include.bondForm)}
					to={{ pathname, section: "bond", id: "form" }}
				>
					<SummaryItemGroup>
						<SummaryItem
							label="Form"
							value={
								bondFormTemplate?.name ??
								(bondData.bondForm.upload
									? bondData.bondForm.upload.files
											.map((file) => `${file.name}.${file.extension}`)
											.join(", ")
									: undefined)
							}
							error={formState.errors.bond?.bondForm}
							schemaInclude={Boolean(request.draft.schema.bond?.include.bondForm)}
							to={{ pathname, section: "bond", id: "form" }}
						/>
						<SummaryItem
							label="Type"
							value={bondFormTypeLabel}
							error={formState.errors.bond?.bondForm}
							schemaInclude={Boolean(request.draft.schema.bond?.include.bondForm)}
							to={{ pathname, section: "bond", id: "form" }}
						/>
					</SummaryItemGroup>
				</SummaryItemSection>
			</SummarySection>
		</div>
	);
};

import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { Dropdown } from "../Dropdown";

export type FormDropdownProps<TFieldValues extends FieldValues> = {
	buttonClassName?: string;
	buttonIconClassName?: string;
	optionsClassName?: string;
	optionClassName?: string;
	optionIconClassName?: string;
	placeholder?: string;
	condensed?: boolean;
	disabled?: boolean;
	readonly?: boolean;
	options: {
		label: string;
		value: string;
		className?: string;
	}[];
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
};

export const FormDropdown = <TFieldValues extends FieldValues>({
	name,
	control,
	disabled,
	readonly,
	...props
}: FormDropdownProps<TFieldValues>) => (
	<Controller
		name={name}
		control={control}
		render={({ field, fieldState }) => (
			<Dropdown {...props} {...field} disabled={disabled || readonly} error={fieldState.error} />
		)}
	/>
);

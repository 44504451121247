import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { Tooltip } from "./Tooltip";

type LineItemProps = {
	label: ReactNode;
	marker?: boolean;
	className?: string;
	labelClassName?: string;
	id?: string;
	tooltip?: string;
	children?: ReactNode;
};

export const LineItem = ({
	label,
	marker,
	className,
	labelClassName,
	id,
	tooltip,
	children,
}: LineItemProps) => (
	<div id={id} className={cn("w-full flex items-center", className)}>
		<div className="relative h-fit flex items-center gap-x-[8px]">
			<div className={cn("text-gray-800/95 text-[14.75px] font-[485] max-w-fit", labelClassName)}>
				{label}
			</div>
			{tooltip && <Tooltip id={tooltip} content={tooltip} className="w-[18px] mb-0" />}
			{marker && (
				<div className="absolute top-[10px] left-[-14px] border-b-[4px] border-b-gray-300 border-l-[3px] border-l-transparent border-r-[3px] border-r-transparent rotate-90"></div>
			)}
		</div>
		<div
			className="flex-1 h-[2px] mx-[20px] bg-repeat-space-[10px]"
			style={{
				backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='2' viewBox='0 0 14 2' fill='none'%3E%3Ccircle cx='1' cy='1' r='1' fill='%239CA3AF'/%3E%3C/svg%3E")`,
			}}
		/>
		<div className="min-w-fit">{children}</div>
	</div>
);

import { AddressFormFieldControl } from "../../../api";
import { AddressAutocomplete } from "../AddressAutocomplete";

export type FormAddressAutocompleteProps = {
	control: AddressFormFieldControl;
	placeholder?: string;
	className?: string;
	condensed?: true;
};

export const FormAddressAutocomplete = ({ control, ...props }: FormAddressAutocompleteProps) => (
	<AddressAutocomplete {...props} {...control} />
);

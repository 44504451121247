import { HiOutlineExclamationCircle } from "react-icons/hi2";
import { cn } from "../../lib/utils";

type FormErrorCardProps = {
	error?: { message?: string };
	className?: string;
};

export const FormErrorCard = ({ error, className }: FormErrorCardProps) => {
	if (error !== undefined && error.message !== undefined) {
		return (
			<div
				className={cn(
					"flex items-center space-x-[10px] p-[15px] border-[2px] border-red-500 text-red-500 text-sm rounded bg-red-50 bg-opacity-50 w-fit max-w-full",
					className,
				)}
			>
				<HiOutlineExclamationCircle className="text-[20px] fill-white stroke-[1.75px]" />
				<span>{error?.message}</span>
			</div>
		);
	} else {
		return null;
	}
};

import { z } from 'zod';
import { Data as Data$1 } from './surety/surety-carrier-agency-relationship.types.mjs';
import * as suretyCarrierAgencyRelationship_types from './surety/surety-carrier-agency-relationship.types.mjs';
export { suretyCarrierAgencyRelationship_types as Surety };

var Data;
(function (Data) {
    Data.schema = z.object({
        surety: Data$1.schema
    });
})(Data || (Data = {}));

export { Data };

import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { CheckboxGroup, CheckboxGroupProps } from "../CheckboxGroup";

type FormCheckboxGroupProps<TFieldValues extends FieldValues> = Pick<
	CheckboxGroupProps,
	"options" | "className" | "labelClassName"
> & {
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
};

export const FormCheckboxGroup = <TFieldValues extends FieldValues>({
	control,
	name,
	...props
}: FormCheckboxGroupProps<TFieldValues>) => (
	<Controller
		control={control}
		name={name}
		render={({ field, fieldState }) => (
			<CheckboxGroup {...props} {...field} error={fieldState.error} />
		)}
	/>
);

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { AppLoadingProvider } from "./providers/AppLoadingProvider";
import { AuthProvider } from "./providers/AuthProvider";
import { GlobalErrorMessageModalProvider } from "./providers/GlobalErrorHandlingProvider";

function App() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 60000 * 5,
				cacheTime: 60000 * 5,
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
			},
		},
	});

	return (
		<BrowserRouter>
			<AuthProvider>
				<AppLoadingProvider>
					<GlobalErrorMessageModalProvider>
						<QueryClientProvider client={queryClient}>
							<AppRouter />
						</QueryClientProvider>
					</GlobalErrorMessageModalProvider>
				</AppLoadingProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default Sentry.withProfiler(App);

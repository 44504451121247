import * as suretyQuote_dto_mixins from './surety-quote.dto.mixins.mjs';
export { suretyQuote_dto_mixins as Mixins };
import { z } from 'zod';
import { Get as Get$1 } from './draft/draft-surety-quote.dto.mjs';
import * as draftSuretyQuote_dto from './draft/draft-surety-quote.dto.mjs';
export { draftSuretyQuote_dto as Draft };
import { Get as Get$2 } from './submitted/submitted-surety-quote.dto.mjs';
import * as submittedSuretyQuote_dto from './submitted/submitted-surety-quote.dto.mjs';
export { submittedSuretyQuote_dto as Submitted };
import { Submit as Submit$1 } from './subtypes/contract/contract-surety-quote.dto.mjs';
import * as contractSuretyQuote_dto from './subtypes/contract/contract-surety-quote.dto.mjs';
export { contractSuretyQuote_dto as Contract };
import { zswitch } from '../../../types/utils.mjs';
import { SuretyType } from '../../../enums.mjs';
import * as suretyQuotePreview_dto from './preview/surety-quote-preview.dto.mjs';
export { suretyQuotePreview_dto as Preview };

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = zswitch(input => {
            if (input.status === "draft")
                return Get$1.responseSchema;
            return Get$2.Response.schema;
        });
        (function (Admin) {
            Admin.schema = zswitch(input => {
                if (input.status === "draft")
                    return z.never();
                return Get$2.Response.Admin.schema;
            });
        })(Response.Admin || (Response.Admin = {}));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Submit;
(function (Submit) {
    (function (Request) {
        (function (Base) {
            Base.schema = zswitch(input => {
                if (input.suretyType === "contract") {
                    return Submit$1.Request.Base.schema;
                }
                return z.never();
            });
        })(Request.Base || (Request.Base = {}));
        (function (NewAccount) {
            (function (Bond) {
                Bond.schema = Submit$1.Request.NewAccount.Data.Bond.schema;
            })(NewAccount.Bond || (NewAccount.Bond = {}));
            NewAccount.schema = zswitch(input => {
                const typedInput = z.object({
                    suretyType: z.literal(SuretyType.contract)
                }).safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$1.Request.NewAccount.schema;
                    default:
                        return z.never();
                }
            });
        })(Request.NewAccount || (Request.NewAccount = {}));
        (function (ExistingAccount) {
            (function (Bond) {
                Bond.schema = Submit$1.Request.ExistingAccount.Data.Bond.schema;
            })(ExistingAccount.Bond || (ExistingAccount.Bond = {}));
            ExistingAccount.buildDtoSchema = (formSchema) => zswitch(input => {
                const typedInput = z.object({
                    suretyType: z.literal(SuretyType.contract)
                }).safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$1.Request.ExistingAccount.buildDtoSchema(formSchema);
                    default:
                        return z.never();
                }
            });
        })(Request.ExistingAccount || (Request.ExistingAccount = {}));
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Submit$1.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Get, Submit };

import { Button } from "./Button";
import { SearchInput } from "./SearchInput";

export type MultiSelectSearchProps = {
	setSearchValue: (value: string) => void;
	valuesChanged: boolean;
	onSaveClick: (open: boolean) => void;
	title: string;
	placeholder: string;
};

export type MultiSelectContentProps = {
	groupComponents: React.ReactNode[];
	children: React.ReactNode;
	hasFilters: boolean;
};

export const MultiSelectSearch = ({
	setSearchValue,
	valuesChanged,
	onSaveClick,
	title,
	placeholder,
}: MultiSelectSearchProps) => {
	return (
		<div className="flex flex-col space-y-[10px] w-full min-h-fit bg-gray-100 rounded-t-md px-[24px] pb-[20px] pt-[16px] border-b border-b-gray-200">
			<span className="text-[14.75px] font-semibold text-gray-700 w-fit break-normal">{title}</span>
			<div className="flex items-center space-x-[20px]">
				<SearchInput
					className="w-full border border-gray-400"
					onChange={setSearchValue}
					placeholder={placeholder}
				/>
				<Button
					color={valuesChanged ? "light-blue" : "gray"}
					filled={valuesChanged}
					thinFont
					onClick={() => onSaveClick(false)}
					className={
						valuesChanged
							? "w-fit"
							: "w-fit bg-gray-200/60 border-gray-400/50 enabled:hover:brightness-90"
					}
				>
					{valuesChanged ? <>Save</> : <>Close</>}
				</Button>
			</div>
		</div>
	);
};

export const MultiSelectContent = ({
	groupComponents,
	children,
	hasFilters,
}: MultiSelectContentProps) => {
	return (
		<div className="flex flex-1 w-full">
			{hasFilters && (
				<div className="flex flex-col space-y-[12px] w-[200px] h-full bg-gray-50 border-r border-gray-200 px-[12px] py-[12px]">
					<span className="text-[12px] text-gray-500 font-[450] italic px-[8px]">Select From</span>
					<div className="flex flex-col space-y-[6px] h-fit">{children}</div>
				</div>
			)}
			<div className="relative flex-1 flex flex-col w-full overflow-auto overscroll-none">
				<div className="w-full h-[4px] bg-white shrink-0"></div>
				{!groupComponents.length && (
					<span className="text-[14px] text-gray-500 font-regular px-[20px] py-[10px] self-center italic">
						No results found
					</span>
				)}
				{groupComponents}
				<div className="w-full h-[16px] z-30 bg-white shrink-0"></div>
			</div>
		</div>
	);
};

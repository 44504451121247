import { DateTime } from "luxon";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { DatePicker } from "../DatePicker";

type FormDatePickerProps<TFieldValues extends FieldValues> = {
	defaultMonth?: DateTime;
	className?: string;
	fromDate?: DateTime;
	toDate?: DateTime;
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	readonly?: boolean;
	condensed?: true;
};

export const FormDatePicker = <TFieldValues extends FieldValues>({
	control,
	name,
	...props
}: FormDatePickerProps<TFieldValues>) => (
	<Controller
		name={name}
		control={control}
		render={({ field, fieldState }) => (
			<DatePicker {...field} {...props} error={fieldState.error} />
		)}
	/>
);

import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { CheckboxOption } from "../CheckboxOption";

type FormCheckboxOptionProps<TFieldValues extends FieldValues> = {
	control: Control<TFieldValues, any, any>;
	name: FieldPath<TFieldValues>;
	label?: string;
	condensed?: true;
	compact?: true;
	readonly?: boolean;
};

export const FormCheckboxOption = <TFieldValues extends FieldValues>({
	control,
	name,
	...props
}: FormCheckboxOptionProps<TFieldValues>) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<CheckboxOption {...props} {...field} checked={field.value} error={fieldState.error} />
			)}
		/>
	);
};

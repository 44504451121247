import { z } from 'zod';
import { Upload as Upload$1, Approve as Approve$1 } from './bid-contract-surety-bond-form-dto.mjs';
import * as bidContractSuretyBondFormDto from './bid-contract-surety-bond-form-dto.mjs';
export { bidContractSuretyBondFormDto as Bid };
import { Upload as Upload$2, Approve as Approve$2 } from './final-contract-surety-bond-form.dto.mjs';
import * as finalContractSuretyBondForm_dto from './final-contract-surety-bond-form.dto.mjs';
export { finalContractSuretyBondForm_dto as Final };

var Upload;
(function (Upload) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = z.discriminatedUnion("contractSuretyType", [
                Upload$1.AgentIssue.Request.schema,
                Upload$2.AgentIssue.Request.schema
            ]);
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Upload.AgentIssue || (Upload.AgentIssue = {}));
    (function (PhysicalIssue) {
        (function (Request) {
            Request.schema = z.discriminatedUnion("bondFormType", [
                Upload$1.PhysicalIssue.Request.schema,
                Upload$2.PhysicalIssue.Request.schema
            ]);
        })(PhysicalIssue.Request || (PhysicalIssue.Request = {}));
    })(Upload.PhysicalIssue || (Upload.PhysicalIssue = {}));
})(Upload || (Upload = {}));
var Approve;
(function (Approve) {
    (function (AgentIssue) {
        (function (Request) {
            Request.schema = z.discriminatedUnion("contractSuretyType", [
                Upload$1.AgentIssue.Request.schema.omit({ bondForm: true }).extend({
                    bondForm: Upload$1.AgentIssue.Request.schema.shape.bondForm.omit({ file: true })
                }),
                Upload$2.AgentIssue.Request.schema.omit({ bondForm: true }).extend({
                    bondForm: Upload$2.AgentIssue.Request.schema.shape.bondForm.omit({ file: true })
                }),
            ]);
        })(AgentIssue.Request || (AgentIssue.Request = {}));
    })(Approve.AgentIssue || (Approve.AgentIssue = {}));
    (function (Request) {
        Request.schema = z.discriminatedUnion("contractSuretyType", [
            Approve$1.Request.schema,
            Approve$2.Request.schema
        ]);
    })(Approve.Request || (Approve.Request = {}));
})(Approve || (Approve = {}));

export { Approve, Upload };

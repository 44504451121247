import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { PhoneInput, PhoneInputProps, PhoneInputRefType } from "react-international-phone";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";

type PhoneNumberInputProps = PhoneInputProps & {
	condensed?: true;
	compact?: true;
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
};

export const PhoneNumberInput = forwardRef<PhoneInputRefType, PhoneNumberInputProps>(
	(props, ref) => {
		return (
			<div className="relative flex w-full h-fit">
				<PhoneInput
					ref={ref}
					{...props}
					hideDropdown
					disableDialCodeAndPrefix
					showDisabledDialCodeAndPrefix
					dialCodePreviewStyleProps={{
						className: "h-full flex items-center text-gray-400 pl-[12px] pr-[12px]",
					}}
					inputProps={{
						...props.inputProps,
						readOnly: props.readonly,
						autoComplete: "one-time-code",
						["data-1p-ignore" as any]: true,
						["data-lpignore" as any]: true,
					}}
					className={cn(
						"relative flex shrink-0 w-full h-[50px] rounded-md outline outline-[1px] outline-offset-[-1px] text-gray-900 outline-gray-300 bg-gray-50 bg-opacity-70 text-[16.5px] leading-[20px] placeholder:text-gray-500 focus-within:outline-[2px] focus-within:outline-gray-600 focus-within:hover:outline-gray-600",
						props.disabled ? "cursor-default opacity-50" : "hover:outline-gray-500",
						props.condensed
							? "h-[46px] max-h-[46px] text-[16px] font-normal"
							: props.compact
								? "h-[38px] text-[15px] font-normal"
								: undefined,
						props.className,
						props.readonly ? "opacity-80" : undefined,
						props.error
							? "outline-red-500 hover:outline-red-500 focus-within:outline-red-500 focus-within:outline-[2px]"
							: undefined,
					)}
					inputClassName={cn(
						"pr-[18px] py-[17px] w-full outline-none bg-transparent",
						props.readonly ? "bg-gray-100 text-gray-800" : undefined,
					)}
				/>
				<FormError error={props.error} errorMessage={props.errorMessage} />
			</div>
		);
	},
);

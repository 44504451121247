import { z } from 'zod';
import { nameSuffixSchema, suretyAccountStatusSchema, SuretyAccountStatus, SuretyType, suretyTypeSchema } from '../../../../enums.mjs';
import { individualContactId, firstName, lastName, partialAddressSchema, decryptedSSN, email, suretyAccountId, agencyId, companyContactId, addressSchema, fein } from '../../../../types/opaque.mjs';
import { attachmentSchema, zswitch, suretyAccountBondingLineSchema } from '../../../../types/utils.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import { ContactRelationshipConfig, Exposure } from '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import { Get as Get$5, Update as Update$1, Submit as Submit$2 } from './contract/admin-contract-surety-account.dto.mjs';
import * as adminContractSuretyAccount_dto from './contract/admin-contract-surety-account.dto.mjs';
export { adminContractSuretyAccount_dto as Contract };
import { Submit as Submit$1 } from '../../../surety/account/surety-account.dto.mjs';
import { Get as Get$1 } from '../../../surety/quote/preview/submitted/submitted-surety-quote-preview.dto.mjs';
import { Get as Get$2 } from '../../../surety/bond/preview/surety-bond-preview.dto.mjs';
import { Get as Get$3 } from '../../financial-statement/admin-financial-statement.dto.mjs';
import { Get as Get$4 } from '../../wip-statement/admin-wip-statement.dto.mjs';
import { Admin as Admin$1 } from '../../../credit-report/company-credit-report.dto.mjs';
import { OFAC, Admin } from '../../../credit-report/individual-credit-report.dto.mjs';
import * as adminSuretyAccountDraft_dto from './draft/admin-surety-account-draft.dto.mjs';
export { adminSuretyAccountDraft_dto as Draft };

var Get;
(function (Get) {
    (function (Response) {
        let Individual;
        (function (Individual) {
            Individual.schema = z.object({
                id: individualContactId,
                firstName: firstName,
                middleInitial: z.string().optional(),
                lastName: lastName,
                suffix: nameSuffixSchema.optional(),
                address: partialAddressSchema,
                ssn: decryptedSSN.optional(),
                email: email.optional(),
                phone: z.string().optional(),
                spouse: z.object({
                    id: individualContactId,
                    firstName: firstName,
                    middleInitial: z.string().optional(),
                    lastName: lastName,
                    suffix: nameSuffixSchema.optional(),
                    address: partialAddressSchema,
                    email: email.optional(),
                }).optional(),
                ownsAHome: z.boolean().optional(),
                ofacCheck: OFAC.Admin.Response.schema.optional(),
                creditReports: z.array(Admin.Response.schema),
                config: ContactRelationshipConfig.schema
            });
        })(Individual = Response.Individual || (Response.Individual = {}));
        Response.schema = z.object({
            id: suretyAccountId,
            agency: z.object({
                id: agencyId,
                displayName: z.string()
            }),
            status: suretyAccountStatusSchema.exclude([SuretyAccountStatus.draft]),
            displayName: z.string(),
            primaryCompanyId: companyContactId,
            companies: z.array(z.object({
                id: companyContactId,
                name: z.string(),
                address: addressSchema,
                fein: fein.optional(),
                creditReports: z.array(Admin$1.Response.schema),
                config: ContactRelationshipConfig.schema
            })),
            individuals: z.array(Individual.schema),
            fundNames: z.array(z.string()),
            adjustedFSR: z.number().optional(),
            averageFICO: z.number().optional(),
            quotes: z.array(Get$1.Response.schema),
            bonds: z.array(Get$2.Response.schema),
            exposure: Exposure.schema,
            financialStatements: z.array(Get$3.Response.schema),
            wipStatements: z.array(Get$4.Response.schema),
            attachments: z.array(attachmentSchema()),
            contract: Get$5.Response.schema.optional(),
            createdAt: z.string().nullable(),
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Update;
(function (Update) {
    (function (Status) {
        (function (Request) {
            Request.schema = zswitch(input => {
                switch (input.suretyType) {
                    case SuretyType.contract: return Update$1.Status.Request.schema;
                    default: return z.never();
                }
            });
        })(Status.Request || (Status.Request = {}));
    })(Update.Status || (Update.Status = {}));
    (function (BondingLine) {
        (function (Request) {
            Request.schema = z.object({
                suretyType: suretyTypeSchema,
                bondingLine: suretyAccountBondingLineSchema,
            });
        })(BondingLine.Request || (BondingLine.Request = {}));
    })(Update.BondingLine || (Update.BondingLine = {}));
})(Update || (Update = {}));
var Submit;
(function (Submit) {
    (function (Request) {
        (function (Base) {
            Base.schema = Submit$1.Request.Base.schema.and(z.object({
                options: z.object({
                    notifyFollowers: z.boolean()
                })
            }).passthrough());
        })(Request.Base || (Request.Base = {}));
        (function (Existing) {
            Existing.buildDtoValidationSchema = (formSchema) => zswitch(input => {
                const typedInput = z.object({
                    suretyType: z.literal(SuretyType.contract)
                }).safeParse(input);
                if (!typedInput.success)
                    return z.never();
                switch (typedInput.data.suretyType) {
                    case "contract":
                        return Submit$2.Request.Existing.buildDtoValidationSchema(formSchema);
                    default:
                        return z.never();
                }
            });
        })(Request.Existing || (Request.Existing = {}));
    })(Submit.Request || (Submit.Request = {}));
    (function (Response) {
        Response.schema = Get.Response.schema;
    })(Submit.Response || (Submit.Response = {}));
})(Submit || (Submit = {}));

export { Get, Submit, Update };

import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { FormSchemaInclude, FormSchemaIncludeProps } from "./FormSchemaInclude";

type FormItemProps = FormSchemaIncludeProps & {
	condensed?: true;
	compact?: true;
	className?: string;
	id?: string;
	children: ReactNode;
};

export const FormItem = (props: FormItemProps) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<div
			id={props.id}
			className={cn(
				"relative min-h-fit flex flex-1 flex-col justify-end space-y-[8px]",
				props.condensed ? "space-y-[8px]" : props.compact ? "space-y-[8px]" : undefined,
				props.className,
			)}
		>
			{props.children}
		</div>
	</FormSchemaInclude>
);

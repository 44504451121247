import { useAuth0 } from "@auth0/auth0-react";
import { AdminApp } from "./AdminApp";
import { AgentApp } from "./AgentApp";
import { useAuthenticatedUser } from "./providers/AppLoadingProvider";
import { CompleteAccountSetupView } from "./views/agent/setup/CompleteAccountSetupView";

export const AppRouter = () => {
	const { loginWithRedirect } = useAuth0();
	const { user } = useAuthenticatedUser();

	if (user.role === "admin-v2") {
		return <AdminApp />;
	} else if (user.role === "agent" || user.role === "agent-admin") {
		if (user.setupComplete) return <AgentApp />;
		return <CompleteAccountSetupView />;
	} else {
		loginWithRedirect();
	}
};

import { z } from 'zod';
import { ContractSuretyType } from '../../../../../enums.mjs';
import '../../../../../types/opaque.mjs';
import { rateSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';
import { UpdateStatus } from './contract-surety-quote-mixins.dto.mjs';
import { Approve } from '../../bond-form/contract/bid-contract-surety-bond-form-dto.mjs';

var Bid;
(function (Bid) {
    (function (UpdateStatus$1) {
        (function (Request) {
            Request.approveSchema = UpdateStatus.Request.baseApproveSchema.extend({
                contractSuretyType: z.literal(ContractSuretyType.bid),
                finalBondRate: rateSchema,
                bondForm: Approve.Request.schema.omit({ suretyType: true, contractSuretyType: true }).optional()
            });
        })(UpdateStatus$1.Request || (UpdateStatus$1.Request = {}));
    })(Bid.UpdateStatus || (Bid.UpdateStatus = {}));
})(Bid || (Bid = {}));

export { Bid };

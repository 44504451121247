import { ReactNode } from "react";
import { cn } from "../lib/utils";

export type CardProps = {
	className?: string;
	children?: ReactNode;
};

export const Card = ({ className, children }: CardProps) => {
	return (
		<div
			className={cn(
				"w-full h-fit min-h-fit border border-gray-300 rounded-md bg-white z-2",
				className,
				"relative",
			)}
		>
			{children}
		</div>
	);
};

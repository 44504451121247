import type { ContractSuretyAccountUnderwritingModelSize } from "@inrev/common";
import type { FieldValues } from "react-hook-form";
import { Icon } from "../../../../components/ui/Icon";
import { RadioCardItem } from "../../../../components/ui/RadioCardItem";
import {
	FormRadioGroup,
	type FormRadioGroupProps,
} from "../../../../components/ui/form/FormRadioGroup";

type AccountExposureSizeSelectProps<TFieldValues extends FieldValues> = Pick<
	FormRadioGroupProps<TFieldValues>,
	"name" | "control"
> & { disabledOptions?: ContractSuretyAccountUnderwritingModelSize[] };

export const AccountExposureSizeSelect = <TFieldValues extends FieldValues>({
	name,
	control,
	disabledOptions,
}: AccountExposureSizeSelectProps<TFieldValues>) => (
	<FormRadioGroup name={name} control={control} className="w-[510px]">
		<RadioCardItem value="small" disabled={disabledOptions?.includes("small")}>
			<div className="py-[20px] px-[25px] w-full h-full flex flex-col items-start space-y-[8px] leading-[20px]">
				<div className="text-[16px] text-gray-900 font-semibold leading-[18px]">Up to $500k</div>
				<div className="text-[14px] text-gray-600">Minimal eligibility details, credit score</div>
				<div className="text-[12px] text-gray-800 font-medium mt-[4px] leading-[15px] flex space-x-[6px] items-center">
					<Icon type="lightning" className="fill-inrev-yellow" />
					<span>Fastest underwriting</span>
				</div>
			</div>
		</RadioCardItem>
		<RadioCardItem value="medium" disabled={disabledOptions?.includes("medium")}>
			<div className="py-[20px] px-[25px] w-full h-full flex flex-col items-start space-y-[8px] leading-[20px]">
				<div className="text-[16px] text-gray-900 font-semibold leading-[18px]">$500k - $1M</div>
				<div className="text-[14px] text-gray-600">
					Some financial details, some eligibility details, credit score
				</div>
			</div>
		</RadioCardItem>
		<RadioCardItem value="large" disabled={disabledOptions?.includes("large")}>
			<div className="py-[20px] px-[25px] w-full h-full flex flex-col items-start space-y-[8px] leading-[20px]">
				<div className="text-[16px] text-gray-900 font-semibold leading-[18px]">More than $1M</div>
				<div className="text-[14px] text-gray-600">
					Full financial details, full eligibility details, credit score
				</div>
				<div className="text-[12px] text-gray-800 font-medium mt-[4px] leading-[15px] flex space-x-[6px] items-center">
					<Icon type="long-arrow" width={8} height={11} className="fill-inrev-green" />
					<span>Highest limits</span>
				</div>
			</div>
		</RadioCardItem>
	</FormRadioGroup>
);

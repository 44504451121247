import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { PercentInput } from "../PercentInput";

type FormPercentInputProps<TFieldValues extends FieldValues> = {
	className?: string;
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	precision?: number;
	min?: number;
	max?: number;
	condensed?: true;
	compact?: true;
	updateOnBlur?: true;
	readonly?: boolean;
};

export const FormPercentInput = <TFieldValues extends FieldValues>(
	props: FormPercentInputProps<TFieldValues>,
) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				const { value, ...fieldProps } = field;
				return (
					<PercentInput {...props} {...fieldProps} defaultValue={value} error={fieldState.error} />
				);
			}}
		/>
	);
};

import { Card } from "../../../components/ui/Card";
import { SideAnchoredTextBlock } from "../../../components/ui/SideAnchoredTextBlock";
import { AdminDataList, AdminDataListProps } from "./AdminDataList";

type AdminProjectCardProps = {
	projectDescription: string;
	data: AdminDataListProps["data"];
};

export const AdminProjectCard = ({ projectDescription, data }: AdminProjectCardProps) => {
	return (
		<Card>
			<div className="w-full px-[33px] pt-[26px] pb-[25px] flex flex-col space-y-[25px]">
				<div className="text-[18px] text-gray-800 font-semibold leading-[23px]">Project</div>
				<SideAnchoredTextBlock
					text={projectDescription}
					containerClassName="px-[5px] mx-[10px] !mb-[-10px]"
					textBlockClassName="text-[15px]"
				/>
				<AdminDataList data={data} />
			</div>
		</Card>
	);
};

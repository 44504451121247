import { z } from 'zod';
import { Get as Get$2 } from '../submitted-surety-quote-mixins.mjs';
import { Get as Get$1 } from './submitted-contract-surety-quote-mixins.dto.mjs';
import { Details } from '../../../../../types/surety/quote/submitted/contract/submitted-final-contract-surety-quote.types.mjs';

var Get;
(function (Get) {
    (function (Response) {
        const baseSchema = Get$1.Response.baseSchema
            .extend(Details.schema.omit({ underwriting: true }).shape);
        const underwrittenSchema = z.object(Get$1.Response.underwrittenSchemaShape);
        let Review;
        (function (Review) {
            Review.schema = z.object(Get$2.Response.reviewStatusShape);
        })(Review = Response.Review || (Response.Review = {}));
        let Approved;
        (function (Approved) {
            Approved.schema = underwrittenSchema.extend(Get$2.Response.approvedStatusShape);
        })(Approved = Response.Approved || (Response.Approved = {}));
        let Accepted;
        (function (Accepted) {
            Accepted.schema = underwrittenSchema
                .extend(Get$2.Response.acceptedStatusShape);
        })(Accepted = Response.Accepted || (Response.Accepted = {}));
        let Rejected;
        (function (Rejected) {
            Rejected.schema = underwrittenSchema.extend(Get$2.Response.rejectedStatusShape);
        })(Rejected = Response.Rejected || (Response.Rejected = {}));
        let Declined;
        (function (Declined) {
            Declined.schema = underwrittenSchema.partial().extend(Get$2.Response.declinedStatusShape);
        })(Declined = Response.Declined || (Response.Declined = {}));
        let Pending;
        (function (Pending) {
            Pending.schema = underwrittenSchema.extend(Get$1.Response.pendingStatusShape);
        })(Pending = Response.Pending || (Response.Pending = {}));
        let Bound;
        (function (Bound) {
            Bound.schema = underwrittenSchema.extend(Get$2.Response.boundStatusShape);
        })(Bound = Response.Bound || (Response.Bound = {}));
        Response.schema = baseSchema.and(z.discriminatedUnion("status", [
            Review.schema,
            Approved.schema,
            Accepted.schema,
            Rejected.schema,
            Declined.schema,
            Pending.schema,
            Bound.schema
        ]));
        (function (Admin) {
            let Review;
            (function (Review) {
                Review.schema = Response.Review.schema.extend(Get$1.Response.Admin.reviewStatusShape);
            })(Review = Admin.Review || (Admin.Review = {}));
            let Approved;
            (function (Approved) {
                Approved.schema = Response.Approved.schema;
            })(Approved = Admin.Approved || (Admin.Approved = {}));
            let Accepted;
            (function (Accepted) {
                Accepted.schema = Response.Accepted.schema;
            })(Accepted = Admin.Accepted || (Admin.Accepted = {}));
            let Rejected;
            (function (Rejected) {
                Rejected.schema = Response.Rejected.schema;
            })(Rejected = Admin.Rejected || (Admin.Rejected = {}));
            let Declined;
            (function (Declined) {
                Declined.schema = Response.Declined.schema;
            })(Declined = Admin.Declined || (Admin.Declined = {}));
            let Pending;
            (function (Pending) {
                Pending.schema = Response.Pending.schema;
            })(Pending = Admin.Pending || (Admin.Pending = {}));
            let Bound;
            (function (Bound) {
                Bound.schema = Response.Bound.schema;
            })(Bound = Admin.Bound || (Admin.Bound = {}));
            Admin.schema = baseSchema.extend({
                ...Get$2.Response.Admin.baseShape,
                underwriting: Details.schema.shape.underwriting
            }).and(z.discriminatedUnion("status", [
                Review.schema,
                Approved.schema,
                Accepted.schema,
                Rejected.schema,
                Declined.schema,
                Pending.schema,
                Bound.schema
            ]));
        })(Response.Admin || (Response.Admin = {}));
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

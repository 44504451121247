import { Disclosure } from "@headlessui/react";
import { HiChevronRight } from "react-icons/hi2";
import { cn } from "../../../components/lib/utils";

type AdminReviewReasonListProps = {
	reviewReasons: { description: string }[];
	buttonClassName?: string;
	reasonClassName?: string;
};

export const AdminReviewReasonList = ({
	reviewReasons,
	buttonClassName,
	reasonClassName,
}: AdminReviewReasonListProps) => (
	<Disclosure as="span">
		<Disclosure.Button
			className={cn("w-full text-left font-medium text-[16px] text-gray-900", buttonClassName)}
		>
			<div className="flex space-x-[12px] items-center">
				<HiChevronRight className="text-gray-500 text-[14px] stroke-[1.1] ui-open:rotate-90 ui-open:transform" />
				<div className="flex space-x-[8px] items-center">
					<div
						className={cn(
							"p-[5px] rounded-full text-[13px] w-[22px] h-[22px] flex items-center justify-center font-medium",
							reviewReasons.length
								? "text-[#705a02] bg-inrev-yellow bg-opacity-[.15]"
								: "bg-gray-200",
						)}
					>
						{reviewReasons.length}
					</div>
					<div>Review Reason{reviewReasons.length === 1 ? "" : "s"}</div>
				</div>
			</div>
		</Disclosure.Button>
		<Disclosure.Panel className="text-gray-500">
			<div className="flex flex-col space-y-[5px] px-[12px] pb-[10px]">
				{Array.from(new Set(reviewReasons.map((r) => r.description))).map((description, index) => (
					<div
						key={index}
						className={cn(
							"rounded border border-inrev-yellow border-opacity-70 bg-inrev-yellow bg-opacity-[.15] px-[12px] py-[8px] text-[14px] text-gray-700 font-medium",
							reasonClassName,
						)}
					>
						{description}
					</div>
				))}
			</div>
		</Disclosure.Panel>
	</Disclosure>
);

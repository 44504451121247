import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { FormSchemaInclude, FormSchemaIncludeProps } from "./FormSchemaInclude";

type FormSectionProps = FormSchemaIncludeProps & {
	header: ReactNode;
	headerClassName?: string;
	marker?: boolean;
	subHeader?: ReactNode;
	subHeaderClassName?: string;
	contentClassName?: string;
	indent?: true;
	id?: string;
	topPadding?: true;
	children?: ReactNode;
};

export const FormSection = ({
	header,
	headerClassName,
	marker,
	subHeader,
	subHeaderClassName,
	contentClassName,
	indent,
	children,
	id,
	topPadding,
	...props
}: FormSectionProps) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<div
			id={id}
			className={cn("w-full flex flex-col h-fit min-h-fit", topPadding ? "mt-[15px]" : undefined)}
		>
			<div className="w-full flex flex-col space-y-[8px]">
				<div
					className={cn(
						"relative h-fit w-full text-[17px] font-semibold text-gray-900 leading-[21px]",
						headerClassName,
					)}
				>
					{header}
					{marker && (
						<div className="absolute top-[9px] left-[-14px] border-b-[4px] border-b-gray-300 border-l-[3px] border-l-transparent border-r-[3px] border-r-transparent rotate-90"></div>
					)}
				</div>
				{subHeader && (
					<div
						className={cn(
							"text-[14px] font-normal text-gray-600 italic !mb-[5px]",
							subHeaderClassName,
						)}
					>
						{subHeader}
					</div>
				)}
			</div>
			<div
				className={cn(
					"w-full flex flex-col mt-[20px]",
					indent ? "px-[5px]" : undefined,
					contentClassName,
				)}
			>
				{children}
			</div>
		</div>
	</FormSchemaInclude>
);

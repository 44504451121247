import { z } from 'zod';
import '../../../../types/opaque.mjs';
import { zswitch } from '../../../../types/utils.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';
import { Contract } from './contract/contract-surety-quote.dto.mjs';

var UpdateStatus;
(function (UpdateStatus) {
    (function (Request) {
        Request.schema = zswitch(input => {
            switch (input.suretyType) {
                case "contract": return Contract.UpdateStatus.Request.schema;
                default: return z.never();
            }
        });
    })(UpdateStatus.Request || (UpdateStatus.Request = {}));
})(UpdateStatus || (UpdateStatus = {}));

export { UpdateStatus };

import { Dtos, SuretyBondId } from "@inrev/common";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GlobalErrorMessageModalContext } from "../../../providers/GlobalErrorHandlingProvider";
import { useRequest } from "../../../utils/request";
import { ApiError } from "../../shared/types";
import { SuretyBond } from "./types";

export const useFetchBond = (id: string, enabled: boolean = true) => {
	const { get } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const { data, error, isLoading } = useQuery({
		queryKey: ["bonds", id],
		queryFn: async () => await get<SuretyBond>(`/v2/surety/bonds/${id}`),
		retry: false,
		enabled,
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { bond: data, bondError: error, bondLoading: isLoading };
};

export const useCloseBond = (suretyBondId: SuretyBondId) => {
	const queryClient = useQueryClient();
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.SuretyBond.Close.Request): Promise<void> => {
			await post(`/v2/surety/bonds/${suretyBondId}/close`, data, "none");
		},
		onSuccess: () => {
			queryClient.refetchQueries({ queryKey: ["bonds", suretyBondId] });
			queryClient.refetchQueries({ queryKey: ["bondPreviews"] });
			queryClient.invalidateQueries({ queryKey: ["suretyAccounts"] });
			queryClient.invalidateQueries({ queryKey: ["accountPreviews"] });
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { closeBond: mutation.mutate, closeBondLoading: mutation.isLoading };
};

export const useRequestCloseBond = (suretyBondId: SuretyBondId) => {
	const { post } = useRequest();
	const { triggerErrorModal } = useContext(GlobalErrorMessageModalContext);

	const mutation = useMutation({
		mutationFn: async (data: Dtos.SuretyBond.Close.Request): Promise<void> => {
			await post(`/v2/surety/bonds/${suretyBondId}/close/request`, data, "none");
		},
		onError: (error: ApiError) => {
			console.error(error);
			triggerErrorModal(error);
		},
	});

	return { requestCloseBond: mutation.mutate, requestCloseBondLoading: mutation.isLoading };
};

import { CarrierName } from "@inrev/common";
import accelerantLogoIcon from "../assets/logos/accelerant-logo-icon-25h.png";
import accelerantLogoIcon1_5x from "../assets/logos/accelerant-logo-icon-25h@1.5x.png";
import accelerantLogoIcon2x from "../assets/logos/accelerant-logo-icon-25h@2x.png";
import inrevLogoIconDark from "../assets/logos/inrev-logo-icon-dark-25h.png";
import inrevLogoIconDark1_5x from "../assets/logos/inrev-logo-icon-dark-25h@1.5x.png";
import inrevLogoIconDark2x from "../assets/logos/inrev-logo-icon-dark-25h@2x.png";
import lnicLogoIcon from "../assets/logos/lnic-logo-icon-25h.png";
import lnicLogoIcon1_5x from "../assets/logos/lnic-logo-icon-25h@1.5x.png";
import lnicLogoIcon2x from "../assets/logos/lnic-logo-icon-25h@2x.png";

export const carrierLogoIconMap: Record<CarrierName, JSX.Element> = {
	["inrev" as CarrierName]: (
		<img
			src={inrevLogoIconDark}
			srcSet={`${inrevLogoIconDark1_5x} 1.5x, ${inrevLogoIconDark2x} 2x`}
			className="h-[25px] w-[25px] shrink-0"
		/>
	),
	["lexington-national-for-accelerant" as CarrierName]: (
		<img
			src={lnicLogoIcon}
			srcSet={`${lnicLogoIcon1_5x} 1.5x, ${lnicLogoIcon2x} 2x`}
			className="h-[25px] w-[25px] shrink-0"
		/>
	),
	["accelerant" as CarrierName]: (
		<img
			src={accelerantLogoIcon}
			srcSet={`${accelerantLogoIcon1_5x} 1.5x, ${accelerantLogoIcon2x} 2x`}
			className="h-[25px] w-[30px] shrink-0"
		/>
	),
};

export const carrierSingleLimitMap: Record<CarrierName, number> = {
	["inrev" as CarrierName]: 2500000,
	["lexington-national-for-accelerant" as CarrierName]: 2500000,
	["accelerant" as CarrierName]: 2500000,
};

export const carrierAggregateLimitMap: Record<CarrierName, number> = {
	["inrev" as CarrierName]: 5000000,
	["lexington-national-for-accelerant" as CarrierName]: 5000000,
	["accelerant" as CarrierName]: 5000000,
};

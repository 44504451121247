import { ReactNode } from "react";
import { cn } from "../../lib/utils";
import { FormSchemaInclude, FormSchemaIncludeProps } from "./FormSchemaInclude";

type FormRowProps = FormSchemaIncludeProps & {
	className?: string;
	children: ReactNode;
};

export const FormRow = ({ className, children, ...props }: FormRowProps) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<div className={cn("w-full flex gap-x-[22px] justify-center", className)}>{children}</div>
	</FormSchemaInclude>
);

import { Create as Create$1, Update as Update$1 } from './contract/admin-contract-surety-account-draft.dto.mjs';
import * as adminContractSuretyAccountDraft_dto from './contract/admin-contract-surety-account-draft.dto.mjs';
export { adminContractSuretyAccountDraft_dto as Contract };

var Create;
(function (Create) {
    (function (Request) {
        Request.schema = Create$1.Request.schema;
    })(Create.Request || (Create.Request = {}));
    (function (Response) {
        Response.schema = Create$1.Response.schema;
    })(Create.Response || (Create.Response = {}));
})(Create || (Create = {}));
var Update;
(function (Update) {
    (function (Request) {
        Request.schema = Update$1.Request.schema;
    })(Update.Request || (Update.Request = {}));
    (function (Response) {
        Response.schema = Update$1.Response.schema;
    })(Update.Response || (Update.Response = {}));
})(Update || (Update = {}));

export { Create, Update };

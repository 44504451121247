import { ComponentType, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableLayoutProps } from "../../../components/layout/TableLayout";
import { DataTable } from "../../../components/ui/DataTable/DataTable";
import { Dropdown } from "../../../components/ui/Dropdown";
import { SearchInput } from "../../../components/ui/SearchInput";
import { BondRequestPreview } from "../../../domain/agent/request/types";
import { useFetchBondRequestPreviews } from "../../../domain/shared/previews/api";
import { requestsTableColumns } from "./columns";

type RequestsViewProps = {
	tableLayout: ComponentType<Omit<TableLayoutProps, "includeHeaderActionButtons">>;
};

export const RequestsView = (props: RequestsViewProps) => {
	const navigate = useNavigate();
	const [statusFilterValue, setStatusFilterValue] = useState<string>("all");
	const [statusColumnFilter, setStatusColumnFilter] = useState<
		({ id: "status"; value: string } | { id: "archived"; value: boolean })[]
	>([{ id: "archived", value: false }]);
	const [searchString, setSearchString] = useState<string>("");
	const { bondRequestPreviews } = useFetchBondRequestPreviews();

	useEffect(() => {
		if (statusFilterValue === "all" || statusFilterValue === null) {
			setStatusColumnFilter([{ id: "archived", value: false }]);
		} else if (statusFilterValue === "archived") {
			setStatusColumnFilter([{ id: "archived", value: true }]);
		} else
			setStatusColumnFilter([
				{ id: "status", value: statusFilterValue },
				{ id: "archived", value: false },
			]);
	}, [statusFilterValue]);

	const handleRowClick = (row: BondRequestPreview) => {
		navigate(`/requests/${row.id}`);
	};

	return (
		<props.tableLayout
			title={
				<div className="w-full h-full">
					<div className="flex items-center h-full space-x-[25px]">
						<div className="leading-[23px]">Bond Requests</div>
						<div className="flex items-center space-x-[12px]">
							<SearchInput
								className="w-[250px] bg-gray-50 border border-gray-300 focus-within:outline-gray-400 focus-within:shadow-none"
								inputClassName="placeholder:text-gray-500"
								placeholder="Search bond requests..."
								onChange={(value) => setSearchString(value)}
							/>
							<Dropdown
								value={statusFilterValue}
								options={[
									{ value: "all", label: "All" },
									{ value: "draft", label: "Draft" },
									{ value: "review", label: "Review" },
									{ value: "approved", label: "Approved" },
									{ value: "declined", label: "Declined" },
									{ value: "accepted", label: "Accepted" },
									{ value: "rejected", label: "Rejected" },
									{ value: "pending", label: "Pending" },
									{ value: "archived", label: "Archived" },
								]}
								buttonClassName="gap-[3px] w-fit h-[33px] mb-[1px] py-[5px] pl-[12px] pr-[8px] text-[12px] font-medium text-gray-800 border outline-gray-300 bg-gray-50 bg-opacity-100 hover:brightness-[.98] data-[headlessui-state=open]:border-gray-500 data-[headlessui-state=open]:brightness-[.94] rounded hover:rounded-0"
								buttonIconClassName="text-[13px] text-gray-400"
								optionsClassName="divide-y divide-gray-100/80 shadow-[rgba(0,0,0,0.05)_0px_0px_4px,_rgba(0,0,0,0.08)_0px_2px_8px] outline-gray-500"
								optionClassName="text-[13px] font-medium py-[8px] text-gray-700 hover:bg-gray-100/60"
								onChange={setStatusFilterValue}
							/>
						</div>
					</div>
				</div>
			}
		>
			<div className="w-full h-full flex flex-col space-y-[8px]">
				<div className="w-full flex-1">
					<DataTable
						name="bond_requests"
						columnDefs={requestsTableColumns}
						columnVisibility={{
							archived: false,
						}}
						onRowClick={handleRowClick}
						columnFilters={statusColumnFilter}
						searchString={searchString}
						data={bondRequestPreviews}
						loadingMessage="Loading bond requests..."
					/>
				</div>
			</div>
		</props.tableLayout>
	);
};

import { FileExtension } from "@inrev/common";
import { lookup } from "mrmime";
import { useEffect, useState } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { HiOutlineDocumentPlus } from "react-icons/hi2";
import { Modal } from "../layout/Modal";
import { cn } from "../lib/utils";
import { Button } from "./Button";

type DropzoneProps = {
	allowedExtensions?: FileExtension[];
	onSuccess: (file: File[]) => void;
	className?: string;
	disabled?: boolean;
};

const formattedFileExtensions = Object.values(FileExtension).map((ext) => `.${ext}`);

const getMimeTypes = (allowedExtensions?: FileExtension[]) => {
	const mimeMap: Record<string, string[]> = {};

	(allowedExtensions ?? formattedFileExtensions).forEach((extension) => {
		const mime = lookup(extension);
		if (!mime) throw new Error(`mime not found for extension ${extension}`);
		if (!mimeMap[mime]) mimeMap[mime] = [`.${extension}`];
		else mimeMap[mime].push(`.${extension}`);
	});

	return mimeMap;
};

export const Dropzone = ({ allowedExtensions, onSuccess, className, disabled }: DropzoneProps) => {
	const [dropError, setDropError] = useState<ErrorCode | undefined>();
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

	const handleDrop = (acceptedFiles: File[], rejections: FileRejection[]) => {
		let invalidFileType = false;
		let fileTooLarge = false;

		rejections.forEach((rejection) =>
			rejection.errors.forEach((error) => {
				if (error.code === ErrorCode.FileInvalidType) invalidFileType = true;
				else if (error.code === ErrorCode.FileTooLarge) fileTooLarge = true;
			}),
		);

		if (invalidFileType) {
			setDropError(ErrorCode.FileInvalidType);
		} else if (fileTooLarge) {
			setDropError(ErrorCode.FileTooLarge);
		} else {
			onSuccess(acceptedFiles);
		}
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleDrop,
		maxSize: 30000000,
		accept: getMimeTypes(allowedExtensions),
		disabled,
	});

	useEffect(() => {
		if (dropError) setShowErrorMessage(true);
	}, [dropError]);

	useEffect(() => {
		if (!showErrorMessage) setDropError(undefined);
	}, [showErrorMessage]);

	return (
		<div
			{...getRootProps()}
			className={cn(
				"w-full h-[85px] flex items-center justify-center space-x-[10px] bg-gray-50 bg-opacity-[.65] rounded-md border-dashed border-[2px] border-gray-300 text-[14px] text-gray-600 select-none cursor-pointer hover:bg-gray-100 hover:bg-opacity-60",
				className,
				disabled ? "opacity-50 hover:bg-gray-50 hover:bg-opacity-[.65] cursor-default" : undefined,
				isDragActive ? "bg-gray-100 border-gray-500" : undefined,
			)}
		>
			<input {...getInputProps()} className="hidden" />
			<HiOutlineDocumentPlus className="text-[18px]" />
			<div>Drag and drop or click to select a file</div>
			{showErrorMessage && (
				<Modal onClickOutside={() => setShowErrorMessage(false)}>
					<div className="pt-[30px] pb-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[35px]">
						<div className="flex flex-col items-center space-y-[15px]">
							<div className="text-[19px] text-gray-900 font-medium w-full flex">
								{dropError === ErrorCode.TooManyFiles && "Too many files"}
								{dropError === ErrorCode.FileInvalidType && "Invalid file type"}
								{dropError === ErrorCode.FileTooLarge && "File too large"}
							</div>
							<div className="text-[16px] text-gray-600 font-normal">
								{dropError === ErrorCode.TooManyFiles && "Please upload one file at a time"}
								{dropError === ErrorCode.FileInvalidType &&
									`Please upload a file that is one of the following types: ${(allowedExtensions ?? formattedFileExtensions).join(", ")}`}
								{dropError === ErrorCode.FileTooLarge &&
									"Please upload a file that is smaller than 30MB"}
							</div>
						</div>
						<Button
							color="gray"
							className="w-[125px]"
							filled
							onClick={() => setShowErrorMessage(false)}
						>
							OK
						</Button>
					</div>
				</Modal>
			)}
		</div>
	);
};

import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { Card, CardProps } from "./Card";

export type CardGrayBodyProps = CardProps & {
	header: ReactNode;
	headerClassName?: string;
	contentClassName?: string;
	hideBody?: boolean;
};

export const CardGrayBody = ({
	className,
	header,
	headerClassName,
	contentClassName,
	hideBody,
	children,
}: CardGrayBodyProps) => (
	<Card className={cn("flex flex-col", className)}>
		<div
			className={cn(
				"w-full px-[33px] pt-[26px] pb-[25px] bg-white border-b border-b-gray-300 rounded-t-md",
				headerClassName,
				hideBody ? "border-b-0 rounded-b-md" : undefined,
			)}
		>
			{header}
		</div>
		{!hideBody && (
			<div
				className={cn("w-full h-fit min-h-fit flex-1 rounded-b-md bg-gray-50", contentClassName)}
			>
				{children}
			</div>
		)}
	</Card>
);

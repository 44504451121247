import { ReactNode } from "react";
import { FormSchemaInclude, FormSchemaIncludeProps } from "../layout/form/FormSchemaInclude";
import { StackedCardProps } from "./StackedCard";
import { StackedCardGrayBody } from "./StackedCardGrayBody";

type LineItemCard = FormSchemaIncludeProps &
	StackedCardProps & {
		header: ReactNode;
		id?: string;
	};

export const LineItemStackedCard = ({
	header,
	children,
	className,
	id,
	...props
}: LineItemCard) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<div id={id} className="w-full h-fit pr-[6px]">
			<StackedCardGrayBody className={className} header={header} children={children} />
		</div>
	</FormSchemaInclude>
);

import { Data as Data$1 } from '../../issued/contract/issued-contract-surety-bond-form.types.mixins.mjs';

var Data;
(function (Data) {
    (function (Attributes) {
        Attributes.baseSchema = Data$1.Attributes.baseSchema;
    })(Data.Attributes || (Data.Attributes = {}));
})(Data || (Data = {}));

export { Data };

import { ReactNode } from "react";
import { cn } from "../lib/utils";
import { Icon } from "./Icon";

type ActionCardProps = {
	className?: string;
	children?: ReactNode;
};

export const ActionCard = ({ className, children }: ActionCardProps) => (
	<div
		className={cn(
			"flex space-x-[24px] items-center w-full px-[28px] py-[22px] border border-opacity-40 border-gray-500 bg-gray-100 rounded",
			className,
		)}
	>
		<Icon type="action-required" className="h-[25px] self-start" />
		<div className="text-gray-900 text-[15px]">{children}</div>
	</div>
);

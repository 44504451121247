import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState?: AppState) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	let auth0Domain: string;
	let auth0ClientId: string;
	let auth0Audience: string;
	if (
		import.meta.env.VITE_AUTH0_DOMAIN &&
		import.meta.env.VITE_AUTH0_CLIENT_ID &&
		import.meta.env.VITE_AUTH0_AUDIENCE
	) {
		auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
		auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
		auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE;
	} else {
		throw new Error("Auth environment variable are not set");
	}

	return (
		<Auth0Provider
			domain={auth0Domain}
			clientId={auth0ClientId}
			authorizationParams={{
				audience: auth0Audience,
				redirect_uri: `${window.location.origin}/`,
			}}
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</Auth0Provider>
	);
};

import { RadioGroup, RadioOptionProps } from "@headlessui/react";
import { ReactNode } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { cn } from "../../lib/utils";

// type FormRadioProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & { formState: FormState<any> };

export type FormRadioGroupItemProps<TType> = RadioOptionProps<"div", TType> & {
	children: ReactNode;
	className?: string;
};

export type FormRadioGroupProps<TFieldValues extends FieldValues> = {
	control: Control<TFieldValues>;
	name: Path<TFieldValues>;
	className?: string;
	children?: ReactNode;
};

export const FormRadioGroup = <TFieldValues extends FieldValues>({
	name,
	control,
	className,
	children,
}: FormRadioGroupProps<TFieldValues>) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<div>
					{error && <p className="text-red-500">{error.message}</p>}
					<RadioGroup
						{...field}
						className={cn(
							"grid gap-[10px] w-fit min-h-fit rounded-md focus:outline focus:outline-[2px] focus:outline-offset-[5px] focus:outline-gray-400",
							className,
						)}
					>
						{children}
					</RadioGroup>
				</div>
			)}
		/>
	);
};

import { z } from 'zod';
import { suretyTypeSchema, IndemnityAgreementSignatureMethod } from '../../../enums.mjs';
import { electronicCompanySignerSchema, electronicIndividualSignerSchema, physicalCompanySignerSchema, physicalIndividualSignerSchema } from '../../../types/surety/indemnity/indemnity-agreement.mjs';

var Create;
(function (Create) {
    const baseSchema = z.object({
        suretyType: suretyTypeSchema
    });
    (function (Request) {
        const electronicSchema = baseSchema.extend({
            signatureMethod: z.literal(IndemnityAgreementSignatureMethod.electronic),
            companySigners: z.array(electronicCompanySignerSchema),
            individualSigners: z.array(electronicIndividualSignerSchema).superRefine((val, ctx) => {
                const emailIndexMap = {};
                const duplicateEmails = new Set();
                val.forEach((individualSigner, index) => {
                    if (emailIndexMap[individualSigner.signerEmail]?.length) {
                        duplicateEmails.add(individualSigner.signerEmail);
                        emailIndexMap[individualSigner.signerEmail]?.push(index);
                    }
                    else {
                        emailIndexMap[individualSigner.signerEmail] = [index];
                    }
                });
                let errorIndexes = [];
                let emailIndexes;
                duplicateEmails.forEach(email => {
                    emailIndexes = emailIndexMap[email];
                    if (emailIndexes === undefined)
                        throw new Error("Unable to check individual indemnitor email uniqueness");
                    errorIndexes = [...errorIndexes, ...emailIndexes];
                });
                errorIndexes.forEach(errorIndex => {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Individual signers must have a unique email",
                        path: [
                            errorIndex,
                            "signerEmail"
                        ]
                    });
                });
            })
        });
        const physicalSchema = baseSchema.extend({
            signatureMethod: z.literal(IndemnityAgreementSignatureMethod.physical),
            companySigners: z.array(physicalCompanySignerSchema),
            individualSigners: z.array(physicalIndividualSignerSchema)
        });
        Request.schema = z.discriminatedUnion("signatureMethod", [electronicSchema, physicalSchema]);
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create };

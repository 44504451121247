import { z } from 'zod';
import { Details as Details$1 } from './submitted-bid-contract-surety-quote.types.mjs';
import * as submittedBidContractSuretyQuote_types from './submitted-bid-contract-surety-quote.types.mjs';
export { submittedBidContractSuretyQuote_types as Bid };
import { Details as Details$2 } from './submitted-bid-to-final-contract-surety-quote.types.mjs';
import * as submittedBidToFinalContractSuretyQuote_types from './submitted-bid-to-final-contract-surety-quote.types.mjs';
export { submittedBidToFinalContractSuretyQuote_types as BidToFinal };
import { Details as Details$3 } from './submitted-final-contract-surety-quote.types.mjs';
import * as submittedFinalContractSuretyQuote_types from './submitted-final-contract-surety-quote.types.mjs';
export { submittedFinalContractSuretyQuote_types as Final };

var Details;
(function (Details) {
    Details.schema = z.discriminatedUnion("contractSuretyType", [
        Details$1.schema,
        Details$2.schema,
        Details$3.schema,
    ]);
})(Details || (Details = {}));

export { Details };

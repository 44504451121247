import { FileExtension, FileId, FileType, SuretyBondFormType } from "@inrev/common";
import { useEffect, useMemo, useState } from "react";
import { FieldError } from "react-hook-form";
import { HiOutlineBookOpen, HiXMark } from "react-icons/hi2";
import { v4 } from "uuid";
import { bondFormTypeLabelMap } from "../../constants";
import { useFileUpload } from "../../domain/agent/request/api";
import { BondFormTemplate, BondFormTemplatePreview } from "../../domain/agent/request/types";
import { BondFormData, UploadFile } from "../../types";
import { getNameAndExtensionFromFileName } from "../../utils";
import { useRequest } from "../../utils/request";
import { ConfirmationModalClickWrapper } from "../layout/ConfirmationModalClickWrapper";
import { Modal } from "../layout/Modal";
import { ModalItemWithHeader } from "../layout/ModalItemWithHeader";
import { FormError } from "../layout/form/FormError";
import { FormSection } from "../layout/form/FormSection";
import { cn } from "../lib/utils";
import { AttachmentTypeMultiSelect } from "./AttachmentTypeMultiSelect";
import { Button } from "./Button";
import { Dropzone } from "./Dropzone";
import { FileUploadItem, PendingFileUploadItem } from "./FileUploadItem";

export type BondFormSelectProps = {
	value: BondFormData;
	onChange: (value: BondFormData) => void;
	onBlur: () => void;
	onDownload: (id: string) => void;
	bondFormTemplates: BondFormTemplate[];
	allowedBondFormUploadTypesAndLabels: Partial<Record<SuretyBondFormType, string>>;
	header?: string;
	marker?: boolean;
	error?: FieldError;
	id?: string;
};

export const BondFormSelect = ({
	value: bondFormData,
	onChange,
	onBlur,
	onDownload,
	bondFormTemplates,
	allowedBondFormUploadTypesAndLabels,
	header,
	marker,
	error,
	id,
}: BondFormSelectProps) => {
	const [[showLibrary, showUploadTypeSelect], setShow] = useState<[boolean, boolean]>([
		false,
		false,
	]);

	const selectedTemplate = useMemo(
		() => bondFormTemplates.find((template) => template.id === bondFormData.templateId),
		[bondFormData],
	);
	const [filesPendingUpload, setFilesPendingUpload] = useState<
		UploadFile<typeof FileType.blank_bond_form>[]
	>([]);
	const [fileIdsPendingDelete, setFileIdsPendingDelete] = useState<string[]>([]);
	const filesUploaded = bondFormData.upload.files.length > 0 || filesPendingUpload.length > 0;
	const [filesPendingTypeSelection, setFilesPendingTypeSelection] = useState<File[]>([]);
	const [formType, setFormType] = useState<SuretyBondFormType | undefined>();
	const bondFormUploadType = useMemo(() => bondFormData.upload.type, [bondFormData.upload.type]); // Used to break closure in useFileUpload onSuccess cb
	const allowedTypes = useMemo(
		() => Object.keys(allowedBondFormUploadTypesAndLabels) as unknown as SuretyBondFormType[],
		[allowedBondFormUploadTypesAndLabels],
	);
	const { get } = useRequest();

	const typesAndLabelsForTypeSelect = useMemo(() => {
		let typesAndLabels: Record<string, string> = {};
		allowedTypes.forEach((type) => {
			if (allowedBondFormUploadTypesAndLabels[type]) {
				typesAndLabels[type] = allowedBondFormUploadTypesAndLabels[type] as string;
			}
		});
		const map = new Map([["Bond Form Type", typesAndLabels]]);
		return map;
	}, [allowedBondFormUploadTypesAndLabels]);

	const { uploadFn: uploadFile, deleteFn: deleteFile } = useFileUpload<
		typeof FileType.blank_bond_form
	>({
		onUploadSuccess: (data, args) =>
			((
				data: UploadFile<typeof FileType.blank_bond_form>,
				args: { pendingFile: UploadFile<typeof FileType.blank_bond_form> },
			) => {
				setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
				onChange({
					templateId: "",
					upload: { type: bondFormUploadType, files: [...bondFormData.upload.files, data] },
				});
			})(data, args),
		onUploadError: (error, args) => {
			console.error(error);
			if (bondFormData.upload.files.length + filesPendingUpload.length === 1) {
				setFilesPendingUpload([]);
				onChange({ templateId: "", upload: { type: "", files: [] } });
			} else {
				setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
			}
		},
		onDeleteSuccess: (args) => {
			if (bondFormData.upload.files.length + filesPendingUpload.length === 1) {
				onChange({ templateId: "", upload: { type: "", files: [] } });
			} else {
				onChange({
					templateId: "",
					upload: {
						...bondFormData.upload,
						files: bondFormData.upload.files.filter((file) => file.id !== args.id),
					},
				});
			}
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
		},
		onDeleteError: (error, args) => {
			console.error(error);
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
		},
		onUpdateSuccess: (_data, _args) => {
			// no updates allowed
		},
		onUpdateError: (_error, _args) => {
			// no updates allowed
		},
	});

	const handleUpload = (dropzoneFiles: File[]) => {
		const filesToBeTyped: File[] = [];
		dropzoneFiles.forEach((file) => {
			const { fileName, extension } = getNameAndExtensionFromFileName(file.name);
			if (bondFormData.upload.files.length) {
				// Skip type selection if there is already a file uploaded
				const uploadArgs: {
					rawFile: File;
					pendingFile: BondFormData["upload"]["files"][number];
				} = {
					rawFile: file,
					pendingFile: {
						id: v4() as FileId,
						name: fileName,
						extension,
						types: [FileType.blank_bond_form],
					},
				};
				onChange({
					templateId: "",
					upload: { ...bondFormData.upload, type: allowedTypes[0] },
				});
				setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
				uploadFile(uploadArgs);
			} else {
				filesToBeTyped.push(file);
			}
		});
		if (filesToBeTyped.length > 0) {
			setFilesPendingTypeSelection(filesToBeTyped);
		}
	};

	const handleDelete = (id: string) => {
		setFileIdsPendingDelete([...fileIdsPendingDelete, id]);
		deleteFile({ id });
	};

	const handleUploadTypeSelection = (formType: typeof allowedTypes) => {
		const type = formType[0];
		setFormType(type);
	};

	const handleTemplateSelect = (id: string) => {
		onChange({ templateId: id, upload: { type: "", files: [] } });
	};

	const handleTemplateDeselect = () => {
		onChange({ templateId: "", upload: { type: "", files: [] } });
	};

	const onClose = () => {
		setShow([false, false]);
	};

	useEffect(() => {
		onBlur();
	}, [bondFormData]);

	useEffect(() => {
		if (formType && filesPendingTypeSelection.length > 0) {
			filesPendingTypeSelection.forEach((file) => {
				const { fileName, extension } = getNameAndExtensionFromFileName(file.name);
				const uploadArgs: { rawFile: File; pendingFile: BondFormData["upload"]["files"][number] } =
					{
						rawFile: file,
						pendingFile: {
							id: v4() as FileId,
							name: fileName,
							extension,
							types: [FileType.blank_bond_form],
						},
					};
				onChange({ templateId: "", upload: { ...bondFormData.upload, type: formType } });
				setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
				uploadFile(uploadArgs);
			});
			setFormType(undefined);
			setFilesPendingTypeSelection([]);
			onClose();
		}
	}, [formType, filesPendingTypeSelection]);

	useEffect(() => {
		if (filesPendingTypeSelection.length > 0) {
			setShow([false, true]);
		}
	}, [filesPendingTypeSelection]);

	return (
		<FormSection
			header={header ?? "Bond Form"}
			marker={marker}
			subHeader={
				!selectedTemplate && !filesUploaded
					? "Please select a bond form from the library, or upload your own"
					: undefined
			}
			topPadding
		>
			<div
				className={cn(
					"flex flex-col space-y-[30px] max-w-[500px]",
					error ? "relative rounded-md outline outline-offset-[10px] outline-red-500" : undefined,
				)}
				id={id}
			>
				{!selectedTemplate && !filesUploaded && (
					<div className="flex items-center space-x-[15px] text-[12px] text-gray-600 font-medium">
						<Button
							className="ml-[-5px] w-fit"
							color="gray"
							rounded
							filled
							thinFont
							onClick={() => setShow([true, false])}
						>
							<div className="flex min-w-fit items-center space-x-[8px]">
								<HiOutlineBookOpen className="text-[17px] text-gray-800 stroke-[2px]" />
								<div className="min-w-fit">Select a Bond Form</div>
							</div>
						</Button>
						<span>- OR -</span>
					</div>
				)}
				{selectedTemplate && (
					<div className="min-h-[50px] w-[450px] break-words flex items-center space-x-[25px] p-[15px] bg-white border border-gray-200 rounded-[5px] shadow-sm text-[15px] text-gray-900">
						<HiOutlineBookOpen className="text-[25px] text-gray-700" />
						<div className="flex-1 flex flex-col space-y-[2px]">
							<span className="font-medium">{selectedTemplate.name}</span>
							<span className="text-[13px] text-gray-500">
								{allowedBondFormUploadTypesAndLabels[selectedTemplate.type]} Bond
							</span>
						</div>
						<ConfirmationModalClickWrapper
							message={
								<div className="w-[375px] text-center break-words">
									Are you sure you want to remove
									<br />
									{selectedTemplate.name}?
								</div>
							}
							className="items-center"
							confirmButtonText="Remove"
							confirmationButtonClassName="border-[1px] border-gray-700 bg-white text-red-500"
							onConfirm={handleTemplateDeselect}
						>
							<HiXMark className="text-[19px] stroke-[2px] text-gray-400 hover:text-gray-800 cursor-pointer" />
						</ConfirmationModalClickWrapper>
					</div>
				)}
				{!selectedTemplate && (
					<div className="w-full flex flex-col space-y-[15px]">
						<Dropzone
							allowedExtensions={[
								FileExtension.pdf,
								FileExtension.png,
								FileExtension.jpg,
								FileExtension.jpeg,
								FileExtension.gif,
								FileExtension.docx,
								FileExtension.doc,
							]}
							onSuccess={handleUpload}
						/>
						{bondFormData.upload.type !== "" && (
							<div className="w-full max-w-full flex flex-col space-y-[10px] cursor-default">
								{bondFormData.upload.files.map((file) => (
									<FileUploadItem
										key={`uploaded:${file.id}`}
										{...file}
										types={bondFormUploadType === "" ? [] : [bondFormUploadType]}
										disablePDFViewer={true}
										typesConfirmedByAdmin={false}
										typeLabelMap={allowedBondFormUploadTypesAndLabels}
										onDelete={handleDelete}
										disableTypeSelection={true}
										onDownload={() => onDownload(file.id)}
										deleting={fileIdsPendingDelete.includes(file.id)}
									/>
								))}
								{filesPendingUpload.map((file) => (
									<PendingFileUploadItem
										key={`pending:${file.id}`}
										{...file}
										types={bondFormUploadType === "" ? [] : [bondFormUploadType]}
										typeLabelMap={allowedBondFormUploadTypesAndLabels}
									/>
								))}
							</div>
						)}
					</div>
				)}
				{error && <FormError error={error} className="!m-0 top-[-19px]" />}
			</div>
			{showLibrary && (
				<Modal onClickOutside={onClose} itemClassName="mb-[5%]">
					<ModalItemWithHeader
						header={
							<div className="flex items-center space-x-[8px]">
								<HiOutlineBookOpen className="text-[17px] stroke-[2px] text-gray-600" />
								<div>Bond Form Library</div>
							</div>
						}
						className="w-[550px] top-1/4 bottom-1/4"
						onClose={onClose}
					>
						<div className="w-full max-h-[85vh] pt-[20px] pb-[35px] flex">
							<div className="w-full max-h-full flex flex-col space-y-[15px] px-[35px] overflow-y-auto">
								<div className="w-full text-[14px] text-gray-600">
									Select from {bondFormTemplates.length} bond form template
									{bondFormTemplates.length === 1 ? "" : "s"}
								</div>
								<div className="w-full h-full flex flex-col space-y-[10px]">
									{bondFormTemplates.map((template) => (
										<div
											key={template.id}
											className="w-full flex-shrink-0 flex items-center rounded-sm bg-gray-50 border border-gray-200 px-[20px] py-[15px]"
										>
											<div className="flex-1 flex flex-col space-y-[10px]">
												<div className="flex flex-col space-y-[3px]">
													<div className="text-[15px] text-gray-800 font-semibold">
														{template.name}
													</div>
													<div className="text-[14px] text-gray-500">
														{bondFormTypeLabelMap[template.type]}
													</div>
												</div>
												<div className="flex spaxe-x-[5px]">
													{template.tags.map((tag, index) => (
														<div
															key={index}
															className="px-[8px] py-[3px] bg-white rounded-sm border border-gray-300 text-[13px] text-gray-700 font-medium"
														>
															{tag}
														</div>
													))}
												</div>
											</div>
											<div className="flex flex-col space-y-[5px]">
												{/* <a href={template.url} target="_blank"> */}
												<Button
													color="gray"
													onClick={async () => {
														try {
															const data = await get<BondFormTemplatePreview>(
																`/v2/surety/bond-forms/templates/${template.id}/preview`,
															);
															window.open(data.url, "_blank");
														} catch (error) {
															console.error(`Failed to fetch url ${error}`);
														}
													}}
													thinFont
												>
													Preview
												</Button>
												{/*</a>*/}
												<Button
													color="light-blue"
													filled
													thinFont
													onClick={() => {
														handleTemplateSelect(template.id);
														onClose();
													}}
												>
													Select
												</Button>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</ModalItemWithHeader>
				</Modal>
			)}
			{showUploadTypeSelect && (
				<AttachmentTypeMultiSelect
					availableTypes={allowedTypes}
					groupMap={typesAndLabelsForTypeSelect}
					initialTypes={[]}
					title={"Select Bond Form Type"}
					expandedByDefault={true}
					selectOne={true}
					onSave={handleUploadTypeSelection}
					close={onClose}
				/>
			)}
		</FormSection>
	);
};

import { Dtos } from "@inrev/common";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { AdminUpdateAccountStatusFormCard } from "./AdminUpdateAccountStatusForm";

type AdminUpdateAccountStatusModalProps = {
	account: AdminSuretyAccount;
	handleSubmit: (data: Dtos.Admin.SuretyAccount.Update.Status.Request) => void;
	onClose: () => void;
};

export const AdminUpdateAccountStatusModal = ({
	account,
	handleSubmit,
	onClose,
}: AdminUpdateAccountStatusModalProps) => {
	const _handleSubmit = (data: Dtos.Admin.SuretyAccount.Update.Status.Request) => {
		handleSubmit(data);
		onClose();
	};

	return (
		<Modal onClickOutside={onClose} itemClassName="m-[50px]">
			<ModalItemWithHeader
				header="Update Account Analysis & Bonding Lines"
				onClose={onClose}
				className="w-[900px]"
				headerClassName="bg-white"
				bodyClassName="w-full p-[30px] pt-[25px] flex grid-auto-rows space-x-[40px] bg-gray-50"
			>
				<AdminUpdateAccountStatusFormCard
					account={account}
					showScore={false}
					showBondingLinesWithoutStatus={false}
					handleSubmit={_handleSubmit}
				/>
			</ModalItemWithHeader>
		</Modal>
	);
};

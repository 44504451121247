import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { SearchableSelectList, SearchableSelectListProps } from "../SearchableSelectList";

type FormSearchableSelectListProps<TFieldValues extends FieldValues> = Omit<
	SearchableSelectListProps,
	"value" | "onChange" | "onBlur"
> & {
	control: Control<TFieldValues>;
	name: Path<TFieldValues>;
};

export const FormSearchableSelectList = <TFieldValues extends FieldValues>({
	control,
	name,
	...props
}: FormSearchableSelectListProps<TFieldValues>) => (
	<Controller
		control={control}
		name={name}
		render={({ field }) => <SearchableSelectList {...field} {...props} />}
	/>
);

import { zodResolver } from "@hookform/resolvers/zod";
import {
	City,
	CompanyContactId,
	Dtos,
	FEIN,
	StreetAddress,
	USStateOrTerritory,
	ValidAddress,
	ZipCode,
	fein,
} from "@inrev/common";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAddressValidation } from "../../../../api";
import { Modal } from "../../../../components/layout/Modal";
import { ModalItemWithHeader } from "../../../../components/layout/ModalItemWithHeader";
import { FormItem } from "../../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../../components/layout/form/FormRow";
import { Button } from "../../../../components/ui/Button";
import { FormAddressAutocomplete } from "../../../../components/ui/form/FormAddressAutocomplete";
import { FormInput } from "../../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../../components/ui/form/FormItemLabel";
import { FormQuestionLabel } from "../../../../components/ui/form/FormQuestionLabel";
import { FormYesNo } from "../../../../components/ui/form/FormYesNo";
import { useAdminUpsertCompanyContact } from "../../../../domain/admin/account/api";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";
import { formatAddress } from "../../../../utils";
import { nullEmptyResolver } from "../../../../utils/form";
import { numericStringSchema, uniqueSchema } from "../../../../validation";

export type DefaultAdminCompanyFormData = Omit<
	Dtos.Admin.CompanyContact.Create.Request,
	"includeInUnderwriting" | "includeInIndemnity"
> & {
	includeInUnderwriting: boolean | "";
	includeInIndemnity: boolean | "";
};

const getDefaultFormData = (): DefaultAdminCompanyFormData => ({
	name: "",
	fein: "" as FEIN,
	address: {
		street: "" as StreetAddress,
		city: "" as City,
		state: "" as USStateOrTerritory,
		zip: "" as ZipCode,
	} as ValidAddress,
	includeInIndemnity: "",
	includeInUnderwriting: "",
});

const selectedToFormData = (
	selectedCompany: AdminSuretyAccount["companies"][number],
): AdminCompanyFormData => ({
	name: selectedCompany.name,
	fein: selectedCompany.fein,
	address: selectedCompany.address as ValidAddress,
	includeInIndemnity: selectedCompany.config.contract.includeInIndemnity,
	includeInUnderwriting: selectedCompany.config.contract.includeInUnderwriting,
});

export const getCompanyFormSchema = (feins: FEIN[]) =>
	Dtos.Admin.CompanyContact.Create.Request.schema
		.extend({
			fein: uniqueSchema(feins, numericStringSchema.nullish(), "FEIN already used").pipe(
				fein.nullish(),
			),
		})
		.superRefine((val, ctx) => {
			if (!val.includeInIndemnity && !val.includeInUnderwriting) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ["includeInIndemnity"],
					message: "Company must be included in underwriting or indemnity",
				});
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ["includeInUnderwriting"],
					message: "Company must be included in underwriting or indemnity",
				});
			}
		});
export type AdminCompanyFormData = z.infer<ReturnType<typeof getCompanyFormSchema>>;

type AdminCompanyModalProps = {
	account: AdminSuretyAccount;
	selectedCompanyId?: CompanyContactId;
	onClose: () => void;
};

export const AdminCompanyModal = ({
	account,
	selectedCompanyId,
	onClose,
}: AdminCompanyModalProps) => {
	const { upsertCompanyContact, upsertCompanyContactIsLoading, upsertCompanyContactSuccess } =
		useAdminUpsertCompanyContact(account.id, selectedCompanyId);
	const selectedCompany =
		selectedCompanyId !== undefined
			? account.companies.find((company) => company.id === selectedCompanyId)
			: undefined;
	const feins = useMemo(
		() =>
			account.companies
				.filter((c) => c.id !== selectedCompanyId && c.fein !== "" && c.fein)
				.map((c) => c.fein) as FEIN[],
		[account.companies],
	);
	const defaultFormData =
		selectedCompany !== undefined ? selectedToFormData(selectedCompany) : getDefaultFormData();
	const formMethods = useForm<DefaultAdminCompanyFormData, any, AdminCompanyFormData>({
		defaultValues: defaultFormData,
		resolver: nullEmptyResolver(zodResolver(getCompanyFormSchema(feins))),
		reValidateMode: "onBlur",
	});
	const companyAddressValidation = useAddressValidation<DefaultAdminCompanyFormData>(
		formatAddress(defaultFormData.address),
		formMethods,
		"address",
	);

	const handleSubmit = (formData: AdminCompanyFormData) => {
		upsertCompanyContact(formData);
	};

	useEffect(() => {
		if (upsertCompanyContactSuccess) onClose();
	}, [upsertCompanyContactSuccess]);

	return (
		<Modal onClickOutside={onClose}>
			<ModalItemWithHeader
				header={selectedCompanyId !== undefined ? "Update Company" : "New Company"}
				onClose={onClose}
			>
				<div className="max-w-[820px] min-w-[747px] px-[40px] pt-[25px] pb-[35px]">
					<form className="w-full h-fit flex flex-col space-y-[25px]">
						<FormItemGroup condensed>
							<FormRow className="justify-start gap-x-[40px]">
								<FormItem className="max-w-fit">
									<FormQuestionLabel condensed>Include in underwriting?</FormQuestionLabel>
									<FormYesNo name="includeInUnderwriting" control={formMethods.control} />
								</FormItem>
								<FormItem className="max-w-fit">
									<FormQuestionLabel condensed>Include in indemnity?</FormQuestionLabel>
									<FormYesNo name="includeInIndemnity" control={formMethods.control} />
								</FormItem>
							</FormRow>
							<FormRow>
								<FormItem className="flex-2" condensed>
									<FormItemLabel condensed>Name</FormItemLabel>
									<FormInput name="name" control={formMethods.control} condensed />
								</FormItem>
								<FormItem className="max-w-[200px]" condensed>
									<FormItemLabel condensed>FEIN</FormItemLabel>
									<FormInput name="fein" control={formMethods.control} condensed />
								</FormItem>
							</FormRow>
							<FormItem condensed>
								<FormItemLabel condensed>Address</FormItemLabel>
								<FormAddressAutocomplete
									control={companyAddressValidation.addressFieldControl}
									className="h-[46px] text-[16px]"
								/>
							</FormItem>
						</FormItemGroup>
						<div className="w-full flex justify-center mt-[5px]">
							<Button
								onClick={formMethods.handleSubmit(handleSubmit)}
								color="light-blue"
								filled
								className="w-[150px] h-[36px]"
								tabIndex={2}
								loading={formMethods.formState.isSubmitting || upsertCompanyContactIsLoading}
							>
								Submit
							</Button>
						</div>
					</form>
				</div>
			</ModalItemWithHeader>
		</Modal>
	);
};

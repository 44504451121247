import { Import as Import$1 } from './admin-contract-surety-bond-mixins.dto.mjs';
import { z } from 'zod';
import { Import as Import$2 } from './admin-final-contract-surety-bond.dto.mjs';
import { ContractSuretyType } from '../../../../../enums.mjs';
import { rateSchema, currencySchema } from '../../../../../types/utils.mjs';
import { suretyBondId } from '../../../../../types/opaque.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';

var Import;
(function (Import) {
    (function (Request) {
        Request.schema = Import$1.Request.baseSchema.extend({
            contractSuretyType: z.literal(ContractSuretyType.bid_to_final),
            rate: rateSchema,
            premium: currencySchema,
            bond: Import$1.Request.baseSchema.shape.bond.extend({
                bondCreatedFromId: suretyBondId,
                data: Import$2.Request.schema.shape.bond.shape.data.omit({ project: true })
            }),
            bondForm: Import$1.Request.baseSchema.shape.bondForm.extend({
                data: Import$2.Request.schema.shape.bondForm.shape.data
            })
        });
    })(Import.Request || (Import.Request = {}));
})(Import || (Import = {}));

export { Import };

import { ReactNode } from "react";
import { cn } from "../../../components/lib/utils";

export type AdminDataListProps = {
	data: { label: string; value?: string | ReactNode | null }[];
	className?: string;
	rowClassName?: string;
};

export const AdminDataList = ({ data, className, rowClassName }: AdminDataListProps) => (
	<div
		className={cn(
			"flex flex-col w-full divide-y divide-gray-200 divide-solid text-[14px]",
			className,
		)}
	>
		{data.map((item, index) => (
			<div key={index} className={cn("flex py-[15px] px-[10px] space-x-[10px]", rowClassName)}>
				<div className="text-gray-800 font-medium">{item.label}</div>
				<div className="flex items-center justify-end text-gray-600 flex-1">
					{item.value ?? "--"}
				</div>
			</div>
		))}
	</div>
);

import { ReactNode, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { usePrefetchPreviews } from "../domain/shared/previews/api";
import { AppLoading } from "../views/shared/AppLoading";

type PrefetchProviderProps = {
	children: ReactNode;
};

export const AdminPrefetchProvider = ({ children }: PrefetchProviderProps) => {
	const queryClient = useQueryClient();
	const { prefetchPreviews, prefetchPreviewsIsLoading } = usePrefetchPreviews(queryClient);
	prefetchPreviews();

	const renderCount = useRef(0);
	useEffect(() => {
		renderCount.current = renderCount.current + 1;
	});

	if (!prefetchPreviewsIsLoading) {
		return children;
	} else {
		return <AppLoading />;
	}
};

import { User, useAuth0 } from "@auth0/auth0-react";
import { AgencyId, AuthzId, Email, FirstName, LastName, PhoneNumber } from "@inrev/common";
import * as Sentry from "@sentry/react";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { AppLoading } from "../views/shared/AppLoading";

// metadata key needs to be hardcoded in order to get strict type when decoding the token
const metadataKey = "https://api.getinrev.com/app_metadata";

if (!metadataKey)
	throw new Error(
		"You must specify an Auth0 metadata key in your .env file with the key REACT_APP_AUTH0_METADATA_KEY",
	);

type JwtPayloadExtended = {
	[metadataKey]: {
		agency_id: string;
		agent_id: string;
		roles: [string];
		setup_complete: boolean;
		first_name: string;
		last_name: string;
		email: string;
		phone: string;
	};
} & JwtPayload;

export type AuthenticatedUser = {
	authzId: AuthzId;
	agencyId: AgencyId;
	agentId: string;
	role: string;
	setupComplete: boolean;
	firstName: FirstName;
	lastName: LastName;
	email: Email;
	phone: PhoneNumber;
};

export type JwtPayloadMetadata = {
	agency_id: string;
	agent_id: string;
	roles: Array<string>;
	setup_complete: boolean;
};

export type AuthenticatedUserContext = {
	user: AuthenticatedUser;
	setAuthenticatedUser: (user: AuthenticatedUser) => void;
};

const AuthenticatedUserContext = createContext<AuthenticatedUserContext | null>(null);

export const AppLoadingProvider = (props: { children: ReactNode }) => {
	const {
		isLoading,
		isAuthenticated,
		user: auth0User,
		loginWithRedirect,
		getAccessTokenSilently,
	} = useAuth0();
	const [user, setUser] = useState<AuthenticatedUser | undefined>();

	useEffect(() => {
		if (!!user) {
			Sentry.setUser(user);
			if (user.setupComplete && (user.role === "agent" || user.role === "agent-admin")) {
				pendo.initialize({
					visitor: {
						id: user.agentId,
						email: user.email,
						firstName: user.firstName,
						lastName: user.lastName,
						role: user.role,
					},
					account: {
						id: user.agencyId,
					},
				});
			}
		}
	}, [user]);

	useEffect(() => {
		if (!isLoading) {
			if (isAuthenticated) {
				if (auth0User !== undefined) {
					getAccessTokenSilently().then((token) => {
						setTokenMetadata(token, auth0User);
					});
				}
			} else {
				loginWithRedirect();
			}
		}
	}, [isLoading]);

	const setTokenMetadata = (token: string, auth0User?: User) => {
		const decodedToken = jwtDecode<JwtPayloadExtended>(token);
		const { agency_id, agent_id, roles, setup_complete, first_name, last_name, phone } =
			decodedToken[metadataKey];

		setUser({
			...user,
			authzId: decodedToken.sub as AuthzId,
			agencyId: agency_id as AgencyId,
			agentId: agent_id,
			role: roles[0],
			setupComplete: setup_complete,
			firstName: first_name as FirstName,
			lastName: last_name as LastName,
			email: (auth0User?.email ?? "") as Email,
			phone: phone as PhoneNumber,
		});
	};

	if (isLoading || !isAuthenticated || user === undefined) {
		return <AppLoading />;
	} else {
		return (
			<AuthenticatedUserContext.Provider value={{ user, setAuthenticatedUser: setUser }}>
				{props.children}
			</AuthenticatedUserContext.Provider>
		);
	}
};

export const useAuthenticatedUser = () =>
	useContext(AuthenticatedUserContext) as AuthenticatedUserContext;

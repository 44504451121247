import { useMemo, useState } from "react";
import { HiOutlineEye, HiOutlineEyeSlash } from "react-icons/hi2";
import { cn } from "../lib/utils";
import { Button } from "./Button";

type SensitiveDataProps = {
	value: string;
	className?: string;
	iconClassName?: string;
};

export const SensitiveData = ({ value, className, iconClassName }: SensitiveDataProps) => {
	const [show, setShow] = useState(false);
	const shownValue = useMemo(() => {
		if (show) {
			return value;
		}
		return value.replace(/./g, "*");
	}, [show]);

	return (
		<div className={cn("flex items-center space-x-[5px] h-full", className)}>
			<div className={cn("flex-1", show ? "" : "pt-[5px]")}>{shownValue}</div>
			<Button
				color="gray"
				className="border-0 h-fit px-[5px] py-[5px]"
				onClick={() => setShow(!show)}
			>
				{show ? (
					<HiOutlineEyeSlash className={cn("text-[16px]", iconClassName)} />
				) : (
					<HiOutlineEye className={cn("h-[16px] w-[16px]", iconClassName)} />
				)}
			</Button>
		</div>
	);
};

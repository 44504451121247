import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos, SuretyType, Types } from "@inrev/common";
import { createContext } from "react";
import { FieldPath, FormProvider, Resolver, useForm } from "react-hook-form";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import { Modal } from "../../../../../components/layout/Modal";
import { type Section } from "../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { ShrinkingHeaderSectionSchemaNavLayout } from "../../../../../components/layout/ShrinkingHeaderSectionSchemaNavLayout";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { SpinnerCheck } from "../../../../../components/ui/SpinnerCheck";
import {
	useAwaitedUpdateSuretyAccountDraft,
	useSubmitSuretyAccount,
	useUpdateSuretyAccountDraft,
} from "../../../../../domain/agent/account/api";
import { SuretyAccount } from "../../../../../domain/agent/account/types";
import { submitContractSuretyAccountDtoValidationSchema } from "../../../../../domain/agent/account/validation";
import { stripEmptyResolver, useAutoSave } from "../../../../../utils/form";
import { AccountDraftDetailsSection } from "./sections/AccountDraftDetailsSection";
import { AccountDraftFinancialsSection } from "./sections/AccountDraftFinancialsSection";
import { AccountDraftHistorySection } from "./sections/AccountDraftHistorySection";
import { AccountDraftSummarySection } from "./sections/AccountDraftSummarySection";

type AccountDraftViewProps = {
	account: DraftSuretyAccount;
};
type DraftSuretyAccount = Extract<SuretyAccount, { status: "draft" }>;

const accountDraftFormResolver: Resolver<
	Types.SuretyAccount.Draft.Contract.Data,
	{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema }
> = stripEmptyResolver((values, context, options) => {
	if (!context) throw new Error();
	return zodResolver(submitContractSuretyAccountDtoValidationSchema)(
		values,
		context.draftSchema,
		options,
	);
}, true);

export const SuretyAccountDraftContext = createContext<DraftSuretyAccount>(undefined!);

const fieldPathsToAwait: FieldPath<Types.SuretyAccount.Draft.Contract.Data>[] = [
	"details.exposureSize",
	"details.companies",
	"details.individuals",
];

export const AccountDraftView = ({ account }: AccountDraftViewProps) => {
	const formMethods = useForm<
		Types.SuretyAccount.Draft.Contract.Data,
		{ draftSchema: Types.SuretyAccount.Draft.Contract.Schema },
		Dtos.SuretyAccount.Contract.Submit.Request.New.Data
	>({
		reValidateMode: "onBlur",
		defaultValues: account.draft.data,
		context: { draftSchema: account.draft.schema },
		resolver: accountDraftFormResolver,
	});
	const sectionMap: { [K in keyof Types.SuretyAccount.Draft.Schema]: Section<K> } = {
		details: {
			name: "details",
			navLabel: "Details",
			navError: !!formMethods.formState.errors.details,
			component: <AccountDraftDetailsSection />,
			headerTitle: "Details",
		},
		financials: {
			name: "financials",
			navLabel: "Financials",
			navError: !!formMethods.formState.errors.financials,
			component: <AccountDraftFinancialsSection />,
			headerTitle: "Financials",
		},
		history: {
			name: "history",
			navLabel: "History",
			navError: !!formMethods.formState.errors.history,
			component: <AccountDraftHistorySection />,
			headerTitle: "History",
		},
	};
	const { updateSuretyAccountDraft } = useUpdateSuretyAccountDraft(account.id);
	const { awaitUpdateSuretyAccountDraft, awaitUpdateSuretyAccountDraftIsLoading } =
		useAwaitedUpdateSuretyAccountDraft(account.id);
	const { submitSuretyAccount, submitSuretyAccountIsLoading } = useSubmitSuretyAccount(
		account.id,
		SuretyType.contract,
	);
	useAutoSave(
		updateSuretyAccountDraft,
		awaitUpdateSuretyAccountDraft,
		formMethods,
		account.draft.data,
		account.draft,
		fieldPathsToAwait,
	);

	const onSubmit = (data: Dtos.SuretyAccount.Contract.Submit.Request.New.Data) => {
		submitSuretyAccount(data);
	};

	return (
		<SuretyAccountDraftContext.Provider value={account}>
			<FormProvider {...formMethods}>
				<form
					id="bond_request"
					onSubmit={formMethods.handleSubmit(onSubmit)}
					className="w-full h-full"
				>
					<WorkflowLayout title={"New Account Submission"}>
						<ShrinkingHeaderSectionSchemaNavLayout
							schema={account.draft.schema}
							sectionMap={sectionMap}
							onSubmit={formMethods.handleSubmit(onSubmit)}
							confirmationMessage="Are you sure you want to submit this account?"
							summarySectionComponent={AccountDraftSummarySection}
						/>
					</WorkflowLayout>
				</form>
				{awaitUpdateSuretyAccountDraftIsLoading && <LoadingModal />}
				{submitSuretyAccountIsLoading && (
					<Modal>
						<div
							className={
								"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[20px]"
							}
						>
							<div className="flex items-center space-x-[10px]">
								<SpinnerCheck spinning={true} />
								<div className="flex-1 text-gray-800">Underwriting</div>
							</div>
						</div>
					</Modal>
				)}
			</FormProvider>
		</SuretyAccountDraftContext.Provider>
	);
};

import { z } from 'zod';
import { principalSignerSchema } from '../../../../types/surety/bond-form/issued/contract/issued-contract-surety-bond-form.types.mixins.mjs';
import { Obligee } from '../../../../types/surety/bond/contract/contract-surety-bond.types.mjs';
import { validAddressSchema } from '../../../../types/opaque.mjs';
import '../../../../types/utils.mjs';
import '../../../../types/credit-report/company-credit-report.mjs';
import '../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../types/payment/payment-template.mjs';
import '../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../types/surety/account/surety-account.types.mjs';
import '../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../types/surety/indemnity/indemnity-agreement.mjs';

var Create;
(function (Create) {
    (function (Request) {
        Request.baseSchema = z.object({
            suretyType: z.literal('contract')
        });
        Request.baseDataSchema = z.object({
            obligees: z.array(Obligee.schema.pick({ name: true, role: true }).extend({ address: validAddressSchema })).min(1),
            principalSigner: principalSignerSchema.nullish()
        });
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create };

import { z } from 'zod';
import { ContractSuretyType } from '../../../../../../enums.mjs';
import { Get as Get$1 } from './submitted-contract-surety-quote-preview.dto.mixins.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Get$1.Response.baseSchema(ContractSuretyType.bid_to_final).extend({
            contractSuretyType: z.literal(ContractSuretyType.bid_to_final)
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));

export { Get };

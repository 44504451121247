import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos, FileType } from "@inrev/common";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { ConfirmationModalClickWrapper } from "../../../components/layout/ConfirmationModalClickWrapper";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { FormItem } from "../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../components/layout/form/FormRow";
import { FormSchemaInclude } from "../../../components/layout/form/FormSchemaInclude";
import { FormSection } from "../../../components/layout/form/FormSection";
import { Button } from "../../../components/ui/Button";
import { LocalFileUpload } from "../../../components/ui/LocalFileUpload";
import { NotificationCard } from "../../../components/ui/NotificationCard";
import { FormDatePicker } from "../../../components/ui/form/FormDatePicker";
import { FormDropdown } from "../../../components/ui/form/FormDropdown";
import { FormItemLabel } from "../../../components/ui/form/FormItemLabel";
import { FormYesNo } from "../../../components/ui/form/FormYesNo";
import { useUploadCompletedBondForm } from "../../../domain/admin/request/api";
import {
	AdminBondRequest,
	AdminUploadCompletedBondFormFormData,
} from "../../../domain/admin/request/types";
import { stripEmptyResolver } from "../../../utils/form";

type AdminUploadCompletedBondFormFormProps = {
	request: Extract<AdminBondRequest, { status: "pending" }>;
};

const getDefaultFormData = (
	request: AdminUploadCompletedBondFormFormProps["request"],
): AdminUploadCompletedBondFormFormData => {
	return {
		suretyType: request.suretyType,
		contractSuretyType: request.contractSuretyType,
		bondFormType: request.bondForm.bondFormType,
		properties: {
			effectiveDate: request.bondForm.fillData.effectiveDate ?? DateTime.now().toISODate()!,
		},
		attributes: {
			renewable: false,
			...(() => {
				if (request.contractSuretyType === "bid") {
					return {
						bondFormType: "bid",
						cancellable: false,
						continuous: true,
					};
				}
				return {
					bondFormType: request.bondForm.bondFormType ?? "",
					cancellable: request.bondForm.attributes.cancellable ?? "",
					continuous: request.bondForm.attributes.continuous ?? "",
				};
			})(),
		},
	};
};

const _AdminUploadCompletedBondFormForm = ({ request }: AdminUploadCompletedBondFormFormProps) => {
	const formMethods = useForm<
		AdminUploadCompletedBondFormFormData,
		any,
		Dtos.Admin.SuretyBondForm.Upload.PhysicalIssue.Request
	>({
		defaultValues: getDefaultFormData(request),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(
			zodResolver(Dtos.Admin.SuretyBondForm.Upload.PhysicalIssue.Request.schema),
		),
	});
	const bondFormTypeOptions = useMemo(() => {
		if (request.contractSuretyType === "bid") {
			return [{ label: "Bid", value: "bid" }];
		}
		return [
			{ label: "Performance", value: "performance" },
			{ label: "Payment", value: "payment" },
			{ label: "Performance and Payment", value: "pnp" },
		];
	}, [request.contractSuretyType]);
	const file = formMethods.watch("file");

	const { uploadCompletedBondForm, uploadCompletedBondFormIsLoading } = useUploadCompletedBondForm(
		request.id,
	);

	const handleSubmit = (data: Dtos.Admin.SuretyBondForm.Upload.PhysicalIssue.Request) => {
		uploadCompletedBondForm(data);
	};

	return (
		<form className="flex flex-col space-y-[20px]">
			<NotificationCard type="action" text="Upload completed bond form" />
			<FormItemGroup>
				<FormRow>
					<FormItem condensed>
						<FormItemLabel>Effective Date</FormItemLabel>
						<FormDatePicker
							name="properties.effectiveDate"
							control={formMethods.control}
							condensed
						/>
					</FormItem>
					<FormItem condensed>
						<FormItemLabel>Bond Form Type</FormItemLabel>
						<FormDropdown
							name="bondFormType"
							control={formMethods.control}
							options={bondFormTypeOptions}
							readonly={request.contractSuretyType === "bid"}
							placeholder="Select one"
							condensed
						/>
					</FormItem>
				</FormRow>
				<FormSchemaInclude schemaInclude={request.contractSuretyType !== "bid"}>
					<FormRow className="space-x-[50px]">
						<FormItem condensed className="max-w-fit">
							<FormItemLabel>Continuous</FormItemLabel>
							<FormYesNo name="attributes.continuous" control={formMethods.control} />
						</FormItem>
						<FormItem condensed className="flex-2">
							<FormItemLabel>Cancellable</FormItemLabel>
							<FormYesNo name="attributes.cancellable" control={formMethods.control} />
						</FormItem>
					</FormRow>
				</FormSchemaInclude>
			</FormItemGroup>
			<FormSection header="">
				<FormItemGroup>
					<LocalFileUpload
						value={file ? [file] : []}
						onChange={(files) => formMethods.setValue("file", files[0])}
						allowedTypesAndLabels={{
							[FileType.filled_bond_form]: "Filled Bond Form",
						}}
						allowedExtensions={["pdf"]}
						requiredTypes={[FileType.filled_bond_form]}
						maxTypesPerFile={1}
						maxFiles={1}
						hideChecklist={true}
						showErrors={formMethods.formState.errors.file !== undefined}
					/>
					<ConfirmationModalClickWrapper
						shouldConfirm={true}
						message="Are you sure?"
						onConfirm={formMethods.handleSubmit(handleSubmit)}
					>
						<Button color="light-blue" className="max-w-fit self-center px-[50px]" filled>
							Submit
						</Button>
					</ConfirmationModalClickWrapper>
				</FormItemGroup>
			</FormSection>
			{uploadCompletedBondFormIsLoading && <LoadingModal />}
		</form>
	);
};

export const AdminUploadCompletedBondFormForm = ({
	request,
}: AdminUploadCompletedBondFormFormProps) => {
	if (
		request.bondIssuance.pendingInRevIssue ||
		(request.bondIssuance.pendingBondFormReview &&
			(request.bondForm.issuanceMethod === "physical" ||
				request.bondForm.issuanceMethod === "electronic"))
	) {
		return <_AdminUploadCompletedBondFormForm request={request} />;
	}
	return <></>;
};

import { HiCheck } from "react-icons/hi2";
import { cn } from "../lib/utils";
import { Spinner } from "./Spinner";

type SpinnerCheckProps = {
	spinning: boolean;
	className?: string;
};

export const SpinnerCheck = ({ spinning, className }: SpinnerCheckProps) => (
	<div
		className={cn(
			"flex items-center justify-center rounded-full w-[20px] h-[20px] bg-inrev-green ![&_*]:w-[10px] ![&_*]:h-[10px]",
			className,
		)}
	>
		{spinning && (
			<Spinner
				containerClassName="w-[12px] h-[12px]"
				spinnerClassName="border-solid border-[2px] border-white border-x-transparent border-opacity-[.7] w-[12px] h-[12px]"
			/>
		)}
		{!spinning && <HiCheck className="text-[10px] text-white stroke-[4px]" />}
	</div>
);

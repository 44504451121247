import { createContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { useFetchBondRequest } from "../../../domain/agent/request/api";
import { BondRequest } from "../../../domain/agent/request/types";
import { SubmittedBondRequestLayoutWrapper } from "../../shared/request/SubmittedBondRequestLayoutWrapper";
import { AcceptedBondRequestView } from "./status/accepted/AcceptedBondRequestView";
// import { AcceptedBondRequestView } from "./status/accepted/AcceptedBondRequestView";
import { ApprovedBondRequestView } from "./status/approved/ApprovedBondRequestView";
import { DeclinedBondRequestView } from "./status/declined/DeclinedBondRequestView";
import { DraftBondRequestView } from "./status/draft/DraftBondRequestView";
import { PendingBondRequestView } from "./status/pending/PendingBondRequestView";
import { RejectedBondRequestView } from "./status/rejected/RejectedBondRequestView";
// import { PendingBondRequestView } from "./status/pending/PendingBondRequestView";
import { ReviewBondRequestView } from "./status/review/ReviewBondRequestView";

export const BondRequestContext = createContext<BondRequest>(undefined!);

const getView = <TBondRequest extends BondRequest>(request: TBondRequest) => {
	switch (request.status) {
		case "draft":
			return <DraftBondRequestView request={request} />;
		default:
			return (
				<SubmittedBondRequestLayoutWrapper request={request}>
					{(() => {
						switch (request.status) {
							case "approved":
								return <ApprovedBondRequestView request={request} />;
							case "review":
								return <ReviewBondRequestView request={request} />;
							case "declined":
								return <DeclinedBondRequestView request={request} />;
							case "accepted":
								return <AcceptedBondRequestView request={request} />;
							case "rejected":
								return <RejectedBondRequestView request={request} />;
							case "pending":
								return <PendingBondRequestView request={request} />;
							default:
								return null;
						}
					})()}
				</SubmittedBondRequestLayoutWrapper>
			);
	}
};

export const BondRequestView = () => {
	const { id } = useParams();
	const { bondRequest, bondRequestIsFetching, bondRequestError } = useFetchBondRequest(id!);
	const navigate = useNavigate();

	useEffect(() => {
		if (bondRequest !== undefined && bondRequest.status === "bound") {
			navigate(`/bonds/${bondRequest.bondId}`);
		}
	}, [bondRequest]);

	useEffect(() => {
		if (bondRequestError) {
			navigate("/requests");
		}
	}, [bondRequestError]);

	if (bondRequestIsFetching || bondRequest === undefined) return <LoadingModal />;
	else {
		return (
			<BondRequestContext.Provider value={bondRequest}>
				{getView(bondRequest)}
			</BondRequestContext.Provider>
		);
	}
};

import { ReactNode } from "react";
import { UserMenu } from "../ui/UserMenu";

type IslandLayoutProps = {
	children?: ReactNode;
};

export const IslandLayout = ({ children }: IslandLayoutProps) => {
	return (
		<div className="w-full h-full grid grid-rows-[45px_1fr]">
			<div className="w-full h-[45px] flex items-center justify-end px-[30px]">
				<UserMenu />
			</div>
			<div className="w-full flex-1 flex justify-center overflow-y-auto">{children}</div>
		</div>
	);
};

import { cn } from "../../../../components/lib/utils";
import { NotificationCard } from "../../../../components/ui/NotificationCard";
import { AdminSuretyAccount } from "../../../../domain/admin/account/types";

type AdminAccountNotificationStackProps = {
	account: AdminSuretyAccount;
	className?: string;
};

export const AdminAccountNotificationStack = ({
	account,
	className,
}: AdminAccountNotificationStackProps) => {
	const actionsRequired = (() => {
		const actions = [];
		if (account.status === "review") {
			actions.push("Review terms and set bonding lines");
		}
		if (
			account.contract.indemnity.indemnityAgreements[0]?.status === "pending" &&
			account.contract.indemnity.indemnityAgreements[0]?.signatureMethod === "physical"
		) {
			actions.push("Upload a copy of the signed indemnity agreement");
		}
		return actions;
	})();

	const pendingItems = (() => {
		const pendingItems: string[] = [];
		if (account.contract.indemnity.indemnityAgreements[0]?.status === "pending") {
			pendingItems.push("Waiting for one or more indemnitors to sign the indemnity agreement");
		}
		return pendingItems;
	})();

	return actionsRequired.length > 0 || pendingItems.length > 0 ? (
		<div className={cn("w-full flex flex-col space-y-[10px] mb-[-5px]", className)}>
			{actionsRequired.map((action, index) => (
				<NotificationCard key={index} type="action" text={action} />
			))}
			{pendingItems.map((pendingItem, index) => (
				<NotificationCard key={index} type="pending" text={pendingItem} />
			))}
		</div>
	) : (
		<></>
	);
};

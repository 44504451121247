import { useAuth0 } from "@auth0/auth0-react";
import { Transition } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Dtos, USStateOrTerritory } from "@inrev/common";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import inrevLogoDark from "../../../assets/logos/inrev-logo-dark.svg";
import { FormItem } from "../../../components/layout/form/FormItem";
import { FormItemGroup } from "../../../components/layout/form/FormItemGroup";
import { FormRow } from "../../../components/layout/form/FormRow";
import { Button } from "../../../components/ui/Button";
import { Card } from "../../../components/ui/Card";
import { FormDropdown } from "../../../components/ui/form/FormDropdown";
import { FormInput } from "../../../components/ui/form/FormInput";
import { FormItemLabel } from "../../../components/ui/form/FormItemLabel";
import { FormPhoneNumberInput } from "../../../components/ui/form/FormPhoneNumberInput";
import { usStateOrTerritoryOptions } from "../../../constants";
import { useCompleteAgentUserAccountSetup } from "../../../domain/agent/user/api";
import { useAuthenticatedUser } from "../../../providers/AppLoadingProvider";
import { stripEmptyResolver } from "../../../utils/form";

type CompleteAgentAccountSetupFormData = {
	firstName: string | "";
	lastName: string | "";
	phone: string | "";
	npn: string | "";
	residentLicenseState: USStateOrTerritory | "";
};

const getDefaultFormData = (): CompleteAgentAccountSetupFormData => ({
	firstName: "",
	lastName: "",
	phone: "",
	npn: "",
	residentLicenseState: "",
});

export const CompleteAccountSetupView = () => {
	const navigate = useNavigate();
	const { user, setAuthenticatedUser } = useAuthenticatedUser();
	const { completeAgentUserAccountSetup, completeAgentUserAccountSetupIsLoading } =
		useCompleteAgentUserAccountSetup();
	const { getAccessTokenSilently } = useAuth0();
	const formMethods = useForm<
		CompleteAgentAccountSetupFormData,
		any,
		Dtos.Agent.CompleteAccountSetup.Request
	>({
		defaultValues: getDefaultFormData(),
		reValidateMode: "onBlur",
		resolver: stripEmptyResolver(zodResolver(Dtos.Agent.CompleteAccountSetup.Request.schema)),
	});
	const [submittedData, setSubmittedData] =
		useState<Dtos.Agent.CompleteAccountSetup.Request | null>(null);

	useEffect(() => {
		navigate("/setup");
	}, []);

	const handleSubmit = (data: Dtos.Agent.CompleteAccountSetup.Request) => {
		completeAgentUserAccountSetup(data, {
			onSuccess: () => {
				setSubmittedData(data);
				getAccessTokenSilently({ cacheMode: "off" });
			},
		});
	};

	const handleGoToAgentPortal = () => {
		if (!!user && !!submittedData) {
			setAuthenticatedUser({
				...user,
				...submittedData,
				setupComplete: true,
			});
		}
	};

	return (
		<div className="w-screen h-screen overflow-y-auto bg-gray-50/25">
			<div className="w-full h-full flex flex-col gap-[5vh]">
				<div className="mx-[40px] my-[30px]">
					<img src={inrevLogoDark} className="top-[30px] left-[40px] h-[35px]" />
				</div>
				<div className="w-full flex-1 flex justify-center p-[40px] pt-0">
					{!!!submittedData && (
						<Transition
							appear={true}
							show={true}
							as={Fragment}
							enter="transition-all ease-in duration-[425ms]"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition ease-in duration-[100ms]"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full max-w-[600px] h-fit flex flex-col gap-[12px]">
								<div className="text-[28px] font-bold text-inrev-blue/90">
									Welcome to the inRev Agent Portal
								</div>
								<div className="text-[16px] text-gray-600">
									Please provide the following information so we can finish setting up your account
								</div>
								<Card className="p-[26px] pt-[20px] border-gray-200 shadow-sm mt-[18px]">
									<form onSubmit={formMethods.handleSubmit(handleSubmit)}>
										<FormItemGroup>
											<FormRow>
												<FormItem condensed className="flex-1">
													<FormItemLabel condensed>First Name</FormItemLabel>
													<FormInput
														control={formMethods.control}
														name="firstName"
														condensed
														readonly={completeAgentUserAccountSetupIsLoading}
													/>
												</FormItem>
												<FormItem condensed className="flex-1">
													<FormItemLabel condensed>Last Name</FormItemLabel>
													<FormInput
														control={formMethods.control}
														name="lastName"
														condensed
														readonly={completeAgentUserAccountSetupIsLoading}
													/>
												</FormItem>
												<FormItem condensed className="flex-[1]">
													<FormItemLabel condensed>Phone</FormItemLabel>
													<FormPhoneNumberInput
														control={formMethods.control}
														name="phone"
														condensed
														readonly={completeAgentUserAccountSetupIsLoading}
													/>
												</FormItem>
											</FormRow>
											<FormRow>
												<FormItem condensed>
													<FormItemLabel condensed>National Producer Number (NPN)</FormItemLabel>
													<FormInput
														control={formMethods.control}
														name="npn"
														condensed
														readonly={completeAgentUserAccountSetupIsLoading}
													/>
												</FormItem>
												<FormItem condensed>
													<FormItemLabel condensed>Resident License State</FormItemLabel>
													<FormDropdown
														control={formMethods.control}
														name={"residentLicenseState"}
														options={usStateOrTerritoryOptions}
														placeholder="Select a state"
														optionsClassName="max-h-[230px] overflow-y-auto"
														condensed
														readonly={completeAgentUserAccountSetupIsLoading}
													/>
												</FormItem>
											</FormRow>
										</FormItemGroup>
										<div className="w-full flex justify-end mt-[30px]">
											<Button
												type="submit"
												color="light-blue"
												filled
												loading={completeAgentUserAccountSetupIsLoading}
											>
												Create My Account
											</Button>
										</div>
									</form>
								</Card>
							</div>
						</Transition>
					)}
					{!!submittedData && (
						<Transition
							appear={true}
							show={true}
							as={Fragment}
							enter="transition-all ease-in duration-[425ms]"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition ease-in duration-[100ms]"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="w-full max-w-[600px] h-fit flex flex-col items-center gap-[40px]">
								<div className="text-[28px] font-bold text-inrev-blue/90">
									Account Setup Complete
								</div>
								<HiOutlineCheckCircle className="text-inrev-green/80 text-[64px] stroke-[1.5]" />
								<Button color="light-blue" filled onClick={handleGoToAgentPortal}>
									Go to Agent Portal
								</Button>
							</div>
						</Transition>
					)}
				</div>
			</div>
		</div>
	);
};

import { ReactNode, UIEvent, createContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "../lib/utils";
import { Button, ButtonProps } from "../ui/Button";
import { ConfirmationModalClickWrapper } from "./ConfirmationModalClickWrapper";
import { ShrinkingHeader } from "./ShrinkingHeader";

export type Section<TName extends string = string> = {
	name: TName;
	navLabel: string;
	navError: boolean;
	component: ReactNode;
	headerTitle: string;
};

type ShrinkingHeaderSectionNavLayoutCoreProps = {
	currentSection: Section;
	currentSectionIndex: number;
	sections: Section[];
	onSubmit: () => void;
	confirmationMessage?: string;
};

export const ShrinkingHeaderSectionContext = createContext<{
	setHeaderButton: (button: { content?: ReactNode; props?: ButtonProps } | undefined) => void;
}>(undefined!);

const ShrinkingHeaderSectionNavLayoutCore = ({
	currentSection,
	currentSectionIndex,
	sections,
	onSubmit,
	confirmationMessage,
}: ShrinkingHeaderSectionNavLayoutCoreProps) => {
	const [_searchParams, setSearchParams] = useSearchParams();
	const [shrunk, setShrunk] = useState<boolean>(false);
	const [headerButton, setHeaderButton] = useState<
		{ content?: ReactNode; props?: ButtonProps } | undefined
	>();

	const handleScroll = (event: UIEvent<HTMLDivElement>) => {
		const newScrollTop = event.currentTarget.scrollTop;
		if (newScrollTop >= 25) {
			if (!shrunk) setShrunk(true);
		} else if (newScrollTop === 0 && shrunk) setShrunk(false);
	};

	const handleSectionClick = (name: string) => {
		if (name !== currentSection.name) setSearchParams({ section: name });
	};

	const handleNextButtonClick = () => {
		setSearchParams({ section: sections[currentSectionIndex + 1].name });
	};

	const handleBackButtonClick = () => {
		setSearchParams({ section: sections[currentSectionIndex - 1].name });
	};

	const showNextButton = () => currentSectionIndex !== sections.length - 1;
	const showBackButton = () => currentSectionIndex !== 0;
	const showSubmitButton = () => currentSectionIndex === sections.length - 1;

	return (
		<ShrinkingHeaderSectionContext.Provider value={{ setHeaderButton }}>
			<div
				onScroll={handleScroll}
				className={`transition-all ease-linear overflow-y-auto w-full grid grid-cols-[1fr_minmax(min-content,_710px)_minmax(50px,_75px)_130px_1fr] px-[65px]`}
				style={{ gridTemplateRows: `${shrunk ? 55 : 85}px 1fr` }}
			>
				<div className="row-span-2"></div>
				<ShrinkingHeader shrunk={shrunk} title={currentSection.headerTitle} button={headerButton} />
				<div className="row-span-2"></div>
				<div className="row-span-2 sticky top-0 left-0 pt-[30px] flex flex-col space-y-[15px]">
					<div className="w-full bg-gray-50 rounded-[6px] p-[8px] min-h-fit">
						{sections.map((section) => (
							<div
								key={section.name}
								className={cn(
									"w-full flex items-center cursor-pointer hover:bg-gray-100 text-[14px] h-[34px] px-[10px] select-none",
									section.name === currentSection.name
										? "bg-gray-100 text-gray-900 font-medium"
										: "text-gray-600 font-normal",
									section.navError ? "text-red-500" : undefined,
								)}
								onClick={() => handleSectionClick(section.name)}
							>
								{section.navLabel}
							</div>
						))}
					</div>
					<div className="flex flex-col w-full space-y-[5px] min-h-fit">
						{showNextButton() && (
							<Button
								tabIndex={2}
								color="light-blue"
								filled
								onClick={() => handleNextButtonClick()}
							>
								Next
							</Button>
						)}
						{showSubmitButton() && (
							<>
								{!!confirmationMessage ? (
									<ConfirmationModalClickWrapper
										message="Are you sure you want to submit?"
										confirmButtonText="Submit"
										onConfirm={() => onSubmit()}
									>
										<Button tabIndex={2} color="green" filled>
											Submit
										</Button>
									</ConfirmationModalClickWrapper>
								) : (
									<Button tabIndex={2} color="green" type="submit" filled>
										Submit
									</Button>
								)}
							</>
						)}
						{showBackButton() && (
							<Button
								tabIndex={2}
								color="gray"
								filled
								className="hover:brightness-100"
								onClick={() => handleBackButtonClick()}
							>
								Back
							</Button>
						)}
					</div>
				</div>
				<div className="row-span-2"></div>
				<div className="w-full h-full px-[15px]">{currentSection.component}</div>
			</div>
		</ShrinkingHeaderSectionContext.Provider>
	);
};

export type ShrinkingHeaderSectionNavLayoutProps = Omit<
	ShrinkingHeaderSectionNavLayoutCoreProps,
	"currentSection" | "currentSectionIndex"
> & {
	sections: Section[];
};

export const ShrinkingHeaderSectionNavLayout = (props: ShrinkingHeaderSectionNavLayoutProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const currentSectionIndex = useMemo(
		() => props.sections.findIndex((section) => section.name === searchParams.get("section")),
		[searchParams],
	);
	const [currentSection, setCurrentSection] = useState<Section | null>(null);

	useEffect(() => {
		const newSection = props.sections[currentSectionIndex];
		if (!newSection) setSearchParams({ section: props.sections[0].name }, { replace: true });
		else setCurrentSection(newSection);
	}, [currentSectionIndex]);

	if (!currentSection) return <></>;
	return (
		<ShrinkingHeaderSectionNavLayoutCore
			{...props}
			currentSection={currentSection}
			currentSectionIndex={currentSectionIndex}
			key={currentSection.name}
		/>
	);
};

import { Get as Get$1, Import as Import$1 } from './admin-contract-surety-bond-mixins.dto.mjs';
import { Underwriting } from '../../../../../types/surety/quote/submitted/contract/submitted-contract-surety-quote.types.mixins.mjs';
import { z } from 'zod';
import { ContractSuretyType, dateTimeSchema, finalContractSuretyBondFormTypeSchema, bidSuretyBondClosedReasonSchema, SuretyBondFormType, bidBondAmountTypeSchema } from '../../../../../enums.mjs';
import { currencySchema, rateSchema } from '../../../../../types/utils.mjs';
import { Close as Close$1 } from '../../../../../types/surety/bond/contract/bid-contract-surety-bond.types.mjs';
import { Get as Get$2 } from '../../../../surety/bond/contract/bid-contract-surety-bond.dto.mjs';
import { Get as Get$3 } from '../../bond-form/admin-issued-surety-bond-form.dto.mjs';

var Get;
(function (Get) {
    (function (Response) {
        Response.schema = Get$1.Response.baseSchema.extend(Get$2.Response.rawSchema.shape)
            .extend({
            bondForm: Get$3.Response.schema,
        });
    })(Get.Response || (Get.Response = {}));
})(Get || (Get = {}));
var Import;
(function (Import) {
    (function (Request) {
        Request.schema = Import$1.Request.baseSchema.extend({
            contractSuretyType: z.literal(ContractSuretyType.bid),
            bond: Import$1.Request.baseSchema.shape.bond.extend({
                data: z.object({
                    bidAmount: currencySchema,
                    bidDate: dateTimeSchema,
                    finalBondFormType: finalContractSuretyBondFormTypeSchema,
                    finalBondContingentRate: rateSchema,
                    finalBondContingentPremium: currencySchema,
                    closedReason: bidSuretyBondClosedReasonSchema.nullish(),
                    tListingRequired: z.boolean(),
                    isFinalBondCompletionBond: z.boolean(),
                    project: Underwriting.Project.Input.schema
                })
            }),
            bondForm: Import$1.Request.baseSchema.shape.bondForm.extend({
                data: z.object({
                    bondFormType: z.literal(SuretyBondFormType.bid),
                    bidBondAmountType: bidBondAmountTypeSchema,
                    bondAmountPercentOfBid: z.coerce.number().min(0).multipleOf(0.001),
                })
            })
        });
    })(Import.Request || (Import.Request = {}));
})(Import || (Import = {}));
var Close;
(function (Close) {
    (function (Request) {
        Request.schema = Close$1.Payload.schema;
    })(Close.Request || (Close.Request = {}));
})(Close || (Close = {}));

export { Close, Get, Import };

import { ReactNode } from "react";
import { useIsMutating } from "react-query";
import { LoadingModal } from "../components/layout/LoadingModal";

type GlobalLoadingProviderProps = {
	children: ReactNode;
};

export const GlobalLoadingProvider = ({ children }: GlobalLoadingProviderProps) => {
	const createBondRequestIsLoading = useIsMutating({
		mutationKey: "createBondRequest",
		exact: true,
	});
	const createSuretyAccountIsLoading = useIsMutating({
		mutationKey: "createSuretyAccount",
		exact: true,
	});

	return (
		<>
			{children}
			{createBondRequestIsLoading | createSuretyAccountIsLoading ? <LoadingModal /> : <></>}
		</>
	);
};

import { ReactNode, forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { cn } from "../lib/utils";
import { Checkbox, CheckboxProps } from "./Checkbox";

type CheckboxOptionProps = CheckboxProps & {
	label?: ReactNode;
	labelClassName?: string;
	error?: FieldError;
	readonly?: boolean;
	compact?: true;
	tooltip?: ReactNode;
};

export const CheckboxOption = forwardRef<HTMLButtonElement, CheckboxOptionProps>(
	({ label, labelClassName, tooltip, ...props }: CheckboxOptionProps) => (
		<div
			className={cn(
				"group flex items-center h-[29px] space-x-[14px] cursor-pointer",
				props.compact ? "space-x-[11px]" : undefined,
			)}
			onClick={() => props.onChange && props.onChange(!props.checked)}
		>
			<Checkbox {...props} />
			{label !== undefined && (
				<span
					className={cn(
						"text-[15px] font-normal text-gray-900 leading-[19px]",
						props.compact ? "text-[13px]" : undefined,
						labelClassName,
					)}
				>
					{label} {tooltip}
				</span>
			)}
		</div>
	),
);

import { RadioGroup, RadioGroupProps } from "@headlessui/react";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "../layout/form/FormError";
import { cn } from "../lib/utils";
import { RadioCardItem } from "./RadioCardItem";

export type YesNoProps = RadioGroupProps<"div", boolean | ""> & {
	error?: FieldError;
	errorMessage?: string;
	readonly?: boolean;
	condensed?: true;
};

export const YesNo = forwardRef<HTMLDivElement, YesNoProps>(
	({ error, readonly, condensed, ...props }, _ref) => (
		<RadioGroup
			{...props}
			onChange={(value) => {
				props.onChange && props.onChange(value);
				props.onBlur && props.onBlur(undefined!);
			}}
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					e.stopPropagation();
				}
			}}
			className={cn(
				"relative w-fit flex space-x-[10px] rounded-md",
				condensed ? "max-h-[46px]" : undefined,
				error
					? `outline outline-red-500 ${condensed ? "outline-offset-[5px]" : "outline-offset-[6px]"}`
					: undefined,
			)}
			disabled={readonly}
		>
			<RadioCardItem
				value={true}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.stopPropagation();
					}
				}}
				className="w-fit px-[15px] py-[10px] space-x-[12px] border-gray-200 h-fit leading-[20px] select-none"
				readonly={readonly}
			>
				Yes
			</RadioCardItem>
			<RadioCardItem
				value={false}
				className="w-fit px-[15px] py-[10px] space-x-[12px] border-gray-200 h-fit leading-[20px] select-none"
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.stopPropagation();
					}
				}}
				readonly={readonly}
			>
				No
			</RadioCardItem>
			<FormError
				error={error}
				errorMessage={props.errorMessage}
				className={cn("left-[-10px] top-auto", condensed ? "bottom-[-15px]" : "bottom-[-19px]")}
			/>
		</RadioGroup>
	),
);

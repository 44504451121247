import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingModal } from "../../../components/layout/LoadingModal";
import { useFetchAdminBondRequest } from "../../../domain/admin/request/api";
import { AdminBondRequestLayout } from "../shared/AdminBondRequestLayout";

export const AdminBondRequestView = () => {
	const { id } = useParams();
	const { bondRequest, bondRequestLoading, bondRequestError } = useFetchAdminBondRequest(id!);
	const navigate = useNavigate();

	useEffect(() => {
		if (bondRequest !== undefined && bondRequest.status === "bound") {
			navigate(`/bonds/${bondRequest.bondId}`);
		}
	}, [bondRequest]);

	useEffect(() => {
		if (bondRequestError) {
			navigate("/requests");
		}
	}, [bondRequestError]);

	if (bondRequestLoading || bondRequest === undefined) return <LoadingModal />;
	return <AdminBondRequestLayout request={bondRequest} />;
};

import { z } from 'zod';
import { nameSuffixSchema } from '../../../../../enums.mjs';
import { firstName, lastName, validAddressSchema, email, decryptedSSN } from '../../../../../types/opaque.mjs';
import '../../../../../types/utils.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';

var Create;
(function (Create) {
    (function (Request) {
        const individualSchema = z.object({
            firstName: firstName,
            middleInitial: z.string().toUpperCase().max(1).nullish(),
            lastName: lastName,
            suffix: nameSuffixSchema.nullish(),
            address: validAddressSchema,
            email: email.nullish(),
            ssn: decryptedSSN.nullish(),
            married: z.boolean(),
            ownsAHome: z.boolean(),
            includeInUnderwriting: z.boolean(),
            includeInIndemnity: z.boolean(),
        });
        Request.schema = individualSchema.extend({
            spouse: individualSchema.omit({ married: true }).nullish()
        });
    })(Create.Request || (Create.Request = {}));
})(Create || (Create = {}));

export { Create };

import { zodResolver } from "@hookform/resolvers/zod";
import {
	CompanyContactId,
	ContractSuretyBondFormType,
	ContractSuretyType,
	Dtos,
	SuretyAccountId,
	stripEmpty,
} from "@inrev/common";
import { createContext, useEffect, useMemo, useState } from "react";
import { FieldPath, FormProvider, Resolver, useForm } from "react-hook-form";
import { LoadingModal } from "../../../../../components/layout/LoadingModal";
import { Modal } from "../../../../../components/layout/Modal";
import { type Section } from "../../../../../components/layout/ShrinkingHeaderSectionNavLayout";
import { ShrinkingHeaderSectionSchemaNavLayout } from "../../../../../components/layout/ShrinkingHeaderSectionSchemaNavLayout";
import { WorkflowLayout } from "../../../../../components/layout/WorkflowLayout";
import { SpinnerCheck } from "../../../../../components/ui/SpinnerCheck";
import { PrincipalPreview, SuretyAccountPreview } from "../../../../../domain/agent/account/types";
import {
	useAwaitedUpdateBondRequestDraft,
	useFetchBondFormTemplates,
	useSubmitBondRequest,
	useUpdateBondRequestDraft,
} from "../../../../../domain/agent/request/api";
import {
	BondFormTemplate,
	BondRequest,
	BondRequestDraftData,
	BondRequestDraftSchema,
} from "../../../../../domain/agent/request/types";
import { getBondRequestDataValidationSchema } from "../../../../../domain/agent/request/validation";
import { useFetchAccountPreviews } from "../../../../../domain/shared/previews/api";
import { formatAddress } from "../../../../../utils";
import { stripEmptyResolver, useAutoSave } from "../../../../../utils/form";
import { DraftBidToFinalBondRequestView } from "./bid-to-final/DraftBidToFinalBondRequestView";
import { bondFormTypesAndLabels } from "./constants";
import { DraftBondRequestBondSection } from "./sections/DraftBondRequestBondSection";
import { DraftBondRequestFinancialsSection } from "./sections/DraftBondRequestFinancialsSection";
import { DraftBondRequestHistorySection } from "./sections/DraftBondRequestHistorySection";
import { DraftBondRequestPrincipalSection } from "./sections/DraftBondRequestPrincipalSection";
import { DraftBondRequestSummarySection } from "./sections/DraftBondRequestSummarySection";

type DraftBondRequestViewProps = {
	request: DraftBondRequest;
};
type DraftBondRequest = BondRequest & Extract<BondRequest, { status: "draft" }>;
export type DraftBondRequestSectionName = keyof BondRequestDraftSchema;

const draftBondRequestFormResolver: Resolver<
	BondRequestDraftData,
	{ draftSchema: BondRequestDraftSchema }
> = (values, context, options) => {
	if (!context) throw new Error();
	return zodResolver(getBondRequestDataValidationSchema(context.draftSchema))(
		values,
		context.draftSchema,
		options,
	);
};

const getPrincipalPreviewsMapFromAccountPreviews = (
	accountPreviews: SuretyAccountPreview[],
): Record<CompanyContactId, PrincipalPreview> => {
	const principalPreviewsMap: Record<CompanyContactId, PrincipalPreview> = {};
	accountPreviews
		.filter((a) => a.status !== "draft")
		.forEach((account) => {
			const searchValues: string[] = [
				...account.companies.flatMap((company) => [company.name, formatAddress(company.address)]),
				...account.individuals.map((individual) => individual.name),
			];
			if (account.displayName) searchValues.push(account.displayName);
			account.companies.forEach((company) => {
				principalPreviewsMap[company.id] = {
					...company,
					companyContactId: company.id,
					accountId: account.id,
					accountDisplayName: account.displayName,
					searchValues,
				};
			});
		});
	return principalPreviewsMap;
};

export const DraftBondRequestContext = createContext<{
	request: DraftBondRequest;
	principalPreviewsMap: Record<CompanyContactId, PrincipalPreview>;
	bondFormTemplates: BondFormTemplate[];
}>(undefined!);

const getFieldPathsToAwait = (
	bondType: ContractSuretyType | "",
): FieldPath<BondRequestDraftData>[] => {
	if (bondType === ContractSuretyType.bid_to_final) return ["bond.actualBidAmount"];
	return [
		"principal.newPrincipal",
		"principal.principalAccountId",
		"principal.exposureSize",
		"principal.companies",
		"principal.individuals",
		"bond.type",
		"bond.bondAmount",
		"bond.bidAmount",
		"bond.bondAmountPercentOfBid",
		"financials.statementDate",
		"financials.preparationMethod",
	];
};

export const getBondFormTemplates = (
	templates: BondFormTemplate[],
	bondType?: ContractSuretyType,
) => {
	if (bondType === ContractSuretyType.final || bondType === ContractSuretyType.bid_to_final) {
		return templates.filter((t) => ["payment", "performance", "pnp"].includes(t.type));
	}
	if (bondType === ContractSuretyType.bid) {
		return templates.filter((t) => t.type === ContractSuretyBondFormType.bid);
	}
	return [];
};

export const DraftBondRequestView = ({ request }: DraftBondRequestViewProps) => {
	const { accountPreviews } = useFetchAccountPreviews();
	const principalPreviewsMap = useMemo(() => {
		return getPrincipalPreviewsMapFromAccountPreviews(accountPreviews || []);
	}, [accountPreviews]);
	const formMethods = useForm<
		BondRequestDraftData,
		{ draftSchema: BondRequestDraftSchema },
		Dtos.SuretyQuote.Submit.Request["data"]
	>({
		reValidateMode: "onBlur",
		defaultValues: request.draft.data,
		context: { draftSchema: request.draft.schema },
		resolver: stripEmptyResolver(draftBondRequestFormResolver),
	});
	const newPrincipal = formMethods.watch("principal.newPrincipal");
	const newPrincipalName = formMethods.watch("principal.company.name");
	const principalAccountId = formMethods.watch("principal.principalAccountId");
	const principalCompanyId = formMethods.watch("principal.principalCompanyId");
	const bondType = formMethods.watch("bond.type");
	const bondFormTemplates = useFetchBondFormTemplates();
	const filteredBondFormTemplates = useMemo(() => {
		return getBondFormTemplates(bondFormTemplates, stripEmpty(bondType));
	}, [bondFormTemplates, bondType]);
	const [principalName, setPrincipalName] = useState<string | undefined>(undefined);
	const sectionMap: { [K in keyof BondRequestDraftSchema]: Section<K> } = {
		principal: {
			name: "principal",
			navLabel: "Principal",
			navError: !!formMethods.formState.errors.principal,
			component: <DraftBondRequestPrincipalSection />,
			headerTitle: "Principal",
		},
		financials: {
			name: "financials",
			navLabel: "Financials",
			navError: !!formMethods.formState.errors.financials,
			component: <DraftBondRequestFinancialsSection />,
			headerTitle: "Financials",
		},
		history: {
			name: "history",
			navLabel: "History",
			navError: !!formMethods.formState.errors.history,
			component: <DraftBondRequestHistorySection />,
			headerTitle: "History",
		},
		bond: {
			name: "bond",
			navLabel: "Bond",
			navError: !!formMethods.formState.errors.bond,
			component: <DraftBondRequestBondSection />,
			headerTitle: "Bond",
		},
	};
	const { updateBondRequestDraft } = useUpdateBondRequestDraft(request.id);
	const { awaitUpdateBondRequestDraft, awaitUpdateBondRequestDraftIsLoading } =
		useAwaitedUpdateBondRequestDraft(request.id);
	const { submitBondRequest, submitBondRequestIsLoading } = useSubmitBondRequest(request.id);
	const fieldPathsToAwait = useMemo(
		() => getFieldPathsToAwait(request.draft.data.bond.type),
		[request.draft.data.bond.type],
	);

	useAutoSave(
		updateBondRequestDraft,
		awaitUpdateBondRequestDraft,
		formMethods,
		request.draft.data,
		request.draft,
		fieldPathsToAwait,
	);

	useEffect(() => {
		if (Object.keys(principalPreviewsMap).length > 0) {
			if (principalCompanyId === "") {
				if (!newPrincipal && principalName !== undefined) {
					setPrincipalName(undefined);
				}
				if (principalAccountId !== "") {
					formMethods.setValue("principal.principalAccountId", "", { shouldDirty: true });
				}
			} else if (principalAccountId === "" && accountPreviews) {
				const principalPreview = principalPreviewsMap[principalCompanyId];
				if (!principalPreview)
					throw new Error(
						`Could not find principal preview for principal with id ${principalCompanyId}`,
					);
				const accountId = principalPreview.accountId;
				if (!accountId)
					throw new Error(`Could not find account for principal with id ${principalCompanyId}`);
				formMethods.setValue("principal.principalAccountId", accountId as SuretyAccountId);
				setPrincipalName(principalPreview.name);
			}
		}
	}, [principalCompanyId, accountPreviews]);

	useEffect(() => {
		if (newPrincipal) {
			setPrincipalName(stripEmpty(newPrincipalName));
		}
	}, [newPrincipalName]);

	useEffect(() => {
		if (newPrincipal) {
			setPrincipalName(stripEmpty(newPrincipalName));
		} else if (
			stripEmpty(principalCompanyId) &&
			principalPreviewsMap[principalCompanyId as CompanyContactId] !== undefined
		) {
			setPrincipalName(principalPreviewsMap[principalCompanyId as CompanyContactId].name);
		} else {
			setPrincipalName(undefined);
		}
	}, [newPrincipal, principalCompanyId]);

	const onSubmit = (data: Dtos.SuretyQuote.Submit.Request["data"]) => {
		submitBondRequest({ data, draftData: formMethods.getValues() });
	};

	if (!accountPreviews) return <LoadingModal />;
	return (
		<DraftBondRequestContext.Provider
			value={{ request, principalPreviewsMap, bondFormTemplates: filteredBondFormTemplates }}
		>
			<FormProvider {...formMethods}>
				<form id="bond_request" className="w-full h-full">
					{request.draft.data.bond.type === ContractSuretyType.bid_to_final && (
						<DraftBidToFinalBondRequestView
							request={request}
							bondFormTypesAndLabels={bondFormTypesAndLabels}
							bondFormTemplates={filteredBondFormTemplates}
							principalName={principalName}
							onSubmit={formMethods.handleSubmit(onSubmit)}
						/>
					)}
					{request.draft.data.bond.type !== ContractSuretyType.bid_to_final && (
						<WorkflowLayout
							title={
								<div className="flex items-center space-x-[8px]">
									<span>
										{`${bondType === ContractSuretyType.bid ? "Bid " : bondType === ContractSuretyType.final ? "Final " : ""}`}
										Bond Request
									</span>
									{principalName !== undefined ? (
										<>
											<span className="text-gray-300">|</span>
											<span className="text-gray-500 text-[13px]">{principalName}</span>
										</>
									) : (
										<></>
									)}
								</div>
							}
						>
							<ShrinkingHeaderSectionSchemaNavLayout
								schema={request.draft.schema}
								sectionMap={sectionMap}
								summarySectionComponent={DraftBondRequestSummarySection}
								onSubmit={formMethods.handleSubmit(onSubmit)}
								confirmationMessage="Are you sure you want to submit?"
							/>
						</WorkflowLayout>
					)}
				</form>
				{awaitUpdateBondRequestDraftIsLoading && <LoadingModal />}
				{submitBondRequestIsLoading && (
					<Modal>
						<div
							className={
								"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[20px]"
							}
						>
							<div className="flex items-center space-x-[10px]">
								<SpinnerCheck spinning={submitBondRequestIsLoading} />
								<div className="flex-1 text-gray-800">Underwriting</div>
							</div>
						</div>
					</Modal>
				)}
			</FormProvider>
		</DraftBondRequestContext.Provider>
	);
};

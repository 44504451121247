import {
	AttachmentFile,
	AttachmentFileType,
	FileExtension,
	FileType,
	SuretyBondFormType,
	UploadedFile,
} from "@inrev/common";

export enum NameSuffix {
	JR = "JR",
	III = "III",
	IV = "IV",
	V = "V",
}

export type PendingAttachment<TAllowedFileTypes extends AttachmentFileType = AttachmentFileType> =
	AttachmentFile<TAllowedFileTypes>;

// removes the admin flag from being able to be set by the client
export type UploadFile<
	TAllowedFileType extends FileType = FileType,
	TAllowedExtension extends FileExtension = FileExtension,
> = Omit<UploadedFile<TAllowedFileType, TAllowedExtension>, "typesConfirmedByAdmin">;

export type BondFormData = {
	templateId: string | "";
	upload: {
		type: SuretyBondFormType | "";
		files: UploadFile<typeof FileType.blank_bond_form>[];
	};
};

export type TimePeriod = {
	days?: number;
	weeks?: number;
	months?: number;
	years?: number;
};

export type DataTableActionButtonMap = {
	delete?: (id: string) => void;
	archive?: (id: string) => void;
};

export type RequiredDeep<T extends Record<string, unknown>> = {
	[K in keyof T]-?: T[K] extends Record<string, any>[]
		? RequiredDeep<T[K][number]>[]
		: T[K] extends Record<string, any>
			? RequiredDeep<T[K]>
			: T[K];
};

type Join<K, P> = K extends string | number
	? P extends string | number
		? `${K}${"" extends P ? "" : "."}${P}`
		: never
	: never;

type Prev = [
	never,
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	...0[],
];

export type Paths<T, D extends number = 10> = [D] extends [never]
	? never
	: T extends object
		? {
				[K in keyof T]-?: K extends string | number
					? `${K}` | (Paths<T[K], Prev[D]> extends infer R ? Join<K, R> : never)
					: never;
			}[keyof T]
		: "";

export type LocallyUploadedFile<
	TAllowedFileType extends FileType = FileType,
	TAllowedExtension extends FileExtension = FileExtension,
> = Omit<
	UploadedFile<TAllowedFileType, TAllowedExtension> & {
		base64: string;
	},
	"typesConfirmedByAdmin"
>;

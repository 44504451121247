import { MainNav } from "../../../components/ui/MainNav";

export const AdminMainNav = () => (
	<MainNav
		label="Admin"
		items={{
			dashboard: false,
			requests: true,
			bonds: true,
			accounts: true,
		}}
	/>
);

import { Controller, FieldValues } from "react-hook-form";
import { FormSchemaInclude, FormSchemaIncludeProps } from "../../layout/form/FormSchemaInclude";
import { YesNoLineItem } from "../YesNoLineItem";
import { FormInputProps } from "./FormInput";

type FormYesNoLineItemProps<TFieldValues extends FieldValues> = FormInputProps<TFieldValues> &
	FormSchemaIncludeProps & {
		label: string;
		marker?: boolean;
		readonly?: boolean;
		className?: string;
		id?: string;
		tooltip?: string;
	};

export const FormYesNoLineItem = <TFieldValues extends FieldValues>({
	name,
	control,
	...props
}: FormYesNoLineItemProps<TFieldValues>) => (
	<FormSchemaInclude schemaInclude={"schemaInclude" in props ? props.schemaInclude : true}>
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<YesNoLineItem
					{...field}
					{...props}
					error={fieldState.error}
					errorMessage={props.errorMessage}
				/>
			)}
		/>
	</FormSchemaInclude>
);

import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { NumberInput } from "../NumberInput";

type FormNumberInputProps<TFieldValues extends FieldValues> = {
	className?: string;
	control: Control<TFieldValues, any, any>;
	name: Path<TFieldValues>;
	errorMessage?: string;
	precision?: number;
	min?: number;
	max?: number;
	maxLength?: number;
	condensed?: true;
	updateOnBlur?: true;
	readonly?: boolean;
};

export const FormNumberInput = <TFieldValues extends FieldValues>({
	condensed,
	updateOnBlur,
	className,
	readonly,
	...props
}: FormNumberInputProps<TFieldValues>) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				const { value, ...fieldProps } = field;
				return (
					<NumberInput
						{...props}
						{...fieldProps}
						defaultValue={value}
						precision={props.precision}
						min={props.min}
						max={props.max}
						condensed={condensed}
						className={className}
						error={fieldState.error}
						readonly={readonly}
					/>
				);
			}}
		/>
	);
};

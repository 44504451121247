import { AgencyId } from "@inrev/common";
import { useState } from "react";
import { HiOutlineUsers } from "react-icons/hi2";
import {
	FollowerControlsProps,
	ManageFollowersModal,
} from "../../../components/ui/FollowerControls";
import { useAdminFetchAgentPreviewsForAgency } from "../../../domain/admin/agent/api";

export type AdminFollowerControlsProps = Omit<
	FollowerControlsProps,
	"notFollowingLabel" | "useFetchAgentPreviews"
> & {
	agencyId: AgencyId;
};

export const AdminFollowerControls = (props: AdminFollowerControlsProps) => {
	const [manageFollowersModalOpen, setManageFollowersModalOpen] = useState<boolean>(false);

	return (
		<div className="flex items-center space-x-[8px] pt-[6px]">
			<span className="text-gray-700 text-[12px] font-[550]">Agent Followers</span>
			{!!props.followers && (
				<div
					className="flex items-center justify-center w-fit h-[24px] min-h-[24px] space-x-[5px] px-[10px] text-[12px] font-[550] text-gray-700 border border-gray-400 bg-white hover:text-gray-800 hover:border-gray-600 rounded-full cursor-pointer"
					onClick={() => setManageFollowersModalOpen(true)}
				>
					<span>{props.followers.length}</span>
					<HiOutlineUsers className="text-[12.5px] text-gray-600 stroke-[1.75]" />
				</div>
			)}
			{manageFollowersModalOpen && (
				<ManageFollowersModal
					{...props}
					onClose={() => setManageFollowersModalOpen(false)}
					useFetchAgentPreviews={() => useAdminFetchAgentPreviewsForAgency(props.agencyId)}
				/>
			)}
		</div>
	);
};

import { ReactElement, ReactNode } from "react";
import {
	HiOutlineBolt,
	HiOutlineCheckCircle,
	HiOutlinePencil,
	HiOutlineXCircle,
} from "react-icons/hi2";
import { SuretyAccount } from "../../domain/agent/account/types";
import { SuretyBond } from "../../domain/agent/bond/types";
import { BondRequest } from "../../domain/agent/request/types";
import { cn } from "../lib/utils";
import { Icon } from "./Icon";

type StatusTagCoreProps = {
	textColor: keyof typeof textColorMap;
	bgColor?: keyof typeof bgColorMap;
	borderColor?: keyof typeof borderMap;
	icon?: ReactNode;
	label: string;
	className?: string;
};

const textColorMap = {
	green: "text-inrev-green",
	"light-blue": "text-inrev-light-blue",
	gray: "text-gray-700",
	"light-gray": "text-gray-500",
	gold: "text-inrev-gold",
	red: "text-red-500",
};

const bgColorMap = {
	green: "bg-inrev-green bg-opacity-[0.08] px-[8px] py-[5px] rounded-[3px]",
	yellow: "bg-inrev-yellow bg-opacity-[0.08] px-[8px] py-[5px] rounded-[3px]",
	red: "bg-red-500 bg-opacity-[0.08] px-[8px] py-[5px] rounded-[3px]",
};

const borderMap = {
	gray: "border border-gray-700 rounded-[3px] px-[8px] py-[5px]",
	red: "border border-red-500 px-[8px] py-[5px] rounded-[3px]",
	green: "border border-inrev-green px-[8px] py-[5px] rounded-[3px]",
	gold: "border border-inrev-gold px-[8px] py-[5px] rounded-[3px]",
	"light-blue": "border border-inrev-light-blue px-[8px] py-[5px] rounded-[3px]",
};

const StatusTagCore = ({
	textColor,
	bgColor,
	borderColor,
	icon,
	label,
	className,
}: StatusTagCoreProps) => {
	return (
		<div
			className={cn(
				`flex items-center gap-[7px] w-fit h-fit min-h-fit text-[13px] font-semibold leading-[15px] select-none shrink-0 text-nowrap`,
				textColorMap[textColor],
				bgColor ? bgColorMap[bgColor] : undefined,
				borderColor ? borderMap[borderColor] : undefined,
				className,
			)}
		>
			{icon ?? <></>}
			<span>{label}</span>
		</div>
	);
};

export const ReviewStatusCard = () => (
	<StatusTagCore
		textColor="gold"
		bgColor="yellow"
		borderColor="gold"
		label="Underwriter Review"
		className="overflow-visible shadow-inrev-dark-yellow/25"
	/>
);
export const DraftStatusTag = () => (
	<StatusTagCore
		textColor="gray"
		icon={<HiOutlinePencil className="text-[14px]" />}
		label="Draft"
		className="space-x-[8px]"
	/>
);
export const ApprovedStatusTag = () => (
	<StatusTagCore textColor="green" borderColor="green" label="Approved" />
);
export const DeclinedStatusTag = () => (
	<StatusTagCore textColor="red" borderColor="red" label="Declined" />
);
export const AcceptedStatusTag = () => (
	<StatusTagCore
		textColor="green"
		borderColor="green"
		icon={<HiOutlineCheckCircle className="fill-white text-[16px] mx-[-1px]" />}
		label="Terms Accepted"
	/>
);
export const RejectedStatusTag = () => (
	<StatusTagCore
		textColor="red"
		borderColor="red"
		icon={<HiOutlineXCircle type="close" className="fill-white text-[16px] mx-[-1px]" />}
		label="Terms Rejected"
	/>
);
export const OpenStatusTag = () => (
	<StatusTagCore
		textColor="light-blue"
		icon={
			<Icon type="shield" className="fill-inrev-light-blue stroke-[1]" width={13} height={17} />
		}
		label="Open"
	/>
);
export const ClosedStatusTag = () => (
	<StatusTagCore
		textColor="light-gray"
		icon={<Icon type="shield-slash" className="stroke-[1] h-[15px]" />}
		label="Closed"
	/>
);
export const ActiveStatusTag = () => (
	<StatusTagCore
		textColor="light-blue"
		borderColor="light-blue"
		className="gap-x-[5px]"
		icon={<HiOutlineBolt className="fill-white text-[14px]" />}
		label="Active"
	/>
);
export const PendingStatusTag = () => (
	<StatusTagCore
		textColor="gray"
		borderColor="gray"
		icon={
			<Icon type="stopwatch" className="h-[14px] stroke-gray-700 stroke-[1] overflow-visible" />
		}
		label="Pending"
	/>
);

const statusMap: Partial<
	Record<
		BondRequest["status"] | SuretyBond["status"] | SuretyAccount["status"],
		ReactElement<StatusTagCoreProps>
	>
> = {
	draft: <DraftStatusTag />,
	approved: <ApprovedStatusTag />,
	review: <ReviewStatusCard />,
	declined: <DeclinedStatusTag />,
	accepted: <AcceptedStatusTag />,
	rejected: <RejectedStatusTag />,
	open: <OpenStatusTag />,
	closed: <ClosedStatusTag />,
	active: <ActiveStatusTag />,
	pending: <PendingStatusTag />,
};

export const getStatusTagByStatus = (status: keyof typeof statusMap) => statusMap[status] ?? null;

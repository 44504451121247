import {
	ContractDamageDescriptions,
	ContractHazardDescriptions,
	ContractSuretyType,
	defaultAttachmentTypeLabelMap,
	formatToDollar,
	formatYesNo,
} from "@inrev/common";
import { ReactNode, useState } from "react";
import { AttachmentsSection } from "../../../components/layout/AttachmentsSection";
import { CommentsSection } from "../../../components/layout/CommentsSection";
import { Button } from "../../../components/ui/Button";
import { AdminBondRequest } from "../../../domain/admin/request/types";
import { useComments } from "../../../domain/shared/comments/api";
import { useFollowers } from "../../../domain/shared/followers/api";
import { BondRequestCardLayout } from "../../shared/request/BondRequestCardLayout";
import { AdminSubmittedBondRequestLayoutWrapper } from "../../shared/request/SubmittedBondRequestLayoutWrapper";
import { AdminBondRequestBidResultsCard } from "./AdminBondRequestBidResultsCard";
import { AdminBondRequestBondFormCard } from "./AdminBondRequestBondFormCard";
import { AdminBondRequestCardHeader } from "./AdminBondRequestCardHeader";
import { AdminBondRequestNotificationStack } from "./AdminBondRequestNotificationStack";
import { AdminBondRequestTerms } from "./AdminBondRequestTerms";
import { AdminChangeBondRequestTermsModal } from "./AdminChangeBondRequestTermsModal";
import { AdminDataListProps } from "./AdminDataList";
import { AdminFollowerControls } from "./AdminFollowerControls";
import { AdminProjectCard } from "./AdminProjectCard";
import { AdminReviewReasonList } from "./AdminReviewReasonList";
import { AdminUpdateBondRequestForm } from "./AdminUpdateBondRequestForm";
import { NAICSCodesList } from "./NAICSCodeList";

type AdminBondRequestLayoutProps = {
	request: AdminBondRequest;
	children?: ReactNode;
};

export const AdminBondRequestLayout = ({ request, children }: AdminBondRequestLayoutProps) => {
	const { comments, commentsLoading, createComment, createCommentLoading } = useComments(
		"quotes",
		request.id,
		true,
	);
	const [attachments, setAttachments] = useState(request.attachments);
	const [isChangeTermsModalOpen, setIsChangeTermsModalOpen] = useState(false);
	const followersControl = useFollowers("quotes", request.id, true);

	return (
		<AdminSubmittedBondRequestLayoutWrapper request={request}>
			<AdminBondRequestNotificationStack request={request} />
			<BondRequestCardLayout>
				<AdminBondRequestCardHeader
					request={request}
					actionButtons={
						request.status === "approved" || request.status === "declined" ? (
							<div className="flex flex-col space-y-[5px]">
								<Button color="gray" thinFont onClick={() => setIsChangeTermsModalOpen(true)}>
									Change Terms
								</Button>
							</div>
						) : undefined
					}
					followersControl={followersControl}
				/>
				<div className="flex flex-col space-y-[25px]">
					<div className="px-[40px] pt-[5px] pb-[40px]">
						<AdminReviewReasonList reviewReasons={request.reviewReasons} />
					</div>
					{request.status !== "review" && request.status !== "declined" && (
						<AdminBondRequestTerms request={request} />
					)}
					{request.status === "review" && (
						<AdminUpdateBondRequestForm request={request} className="!mt-[-15px]" />
					)}
				</div>
			</BondRequestCardLayout>
			{children}
			<AdminBondRequestBondFormCard request={request} />
			{request.contractSuretyType === ContractSuretyType.bid_to_final && (
				<AdminBondRequestBidResultsCard request={request} />
			)}
			<AdminProjectCard
				projectDescription={request.underwriting.project.input.description}
				data={(() => {
					const data: AdminDataListProps["data"] = [];

					if (request.contractSuretyType === "bid") {
						data.push({ label: "Bid Amount", value: formatToDollar(request.bidAmount) });
					}
					data.push({ label: "Bond Amount", value: formatToDollar(request.bondAmount) });
					if (request.contractSuretyType === "final") {
						data.push({ label: "Contract Amount", value: formatToDollar(request.contractAmount) });
					}
					data.push({
						label: "Location",
						value: `${request.underwriting.project.input.address.city}, ${request.underwriting.project.input.address.state}`,
					});
					data.push({
						label: "Completion Bond",
						value: formatYesNo(request.underwriting.quote.input.completionBond),
					});
					data.push({
						label: "Months to Complete",
						value: request.underwriting.project.input.monthsToComplete.toString(),
					});
					data.push({
						label: "Contract Warranty Months",
						value: request.underwriting.project.input.contractWarrantyMonths.toString(),
					});
					data.push({
						label: "Scope of Work",
						value: (
							<NAICSCodesList
								naicsCodes={request.underwriting.project.input.scopeOfWork}
								showRatio
								className="min-w-[400px] max-w-[400px]"
							/>
						),
					});
					data.push({
						label: "Contract Hazards",
						value: request.underwriting.project.input.contractHazards.length ? (
							<div className="w-fit flex flex-col space-y-[2px] text-[13px] text-right">
								{request.underwriting.project.input.contractHazards.map((hazard, index) => (
									<span
										key={index}
									>{`${ContractHazardDescriptions[hazard][0].toLocaleUpperCase()}${ContractHazardDescriptions[hazard].slice(1)}`}</span>
								))}
							</div>
						) : null,
					});
					data.push({
						label: "Contract Damages",
						value: request.underwriting.project.input.contractDamages.length ? (
							<div className="w-fit flex flex-col space-y-[2px] text-[13px] text-right">
								{request.underwriting.project.input.contractDamages.map((damage, index) => (
									<span
										key={index}
									>{`${ContractDamageDescriptions[damage][0].toLocaleUpperCase()}${ContractDamageDescriptions[damage].slice(1)}`}</span>
								))}
							</div>
						) : null,
					});

					return data;
				})()}
			/>
			<CommentsSection
				comments={comments}
				commentsLoading={commentsLoading}
				createComment={createComment}
				createCommentLoading={createCommentLoading}
				commentDownloadHeader={`${request.displayTitle} Request For ${request.principal.name}`}
				headerEnd={
					<AdminFollowerControls
						{...followersControl}
						manageFollowersSubtext="Followers will receive email notifications for all comments and changes to the status of this bond request"
						agencyId={request.agencyId}
					/>
				}
			/>
			<AttachmentsSection
				allowTypeConfirmation={true}
				downloadAllName={`${request.account.displayName} ${request.displayTitle} Attachments`}
				upload={{
					url: `/v2/admin/surety/quotes/${request.id}/attachments`,
					onChange: setAttachments,
					allowedTypesAndLabels: defaultAttachmentTypeLabelMap,
					invalidateQueryKeys: [
						["adminBondRequests", request.id],
						["adminSuretyAccounts", request.account.id],
					],
				}}
				download={{
					baseUrl: `/v2/admin/surety/quotes/${request.id}/attachments`,
					baseQueryKey: "adminBondRequestAttachments",
				}}
				attachments={attachments}
				typeLabelMap={defaultAttachmentTypeLabelMap}
			/>
			{isChangeTermsModalOpen && (
				<AdminChangeBondRequestTermsModal
					request={request}
					onClose={() => setIsChangeTermsModalOpen(false)}
				/>
			)}
		</AdminSubmittedBondRequestLayoutWrapper>
	);
};

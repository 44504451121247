import { z } from 'zod';
import { SuretyType, contractSuretyTypeSchema, SuretyQuoteStatus } from '../../../../../enums.mjs';
import { carrierName } from '../../../../../types/opaque.mjs';
import { percentSchema } from '../../../../../types/utils.mjs';
import '../../../../../types/credit-report/company-credit-report.mjs';
import '../../../../../types/credit-report/individual-credit-report.mjs';
import '../../../../../types/payment/payment-template.mjs';
import '../../../../../types/agency/carrier-agency-relationship.types.mjs';
import '../../../../../types/surety/account/surety-account.types.mjs';
import '../../../../../types/surety/quote/draft/contract/draft-contract-surety-quote.types.mjs';
import '../../../../../types/surety/quote/submitted/submitted-surety-quote.types.mjs';
import '../../../../../types/surety/bond-form/submitted/submitted-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond-form/issued/issued-surety-bond-form.types.mjs';
import '../../../../../types/surety/bond/surety-bond.types.mjs';
import '../../../../../types/surety/financial-statement/surety-financial-statement.mjs';
import '../../../../../types/surety/indemnity/indemnity-agreement.mjs';

var UpdateStatus;
(function (UpdateStatus) {
    (function (Request) {
        Request.baseSchema = z.object({
            suretyType: z.literal(SuretyType.contract),
            contractSuretyType: contractSuretyTypeSchema,
            reviewNote: z.string().nullish()
        });
        Request.baseApproveSchema = Request.baseSchema.extend({
            status: z.literal(SuretyQuoteStatus.approved),
            agencyCommissionPercentage: percentSchema,
            carrierName: carrierName,
        });
    })(UpdateStatus.Request || (UpdateStatus.Request = {}));
})(UpdateStatus || (UpdateStatus = {}));

export { UpdateStatus };
